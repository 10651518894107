.tinker-toggle
  img
    margin: 0 0.5rem 0 0
    height: 16px

  .close-tinker-icon
    height: 16px

  .blx-toggle
    display: inline-flex
    vertical-align: middle

  .blx-toggle-text
    display: flex
    width: 2.5rem

.tinker-link-broken img
  vertical-align: text-bottom

.is-tinker-text-field
  width: 6rem
  margin-right: 0.5rem
  display: flex

.display-quantity-and-size-field
  display: flex
  flex-direction: column
  width: 100%
  background-color: #fff

.display-tinker-field
  display: inline-flex
  vertical-align: middle

.scenario-editor-row
  .is-me-tinker-section
    display: flex
    align-items: center
    width: 16rem

  .is-collab-resource-tinker-section
    width: 16rem
    display: flex
    align-items: center

    .is-tinker-text-field
      margin-right: 0
      width: 5rem

    .blx-toggle
      margin-left: 0.5rem
    
    &.flex-start-imp
      align-items: flex-start !important

.template-editor-page-content .is-resource-tinker-section
    width: 18rem
    display: flex

.template-delete-btn
    position: absolute 
    right: 10px

