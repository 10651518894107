.population-table
  @extend %outlined-box

.current-state-panel
  .columns
    width: 100%
  .scenario-editor-total-row
    border-bottom: 0px !important
    margin-top: initial
  .scenario-editor-header-row .column:first-child,
  .scenario-editor-total-row .column:first-child
    margin-left: $blx-text-field-padding
  .panel
    margin-top: 4rem
  .panel-title
    font-size: 16px
  .panel-body
    border: initial
    .space-analysis-values-container
      &:not(:last-child)
        border-bottom-left-radius: initial
        border-bottom-right-radius: initial
      &:not(:first-child)
        border-top: initial
        border-top-left-radius: initial
        border-top-right-radius: initial
  li
    width: 130px
    white-space: nowrap
    overflow: hidden
    text-overflow: ellipsis
  .blx-horizontal-tabs
    margin-top: .5rem
  button.blx-icon-back,
  button.blx-icon-forward
    margin-top: 1rem
    margin-left: -.25rem
  .add-button
    .blx-button.blx-secondary
      padding: .75rem
  .location-tab-and-delete
    .blx-tab-item
      margin-left: .75rem
      white-space: nowrap
      overflow: hidden
      text-overflow: ellipsis
    .delete-location-button
      visibility: hidden
    &:hover
      background-color: $light-00
      border-radius: $base-border-radius
      .delete-location-button
        visibility: visible
        z-index: 1
        margin-top: .5rem
      input[type="radio"]:hover + .blx-tab-item::after
        background-color: transparent
      .blx-tab-item
        color: $dark-00

.current-state-panel-name
  color: $light-02
  margin-top: -2rem

// in the scenario list, scenarios have a left spacing due to sortable list left icon.
// adding this left margin to current state panel to match that spacing.
.current-state-scenario-panel
  margin-left: 3.6rem
  .blx-tag
    margin-top: 0.5rem
    font-size: 12px

.edit-current-state-button
  margin: 0.5rem 0 0 1rem

.upload-space-analysis-button
  margin: 3rem 5rem

.population-header-title
  text-transform: uppercase
  font-weight: $weight-normal

.population-total-row
  font-weight: $medium-weight

.current-state-create-text
  color: $grey-new
  white-space: pre
  text-align: center
  margin: 10% 0

.flex.flex-col.flex-align-items-center
  .blx-button.blx-primary
    margin-bottom: 20%

.single-mult-toggle
  padding: 1rem 0
  .blx-toggle
    margin-top: 1.5rem
    margin-left: 3rem

.add-population-button
  .blx-button.blx-secondary
    margin-bottom: 0
    margin-top: 0

.population-display-border
  border-bottom: 1px solid $grey-lighter

.columns.is-marginless.population-display-border
  line-height: 10px

.current-state-panel
  padding: 1rem

  h5
    font-weight: $weight-normal

.space-analysis-values-container
  border: 1px solid $grey-lighter
  border-radius: 7px
  padding: 20px
  .space-analysis-label
    white-space: nowrap
    font-size: 0.9rem
    color: $light-02
  .space-analysis-value
    font-weight: $weight-medium
    font-size: 1.15rem

.space-analysis-source-link-wrapper
  font-weight: $weight-normal
  margin-top: 1.5rem
  margin-bottom: unset

.space-analysis-source-link
  font-size: inherit
  font-weight: inherit
  text-decoration: underline
  overflow-wrap: break-word

.space-analysis-updated-at
  margin-top: unset
  margin-bottom: 1.5rem

.space-analysis-input-container
  .blx-text-field
    width: 100%
    min-width: 100px
    margin-bottom: 0
    input[type="number"]
      &::-webkit-inner-spin-button,
      &::-webkit-outer-spin-button
        -webkit-appearance: none
    .blx-ui-text,
    .blx-invalid-input-message
      white-space: nowrap
    .blx-text-field-suffix
      color: $light-02
      right: 20px
  .usf-to-seats
    margin-left: 3rem
    white-space: nowrap
    margin-top: 0.25rem
  hr
    background-color: $grey-lighter
    margin-left: -0.5rem
  .blx-toggle.blx-with-margin
    margin-left: 0
    width: 150%
  .column
    .flex.flex-col
      margin-left: 5rem

.open-percentage-input
  position: relative

.error-asterisk
  color: $danger-02

.sapce-analysis-divider
  position: relative
  padding-left: 1px
  width: 0
  background-color: #BAC2BE
  margin: 2rem 1rem

.sapce-analysis-divider-text
  position: absolute
  top: 50%
  background-color: $white
  right: -8px
  color: #BAC2BE
