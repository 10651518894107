.shared-modal {
  .MuiBackdrop-root {
    background-color: rgba($white, 75%);
  }
  .MuiDialog-paper {
    background-color: $white;
    box-shadow: 0px 3px 8px 0px rgba($black, 20%);
    border-top: 4px solid $primary;
    @media print {
      opacity: 0;
    }
  }
  .input-container {
    margin-top: 10px;
    .share-container {
      display: flex;
      align-items: center;
      .share-link {
        width: 75%;
      }
      .delete-link {
        width: 25%;
        padding-left: 10px;
      }
    }
    .MuiOutlinedInput-root {
      padding: 4px;
      border-radius: 4px;
    }
  }
  .note-text {
    font-size: $size-7;
    color: $grey-blur;
    margin: 5px 0px;
  }
  .export-container{
    margin: 10px 0;
    .export-btn{
        margin-top: 10px;
        .spinner {
          width: 20px !important;
          height: 20px !important;
          margin-left: 5px;
          color: #ffffff;
        }
    }
  }
}
