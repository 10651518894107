// https://github.com/Wikiki/bulma-tooltip/blob/master/src/extension.sass

$tooltip-background-color: $grey-dark !default
$tooltip-max-width: 24rem !default

.tooltip
  position: relative
  padding: 0 0.25rem

  &.has-icon
    display: inline-flex

  &:hover,
  &.is-tooltip-active
    &:not(.is-loading)
      &::after
        z-index: $tooltip-z
        position: absolute
        display: inline-block
        pointer-events: none
    &::before
      z-index: $tooltip-z
      position: absolute
      display: inline-block
      pointer-events: none
    &:not(.is-loading)
      &::after
        content: ""
        border-style: solid
        border-width: .5rem
    &::before
      opacity: 0
      content: attr(data-tooltip)
      overflow: hidden
      white-space: nowrap
      text-overflow: ellipsis
      font-size: $size-7
      padding: .4rem .8rem
      transform: translate(-50%, 1rem)
      background: $tooltip-background-color
      border-radius: $radius
      color: $white
      max-width: $tooltip-max-width

  &:focus,
  &:hover,
  &.is-tooltip-active
    &:not(.is-loading)
      &::after
        top: 0
        bottom: auto
        left: 50%
        opacity: 1
        margin-left: -.5rem
        margin-top: -.5rem
        border-color: $tooltip-background-color transparent transparent transparent
    &::before
      top: auto
      bottom: 100%
      left: 50%
      opacity: 1
      transform: translate(-50%, -0.5rem)

  &.is-tooltip-right
    &::before
      top: auto
      bottom: 50%
      left: 100%
      right: auto
      transform: translate(-1rem, 50%)
    &:focus,
    &:hover,
    &.is-tooltip-active
      &:not(.is-loading)
        &::after
          top: 50%
          left: 100%
          right: auto
          border-color: transparent $tooltip-background-color transparent transparent
      &::before
        transform: translate(0.5rem, 50%)

  &.is-tooltip-bottom
    &::before
      top: 100%
      bottom: auto
      left: 50%
      right: auto
      transform: translate(-50%, -1rem)
    &:focus,
    &:hover,
    &.is-tooltip-active
      &:not(.is-loading)
        &::after
          top: 100%
          bottom: auto
          left: 50%
          right: auto
          border-color: transparent transparent $tooltip-background-color transparent
      &::before
        transform: translate(-50%, 0.5rem)

  &.is-tooltip-left
    &::before
      top: auto
      bottom: 50%
      left: auto
      right: 100%
      transform: translate(1rem, 50%)
    &:focus,
    &:hover,
    &.is-tooltip-active
      &:not(.is-loading)
        &::after
          top: 50%
          left: auto
          right: calc(100% - .5rem)
          border-color: transparent transparent transparent $tooltip-background-color
      &::before
        transform: translate(-0.5rem, 50%)

  &.is-tooltip-multiline
    &::before
      min-width: $tooltip-max-width
      text-overflow: clip
      white-space: normal
      word-break: keep-all

  @each $name, $pair in $colors
    $color: nth($pair, 1)
    $color-invert: nth($pair, 2)
    &.is-tooltip-#{$name}
      &:not(.is-loading)
        &::after
          border-color: $color transparent transparent transparent
      &.is-tooltip-right
        &:not(.is-loading)
          &::after
            border-color: transparent $color transparent transparent
      &.is-tooltip-bottom
        &:not(.is-loading)
          &::after
            border-color: transparent transparent $color transparent
      &.is-tooltip-left
        &:not(.is-loading)
          &::after
            border-color: transparent transparent transparent $color
      &::before
        background: $color
        color: $color-invert