.scenario-editor-row
  display: flex
  align-items: flex-start
  justify-content: space-between
  padding: 0.7rem 1rem
  border-bottom: 1px solid $light-01
  break-inside: avoid

  .is-thin
    width: 2.5rem
    text-align: center

  .tinker-link,.tinker-link-broken
    display: inline-flex
    height: 40px
    align-items: center

  .tinker-icon
    display: inline-block
    height: 24px
    width: 24px
    background: url(/public/svg/icons/spacer-pro-link-icon.svg) no-repeat center
  
  .tinker-broken-icon
    display: inline-block
    height: 24px
    width: 24px
    background: url(/public/svg/icons/spacer-pro-link-icon-broken.svg) no-repeat center
    
  .is-name-field
    width: 20rem
    .blx-icon-drag
     background-image: url(/public/svg/icons/drag-drop.svg)
    .blx-icon
     height: 18px 

  .is-planning-year-dropdown
    width: 9rem

  .blx-dropdown
    min-width: auto

  .is-growth-type-dropdown
    width: 13rem
    .blx-dropdown-wrapper,
    .blx-dropdown
      min-width: 100%

  .is-text-field-without-label-width
    width: 4rem

  .is-text-field-with-label-width
    width: 7rem

  .is-text-field-without-label-width
    .total-border
      width: 100%
    .is-text-field-with-label-width
      width: auto

  .is-text-field-with-label-width,
  .is-text-field-without-label-width
    .blx-text-field
      .blx-text-field-container input
        padding: 11px 2px 11px 8px


  .is-toggle-width
    width: 8rem

  .is-sample-toggle-width
    width: 18rem

  .is-slider-width
    width: 15rem

  .is-collaboration-ratio-width
    width: 10rem

  .is-calculated-total-display-width
    width: 15rem

  .blx-invalid-input-message
    position: initial

  &:not(.scenario-editor-total-row)
    .grey-info-text
      margin-left: 1rem

  .collaboration-row-ratio
    .blx-text-field
      .blx-text-field-prefix,
      .blx-text-field-suffix
        color: $light-02

      .blx-text-field-prefix ~ input
        padding-left: 35px

      .blx-text-field-prefix,
      .blx-text-field-suffix
        font-size: 14px

      .blx-text-field-prefix
        left: 20px

      .blx-text-field-suffix
        right: 20px

      input
        padding-bottom: 10px
        &::-webkit-inner-spin-button
          display: none

.scenario-editor-row:hover .delete-row-button
  visibility: visible

.scenario-editor-row:hover
  background-color: var(--secondary-01, #ecf5f1)


.scenario-editor-row:hover .delete-template-row-button
  visibility: visible

.scenario-editor-row:hover .blx-icon-drag
  visibility: visible
  cursor: grab

.scenario-editor-row:hover .tinker-icon
  display: inline-block
  height: 24px
  width: 24px
  background: url(/public/svg/icons/spacer-pro-link-icon-hover.svg) no-repeat center

.scenario-editor-row:hover .tinker-broken-icon
  display: inline-block
  height: 24px
  width: 24px
  background: url(/public/svg/icons/spacer-pro-link-icon-broken-hover.svg) no-repeat center

.scenario-editor-header-row
  border-bottom: 1px solid $light-01
  background-color: $light-00
  margin-top: 0
  margin-bottom: 0
  padding-top: 1rem
  align-items: flex-end

  .tooltip::before
    text-transform: none
    font-weight: $weight-normal

.scenario-editor-row.scenario-editor-header-row:hover
  background-color: var(--light-00, #f6f8f6)

.scenario-editor-total-row
  font-weight: $weight-medium
  padding-bottom: 1rem
  align-items: center

// -- reset width of blx text fields
.space-program-row .blx-text-field,
.scenario-editor-row .blx-text-field
  margin: 0
  width: 100%
  min-width: 65px

.delete-row-button
  visibility: hidden
  margin-left: 3px

.delete-template-row-button
  margin-right: 0.5rem
  visibility: hidden
  input
    margin-bottom: -0.25rem
    
.blx-icon-drag
  visibility: hidden

.negative-margin
  margin-left: -0.5rem

.row-toggle
  .blx-toggle
    margin: 0
    label
       line-height: inherit
    .blx-toggle-container
      font-size: 12px
      input:focus ~ .blx-toggle-text
        padding: 9px

.hidden-slider-text .slider-wrapper .slider-text
  visibility: hidden
  height: 0


.allocation-slider .slider-wrapper .slider-custom
  -webkit-appearance: none
  -webkit-user-select: none
  -webkit-transition: .2s
  border-radius: 20px
  cursor: pointer
  &:focus
    outline: none
  &::-webkit-slider-runnable-track
    width: 100%
    height: 5px
  &::-webkit-slider-thumb
    -webkit-appearance: none
    width: 20px
    height: 20px
    background: $primary-00-hex
    border-radius: 10px
    margin-top: -0.5rem

.slider-custom
  width: 10rem

.allocation-percentage
  margin-left: 0.5rem
  justify-content: left

  .blx-text-field
    min-width: 48px
    .blx-text-field-container input
      padding: 11px 2px 11px 8px

  .percent-symbol
    margin-left: 0.25rem

.total-border
  padding: 0.5rem 1rem
  border: solid 1px $light-02
  border-radius: $base-border-radius
  display: inline-flex
  align-items: center
  vertical-align: middle
  justify-content: space-between

.sf-label
  margin-left: 0.3rem
  white-space: pre

.scenario-editor-total-row .is-slider-width
  text-align: end

.delete-location-button
  position: absolute
  margin-left: 0.3rem
  .blx-button
    .blx-icon
      margin-top: -0.7rem
      left: 3px

.location-editor-row
  align-items: baseline
  width: 100%
  position: relative

.location-name-text-field-container
  margin-left: 1.8rem
  .blx-text-field
    min-width: auto
    margin-right: 0

.size-field
  input.blx-invalid
    border: 1px solid $danger
  &.tooltip::before
      min-width: 16rem
      max-width: 16rem

.add-row-button
  margin-left: 1.7rem

#growth-section .is-text-field-without-label-width
  width: 7rem

.growth-rate-input.blx-text-field
  input
    padding-right: 45px

.temporary-resource-row
  .scenario-editor-row
    justify-content: initial

    .is-toggle-width
      margin-left: 2.75rem

.dropdown-row-name-field
  width: 95%

  .blx-dropdown-custom-trigger .blx-text-field
      max-width: initial
      width: 260px

  .blx-dropdown-item:last-child
    button
      color: $light-02-hex

.temp-name-field
  .blx-text-field
    max-width: initial
    width: 20rem

#growth-section .is-text-field-with-label-width,
.space-program-summary-table .is-text-field-with-label-width
  .blx-text-field
    .blx-text-field-container input
      padding: 11px 8px
  .blx-text-field:hover,
  .blx-text-field:focus-within
    .blx-text-field-suffix
      visibility: hidden

#growth-section
  .scenario-editor-total-row
    margin-top: 0

.scenario-options
  display: flex
  position: absolute
  top: 32px
  right: 0
  align-items: center

.scenario-positive-margin
  width: 240px
  padding-left: 20px

.temporary-resource-row .is-name-field .scenario-positive-margin .temp-name-field .blx-text-field
  width: 240px

.temporary-shared-focus-row .is-name-field .scenario-positive-margin .temp-name-field .blx-text-field
  width: 240px