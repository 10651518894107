.blx-alert
  position: fixed
  left: 15%
  top: $navbar-height
  width: 70%
  z-index: $navbar-fixed-z - 1

.blx-dropdown-custom-trigger
  padding: 0
  border: none
  background-color: transparent
  cursor: pointer
  &:focus
    outline: none

.blx-dropdown-trigger
    //  min-width: 70px
    //  max-width: 200px
    //  flex-direction: column
.blx-dropdown-arrow
          background-image: none

.blx-vertical-tabs
  padding: 20px 20px 0 10px

.blx-vertical-tabs input[type="radio"]:focus + .blx-tab-item,
.blx-horizontal-tabs input[type="radio"]:focus + .blx-tab-item,
.blx-vertical-tabs input[type="radio"]:checked + .blx-tab-item,
.blx-horizontal-tabs input[type="radio"]:checked + .blx-tab-item
  color: $primary-00
  cursor: pointer
  outline: none

.blx-horizontal-tabs .blx-tab-item:focus,
.blx-vertical-tabs .blx-tab-item:focus,
input[type="radio"]:checked + .blx-tab-item,
input[type="radio"]:focus + .blx-tab-item
  outline: none

input[type="radio"]:focus + .blx-tab-item::after,
.blx-tab-item:focus::after,
.blx-tab-item.blx-focus::after
  background-color: $primary-00

.blx-text-field
  text-align: start

.blx-tag
  color: white !important
  font-size: 10px
  height: 18px
  line-height: 18px
  background-color: #778F9C
  padding: 0 10px
  border-radius: 9px
  text-align: center

.blx-popover-trigger
  .blx-icon-more-vertical
    background-color: $light-02

  &:hover
    background-color: transparent

  &:focus
    border-color: transparent

.blx-modal-buttons
  margin-top: $size-2

.blx-text-field-container .blx-text-field
  margin-bottom: 0

.blx-modal
  width: 380px

.blx-link
  padding: 1px 7px 2px

.blx-link:focus
  background-color: transparent
  color: $primary-01

.blx-primary:focus::before
  height: 100%
  width: 100%

.blx-vertical-tabs
  .blx-tab-item
    padding: 0 15px
    font-size: 16px
    height: auto
    min-height: auto
    line-height: initial
    margin: 1rem 0
    &::after
      top: 0
      left: 0
      width: 2px

.blx-alert.blx-warning
  position: relative
  width: 100%
  left: 0
  top: 0

.blx-toggle .blx-toggle-container
  .blx-toggle-text
    padding: 10px 10px
  input:hover ~ .blx-toggle-text,
  input:focus ~ .blx-toggle-text
    padding: 10px // avoid toggles growing in size on hover
  input.checked:not(:hover) ~.blx-toggle-text // allow making something look checked without disabling the option
    background-color: $primary-00
    border: 1px solid $primary-00
    color: $secondary-00

.top-nav
  .blx-horizontal-tabs input[type="radio"]:focus + .blx-tab-item
    outline: none

.left-nav
  .blx-vertical-tabs
    .blx-tab-item:focus,
    input[type="radio"]:checked + .blx-tab-item
      color: $primary-00

.blx-h-button-group
  .blx-button:last-child
    margin-right: 0

.blx-dropdown-wrapper
  display: inline-flex
  

.blx-modal-buttons
  margin-right: 1rem

.navbar
  background: white

.blx-dropdown-item
  color: $green-forest-dark

.blx-dropdown-item:hover button
  background: $green-forest-dark
  color: white !important

.blx-dropdown-item.blx-selected button:before
  background: #17e88f

.blx-dropdown-item:hover.blx-selected button:before
  background: #17e88f

.blx-horizontal-tabs .blx-tab-item
  color: $grey-new

.blx-text-field input, .blx-text-field textarea, .blx-dropdown-trigger
  border-color: #959595 !important

.blx-checkbox
  .blx-label
    color: #1A1A1A
    font-size: 16px

.is-600
  font-weight: 600

.floating-panel-content
  color: #1A1A1A

.navbar-menu .navbar-item
  margin-left: 15px

.entity-info-page .entity-name 
  color: #1A1A1A !important
  font-size: 21px !important

.edit-pencil-icon
  width: 18px
  height: 18px

.t2 
  color: #1A1A1A !important

.modal-name
  color: #1A1A1A !important

.spacer-pro
  background-color: #F5F7F7
  
    
  