.scenario-panel
  &:hover
    cursor: pointer

.scenario-panel-sortable
  padding: 1.5rem 0
  width: 100%

.disabled-list
  opacity: 0.5
  pointer-events: none
  
.all-scenarios
  .blx-sortable-list-item:last-of-type
      margin-bottom: 3rem

  .blx-sortable-list-item:hover
    .blx-icon-drag
      visibility: visible
      cursor: grab
    .scenario-panel
      background-color: $secondary-01

  .blx-sortable-list-item.blx-dragging
    background-color: $light-00
    border: 2px solid $light-01
    padding-right: 2rem

.blx-sortable-list
  justify-content: flex-end

  & .blx-sortable-list-item
    display: flex
    flex-direction: row-reverse // show drag handle first
    justify-content: flex-end
    align-items: center

    & .blx-icon-drag
      margin-right: 2rem
      visibility: hidden
      background-image: url(/public/svg/icons/drag-drop.svg)

.all-scenarios
  width: 90%

.add-scenario-button
  display: flex
  justify-content: flex-end
  margin-bottom: 1.5rem

.scenario-panel-name
  margin-top: -1.3rem

.scenario-menu
  margin-left: -2rem
  margin-top: -1.5rem

.tag-and-edit
  margin: 1rem 0
  .blx-tag
    font-size: 12px

.tag-title
  position: absolute
  font-size: $size-6
  font-weight: bold
  margin-left: -1rem
  margin-top: 1rem

.scenario-summary-number
  font-weight: $weight-medium

.rename-scenario-field
  .blx-text-field
    max-width: 15rem

.edit-tag-dropdown
  margin: 1rem 0

.save-edit-button
  max-width: 3rem

.list-section
  margin:10px 0px
  .space-breakdown-list-row
    margin:0
    .scenario-main-summary-analysis:nth-child(2),
    .scenario-main-summary-analysis:nth-child(3)
      background-color: $pale-white
      margin:0px 10px

.scenario-main-summary-analysis
  width: 120px
  text-align: center
  padding:5px

.scenario-main-summary-recommendation
  width: 33%

.current-recommendation
  background-color: $pale-white
  display: inline-block
  padding: 0px 1rem 0.5rem 1rem

.summary-checkbox
  margin-top: 2rem
