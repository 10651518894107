.project-form
  margin: 0 auto 10rem auto
  width: 520px

  .is-half-three-quarters
    width: 37.5%
    flex: none

  .form-submit-button
    justify-content: flex-start

  .project-type-dropdown
    padding-top: 0.8rem

  .project-description
    width: 107%

  .zip-code label
    width: 130%