
//General entity info page styling
.entity-info-page
  .entity-name
    color: $dark-00
    margin-bottom: 1rem

  hr
    width: 300%
    margin-left: -100%

  .entity-info-projects
    margin-top: 1rem

  .entity-info-projects-title
    color: $primary-00
    margin: 1.5rem 0

  .form-container .blx-text-field.display-only label
    font-size: .75rem
    font-weight: $normal-weight
    color: $dark-00

//Specific entity info page styling -- Client
.client-info-page
  .industry-container
    margin-bottom: 0

  .directory
    margin-top: 2rem
    max-width: 80%

  .client-contact-email
    margin-left: 2rem

  .spoc-tag
    margin-left: 0.7rem

  .client-information
    margin-left: 2rem

  hr
    margin-top: 1rem

  .form-container .blx-text-field
    min-width: 0

//Specific entity info page styling -- User
.user-info-page
  .user-subtitle
    font-weight: $medium-weight
    margin-bottom: 1.5rem

  .directory
    margin-top: 2rem
    max-width: 95%
