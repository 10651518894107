@font-face {
  font-family: Calibre Regular;
  src: url("CalibreWeb-Regular.f38b070f.woff2") format("woff2"), url("CalibreWeb-Regular.833aec0f.woff") format("woff");
}

@font-face {
  font-family: Calibre Medium;
  src: url("CalibreWeb-Medium.826fb72e.woff2") format("woff2"), url("CalibreWeb-Medium.17f480f5.woff") format("woff");
}

@font-face {
  font-family: Calibre Semibold;
  src: url("CalibreWeb-Semibold.436ff0f4.woff2") format("woff2"), url("CalibreWeb-Semibold.86c7ca2f.woff") format("woff");
  font-family: Financier Medium;
  src: url("financier-display-web-medium.1c75a619.eot") format("eot"), url("financier-display-web-medium.80c07a1c.woff") format("woff"), url("financier-display-web-medium.0c6f6b55.woff2") format("woff2");
}

@font-face {
  font-family: Financier Regular;
  src: url("financier-display-web-medium.1c75a619.eot") format("eot"), url("financier-display-web-medium.80c07a1c.woff") format("woff"), url("financier-display-web-medium.0c6f6b55.woff2") format("woff2");
}

@font-face {
  font-family: Financier Semibold;
  src: url("financier-display-web-semibold.74f30e2d.eot") format("eot"), url("financier-display-web-semibold.05571b99.woff") format("woff"), url("financier-display-web-semibold.442ec791.woff2") format("woff2");
}

@font-face {
  font-family: "Financier", sans-serif;
  src: url("financier-display-web-medium-italic.14b5c480.eot") format("eot"), url("financier-display-web-medium-italic.dd08e73f.woff") format("woff"), url("financier-display-web-medium-italic.b1d25820.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: "Financier", sans-serif;
  src: url("financier-display-web-medium-italic.14b5c480.eot") format("eot"), url("financier-display-web-medium-italic.dd08e73f.woff") format("woff"), url("financier-display-web-medium-italic.b1d25820.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: "Financier", sans-serif;
  src: url("financier-display-web-semibold-italic.168ae084.eot") format("eot"), url("financier-display-web-semibold-italic.a2767991.woff") format("woff"), url("financier-display-web-semibold-italic.8d8ddc7b.woff2") format("woff2");
  font-weight: 600;
  font-style: italic;
  font-display: fallback;
}

@keyframes spinAround {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(359deg);
  }
}

.flex {
  display: flex;
}

.force-flex {
  display: flex !important;
}

.flex-row {
  flex-direction: row;
}

.flex-col {
  flex-direction: column;
}

.flex-justify-start {
  justify-content: flex-start;
}

.flex-justify-end {
  justify-content: flex-end;
}

.flex-justify-center {
  justify-content: center;
}

.flex-justify-between {
  justify-content: space-between;
}

.flex-justify-around {
  justify-content: space-around;
}

.flex-align-items-start {
  align-items: flex-start;
}

.flex-align-items-center {
  align-items: center;
}

.flex-align-items-baseline {
  align-items: baseline;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-nowrap {
  flex-wrap: nowrap;
}

.flex-center-all {
  justify-content: center;
  align-items: center;
}

.pull-start {
  align-self: flex-start;
}

.pull-end {
  align-self: flex-end;
}

.pull-center {
  align-self: center;
}

html, body, p, ol, ul, li, dl, dt, dd, blockquote, figure, fieldset, legend, textarea, pre, iframe, hr, h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
}

html {
  box-sizing: border-box;
}

*, :before, :after {
  box-sizing: inherit;
}

img, embed, object, audio, video {
  max-width: 100%;
}

html {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizelegibility;
  -moz-text-size-adjust: 100%;
  text-size-adjust: 100%;
  background-color: #fcfcfc;
  min-width: 300px;
  font-size: 18px;
  overflow: hidden scroll;
}

article, aside, figure, footer, header, hgroup, section {
  display: block;
}

button, input, select, textarea {
  font-family: Calibre Regular;
  font-weight: 400;
}

code, pre {
  -moz-osx-font-smoothing: auto;
  -webkit-font-smoothing: auto;
  font-family: monospace;
}

body {
  color: #1a1a1a;
  font-family: Calibre Regular;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
}

a {
  color: #003f2d;
  cursor: pointer;
  text-decoration: none;
}

a strong {
  color: currentColor;
}

.heap-size-my-office-button:hover, .submitBtn:hover, .find-space-button:hover {
  background-color: #144e3e !important;
}

code {
  color: #fc5328;
  background-color: #fcfcfc;
  padding: .25em .5em;
  font-size: .875em;
  font-weight: normal;
}

hr {
  background-color: #dbdbdb;
  border: none;
  height: 1px;
  margin: .5rem 0;
  display: block;
}

img {
  max-width: 100%;
  height: auto;
}

input[type="checkbox"], input[type="radio"] {
  vertical-align: baseline;
}

small {
  font-size: .875em;
}

span {
  font-style: inherit;
  font-weight: inherit;
}

strong {
  color: #434a48;
  font-weight: 700;
}

pre {
  -webkit-overflow-scrolling: touch;
  color: #1a1a1a;
  white-space: pre;
  word-wrap: normal;
  background-color: #fcfcfc;
  padding: 1.25rem 1.5rem;
  font-size: .875em;
  overflow-x: auto;
}

pre code {
  color: currentColor;
  background-color: #0000;
  padding: 0;
  font-size: 1em;
}

table td, table th {
  text-align: left;
  vertical-align: top;
}

table th {
  color: #434a48;
}

.is-clearfix:after {
  clear: both;
  content: " ";
  display: table;
}

.is-pulled-left {
  float: left !important;
}

.is-pulled-right {
  float: right !important;
}

.is-fullheight {
  min-height: 100vh !important;
}

.is-clipped {
  overflow: hidden !important;
}

.is-ellipsized {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.is-overlay {
  position: absolute;
  inset: 0;
}

.has-letter-spacing-small {
  letter-spacing: .2px;
}

.has-letter-spacing-medium {
  letter-spacing: .4px;
}

.has-letter-spacing-large {
  letter-spacing: .6px;
}

.is-size-1 {
  font-size: 4rem !important;
}

.has-padding-1 {
  padding: 4rem !important;
}

.has-padding-top-1 {
  padding-top: 4rem !important;
}

.has-padding-right-1 {
  padding-right: 4rem !important;
}

.has-padding-bottom-1 {
  padding-bottom: 4rem !important;
}

.has-padding-left-1 {
  padding-left: 4rem !important;
}

.has-margin-1 {
  margin: 4rem !important;
}

.has-margin-top-1 {
  margin-top: 4rem !important;
}

.has-margin-right-1 {
  margin-right: 4rem !important;
}

.has-margin-bottom-1 {
  margin-bottom: 4rem !important;
}

.has-margin-left-1 {
  margin-left: 4rem !important;
}

@media screen and (width <= 767px) {
  .has-padding-1-mobile {
    padding: 4rem !important;
  }

  .has-padding-top-1-mobile {
    padding-top: 4rem !important;
  }

  .has-padding-right-1-mobile {
    padding-right: 4rem !important;
  }

  .has-padding-bottom-1-mobile {
    padding-bottom: 4rem !important;
  }

  .has-padding-left-1-mobile {
    padding-left: 4rem !important;
  }

  .has-no-padding-mobile {
    padding: 0 !important;
  }

  .has-no-padding-top-mobile {
    padding-top: 0 !important;
  }

  .has-no-padding-right-mobile {
    padding-right: 0 !important;
  }

  .has-no-padding-bottom-mobile {
    padding-bottom: 0 !important;
  }

  .has-no-padding-left-mobile {
    padding-left: 0 !important;
  }

  .has-margin-1-mobile {
    margin: 4rem !important;
  }

  .has-margin-top-1-mobile {
    margin-top: 4rem !important;
  }

  .has-margin-right-1-mobile {
    margin-right: 4rem !important;
  }

  .has-margin-bottom-1-mobile {
    margin-bottom: 4rem !important;
  }

  .has-margin-left-1-mobile {
    margin-left: 4rem !important;
  }

  .has-no-margin-mobile {
    margin: 0 !important;
  }

  .has-no-margin-top-mobile {
    margin-top: 0 !important;
  }

  .has-no-margin-right-mobile {
    margin-right: 0 !important;
  }

  .has-no-margin-bottom-mobile {
    margin-bottom: 0 !important;
  }

  .has-no-margin-left-mobile {
    margin-left: 0 !important;
  }
}

.is-size-2 {
  font-size: 3rem !important;
}

.has-padding-2 {
  padding: 3rem !important;
}

.has-padding-top-2 {
  padding-top: 3rem !important;
}

.has-padding-right-2 {
  padding-right: 3rem !important;
}

.has-padding-bottom-2 {
  padding-bottom: 3rem !important;
}

.has-padding-left-2 {
  padding-left: 3rem !important;
}

.has-margin-2 {
  margin: 3rem !important;
}

.has-margin-top-2 {
  margin-top: 3rem !important;
}

.has-margin-right-2 {
  margin-right: 3rem !important;
}

.has-margin-bottom-2 {
  margin-bottom: 3rem !important;
}

.has-margin-left-2 {
  margin-left: 3rem !important;
}

@media screen and (width <= 767px) {
  .has-padding-2-mobile {
    padding: 3rem !important;
  }

  .has-padding-top-2-mobile {
    padding-top: 3rem !important;
  }

  .has-padding-right-2-mobile {
    padding-right: 3rem !important;
  }

  .has-padding-bottom-2-mobile {
    padding-bottom: 3rem !important;
  }

  .has-padding-left-2-mobile {
    padding-left: 3rem !important;
  }

  .has-no-padding-mobile {
    padding: 0 !important;
  }

  .has-no-padding-top-mobile {
    padding-top: 0 !important;
  }

  .has-no-padding-right-mobile {
    padding-right: 0 !important;
  }

  .has-no-padding-bottom-mobile {
    padding-bottom: 0 !important;
  }

  .has-no-padding-left-mobile {
    padding-left: 0 !important;
  }

  .has-margin-2-mobile {
    margin: 3rem !important;
  }

  .has-margin-top-2-mobile {
    margin-top: 3rem !important;
  }

  .has-margin-right-2-mobile {
    margin-right: 3rem !important;
  }

  .has-margin-bottom-2-mobile {
    margin-bottom: 3rem !important;
  }

  .has-margin-left-2-mobile {
    margin-left: 3rem !important;
  }

  .has-no-margin-mobile {
    margin: 0 !important;
  }

  .has-no-margin-top-mobile {
    margin-top: 0 !important;
  }

  .has-no-margin-right-mobile {
    margin-right: 0 !important;
  }

  .has-no-margin-bottom-mobile {
    margin-bottom: 0 !important;
  }

  .has-no-margin-left-mobile {
    margin-left: 0 !important;
  }
}

.is-size-3 {
  font-size: 2.5rem !important;
}

.has-padding-3 {
  padding: 2.5rem !important;
}

.has-padding-top-3 {
  padding-top: 2.5rem !important;
}

.has-padding-right-3 {
  padding-right: 2.5rem !important;
}

.has-padding-bottom-3 {
  padding-bottom: 2.5rem !important;
}

.has-padding-left-3 {
  padding-left: 2.5rem !important;
}

.has-margin-3 {
  margin: 2.5rem !important;
}

.has-margin-top-3 {
  margin-top: 2.5rem !important;
}

.has-margin-right-3 {
  margin-right: 2.5rem !important;
}

.has-margin-bottom-3 {
  margin-bottom: 2.5rem !important;
}

.has-margin-left-3 {
  margin-left: 2.5rem !important;
}

@media screen and (width <= 767px) {
  .has-padding-3-mobile {
    padding: 2.5rem !important;
  }

  .has-padding-top-3-mobile {
    padding-top: 2.5rem !important;
  }

  .has-padding-right-3-mobile {
    padding-right: 2.5rem !important;
  }

  .has-padding-bottom-3-mobile {
    padding-bottom: 2.5rem !important;
  }

  .has-padding-left-3-mobile {
    padding-left: 2.5rem !important;
  }

  .has-no-padding-mobile {
    padding: 0 !important;
  }

  .has-no-padding-top-mobile {
    padding-top: 0 !important;
  }

  .has-no-padding-right-mobile {
    padding-right: 0 !important;
  }

  .has-no-padding-bottom-mobile {
    padding-bottom: 0 !important;
  }

  .has-no-padding-left-mobile {
    padding-left: 0 !important;
  }

  .has-margin-3-mobile {
    margin: 2.5rem !important;
  }

  .has-margin-top-3-mobile {
    margin-top: 2.5rem !important;
  }

  .has-margin-right-3-mobile {
    margin-right: 2.5rem !important;
  }

  .has-margin-bottom-3-mobile {
    margin-bottom: 2.5rem !important;
  }

  .has-margin-left-3-mobile {
    margin-left: 2.5rem !important;
  }

  .has-no-margin-mobile {
    margin: 0 !important;
  }

  .has-no-margin-top-mobile {
    margin-top: 0 !important;
  }

  .has-no-margin-right-mobile {
    margin-right: 0 !important;
  }

  .has-no-margin-bottom-mobile {
    margin-bottom: 0 !important;
  }

  .has-no-margin-left-mobile {
    margin-left: 0 !important;
  }
}

.is-size-4 {
  font-size: 1.5rem !important;
}

.has-padding-4 {
  padding: 1.5rem !important;
}

.has-padding-top-4 {
  padding-top: 1.5rem !important;
}

.has-padding-right-4 {
  padding-right: 1.5rem !important;
}

.has-padding-bottom-4 {
  padding-bottom: 1.5rem !important;
}

.has-padding-left-4 {
  padding-left: 1.5rem !important;
}

.has-margin-4 {
  margin: 1.5rem !important;
}

.has-margin-top-4 {
  margin-top: 1.5rem !important;
}

.has-margin-right-4 {
  margin-right: 1.5rem !important;
}

.has-margin-bottom-4 {
  margin-bottom: 1.5rem !important;
}

.has-margin-left-4 {
  margin-left: 1.5rem !important;
}

@media screen and (width <= 767px) {
  .has-padding-4-mobile {
    padding: 1.5rem !important;
  }

  .has-padding-top-4-mobile {
    padding-top: 1.5rem !important;
  }

  .has-padding-right-4-mobile {
    padding-right: 1.5rem !important;
  }

  .has-padding-bottom-4-mobile {
    padding-bottom: 1.5rem !important;
  }

  .has-padding-left-4-mobile {
    padding-left: 1.5rem !important;
  }

  .has-no-padding-mobile {
    padding: 0 !important;
  }

  .has-no-padding-top-mobile {
    padding-top: 0 !important;
  }

  .has-no-padding-right-mobile {
    padding-right: 0 !important;
  }

  .has-no-padding-bottom-mobile {
    padding-bottom: 0 !important;
  }

  .has-no-padding-left-mobile {
    padding-left: 0 !important;
  }

  .has-margin-4-mobile {
    margin: 1.5rem !important;
  }

  .has-margin-top-4-mobile {
    margin-top: 1.5rem !important;
  }

  .has-margin-right-4-mobile {
    margin-right: 1.5rem !important;
  }

  .has-margin-bottom-4-mobile {
    margin-bottom: 1.5rem !important;
  }

  .has-margin-left-4-mobile {
    margin-left: 1.5rem !important;
  }

  .has-no-margin-mobile {
    margin: 0 !important;
  }

  .has-no-margin-top-mobile {
    margin-top: 0 !important;
  }

  .has-no-margin-right-mobile {
    margin-right: 0 !important;
  }

  .has-no-margin-bottom-mobile {
    margin-bottom: 0 !important;
  }

  .has-no-margin-left-mobile {
    margin-left: 0 !important;
  }
}

.is-size-5 {
  font-size: 1.25rem !important;
}

.has-padding-5 {
  padding: 1.25rem !important;
}

.has-padding-top-5 {
  padding-top: 1.25rem !important;
}

.has-padding-right-5 {
  padding-right: 1.25rem !important;
}

.has-padding-bottom-5 {
  padding-bottom: 1.25rem !important;
}

.has-padding-left-5 {
  padding-left: 1.25rem !important;
}

.has-margin-5 {
  margin: 1.25rem !important;
}

.has-margin-top-5 {
  margin-top: 1.25rem !important;
}

.has-margin-right-5 {
  margin-right: 1.25rem !important;
}

.has-margin-bottom-5 {
  margin-bottom: 1.25rem !important;
}

.has-margin-left-5 {
  margin-left: 1.25rem !important;
}

@media screen and (width <= 767px) {
  .has-padding-5-mobile {
    padding: 1.25rem !important;
  }

  .has-padding-top-5-mobile {
    padding-top: 1.25rem !important;
  }

  .has-padding-right-5-mobile {
    padding-right: 1.25rem !important;
  }

  .has-padding-bottom-5-mobile {
    padding-bottom: 1.25rem !important;
  }

  .has-padding-left-5-mobile {
    padding-left: 1.25rem !important;
  }

  .has-no-padding-mobile {
    padding: 0 !important;
  }

  .has-no-padding-top-mobile {
    padding-top: 0 !important;
  }

  .has-no-padding-right-mobile {
    padding-right: 0 !important;
  }

  .has-no-padding-bottom-mobile {
    padding-bottom: 0 !important;
  }

  .has-no-padding-left-mobile {
    padding-left: 0 !important;
  }

  .has-margin-5-mobile {
    margin: 1.25rem !important;
  }

  .has-margin-top-5-mobile {
    margin-top: 1.25rem !important;
  }

  .has-margin-right-5-mobile {
    margin-right: 1.25rem !important;
  }

  .has-margin-bottom-5-mobile {
    margin-bottom: 1.25rem !important;
  }

  .has-margin-left-5-mobile {
    margin-left: 1.25rem !important;
  }

  .has-no-margin-mobile {
    margin: 0 !important;
  }

  .has-no-margin-top-mobile {
    margin-top: 0 !important;
  }

  .has-no-margin-right-mobile {
    margin-right: 0 !important;
  }

  .has-no-margin-bottom-mobile {
    margin-bottom: 0 !important;
  }

  .has-no-margin-left-mobile {
    margin-left: 0 !important;
  }
}

.is-size-6 {
  font-size: 1rem !important;
}

.has-padding-6 {
  padding: 1rem !important;
}

.has-padding-top-6 {
  padding-top: 1rem !important;
}

.has-padding-right-6 {
  padding-right: 1rem !important;
}

.has-padding-bottom-6 {
  padding-bottom: 1rem !important;
}

.has-padding-left-6 {
  padding-left: 1rem !important;
}

.has-margin-6 {
  margin: 1rem !important;
}

.has-margin-top-6 {
  margin-top: 1rem !important;
}

.has-margin-right-6 {
  margin-right: 1rem !important;
}

.has-margin-bottom-6 {
  margin-bottom: 1rem !important;
}

.has-margin-left-6 {
  margin-left: 1rem !important;
}

@media screen and (width <= 767px) {
  .has-padding-6-mobile {
    padding: 1rem !important;
  }

  .has-padding-top-6-mobile {
    padding-top: 1rem !important;
  }

  .has-padding-right-6-mobile {
    padding-right: 1rem !important;
  }

  .has-padding-bottom-6-mobile {
    padding-bottom: 1rem !important;
  }

  .has-padding-left-6-mobile {
    padding-left: 1rem !important;
  }

  .has-no-padding-mobile {
    padding: 0 !important;
  }

  .has-no-padding-top-mobile {
    padding-top: 0 !important;
  }

  .has-no-padding-right-mobile {
    padding-right: 0 !important;
  }

  .has-no-padding-bottom-mobile {
    padding-bottom: 0 !important;
  }

  .has-no-padding-left-mobile {
    padding-left: 0 !important;
  }

  .has-margin-6-mobile {
    margin: 1rem !important;
  }

  .has-margin-top-6-mobile {
    margin-top: 1rem !important;
  }

  .has-margin-right-6-mobile {
    margin-right: 1rem !important;
  }

  .has-margin-bottom-6-mobile {
    margin-bottom: 1rem !important;
  }

  .has-margin-left-6-mobile {
    margin-left: 1rem !important;
  }

  .has-no-margin-mobile {
    margin: 0 !important;
  }

  .has-no-margin-top-mobile {
    margin-top: 0 !important;
  }

  .has-no-margin-right-mobile {
    margin-right: 0 !important;
  }

  .has-no-margin-bottom-mobile {
    margin-bottom: 0 !important;
  }

  .has-no-margin-left-mobile {
    margin-left: 0 !important;
  }
}

.is-size-7 {
  font-size: .75rem !important;
}

.has-padding-7 {
  padding: .75rem !important;
}

.has-padding-top-7 {
  padding-top: .75rem !important;
}

.has-padding-right-7 {
  padding-right: .75rem !important;
}

.has-padding-bottom-7 {
  padding-bottom: .75rem !important;
}

.has-padding-left-7 {
  padding-left: .75rem !important;
}

.has-margin-7 {
  margin: .75rem !important;
}

.has-margin-top-7 {
  margin-top: .75rem !important;
}

.has-margin-right-7 {
  margin-right: .75rem !important;
}

.has-margin-bottom-7 {
  margin-bottom: .75rem !important;
}

.has-margin-left-7 {
  margin-left: .75rem !important;
}

@media screen and (width <= 767px) {
  .has-padding-7-mobile {
    padding: .75rem !important;
  }

  .has-padding-top-7-mobile {
    padding-top: .75rem !important;
  }

  .has-padding-right-7-mobile {
    padding-right: .75rem !important;
  }

  .has-padding-bottom-7-mobile {
    padding-bottom: .75rem !important;
  }

  .has-padding-left-7-mobile {
    padding-left: .75rem !important;
  }

  .has-no-padding-mobile {
    padding: 0 !important;
  }

  .has-no-padding-top-mobile {
    padding-top: 0 !important;
  }

  .has-no-padding-right-mobile {
    padding-right: 0 !important;
  }

  .has-no-padding-bottom-mobile {
    padding-bottom: 0 !important;
  }

  .has-no-padding-left-mobile {
    padding-left: 0 !important;
  }

  .has-margin-7-mobile {
    margin: .75rem !important;
  }

  .has-margin-top-7-mobile {
    margin-top: .75rem !important;
  }

  .has-margin-right-7-mobile {
    margin-right: .75rem !important;
  }

  .has-margin-bottom-7-mobile {
    margin-bottom: .75rem !important;
  }

  .has-margin-left-7-mobile {
    margin-left: .75rem !important;
  }

  .has-no-margin-mobile {
    margin: 0 !important;
  }

  .has-no-margin-top-mobile {
    margin-top: 0 !important;
  }

  .has-no-margin-right-mobile {
    margin-right: 0 !important;
  }

  .has-no-margin-bottom-mobile {
    margin-bottom: 0 !important;
  }

  .has-no-margin-left-mobile {
    margin-left: 0 !important;
  }
}

.is-size-8 {
  font-size: .5rem !important;
}

.has-padding-8 {
  padding: .5rem !important;
}

.has-padding-top-8 {
  padding-top: .5rem !important;
}

.has-padding-right-8 {
  padding-right: .5rem !important;
}

.has-padding-bottom-8 {
  padding-bottom: .5rem !important;
}

.has-padding-left-8 {
  padding-left: .5rem !important;
}

.has-margin-8 {
  margin: .5rem !important;
}

.has-margin-top-8 {
  margin-top: .5rem !important;
}

.has-margin-right-8 {
  margin-right: .5rem !important;
}

.has-margin-bottom-8 {
  margin-bottom: .5rem !important;
}

.has-margin-left-8 {
  margin-left: .5rem !important;
}

@media screen and (width <= 767px) {
  .has-padding-8-mobile {
    padding: .5rem !important;
  }

  .has-padding-top-8-mobile {
    padding-top: .5rem !important;
  }

  .has-padding-right-8-mobile {
    padding-right: .5rem !important;
  }

  .has-padding-bottom-8-mobile {
    padding-bottom: .5rem !important;
  }

  .has-padding-left-8-mobile {
    padding-left: .5rem !important;
  }

  .has-no-padding-mobile {
    padding: 0 !important;
  }

  .has-no-padding-top-mobile {
    padding-top: 0 !important;
  }

  .has-no-padding-right-mobile {
    padding-right: 0 !important;
  }

  .has-no-padding-bottom-mobile {
    padding-bottom: 0 !important;
  }

  .has-no-padding-left-mobile {
    padding-left: 0 !important;
  }

  .has-margin-8-mobile {
    margin: .5rem !important;
  }

  .has-margin-top-8-mobile {
    margin-top: .5rem !important;
  }

  .has-margin-right-8-mobile {
    margin-right: .5rem !important;
  }

  .has-margin-bottom-8-mobile {
    margin-bottom: .5rem !important;
  }

  .has-margin-left-8-mobile {
    margin-left: .5rem !important;
  }

  .has-no-margin-mobile {
    margin: 0 !important;
  }

  .has-no-margin-top-mobile {
    margin-top: 0 !important;
  }

  .has-no-margin-right-mobile {
    margin-right: 0 !important;
  }

  .has-no-margin-bottom-mobile {
    margin-bottom: 0 !important;
  }

  .has-no-margin-left-mobile {
    margin-left: 0 !important;
  }
}

.is-size-9 {
  font-size: .2rem !important;
}

.has-padding-9 {
  padding: .2rem !important;
}

.has-padding-top-9 {
  padding-top: .2rem !important;
}

.has-padding-right-9 {
  padding-right: .2rem !important;
}

.has-padding-bottom-9 {
  padding-bottom: .2rem !important;
}

.has-padding-left-9 {
  padding-left: .2rem !important;
}

.has-margin-9 {
  margin: .2rem !important;
}

.has-margin-top-9 {
  margin-top: .2rem !important;
}

.has-margin-right-9 {
  margin-right: .2rem !important;
}

.has-margin-bottom-9 {
  margin-bottom: .2rem !important;
}

.has-margin-left-9 {
  margin-left: .2rem !important;
}

@media screen and (width <= 767px) {
  .has-padding-9-mobile {
    padding: .2rem !important;
  }

  .has-padding-top-9-mobile {
    padding-top: .2rem !important;
  }

  .has-padding-right-9-mobile {
    padding-right: .2rem !important;
  }

  .has-padding-bottom-9-mobile {
    padding-bottom: .2rem !important;
  }

  .has-padding-left-9-mobile {
    padding-left: .2rem !important;
  }

  .has-no-padding-mobile {
    padding: 0 !important;
  }

  .has-no-padding-top-mobile {
    padding-top: 0 !important;
  }

  .has-no-padding-right-mobile {
    padding-right: 0 !important;
  }

  .has-no-padding-bottom-mobile {
    padding-bottom: 0 !important;
  }

  .has-no-padding-left-mobile {
    padding-left: 0 !important;
  }

  .has-margin-9-mobile {
    margin: .2rem !important;
  }

  .has-margin-top-9-mobile {
    margin-top: .2rem !important;
  }

  .has-margin-right-9-mobile {
    margin-right: .2rem !important;
  }

  .has-margin-bottom-9-mobile {
    margin-bottom: .2rem !important;
  }

  .has-margin-left-9-mobile {
    margin-left: .2rem !important;
  }

  .has-no-margin-mobile {
    margin: 0 !important;
  }

  .has-no-margin-top-mobile {
    margin-top: 0 !important;
  }

  .has-no-margin-right-mobile {
    margin-right: 0 !important;
  }

  .has-no-margin-bottom-mobile {
    margin-bottom: 0 !important;
  }

  .has-no-margin-left-mobile {
    margin-left: 0 !important;
  }

  .is-size-1-mobile {
    font-size: 4rem !important;
  }

  .has-padding-1 {
    padding: 4rem !important;
  }

  .has-padding-top-1 {
    padding-top: 4rem !important;
  }

  .has-padding-right-1 {
    padding-right: 4rem !important;
  }

  .has-padding-bottom-1 {
    padding-bottom: 4rem !important;
  }

  .has-padding-left-1 {
    padding-left: 4rem !important;
  }

  .has-margin-1 {
    margin: 4rem !important;
  }

  .has-margin-top-1 {
    margin-top: 4rem !important;
  }

  .has-margin-right-1 {
    margin-right: 4rem !important;
  }

  .has-margin-bottom-1 {
    margin-bottom: 4rem !important;
  }

  .has-margin-left-1 {
    margin-left: 4rem !important;
  }
}

@media screen and (width <= 767px) and (width <= 767px) {
  .has-padding-1-mobile {
    padding: 4rem !important;
  }

  .has-padding-top-1-mobile {
    padding-top: 4rem !important;
  }

  .has-padding-right-1-mobile {
    padding-right: 4rem !important;
  }

  .has-padding-bottom-1-mobile {
    padding-bottom: 4rem !important;
  }

  .has-padding-left-1-mobile {
    padding-left: 4rem !important;
  }

  .has-no-padding-mobile {
    padding: 0 !important;
  }

  .has-no-padding-top-mobile {
    padding-top: 0 !important;
  }

  .has-no-padding-right-mobile {
    padding-right: 0 !important;
  }

  .has-no-padding-bottom-mobile {
    padding-bottom: 0 !important;
  }

  .has-no-padding-left-mobile {
    padding-left: 0 !important;
  }

  .has-margin-1-mobile {
    margin: 4rem !important;
  }

  .has-margin-top-1-mobile {
    margin-top: 4rem !important;
  }

  .has-margin-right-1-mobile {
    margin-right: 4rem !important;
  }

  .has-margin-bottom-1-mobile {
    margin-bottom: 4rem !important;
  }

  .has-margin-left-1-mobile {
    margin-left: 4rem !important;
  }

  .has-no-margin-mobile {
    margin: 0 !important;
  }

  .has-no-margin-top-mobile {
    margin-top: 0 !important;
  }

  .has-no-margin-right-mobile {
    margin-right: 0 !important;
  }

  .has-no-margin-bottom-mobile {
    margin-bottom: 0 !important;
  }

  .has-no-margin-left-mobile {
    margin-left: 0 !important;
  }
}

@media screen and (width <= 767px) {
  .is-size-2-mobile {
    font-size: 3rem !important;
  }

  .has-padding-2 {
    padding: 3rem !important;
  }

  .has-padding-top-2 {
    padding-top: 3rem !important;
  }

  .has-padding-right-2 {
    padding-right: 3rem !important;
  }

  .has-padding-bottom-2 {
    padding-bottom: 3rem !important;
  }

  .has-padding-left-2 {
    padding-left: 3rem !important;
  }

  .has-margin-2 {
    margin: 3rem !important;
  }

  .has-margin-top-2 {
    margin-top: 3rem !important;
  }

  .has-margin-right-2 {
    margin-right: 3rem !important;
  }

  .has-margin-bottom-2 {
    margin-bottom: 3rem !important;
  }

  .has-margin-left-2 {
    margin-left: 3rem !important;
  }
}

@media screen and (width <= 767px) and (width <= 767px) {
  .has-padding-2-mobile {
    padding: 3rem !important;
  }

  .has-padding-top-2-mobile {
    padding-top: 3rem !important;
  }

  .has-padding-right-2-mobile {
    padding-right: 3rem !important;
  }

  .has-padding-bottom-2-mobile {
    padding-bottom: 3rem !important;
  }

  .has-padding-left-2-mobile {
    padding-left: 3rem !important;
  }

  .has-no-padding-mobile {
    padding: 0 !important;
  }

  .has-no-padding-top-mobile {
    padding-top: 0 !important;
  }

  .has-no-padding-right-mobile {
    padding-right: 0 !important;
  }

  .has-no-padding-bottom-mobile {
    padding-bottom: 0 !important;
  }

  .has-no-padding-left-mobile {
    padding-left: 0 !important;
  }

  .has-margin-2-mobile {
    margin: 3rem !important;
  }

  .has-margin-top-2-mobile {
    margin-top: 3rem !important;
  }

  .has-margin-right-2-mobile {
    margin-right: 3rem !important;
  }

  .has-margin-bottom-2-mobile {
    margin-bottom: 3rem !important;
  }

  .has-margin-left-2-mobile {
    margin-left: 3rem !important;
  }

  .has-no-margin-mobile {
    margin: 0 !important;
  }

  .has-no-margin-top-mobile {
    margin-top: 0 !important;
  }

  .has-no-margin-right-mobile {
    margin-right: 0 !important;
  }

  .has-no-margin-bottom-mobile {
    margin-bottom: 0 !important;
  }

  .has-no-margin-left-mobile {
    margin-left: 0 !important;
  }
}

@media screen and (width <= 767px) {
  .is-size-3-mobile {
    font-size: 2.5rem !important;
  }

  .has-padding-3 {
    padding: 2.5rem !important;
  }

  .has-padding-top-3 {
    padding-top: 2.5rem !important;
  }

  .has-padding-right-3 {
    padding-right: 2.5rem !important;
  }

  .has-padding-bottom-3 {
    padding-bottom: 2.5rem !important;
  }

  .has-padding-left-3 {
    padding-left: 2.5rem !important;
  }

  .has-margin-3 {
    margin: 2.5rem !important;
  }

  .has-margin-top-3 {
    margin-top: 2.5rem !important;
  }

  .has-margin-right-3 {
    margin-right: 2.5rem !important;
  }

  .has-margin-bottom-3 {
    margin-bottom: 2.5rem !important;
  }

  .has-margin-left-3 {
    margin-left: 2.5rem !important;
  }
}

@media screen and (width <= 767px) and (width <= 767px) {
  .has-padding-3-mobile {
    padding: 2.5rem !important;
  }

  .has-padding-top-3-mobile {
    padding-top: 2.5rem !important;
  }

  .has-padding-right-3-mobile {
    padding-right: 2.5rem !important;
  }

  .has-padding-bottom-3-mobile {
    padding-bottom: 2.5rem !important;
  }

  .has-padding-left-3-mobile {
    padding-left: 2.5rem !important;
  }

  .has-no-padding-mobile {
    padding: 0 !important;
  }

  .has-no-padding-top-mobile {
    padding-top: 0 !important;
  }

  .has-no-padding-right-mobile {
    padding-right: 0 !important;
  }

  .has-no-padding-bottom-mobile {
    padding-bottom: 0 !important;
  }

  .has-no-padding-left-mobile {
    padding-left: 0 !important;
  }

  .has-margin-3-mobile {
    margin: 2.5rem !important;
  }

  .has-margin-top-3-mobile {
    margin-top: 2.5rem !important;
  }

  .has-margin-right-3-mobile {
    margin-right: 2.5rem !important;
  }

  .has-margin-bottom-3-mobile {
    margin-bottom: 2.5rem !important;
  }

  .has-margin-left-3-mobile {
    margin-left: 2.5rem !important;
  }

  .has-no-margin-mobile {
    margin: 0 !important;
  }

  .has-no-margin-top-mobile {
    margin-top: 0 !important;
  }

  .has-no-margin-right-mobile {
    margin-right: 0 !important;
  }

  .has-no-margin-bottom-mobile {
    margin-bottom: 0 !important;
  }

  .has-no-margin-left-mobile {
    margin-left: 0 !important;
  }
}

@media screen and (width <= 767px) {
  .is-size-4-mobile {
    font-size: 1.5rem !important;
  }

  .has-padding-4 {
    padding: 1.5rem !important;
  }

  .has-padding-top-4 {
    padding-top: 1.5rem !important;
  }

  .has-padding-right-4 {
    padding-right: 1.5rem !important;
  }

  .has-padding-bottom-4 {
    padding-bottom: 1.5rem !important;
  }

  .has-padding-left-4 {
    padding-left: 1.5rem !important;
  }

  .has-margin-4 {
    margin: 1.5rem !important;
  }

  .has-margin-top-4 {
    margin-top: 1.5rem !important;
  }

  .has-margin-right-4 {
    margin-right: 1.5rem !important;
  }

  .has-margin-bottom-4 {
    margin-bottom: 1.5rem !important;
  }

  .has-margin-left-4 {
    margin-left: 1.5rem !important;
  }
}

@media screen and (width <= 767px) and (width <= 767px) {
  .has-padding-4-mobile {
    padding: 1.5rem !important;
  }

  .has-padding-top-4-mobile {
    padding-top: 1.5rem !important;
  }

  .has-padding-right-4-mobile {
    padding-right: 1.5rem !important;
  }

  .has-padding-bottom-4-mobile {
    padding-bottom: 1.5rem !important;
  }

  .has-padding-left-4-mobile {
    padding-left: 1.5rem !important;
  }

  .has-no-padding-mobile {
    padding: 0 !important;
  }

  .has-no-padding-top-mobile {
    padding-top: 0 !important;
  }

  .has-no-padding-right-mobile {
    padding-right: 0 !important;
  }

  .has-no-padding-bottom-mobile {
    padding-bottom: 0 !important;
  }

  .has-no-padding-left-mobile {
    padding-left: 0 !important;
  }

  .has-margin-4-mobile {
    margin: 1.5rem !important;
  }

  .has-margin-top-4-mobile {
    margin-top: 1.5rem !important;
  }

  .has-margin-right-4-mobile {
    margin-right: 1.5rem !important;
  }

  .has-margin-bottom-4-mobile {
    margin-bottom: 1.5rem !important;
  }

  .has-margin-left-4-mobile {
    margin-left: 1.5rem !important;
  }

  .has-no-margin-mobile {
    margin: 0 !important;
  }

  .has-no-margin-top-mobile {
    margin-top: 0 !important;
  }

  .has-no-margin-right-mobile {
    margin-right: 0 !important;
  }

  .has-no-margin-bottom-mobile {
    margin-bottom: 0 !important;
  }

  .has-no-margin-left-mobile {
    margin-left: 0 !important;
  }
}

@media screen and (width <= 767px) {
  .is-size-5-mobile {
    font-size: 1.25rem !important;
  }

  .has-padding-5 {
    padding: 1.25rem !important;
  }

  .has-padding-top-5 {
    padding-top: 1.25rem !important;
  }

  .has-padding-right-5 {
    padding-right: 1.25rem !important;
  }

  .has-padding-bottom-5 {
    padding-bottom: 1.25rem !important;
  }

  .has-padding-left-5 {
    padding-left: 1.25rem !important;
  }

  .has-margin-5 {
    margin: 1.25rem !important;
  }

  .has-margin-top-5 {
    margin-top: 1.25rem !important;
  }

  .has-margin-right-5 {
    margin-right: 1.25rem !important;
  }

  .has-margin-bottom-5 {
    margin-bottom: 1.25rem !important;
  }

  .has-margin-left-5 {
    margin-left: 1.25rem !important;
  }
}

@media screen and (width <= 767px) and (width <= 767px) {
  .has-padding-5-mobile {
    padding: 1.25rem !important;
  }

  .has-padding-top-5-mobile {
    padding-top: 1.25rem !important;
  }

  .has-padding-right-5-mobile {
    padding-right: 1.25rem !important;
  }

  .has-padding-bottom-5-mobile {
    padding-bottom: 1.25rem !important;
  }

  .has-padding-left-5-mobile {
    padding-left: 1.25rem !important;
  }

  .has-no-padding-mobile {
    padding: 0 !important;
  }

  .has-no-padding-top-mobile {
    padding-top: 0 !important;
  }

  .has-no-padding-right-mobile {
    padding-right: 0 !important;
  }

  .has-no-padding-bottom-mobile {
    padding-bottom: 0 !important;
  }

  .has-no-padding-left-mobile {
    padding-left: 0 !important;
  }

  .has-margin-5-mobile {
    margin: 1.25rem !important;
  }

  .has-margin-top-5-mobile {
    margin-top: 1.25rem !important;
  }

  .has-margin-right-5-mobile {
    margin-right: 1.25rem !important;
  }

  .has-margin-bottom-5-mobile {
    margin-bottom: 1.25rem !important;
  }

  .has-margin-left-5-mobile {
    margin-left: 1.25rem !important;
  }

  .has-no-margin-mobile {
    margin: 0 !important;
  }

  .has-no-margin-top-mobile {
    margin-top: 0 !important;
  }

  .has-no-margin-right-mobile {
    margin-right: 0 !important;
  }

  .has-no-margin-bottom-mobile {
    margin-bottom: 0 !important;
  }

  .has-no-margin-left-mobile {
    margin-left: 0 !important;
  }
}

@media screen and (width <= 767px) {
  .is-size-6-mobile {
    font-size: 1rem !important;
  }

  .has-padding-6 {
    padding: 1rem !important;
  }

  .has-padding-top-6 {
    padding-top: 1rem !important;
  }

  .has-padding-right-6 {
    padding-right: 1rem !important;
  }

  .has-padding-bottom-6 {
    padding-bottom: 1rem !important;
  }

  .has-padding-left-6 {
    padding-left: 1rem !important;
  }

  .has-margin-6 {
    margin: 1rem !important;
  }

  .has-margin-top-6 {
    margin-top: 1rem !important;
  }

  .has-margin-right-6 {
    margin-right: 1rem !important;
  }

  .has-margin-bottom-6 {
    margin-bottom: 1rem !important;
  }

  .has-margin-left-6 {
    margin-left: 1rem !important;
  }
}

@media screen and (width <= 767px) and (width <= 767px) {
  .has-padding-6-mobile {
    padding: 1rem !important;
  }

  .has-padding-top-6-mobile {
    padding-top: 1rem !important;
  }

  .has-padding-right-6-mobile {
    padding-right: 1rem !important;
  }

  .has-padding-bottom-6-mobile {
    padding-bottom: 1rem !important;
  }

  .has-padding-left-6-mobile {
    padding-left: 1rem !important;
  }

  .has-no-padding-mobile {
    padding: 0 !important;
  }

  .has-no-padding-top-mobile {
    padding-top: 0 !important;
  }

  .has-no-padding-right-mobile {
    padding-right: 0 !important;
  }

  .has-no-padding-bottom-mobile {
    padding-bottom: 0 !important;
  }

  .has-no-padding-left-mobile {
    padding-left: 0 !important;
  }

  .has-margin-6-mobile {
    margin: 1rem !important;
  }

  .has-margin-top-6-mobile {
    margin-top: 1rem !important;
  }

  .has-margin-right-6-mobile {
    margin-right: 1rem !important;
  }

  .has-margin-bottom-6-mobile {
    margin-bottom: 1rem !important;
  }

  .has-margin-left-6-mobile {
    margin-left: 1rem !important;
  }

  .has-no-margin-mobile {
    margin: 0 !important;
  }

  .has-no-margin-top-mobile {
    margin-top: 0 !important;
  }

  .has-no-margin-right-mobile {
    margin-right: 0 !important;
  }

  .has-no-margin-bottom-mobile {
    margin-bottom: 0 !important;
  }

  .has-no-margin-left-mobile {
    margin-left: 0 !important;
  }
}

@media screen and (width <= 767px) {
  .is-size-7-mobile {
    font-size: .75rem !important;
  }

  .has-padding-7 {
    padding: .75rem !important;
  }

  .has-padding-top-7 {
    padding-top: .75rem !important;
  }

  .has-padding-right-7 {
    padding-right: .75rem !important;
  }

  .has-padding-bottom-7 {
    padding-bottom: .75rem !important;
  }

  .has-padding-left-7 {
    padding-left: .75rem !important;
  }

  .has-margin-7 {
    margin: .75rem !important;
  }

  .has-margin-top-7 {
    margin-top: .75rem !important;
  }

  .has-margin-right-7 {
    margin-right: .75rem !important;
  }

  .has-margin-bottom-7 {
    margin-bottom: .75rem !important;
  }

  .has-margin-left-7 {
    margin-left: .75rem !important;
  }
}

@media screen and (width <= 767px) and (width <= 767px) {
  .has-padding-7-mobile {
    padding: .75rem !important;
  }

  .has-padding-top-7-mobile {
    padding-top: .75rem !important;
  }

  .has-padding-right-7-mobile {
    padding-right: .75rem !important;
  }

  .has-padding-bottom-7-mobile {
    padding-bottom: .75rem !important;
  }

  .has-padding-left-7-mobile {
    padding-left: .75rem !important;
  }

  .has-no-padding-mobile {
    padding: 0 !important;
  }

  .has-no-padding-top-mobile {
    padding-top: 0 !important;
  }

  .has-no-padding-right-mobile {
    padding-right: 0 !important;
  }

  .has-no-padding-bottom-mobile {
    padding-bottom: 0 !important;
  }

  .has-no-padding-left-mobile {
    padding-left: 0 !important;
  }

  .has-margin-7-mobile {
    margin: .75rem !important;
  }

  .has-margin-top-7-mobile {
    margin-top: .75rem !important;
  }

  .has-margin-right-7-mobile {
    margin-right: .75rem !important;
  }

  .has-margin-bottom-7-mobile {
    margin-bottom: .75rem !important;
  }

  .has-margin-left-7-mobile {
    margin-left: .75rem !important;
  }

  .has-no-margin-mobile {
    margin: 0 !important;
  }

  .has-no-margin-top-mobile {
    margin-top: 0 !important;
  }

  .has-no-margin-right-mobile {
    margin-right: 0 !important;
  }

  .has-no-margin-bottom-mobile {
    margin-bottom: 0 !important;
  }

  .has-no-margin-left-mobile {
    margin-left: 0 !important;
  }
}

@media screen and (width <= 767px) {
  .is-size-8-mobile {
    font-size: .5rem !important;
  }

  .has-padding-8 {
    padding: .5rem !important;
  }

  .has-padding-top-8 {
    padding-top: .5rem !important;
  }

  .has-padding-right-8 {
    padding-right: .5rem !important;
  }

  .has-padding-bottom-8 {
    padding-bottom: .5rem !important;
  }

  .has-padding-left-8 {
    padding-left: .5rem !important;
  }

  .has-margin-8 {
    margin: .5rem !important;
  }

  .has-margin-top-8 {
    margin-top: .5rem !important;
  }

  .has-margin-right-8 {
    margin-right: .5rem !important;
  }

  .has-margin-bottom-8 {
    margin-bottom: .5rem !important;
  }

  .has-margin-left-8 {
    margin-left: .5rem !important;
  }
}

@media screen and (width <= 767px) and (width <= 767px) {
  .has-padding-8-mobile {
    padding: .5rem !important;
  }

  .has-padding-top-8-mobile {
    padding-top: .5rem !important;
  }

  .has-padding-right-8-mobile {
    padding-right: .5rem !important;
  }

  .has-padding-bottom-8-mobile {
    padding-bottom: .5rem !important;
  }

  .has-padding-left-8-mobile {
    padding-left: .5rem !important;
  }

  .has-no-padding-mobile {
    padding: 0 !important;
  }

  .has-no-padding-top-mobile {
    padding-top: 0 !important;
  }

  .has-no-padding-right-mobile {
    padding-right: 0 !important;
  }

  .has-no-padding-bottom-mobile {
    padding-bottom: 0 !important;
  }

  .has-no-padding-left-mobile {
    padding-left: 0 !important;
  }

  .has-margin-8-mobile {
    margin: .5rem !important;
  }

  .has-margin-top-8-mobile {
    margin-top: .5rem !important;
  }

  .has-margin-right-8-mobile {
    margin-right: .5rem !important;
  }

  .has-margin-bottom-8-mobile {
    margin-bottom: .5rem !important;
  }

  .has-margin-left-8-mobile {
    margin-left: .5rem !important;
  }

  .has-no-margin-mobile {
    margin: 0 !important;
  }

  .has-no-margin-top-mobile {
    margin-top: 0 !important;
  }

  .has-no-margin-right-mobile {
    margin-right: 0 !important;
  }

  .has-no-margin-bottom-mobile {
    margin-bottom: 0 !important;
  }

  .has-no-margin-left-mobile {
    margin-left: 0 !important;
  }
}

@media screen and (width <= 767px) {
  .is-size-9-mobile {
    font-size: .2rem !important;
  }

  .has-padding-9 {
    padding: .2rem !important;
  }

  .has-padding-top-9 {
    padding-top: .2rem !important;
  }

  .has-padding-right-9 {
    padding-right: .2rem !important;
  }

  .has-padding-bottom-9 {
    padding-bottom: .2rem !important;
  }

  .has-padding-left-9 {
    padding-left: .2rem !important;
  }

  .has-margin-9 {
    margin: .2rem !important;
  }

  .has-margin-top-9 {
    margin-top: .2rem !important;
  }

  .has-margin-right-9 {
    margin-right: .2rem !important;
  }

  .has-margin-bottom-9 {
    margin-bottom: .2rem !important;
  }

  .has-margin-left-9 {
    margin-left: .2rem !important;
  }
}

@media screen and (width <= 767px) and (width <= 767px) {
  .has-padding-9-mobile {
    padding: .2rem !important;
  }

  .has-padding-top-9-mobile {
    padding-top: .2rem !important;
  }

  .has-padding-right-9-mobile {
    padding-right: .2rem !important;
  }

  .has-padding-bottom-9-mobile {
    padding-bottom: .2rem !important;
  }

  .has-padding-left-9-mobile {
    padding-left: .2rem !important;
  }

  .has-no-padding-mobile {
    padding: 0 !important;
  }

  .has-no-padding-top-mobile {
    padding-top: 0 !important;
  }

  .has-no-padding-right-mobile {
    padding-right: 0 !important;
  }

  .has-no-padding-bottom-mobile {
    padding-bottom: 0 !important;
  }

  .has-no-padding-left-mobile {
    padding-left: 0 !important;
  }

  .has-margin-9-mobile {
    margin: .2rem !important;
  }

  .has-margin-top-9-mobile {
    margin-top: .2rem !important;
  }

  .has-margin-right-9-mobile {
    margin-right: .2rem !important;
  }

  .has-margin-bottom-9-mobile {
    margin-bottom: .2rem !important;
  }

  .has-margin-left-9-mobile {
    margin-left: .2rem !important;
  }

  .has-no-margin-mobile {
    margin: 0 !important;
  }

  .has-no-margin-top-mobile {
    margin-top: 0 !important;
  }

  .has-no-margin-right-mobile {
    margin-right: 0 !important;
  }

  .has-no-margin-bottom-mobile {
    margin-bottom: 0 !important;
  }

  .has-no-margin-left-mobile {
    margin-left: 0 !important;
  }
}

@media screen and (width >= 768px), print {
  .is-size-1-tablet {
    font-size: 4rem !important;
  }

  .has-padding-1 {
    padding: 4rem !important;
  }

  .has-padding-top-1 {
    padding-top: 4rem !important;
  }

  .has-padding-right-1 {
    padding-right: 4rem !important;
  }

  .has-padding-bottom-1 {
    padding-bottom: 4rem !important;
  }

  .has-padding-left-1 {
    padding-left: 4rem !important;
  }

  .has-margin-1 {
    margin: 4rem !important;
  }

  .has-margin-top-1 {
    margin-top: 4rem !important;
  }

  .has-margin-right-1 {
    margin-right: 4rem !important;
  }

  .has-margin-bottom-1 {
    margin-bottom: 4rem !important;
  }

  .has-margin-left-1 {
    margin-left: 4rem !important;
  }
}

@media screen and (width >= 768px) and (width <= 767px) {
  .has-padding-1-mobile {
    padding: 4rem !important;
  }

  .has-padding-top-1-mobile {
    padding-top: 4rem !important;
  }

  .has-padding-right-1-mobile {
    padding-right: 4rem !important;
  }

  .has-padding-bottom-1-mobile {
    padding-bottom: 4rem !important;
  }

  .has-padding-left-1-mobile {
    padding-left: 4rem !important;
  }

  .has-no-padding-mobile {
    padding: 0 !important;
  }

  .has-no-padding-top-mobile {
    padding-top: 0 !important;
  }

  .has-no-padding-right-mobile {
    padding-right: 0 !important;
  }

  .has-no-padding-bottom-mobile {
    padding-bottom: 0 !important;
  }

  .has-no-padding-left-mobile {
    padding-left: 0 !important;
  }

  .has-margin-1-mobile {
    margin: 4rem !important;
  }

  .has-margin-top-1-mobile {
    margin-top: 4rem !important;
  }

  .has-margin-right-1-mobile {
    margin-right: 4rem !important;
  }

  .has-margin-bottom-1-mobile {
    margin-bottom: 4rem !important;
  }

  .has-margin-left-1-mobile {
    margin-left: 4rem !important;
  }

  .has-no-margin-mobile {
    margin: 0 !important;
  }

  .has-no-margin-top-mobile {
    margin-top: 0 !important;
  }

  .has-no-margin-right-mobile {
    margin-right: 0 !important;
  }

  .has-no-margin-bottom-mobile {
    margin-bottom: 0 !important;
  }

  .has-no-margin-left-mobile {
    margin-left: 0 !important;
  }
}

@media screen and (width >= 768px), print {
  .is-size-2-tablet {
    font-size: 3rem !important;
  }

  .has-padding-2 {
    padding: 3rem !important;
  }

  .has-padding-top-2 {
    padding-top: 3rem !important;
  }

  .has-padding-right-2 {
    padding-right: 3rem !important;
  }

  .has-padding-bottom-2 {
    padding-bottom: 3rem !important;
  }

  .has-padding-left-2 {
    padding-left: 3rem !important;
  }

  .has-margin-2 {
    margin: 3rem !important;
  }

  .has-margin-top-2 {
    margin-top: 3rem !important;
  }

  .has-margin-right-2 {
    margin-right: 3rem !important;
  }

  .has-margin-bottom-2 {
    margin-bottom: 3rem !important;
  }

  .has-margin-left-2 {
    margin-left: 3rem !important;
  }
}

@media screen and (width >= 768px) and (width <= 767px) {
  .has-padding-2-mobile {
    padding: 3rem !important;
  }

  .has-padding-top-2-mobile {
    padding-top: 3rem !important;
  }

  .has-padding-right-2-mobile {
    padding-right: 3rem !important;
  }

  .has-padding-bottom-2-mobile {
    padding-bottom: 3rem !important;
  }

  .has-padding-left-2-mobile {
    padding-left: 3rem !important;
  }

  .has-no-padding-mobile {
    padding: 0 !important;
  }

  .has-no-padding-top-mobile {
    padding-top: 0 !important;
  }

  .has-no-padding-right-mobile {
    padding-right: 0 !important;
  }

  .has-no-padding-bottom-mobile {
    padding-bottom: 0 !important;
  }

  .has-no-padding-left-mobile {
    padding-left: 0 !important;
  }

  .has-margin-2-mobile {
    margin: 3rem !important;
  }

  .has-margin-top-2-mobile {
    margin-top: 3rem !important;
  }

  .has-margin-right-2-mobile {
    margin-right: 3rem !important;
  }

  .has-margin-bottom-2-mobile {
    margin-bottom: 3rem !important;
  }

  .has-margin-left-2-mobile {
    margin-left: 3rem !important;
  }

  .has-no-margin-mobile {
    margin: 0 !important;
  }

  .has-no-margin-top-mobile {
    margin-top: 0 !important;
  }

  .has-no-margin-right-mobile {
    margin-right: 0 !important;
  }

  .has-no-margin-bottom-mobile {
    margin-bottom: 0 !important;
  }

  .has-no-margin-left-mobile {
    margin-left: 0 !important;
  }
}

@media screen and (width >= 768px), print {
  .is-size-3-tablet {
    font-size: 2.5rem !important;
  }

  .has-padding-3 {
    padding: 2.5rem !important;
  }

  .has-padding-top-3 {
    padding-top: 2.5rem !important;
  }

  .has-padding-right-3 {
    padding-right: 2.5rem !important;
  }

  .has-padding-bottom-3 {
    padding-bottom: 2.5rem !important;
  }

  .has-padding-left-3 {
    padding-left: 2.5rem !important;
  }

  .has-margin-3 {
    margin: 2.5rem !important;
  }

  .has-margin-top-3 {
    margin-top: 2.5rem !important;
  }

  .has-margin-right-3 {
    margin-right: 2.5rem !important;
  }

  .has-margin-bottom-3 {
    margin-bottom: 2.5rem !important;
  }

  .has-margin-left-3 {
    margin-left: 2.5rem !important;
  }
}

@media screen and (width >= 768px) and (width <= 767px) {
  .has-padding-3-mobile {
    padding: 2.5rem !important;
  }

  .has-padding-top-3-mobile {
    padding-top: 2.5rem !important;
  }

  .has-padding-right-3-mobile {
    padding-right: 2.5rem !important;
  }

  .has-padding-bottom-3-mobile {
    padding-bottom: 2.5rem !important;
  }

  .has-padding-left-3-mobile {
    padding-left: 2.5rem !important;
  }

  .has-no-padding-mobile {
    padding: 0 !important;
  }

  .has-no-padding-top-mobile {
    padding-top: 0 !important;
  }

  .has-no-padding-right-mobile {
    padding-right: 0 !important;
  }

  .has-no-padding-bottom-mobile {
    padding-bottom: 0 !important;
  }

  .has-no-padding-left-mobile {
    padding-left: 0 !important;
  }

  .has-margin-3-mobile {
    margin: 2.5rem !important;
  }

  .has-margin-top-3-mobile {
    margin-top: 2.5rem !important;
  }

  .has-margin-right-3-mobile {
    margin-right: 2.5rem !important;
  }

  .has-margin-bottom-3-mobile {
    margin-bottom: 2.5rem !important;
  }

  .has-margin-left-3-mobile {
    margin-left: 2.5rem !important;
  }

  .has-no-margin-mobile {
    margin: 0 !important;
  }

  .has-no-margin-top-mobile {
    margin-top: 0 !important;
  }

  .has-no-margin-right-mobile {
    margin-right: 0 !important;
  }

  .has-no-margin-bottom-mobile {
    margin-bottom: 0 !important;
  }

  .has-no-margin-left-mobile {
    margin-left: 0 !important;
  }
}

@media screen and (width >= 768px), print {
  .is-size-4-tablet {
    font-size: 1.5rem !important;
  }

  .has-padding-4 {
    padding: 1.5rem !important;
  }

  .has-padding-top-4 {
    padding-top: 1.5rem !important;
  }

  .has-padding-right-4 {
    padding-right: 1.5rem !important;
  }

  .has-padding-bottom-4 {
    padding-bottom: 1.5rem !important;
  }

  .has-padding-left-4 {
    padding-left: 1.5rem !important;
  }

  .has-margin-4 {
    margin: 1.5rem !important;
  }

  .has-margin-top-4 {
    margin-top: 1.5rem !important;
  }

  .has-margin-right-4 {
    margin-right: 1.5rem !important;
  }

  .has-margin-bottom-4 {
    margin-bottom: 1.5rem !important;
  }

  .has-margin-left-4 {
    margin-left: 1.5rem !important;
  }
}

@media screen and (width >= 768px) and (width <= 767px) {
  .has-padding-4-mobile {
    padding: 1.5rem !important;
  }

  .has-padding-top-4-mobile {
    padding-top: 1.5rem !important;
  }

  .has-padding-right-4-mobile {
    padding-right: 1.5rem !important;
  }

  .has-padding-bottom-4-mobile {
    padding-bottom: 1.5rem !important;
  }

  .has-padding-left-4-mobile {
    padding-left: 1.5rem !important;
  }

  .has-no-padding-mobile {
    padding: 0 !important;
  }

  .has-no-padding-top-mobile {
    padding-top: 0 !important;
  }

  .has-no-padding-right-mobile {
    padding-right: 0 !important;
  }

  .has-no-padding-bottom-mobile {
    padding-bottom: 0 !important;
  }

  .has-no-padding-left-mobile {
    padding-left: 0 !important;
  }

  .has-margin-4-mobile {
    margin: 1.5rem !important;
  }

  .has-margin-top-4-mobile {
    margin-top: 1.5rem !important;
  }

  .has-margin-right-4-mobile {
    margin-right: 1.5rem !important;
  }

  .has-margin-bottom-4-mobile {
    margin-bottom: 1.5rem !important;
  }

  .has-margin-left-4-mobile {
    margin-left: 1.5rem !important;
  }

  .has-no-margin-mobile {
    margin: 0 !important;
  }

  .has-no-margin-top-mobile {
    margin-top: 0 !important;
  }

  .has-no-margin-right-mobile {
    margin-right: 0 !important;
  }

  .has-no-margin-bottom-mobile {
    margin-bottom: 0 !important;
  }

  .has-no-margin-left-mobile {
    margin-left: 0 !important;
  }
}

@media screen and (width >= 768px), print {
  .is-size-5-tablet {
    font-size: 1.25rem !important;
  }

  .has-padding-5 {
    padding: 1.25rem !important;
  }

  .has-padding-top-5 {
    padding-top: 1.25rem !important;
  }

  .has-padding-right-5 {
    padding-right: 1.25rem !important;
  }

  .has-padding-bottom-5 {
    padding-bottom: 1.25rem !important;
  }

  .has-padding-left-5 {
    padding-left: 1.25rem !important;
  }

  .has-margin-5 {
    margin: 1.25rem !important;
  }

  .has-margin-top-5 {
    margin-top: 1.25rem !important;
  }

  .has-margin-right-5 {
    margin-right: 1.25rem !important;
  }

  .has-margin-bottom-5 {
    margin-bottom: 1.25rem !important;
  }

  .has-margin-left-5 {
    margin-left: 1.25rem !important;
  }
}

@media screen and (width >= 768px) and (width <= 767px) {
  .has-padding-5-mobile {
    padding: 1.25rem !important;
  }

  .has-padding-top-5-mobile {
    padding-top: 1.25rem !important;
  }

  .has-padding-right-5-mobile {
    padding-right: 1.25rem !important;
  }

  .has-padding-bottom-5-mobile {
    padding-bottom: 1.25rem !important;
  }

  .has-padding-left-5-mobile {
    padding-left: 1.25rem !important;
  }

  .has-no-padding-mobile {
    padding: 0 !important;
  }

  .has-no-padding-top-mobile {
    padding-top: 0 !important;
  }

  .has-no-padding-right-mobile {
    padding-right: 0 !important;
  }

  .has-no-padding-bottom-mobile {
    padding-bottom: 0 !important;
  }

  .has-no-padding-left-mobile {
    padding-left: 0 !important;
  }

  .has-margin-5-mobile {
    margin: 1.25rem !important;
  }

  .has-margin-top-5-mobile {
    margin-top: 1.25rem !important;
  }

  .has-margin-right-5-mobile {
    margin-right: 1.25rem !important;
  }

  .has-margin-bottom-5-mobile {
    margin-bottom: 1.25rem !important;
  }

  .has-margin-left-5-mobile {
    margin-left: 1.25rem !important;
  }

  .has-no-margin-mobile {
    margin: 0 !important;
  }

  .has-no-margin-top-mobile {
    margin-top: 0 !important;
  }

  .has-no-margin-right-mobile {
    margin-right: 0 !important;
  }

  .has-no-margin-bottom-mobile {
    margin-bottom: 0 !important;
  }

  .has-no-margin-left-mobile {
    margin-left: 0 !important;
  }
}

@media screen and (width >= 768px), print {
  .is-size-6-tablet {
    font-size: 1rem !important;
  }

  .has-padding-6 {
    padding: 1rem !important;
  }

  .has-padding-top-6 {
    padding-top: 1rem !important;
  }

  .has-padding-right-6 {
    padding-right: 1rem !important;
  }

  .has-padding-bottom-6 {
    padding-bottom: 1rem !important;
  }

  .has-padding-left-6 {
    padding-left: 1rem !important;
  }

  .has-margin-6 {
    margin: 1rem !important;
  }

  .has-margin-top-6 {
    margin-top: 1rem !important;
  }

  .has-margin-right-6 {
    margin-right: 1rem !important;
  }

  .has-margin-bottom-6 {
    margin-bottom: 1rem !important;
  }

  .has-margin-left-6 {
    margin-left: 1rem !important;
  }
}

@media screen and (width >= 768px) and (width <= 767px) {
  .has-padding-6-mobile {
    padding: 1rem !important;
  }

  .has-padding-top-6-mobile {
    padding-top: 1rem !important;
  }

  .has-padding-right-6-mobile {
    padding-right: 1rem !important;
  }

  .has-padding-bottom-6-mobile {
    padding-bottom: 1rem !important;
  }

  .has-padding-left-6-mobile {
    padding-left: 1rem !important;
  }

  .has-no-padding-mobile {
    padding: 0 !important;
  }

  .has-no-padding-top-mobile {
    padding-top: 0 !important;
  }

  .has-no-padding-right-mobile {
    padding-right: 0 !important;
  }

  .has-no-padding-bottom-mobile {
    padding-bottom: 0 !important;
  }

  .has-no-padding-left-mobile {
    padding-left: 0 !important;
  }

  .has-margin-6-mobile {
    margin: 1rem !important;
  }

  .has-margin-top-6-mobile {
    margin-top: 1rem !important;
  }

  .has-margin-right-6-mobile {
    margin-right: 1rem !important;
  }

  .has-margin-bottom-6-mobile {
    margin-bottom: 1rem !important;
  }

  .has-margin-left-6-mobile {
    margin-left: 1rem !important;
  }

  .has-no-margin-mobile {
    margin: 0 !important;
  }

  .has-no-margin-top-mobile {
    margin-top: 0 !important;
  }

  .has-no-margin-right-mobile {
    margin-right: 0 !important;
  }

  .has-no-margin-bottom-mobile {
    margin-bottom: 0 !important;
  }

  .has-no-margin-left-mobile {
    margin-left: 0 !important;
  }
}

@media screen and (width >= 768px), print {
  .is-size-7-tablet {
    font-size: .75rem !important;
  }

  .has-padding-7 {
    padding: .75rem !important;
  }

  .has-padding-top-7 {
    padding-top: .75rem !important;
  }

  .has-padding-right-7 {
    padding-right: .75rem !important;
  }

  .has-padding-bottom-7 {
    padding-bottom: .75rem !important;
  }

  .has-padding-left-7 {
    padding-left: .75rem !important;
  }

  .has-margin-7 {
    margin: .75rem !important;
  }

  .has-margin-top-7 {
    margin-top: .75rem !important;
  }

  .has-margin-right-7 {
    margin-right: .75rem !important;
  }

  .has-margin-bottom-7 {
    margin-bottom: .75rem !important;
  }

  .has-margin-left-7 {
    margin-left: .75rem !important;
  }
}

@media screen and (width >= 768px) and (width <= 767px) {
  .has-padding-7-mobile {
    padding: .75rem !important;
  }

  .has-padding-top-7-mobile {
    padding-top: .75rem !important;
  }

  .has-padding-right-7-mobile {
    padding-right: .75rem !important;
  }

  .has-padding-bottom-7-mobile {
    padding-bottom: .75rem !important;
  }

  .has-padding-left-7-mobile {
    padding-left: .75rem !important;
  }

  .has-no-padding-mobile {
    padding: 0 !important;
  }

  .has-no-padding-top-mobile {
    padding-top: 0 !important;
  }

  .has-no-padding-right-mobile {
    padding-right: 0 !important;
  }

  .has-no-padding-bottom-mobile {
    padding-bottom: 0 !important;
  }

  .has-no-padding-left-mobile {
    padding-left: 0 !important;
  }

  .has-margin-7-mobile {
    margin: .75rem !important;
  }

  .has-margin-top-7-mobile {
    margin-top: .75rem !important;
  }

  .has-margin-right-7-mobile {
    margin-right: .75rem !important;
  }

  .has-margin-bottom-7-mobile {
    margin-bottom: .75rem !important;
  }

  .has-margin-left-7-mobile {
    margin-left: .75rem !important;
  }

  .has-no-margin-mobile {
    margin: 0 !important;
  }

  .has-no-margin-top-mobile {
    margin-top: 0 !important;
  }

  .has-no-margin-right-mobile {
    margin-right: 0 !important;
  }

  .has-no-margin-bottom-mobile {
    margin-bottom: 0 !important;
  }

  .has-no-margin-left-mobile {
    margin-left: 0 !important;
  }
}

@media screen and (width >= 768px), print {
  .is-size-8-tablet {
    font-size: .5rem !important;
  }

  .has-padding-8 {
    padding: .5rem !important;
  }

  .has-padding-top-8 {
    padding-top: .5rem !important;
  }

  .has-padding-right-8 {
    padding-right: .5rem !important;
  }

  .has-padding-bottom-8 {
    padding-bottom: .5rem !important;
  }

  .has-padding-left-8 {
    padding-left: .5rem !important;
  }

  .has-margin-8 {
    margin: .5rem !important;
  }

  .has-margin-top-8 {
    margin-top: .5rem !important;
  }

  .has-margin-right-8 {
    margin-right: .5rem !important;
  }

  .has-margin-bottom-8 {
    margin-bottom: .5rem !important;
  }

  .has-margin-left-8 {
    margin-left: .5rem !important;
  }
}

@media screen and (width >= 768px) and (width <= 767px) {
  .has-padding-8-mobile {
    padding: .5rem !important;
  }

  .has-padding-top-8-mobile {
    padding-top: .5rem !important;
  }

  .has-padding-right-8-mobile {
    padding-right: .5rem !important;
  }

  .has-padding-bottom-8-mobile {
    padding-bottom: .5rem !important;
  }

  .has-padding-left-8-mobile {
    padding-left: .5rem !important;
  }

  .has-no-padding-mobile {
    padding: 0 !important;
  }

  .has-no-padding-top-mobile {
    padding-top: 0 !important;
  }

  .has-no-padding-right-mobile {
    padding-right: 0 !important;
  }

  .has-no-padding-bottom-mobile {
    padding-bottom: 0 !important;
  }

  .has-no-padding-left-mobile {
    padding-left: 0 !important;
  }

  .has-margin-8-mobile {
    margin: .5rem !important;
  }

  .has-margin-top-8-mobile {
    margin-top: .5rem !important;
  }

  .has-margin-right-8-mobile {
    margin-right: .5rem !important;
  }

  .has-margin-bottom-8-mobile {
    margin-bottom: .5rem !important;
  }

  .has-margin-left-8-mobile {
    margin-left: .5rem !important;
  }

  .has-no-margin-mobile {
    margin: 0 !important;
  }

  .has-no-margin-top-mobile {
    margin-top: 0 !important;
  }

  .has-no-margin-right-mobile {
    margin-right: 0 !important;
  }

  .has-no-margin-bottom-mobile {
    margin-bottom: 0 !important;
  }

  .has-no-margin-left-mobile {
    margin-left: 0 !important;
  }
}

@media screen and (width >= 768px), print {
  .is-size-9-tablet {
    font-size: .2rem !important;
  }

  .has-padding-9 {
    padding: .2rem !important;
  }

  .has-padding-top-9 {
    padding-top: .2rem !important;
  }

  .has-padding-right-9 {
    padding-right: .2rem !important;
  }

  .has-padding-bottom-9 {
    padding-bottom: .2rem !important;
  }

  .has-padding-left-9 {
    padding-left: .2rem !important;
  }

  .has-margin-9 {
    margin: .2rem !important;
  }

  .has-margin-top-9 {
    margin-top: .2rem !important;
  }

  .has-margin-right-9 {
    margin-right: .2rem !important;
  }

  .has-margin-bottom-9 {
    margin-bottom: .2rem !important;
  }

  .has-margin-left-9 {
    margin-left: .2rem !important;
  }
}

@media screen and (width >= 768px) and (width <= 767px) {
  .has-padding-9-mobile {
    padding: .2rem !important;
  }

  .has-padding-top-9-mobile {
    padding-top: .2rem !important;
  }

  .has-padding-right-9-mobile {
    padding-right: .2rem !important;
  }

  .has-padding-bottom-9-mobile {
    padding-bottom: .2rem !important;
  }

  .has-padding-left-9-mobile {
    padding-left: .2rem !important;
  }

  .has-no-padding-mobile {
    padding: 0 !important;
  }

  .has-no-padding-top-mobile {
    padding-top: 0 !important;
  }

  .has-no-padding-right-mobile {
    padding-right: 0 !important;
  }

  .has-no-padding-bottom-mobile {
    padding-bottom: 0 !important;
  }

  .has-no-padding-left-mobile {
    padding-left: 0 !important;
  }

  .has-margin-9-mobile {
    margin: .2rem !important;
  }

  .has-margin-top-9-mobile {
    margin-top: .2rem !important;
  }

  .has-margin-right-9-mobile {
    margin-right: .2rem !important;
  }

  .has-margin-bottom-9-mobile {
    margin-bottom: .2rem !important;
  }

  .has-margin-left-9-mobile {
    margin-left: .2rem !important;
  }

  .has-no-margin-mobile {
    margin: 0 !important;
  }

  .has-no-margin-top-mobile {
    margin-top: 0 !important;
  }

  .has-no-margin-right-mobile {
    margin-right: 0 !important;
  }

  .has-no-margin-bottom-mobile {
    margin-bottom: 0 !important;
  }

  .has-no-margin-left-mobile {
    margin-left: 0 !important;
  }
}

@media screen and (width <= 989px) {
  .is-size-1-touch {
    font-size: 4rem !important;
  }

  .has-padding-1 {
    padding: 4rem !important;
  }

  .has-padding-top-1 {
    padding-top: 4rem !important;
  }

  .has-padding-right-1 {
    padding-right: 4rem !important;
  }

  .has-padding-bottom-1 {
    padding-bottom: 4rem !important;
  }

  .has-padding-left-1 {
    padding-left: 4rem !important;
  }

  .has-margin-1 {
    margin: 4rem !important;
  }

  .has-margin-top-1 {
    margin-top: 4rem !important;
  }

  .has-margin-right-1 {
    margin-right: 4rem !important;
  }

  .has-margin-bottom-1 {
    margin-bottom: 4rem !important;
  }

  .has-margin-left-1 {
    margin-left: 4rem !important;
  }
}

@media screen and (width <= 989px) and (width <= 767px) {
  .has-padding-1-mobile {
    padding: 4rem !important;
  }

  .has-padding-top-1-mobile {
    padding-top: 4rem !important;
  }

  .has-padding-right-1-mobile {
    padding-right: 4rem !important;
  }

  .has-padding-bottom-1-mobile {
    padding-bottom: 4rem !important;
  }

  .has-padding-left-1-mobile {
    padding-left: 4rem !important;
  }

  .has-no-padding-mobile {
    padding: 0 !important;
  }

  .has-no-padding-top-mobile {
    padding-top: 0 !important;
  }

  .has-no-padding-right-mobile {
    padding-right: 0 !important;
  }

  .has-no-padding-bottom-mobile {
    padding-bottom: 0 !important;
  }

  .has-no-padding-left-mobile {
    padding-left: 0 !important;
  }

  .has-margin-1-mobile {
    margin: 4rem !important;
  }

  .has-margin-top-1-mobile {
    margin-top: 4rem !important;
  }

  .has-margin-right-1-mobile {
    margin-right: 4rem !important;
  }

  .has-margin-bottom-1-mobile {
    margin-bottom: 4rem !important;
  }

  .has-margin-left-1-mobile {
    margin-left: 4rem !important;
  }

  .has-no-margin-mobile {
    margin: 0 !important;
  }

  .has-no-margin-top-mobile {
    margin-top: 0 !important;
  }

  .has-no-margin-right-mobile {
    margin-right: 0 !important;
  }

  .has-no-margin-bottom-mobile {
    margin-bottom: 0 !important;
  }

  .has-no-margin-left-mobile {
    margin-left: 0 !important;
  }
}

@media screen and (width <= 989px) {
  .is-size-2-touch {
    font-size: 3rem !important;
  }

  .has-padding-2 {
    padding: 3rem !important;
  }

  .has-padding-top-2 {
    padding-top: 3rem !important;
  }

  .has-padding-right-2 {
    padding-right: 3rem !important;
  }

  .has-padding-bottom-2 {
    padding-bottom: 3rem !important;
  }

  .has-padding-left-2 {
    padding-left: 3rem !important;
  }

  .has-margin-2 {
    margin: 3rem !important;
  }

  .has-margin-top-2 {
    margin-top: 3rem !important;
  }

  .has-margin-right-2 {
    margin-right: 3rem !important;
  }

  .has-margin-bottom-2 {
    margin-bottom: 3rem !important;
  }

  .has-margin-left-2 {
    margin-left: 3rem !important;
  }
}

@media screen and (width <= 989px) and (width <= 767px) {
  .has-padding-2-mobile {
    padding: 3rem !important;
  }

  .has-padding-top-2-mobile {
    padding-top: 3rem !important;
  }

  .has-padding-right-2-mobile {
    padding-right: 3rem !important;
  }

  .has-padding-bottom-2-mobile {
    padding-bottom: 3rem !important;
  }

  .has-padding-left-2-mobile {
    padding-left: 3rem !important;
  }

  .has-no-padding-mobile {
    padding: 0 !important;
  }

  .has-no-padding-top-mobile {
    padding-top: 0 !important;
  }

  .has-no-padding-right-mobile {
    padding-right: 0 !important;
  }

  .has-no-padding-bottom-mobile {
    padding-bottom: 0 !important;
  }

  .has-no-padding-left-mobile {
    padding-left: 0 !important;
  }

  .has-margin-2-mobile {
    margin: 3rem !important;
  }

  .has-margin-top-2-mobile {
    margin-top: 3rem !important;
  }

  .has-margin-right-2-mobile {
    margin-right: 3rem !important;
  }

  .has-margin-bottom-2-mobile {
    margin-bottom: 3rem !important;
  }

  .has-margin-left-2-mobile {
    margin-left: 3rem !important;
  }

  .has-no-margin-mobile {
    margin: 0 !important;
  }

  .has-no-margin-top-mobile {
    margin-top: 0 !important;
  }

  .has-no-margin-right-mobile {
    margin-right: 0 !important;
  }

  .has-no-margin-bottom-mobile {
    margin-bottom: 0 !important;
  }

  .has-no-margin-left-mobile {
    margin-left: 0 !important;
  }
}

@media screen and (width <= 989px) {
  .is-size-3-touch {
    font-size: 2.5rem !important;
  }

  .has-padding-3 {
    padding: 2.5rem !important;
  }

  .has-padding-top-3 {
    padding-top: 2.5rem !important;
  }

  .has-padding-right-3 {
    padding-right: 2.5rem !important;
  }

  .has-padding-bottom-3 {
    padding-bottom: 2.5rem !important;
  }

  .has-padding-left-3 {
    padding-left: 2.5rem !important;
  }

  .has-margin-3 {
    margin: 2.5rem !important;
  }

  .has-margin-top-3 {
    margin-top: 2.5rem !important;
  }

  .has-margin-right-3 {
    margin-right: 2.5rem !important;
  }

  .has-margin-bottom-3 {
    margin-bottom: 2.5rem !important;
  }

  .has-margin-left-3 {
    margin-left: 2.5rem !important;
  }
}

@media screen and (width <= 989px) and (width <= 767px) {
  .has-padding-3-mobile {
    padding: 2.5rem !important;
  }

  .has-padding-top-3-mobile {
    padding-top: 2.5rem !important;
  }

  .has-padding-right-3-mobile {
    padding-right: 2.5rem !important;
  }

  .has-padding-bottom-3-mobile {
    padding-bottom: 2.5rem !important;
  }

  .has-padding-left-3-mobile {
    padding-left: 2.5rem !important;
  }

  .has-no-padding-mobile {
    padding: 0 !important;
  }

  .has-no-padding-top-mobile {
    padding-top: 0 !important;
  }

  .has-no-padding-right-mobile {
    padding-right: 0 !important;
  }

  .has-no-padding-bottom-mobile {
    padding-bottom: 0 !important;
  }

  .has-no-padding-left-mobile {
    padding-left: 0 !important;
  }

  .has-margin-3-mobile {
    margin: 2.5rem !important;
  }

  .has-margin-top-3-mobile {
    margin-top: 2.5rem !important;
  }

  .has-margin-right-3-mobile {
    margin-right: 2.5rem !important;
  }

  .has-margin-bottom-3-mobile {
    margin-bottom: 2.5rem !important;
  }

  .has-margin-left-3-mobile {
    margin-left: 2.5rem !important;
  }

  .has-no-margin-mobile {
    margin: 0 !important;
  }

  .has-no-margin-top-mobile {
    margin-top: 0 !important;
  }

  .has-no-margin-right-mobile {
    margin-right: 0 !important;
  }

  .has-no-margin-bottom-mobile {
    margin-bottom: 0 !important;
  }

  .has-no-margin-left-mobile {
    margin-left: 0 !important;
  }
}

@media screen and (width <= 989px) {
  .is-size-4-touch {
    font-size: 1.5rem !important;
  }

  .has-padding-4 {
    padding: 1.5rem !important;
  }

  .has-padding-top-4 {
    padding-top: 1.5rem !important;
  }

  .has-padding-right-4 {
    padding-right: 1.5rem !important;
  }

  .has-padding-bottom-4 {
    padding-bottom: 1.5rem !important;
  }

  .has-padding-left-4 {
    padding-left: 1.5rem !important;
  }

  .has-margin-4 {
    margin: 1.5rem !important;
  }

  .has-margin-top-4 {
    margin-top: 1.5rem !important;
  }

  .has-margin-right-4 {
    margin-right: 1.5rem !important;
  }

  .has-margin-bottom-4 {
    margin-bottom: 1.5rem !important;
  }

  .has-margin-left-4 {
    margin-left: 1.5rem !important;
  }
}

@media screen and (width <= 989px) and (width <= 767px) {
  .has-padding-4-mobile {
    padding: 1.5rem !important;
  }

  .has-padding-top-4-mobile {
    padding-top: 1.5rem !important;
  }

  .has-padding-right-4-mobile {
    padding-right: 1.5rem !important;
  }

  .has-padding-bottom-4-mobile {
    padding-bottom: 1.5rem !important;
  }

  .has-padding-left-4-mobile {
    padding-left: 1.5rem !important;
  }

  .has-no-padding-mobile {
    padding: 0 !important;
  }

  .has-no-padding-top-mobile {
    padding-top: 0 !important;
  }

  .has-no-padding-right-mobile {
    padding-right: 0 !important;
  }

  .has-no-padding-bottom-mobile {
    padding-bottom: 0 !important;
  }

  .has-no-padding-left-mobile {
    padding-left: 0 !important;
  }

  .has-margin-4-mobile {
    margin: 1.5rem !important;
  }

  .has-margin-top-4-mobile {
    margin-top: 1.5rem !important;
  }

  .has-margin-right-4-mobile {
    margin-right: 1.5rem !important;
  }

  .has-margin-bottom-4-mobile {
    margin-bottom: 1.5rem !important;
  }

  .has-margin-left-4-mobile {
    margin-left: 1.5rem !important;
  }

  .has-no-margin-mobile {
    margin: 0 !important;
  }

  .has-no-margin-top-mobile {
    margin-top: 0 !important;
  }

  .has-no-margin-right-mobile {
    margin-right: 0 !important;
  }

  .has-no-margin-bottom-mobile {
    margin-bottom: 0 !important;
  }

  .has-no-margin-left-mobile {
    margin-left: 0 !important;
  }
}

@media screen and (width <= 989px) {
  .is-size-5-touch {
    font-size: 1.25rem !important;
  }

  .has-padding-5 {
    padding: 1.25rem !important;
  }

  .has-padding-top-5 {
    padding-top: 1.25rem !important;
  }

  .has-padding-right-5 {
    padding-right: 1.25rem !important;
  }

  .has-padding-bottom-5 {
    padding-bottom: 1.25rem !important;
  }

  .has-padding-left-5 {
    padding-left: 1.25rem !important;
  }

  .has-margin-5 {
    margin: 1.25rem !important;
  }

  .has-margin-top-5 {
    margin-top: 1.25rem !important;
  }

  .has-margin-right-5 {
    margin-right: 1.25rem !important;
  }

  .has-margin-bottom-5 {
    margin-bottom: 1.25rem !important;
  }

  .has-margin-left-5 {
    margin-left: 1.25rem !important;
  }
}

@media screen and (width <= 989px) and (width <= 767px) {
  .has-padding-5-mobile {
    padding: 1.25rem !important;
  }

  .has-padding-top-5-mobile {
    padding-top: 1.25rem !important;
  }

  .has-padding-right-5-mobile {
    padding-right: 1.25rem !important;
  }

  .has-padding-bottom-5-mobile {
    padding-bottom: 1.25rem !important;
  }

  .has-padding-left-5-mobile {
    padding-left: 1.25rem !important;
  }

  .has-no-padding-mobile {
    padding: 0 !important;
  }

  .has-no-padding-top-mobile {
    padding-top: 0 !important;
  }

  .has-no-padding-right-mobile {
    padding-right: 0 !important;
  }

  .has-no-padding-bottom-mobile {
    padding-bottom: 0 !important;
  }

  .has-no-padding-left-mobile {
    padding-left: 0 !important;
  }

  .has-margin-5-mobile {
    margin: 1.25rem !important;
  }

  .has-margin-top-5-mobile {
    margin-top: 1.25rem !important;
  }

  .has-margin-right-5-mobile {
    margin-right: 1.25rem !important;
  }

  .has-margin-bottom-5-mobile {
    margin-bottom: 1.25rem !important;
  }

  .has-margin-left-5-mobile {
    margin-left: 1.25rem !important;
  }

  .has-no-margin-mobile {
    margin: 0 !important;
  }

  .has-no-margin-top-mobile {
    margin-top: 0 !important;
  }

  .has-no-margin-right-mobile {
    margin-right: 0 !important;
  }

  .has-no-margin-bottom-mobile {
    margin-bottom: 0 !important;
  }

  .has-no-margin-left-mobile {
    margin-left: 0 !important;
  }
}

@media screen and (width <= 989px) {
  .is-size-6-touch {
    font-size: 1rem !important;
  }

  .has-padding-6 {
    padding: 1rem !important;
  }

  .has-padding-top-6 {
    padding-top: 1rem !important;
  }

  .has-padding-right-6 {
    padding-right: 1rem !important;
  }

  .has-padding-bottom-6 {
    padding-bottom: 1rem !important;
  }

  .has-padding-left-6 {
    padding-left: 1rem !important;
  }

  .has-margin-6 {
    margin: 1rem !important;
  }

  .has-margin-top-6 {
    margin-top: 1rem !important;
  }

  .has-margin-right-6 {
    margin-right: 1rem !important;
  }

  .has-margin-bottom-6 {
    margin-bottom: 1rem !important;
  }

  .has-margin-left-6 {
    margin-left: 1rem !important;
  }
}

@media screen and (width <= 989px) and (width <= 767px) {
  .has-padding-6-mobile {
    padding: 1rem !important;
  }

  .has-padding-top-6-mobile {
    padding-top: 1rem !important;
  }

  .has-padding-right-6-mobile {
    padding-right: 1rem !important;
  }

  .has-padding-bottom-6-mobile {
    padding-bottom: 1rem !important;
  }

  .has-padding-left-6-mobile {
    padding-left: 1rem !important;
  }

  .has-no-padding-mobile {
    padding: 0 !important;
  }

  .has-no-padding-top-mobile {
    padding-top: 0 !important;
  }

  .has-no-padding-right-mobile {
    padding-right: 0 !important;
  }

  .has-no-padding-bottom-mobile {
    padding-bottom: 0 !important;
  }

  .has-no-padding-left-mobile {
    padding-left: 0 !important;
  }

  .has-margin-6-mobile {
    margin: 1rem !important;
  }

  .has-margin-top-6-mobile {
    margin-top: 1rem !important;
  }

  .has-margin-right-6-mobile {
    margin-right: 1rem !important;
  }

  .has-margin-bottom-6-mobile {
    margin-bottom: 1rem !important;
  }

  .has-margin-left-6-mobile {
    margin-left: 1rem !important;
  }

  .has-no-margin-mobile {
    margin: 0 !important;
  }

  .has-no-margin-top-mobile {
    margin-top: 0 !important;
  }

  .has-no-margin-right-mobile {
    margin-right: 0 !important;
  }

  .has-no-margin-bottom-mobile {
    margin-bottom: 0 !important;
  }

  .has-no-margin-left-mobile {
    margin-left: 0 !important;
  }
}

@media screen and (width <= 989px) {
  .is-size-7-touch {
    font-size: .75rem !important;
  }

  .has-padding-7 {
    padding: .75rem !important;
  }

  .has-padding-top-7 {
    padding-top: .75rem !important;
  }

  .has-padding-right-7 {
    padding-right: .75rem !important;
  }

  .has-padding-bottom-7 {
    padding-bottom: .75rem !important;
  }

  .has-padding-left-7 {
    padding-left: .75rem !important;
  }

  .has-margin-7 {
    margin: .75rem !important;
  }

  .has-margin-top-7 {
    margin-top: .75rem !important;
  }

  .has-margin-right-7 {
    margin-right: .75rem !important;
  }

  .has-margin-bottom-7 {
    margin-bottom: .75rem !important;
  }

  .has-margin-left-7 {
    margin-left: .75rem !important;
  }
}

@media screen and (width <= 989px) and (width <= 767px) {
  .has-padding-7-mobile {
    padding: .75rem !important;
  }

  .has-padding-top-7-mobile {
    padding-top: .75rem !important;
  }

  .has-padding-right-7-mobile {
    padding-right: .75rem !important;
  }

  .has-padding-bottom-7-mobile {
    padding-bottom: .75rem !important;
  }

  .has-padding-left-7-mobile {
    padding-left: .75rem !important;
  }

  .has-no-padding-mobile {
    padding: 0 !important;
  }

  .has-no-padding-top-mobile {
    padding-top: 0 !important;
  }

  .has-no-padding-right-mobile {
    padding-right: 0 !important;
  }

  .has-no-padding-bottom-mobile {
    padding-bottom: 0 !important;
  }

  .has-no-padding-left-mobile {
    padding-left: 0 !important;
  }

  .has-margin-7-mobile {
    margin: .75rem !important;
  }

  .has-margin-top-7-mobile {
    margin-top: .75rem !important;
  }

  .has-margin-right-7-mobile {
    margin-right: .75rem !important;
  }

  .has-margin-bottom-7-mobile {
    margin-bottom: .75rem !important;
  }

  .has-margin-left-7-mobile {
    margin-left: .75rem !important;
  }

  .has-no-margin-mobile {
    margin: 0 !important;
  }

  .has-no-margin-top-mobile {
    margin-top: 0 !important;
  }

  .has-no-margin-right-mobile {
    margin-right: 0 !important;
  }

  .has-no-margin-bottom-mobile {
    margin-bottom: 0 !important;
  }

  .has-no-margin-left-mobile {
    margin-left: 0 !important;
  }
}

@media screen and (width <= 989px) {
  .is-size-8-touch {
    font-size: .5rem !important;
  }

  .has-padding-8 {
    padding: .5rem !important;
  }

  .has-padding-top-8 {
    padding-top: .5rem !important;
  }

  .has-padding-right-8 {
    padding-right: .5rem !important;
  }

  .has-padding-bottom-8 {
    padding-bottom: .5rem !important;
  }

  .has-padding-left-8 {
    padding-left: .5rem !important;
  }

  .has-margin-8 {
    margin: .5rem !important;
  }

  .has-margin-top-8 {
    margin-top: .5rem !important;
  }

  .has-margin-right-8 {
    margin-right: .5rem !important;
  }

  .has-margin-bottom-8 {
    margin-bottom: .5rem !important;
  }

  .has-margin-left-8 {
    margin-left: .5rem !important;
  }
}

@media screen and (width <= 989px) and (width <= 767px) {
  .has-padding-8-mobile {
    padding: .5rem !important;
  }

  .has-padding-top-8-mobile {
    padding-top: .5rem !important;
  }

  .has-padding-right-8-mobile {
    padding-right: .5rem !important;
  }

  .has-padding-bottom-8-mobile {
    padding-bottom: .5rem !important;
  }

  .has-padding-left-8-mobile {
    padding-left: .5rem !important;
  }

  .has-no-padding-mobile {
    padding: 0 !important;
  }

  .has-no-padding-top-mobile {
    padding-top: 0 !important;
  }

  .has-no-padding-right-mobile {
    padding-right: 0 !important;
  }

  .has-no-padding-bottom-mobile {
    padding-bottom: 0 !important;
  }

  .has-no-padding-left-mobile {
    padding-left: 0 !important;
  }

  .has-margin-8-mobile {
    margin: .5rem !important;
  }

  .has-margin-top-8-mobile {
    margin-top: .5rem !important;
  }

  .has-margin-right-8-mobile {
    margin-right: .5rem !important;
  }

  .has-margin-bottom-8-mobile {
    margin-bottom: .5rem !important;
  }

  .has-margin-left-8-mobile {
    margin-left: .5rem !important;
  }

  .has-no-margin-mobile {
    margin: 0 !important;
  }

  .has-no-margin-top-mobile {
    margin-top: 0 !important;
  }

  .has-no-margin-right-mobile {
    margin-right: 0 !important;
  }

  .has-no-margin-bottom-mobile {
    margin-bottom: 0 !important;
  }

  .has-no-margin-left-mobile {
    margin-left: 0 !important;
  }
}

@media screen and (width <= 989px) {
  .is-size-9-touch {
    font-size: .2rem !important;
  }

  .has-padding-9 {
    padding: .2rem !important;
  }

  .has-padding-top-9 {
    padding-top: .2rem !important;
  }

  .has-padding-right-9 {
    padding-right: .2rem !important;
  }

  .has-padding-bottom-9 {
    padding-bottom: .2rem !important;
  }

  .has-padding-left-9 {
    padding-left: .2rem !important;
  }

  .has-margin-9 {
    margin: .2rem !important;
  }

  .has-margin-top-9 {
    margin-top: .2rem !important;
  }

  .has-margin-right-9 {
    margin-right: .2rem !important;
  }

  .has-margin-bottom-9 {
    margin-bottom: .2rem !important;
  }

  .has-margin-left-9 {
    margin-left: .2rem !important;
  }
}

@media screen and (width <= 989px) and (width <= 767px) {
  .has-padding-9-mobile {
    padding: .2rem !important;
  }

  .has-padding-top-9-mobile {
    padding-top: .2rem !important;
  }

  .has-padding-right-9-mobile {
    padding-right: .2rem !important;
  }

  .has-padding-bottom-9-mobile {
    padding-bottom: .2rem !important;
  }

  .has-padding-left-9-mobile {
    padding-left: .2rem !important;
  }

  .has-no-padding-mobile {
    padding: 0 !important;
  }

  .has-no-padding-top-mobile {
    padding-top: 0 !important;
  }

  .has-no-padding-right-mobile {
    padding-right: 0 !important;
  }

  .has-no-padding-bottom-mobile {
    padding-bottom: 0 !important;
  }

  .has-no-padding-left-mobile {
    padding-left: 0 !important;
  }

  .has-margin-9-mobile {
    margin: .2rem !important;
  }

  .has-margin-top-9-mobile {
    margin-top: .2rem !important;
  }

  .has-margin-right-9-mobile {
    margin-right: .2rem !important;
  }

  .has-margin-bottom-9-mobile {
    margin-bottom: .2rem !important;
  }

  .has-margin-left-9-mobile {
    margin-left: .2rem !important;
  }

  .has-no-margin-mobile {
    margin: 0 !important;
  }

  .has-no-margin-top-mobile {
    margin-top: 0 !important;
  }

  .has-no-margin-right-mobile {
    margin-right: 0 !important;
  }

  .has-no-margin-bottom-mobile {
    margin-bottom: 0 !important;
  }

  .has-no-margin-left-mobile {
    margin-left: 0 !important;
  }
}

@media screen and (width >= 990px) {
  .is-size-1-desktop {
    font-size: 4rem !important;
  }

  .has-padding-1 {
    padding: 4rem !important;
  }

  .has-padding-top-1 {
    padding-top: 4rem !important;
  }

  .has-padding-right-1 {
    padding-right: 4rem !important;
  }

  .has-padding-bottom-1 {
    padding-bottom: 4rem !important;
  }

  .has-padding-left-1 {
    padding-left: 4rem !important;
  }

  .has-margin-1 {
    margin: 4rem !important;
  }

  .has-margin-top-1 {
    margin-top: 4rem !important;
  }

  .has-margin-right-1 {
    margin-right: 4rem !important;
  }

  .has-margin-bottom-1 {
    margin-bottom: 4rem !important;
  }

  .has-margin-left-1 {
    margin-left: 4rem !important;
  }
}

@media screen and (width >= 990px) and (width <= 767px) {
  .has-padding-1-mobile {
    padding: 4rem !important;
  }

  .has-padding-top-1-mobile {
    padding-top: 4rem !important;
  }

  .has-padding-right-1-mobile {
    padding-right: 4rem !important;
  }

  .has-padding-bottom-1-mobile {
    padding-bottom: 4rem !important;
  }

  .has-padding-left-1-mobile {
    padding-left: 4rem !important;
  }

  .has-no-padding-mobile {
    padding: 0 !important;
  }

  .has-no-padding-top-mobile {
    padding-top: 0 !important;
  }

  .has-no-padding-right-mobile {
    padding-right: 0 !important;
  }

  .has-no-padding-bottom-mobile {
    padding-bottom: 0 !important;
  }

  .has-no-padding-left-mobile {
    padding-left: 0 !important;
  }

  .has-margin-1-mobile {
    margin: 4rem !important;
  }

  .has-margin-top-1-mobile {
    margin-top: 4rem !important;
  }

  .has-margin-right-1-mobile {
    margin-right: 4rem !important;
  }

  .has-margin-bottom-1-mobile {
    margin-bottom: 4rem !important;
  }

  .has-margin-left-1-mobile {
    margin-left: 4rem !important;
  }

  .has-no-margin-mobile {
    margin: 0 !important;
  }

  .has-no-margin-top-mobile {
    margin-top: 0 !important;
  }

  .has-no-margin-right-mobile {
    margin-right: 0 !important;
  }

  .has-no-margin-bottom-mobile {
    margin-bottom: 0 !important;
  }

  .has-no-margin-left-mobile {
    margin-left: 0 !important;
  }
}

@media screen and (width >= 990px) {
  .is-size-2-desktop {
    font-size: 3rem !important;
  }

  .has-padding-2 {
    padding: 3rem !important;
  }

  .has-padding-top-2 {
    padding-top: 3rem !important;
  }

  .has-padding-right-2 {
    padding-right: 3rem !important;
  }

  .has-padding-bottom-2 {
    padding-bottom: 3rem !important;
  }

  .has-padding-left-2 {
    padding-left: 3rem !important;
  }

  .has-margin-2 {
    margin: 3rem !important;
  }

  .has-margin-top-2 {
    margin-top: 3rem !important;
  }

  .has-margin-right-2 {
    margin-right: 3rem !important;
  }

  .has-margin-bottom-2 {
    margin-bottom: 3rem !important;
  }

  .has-margin-left-2 {
    margin-left: 3rem !important;
  }
}

@media screen and (width >= 990px) and (width <= 767px) {
  .has-padding-2-mobile {
    padding: 3rem !important;
  }

  .has-padding-top-2-mobile {
    padding-top: 3rem !important;
  }

  .has-padding-right-2-mobile {
    padding-right: 3rem !important;
  }

  .has-padding-bottom-2-mobile {
    padding-bottom: 3rem !important;
  }

  .has-padding-left-2-mobile {
    padding-left: 3rem !important;
  }

  .has-no-padding-mobile {
    padding: 0 !important;
  }

  .has-no-padding-top-mobile {
    padding-top: 0 !important;
  }

  .has-no-padding-right-mobile {
    padding-right: 0 !important;
  }

  .has-no-padding-bottom-mobile {
    padding-bottom: 0 !important;
  }

  .has-no-padding-left-mobile {
    padding-left: 0 !important;
  }

  .has-margin-2-mobile {
    margin: 3rem !important;
  }

  .has-margin-top-2-mobile {
    margin-top: 3rem !important;
  }

  .has-margin-right-2-mobile {
    margin-right: 3rem !important;
  }

  .has-margin-bottom-2-mobile {
    margin-bottom: 3rem !important;
  }

  .has-margin-left-2-mobile {
    margin-left: 3rem !important;
  }

  .has-no-margin-mobile {
    margin: 0 !important;
  }

  .has-no-margin-top-mobile {
    margin-top: 0 !important;
  }

  .has-no-margin-right-mobile {
    margin-right: 0 !important;
  }

  .has-no-margin-bottom-mobile {
    margin-bottom: 0 !important;
  }

  .has-no-margin-left-mobile {
    margin-left: 0 !important;
  }
}

@media screen and (width >= 990px) {
  .is-size-3-desktop {
    font-size: 2.5rem !important;
  }

  .has-padding-3 {
    padding: 2.5rem !important;
  }

  .has-padding-top-3 {
    padding-top: 2.5rem !important;
  }

  .has-padding-right-3 {
    padding-right: 2.5rem !important;
  }

  .has-padding-bottom-3 {
    padding-bottom: 2.5rem !important;
  }

  .has-padding-left-3 {
    padding-left: 2.5rem !important;
  }

  .has-margin-3 {
    margin: 2.5rem !important;
  }

  .has-margin-top-3 {
    margin-top: 2.5rem !important;
  }

  .has-margin-right-3 {
    margin-right: 2.5rem !important;
  }

  .has-margin-bottom-3 {
    margin-bottom: 2.5rem !important;
  }

  .has-margin-left-3 {
    margin-left: 2.5rem !important;
  }
}

@media screen and (width >= 990px) and (width <= 767px) {
  .has-padding-3-mobile {
    padding: 2.5rem !important;
  }

  .has-padding-top-3-mobile {
    padding-top: 2.5rem !important;
  }

  .has-padding-right-3-mobile {
    padding-right: 2.5rem !important;
  }

  .has-padding-bottom-3-mobile {
    padding-bottom: 2.5rem !important;
  }

  .has-padding-left-3-mobile {
    padding-left: 2.5rem !important;
  }

  .has-no-padding-mobile {
    padding: 0 !important;
  }

  .has-no-padding-top-mobile {
    padding-top: 0 !important;
  }

  .has-no-padding-right-mobile {
    padding-right: 0 !important;
  }

  .has-no-padding-bottom-mobile {
    padding-bottom: 0 !important;
  }

  .has-no-padding-left-mobile {
    padding-left: 0 !important;
  }

  .has-margin-3-mobile {
    margin: 2.5rem !important;
  }

  .has-margin-top-3-mobile {
    margin-top: 2.5rem !important;
  }

  .has-margin-right-3-mobile {
    margin-right: 2.5rem !important;
  }

  .has-margin-bottom-3-mobile {
    margin-bottom: 2.5rem !important;
  }

  .has-margin-left-3-mobile {
    margin-left: 2.5rem !important;
  }

  .has-no-margin-mobile {
    margin: 0 !important;
  }

  .has-no-margin-top-mobile {
    margin-top: 0 !important;
  }

  .has-no-margin-right-mobile {
    margin-right: 0 !important;
  }

  .has-no-margin-bottom-mobile {
    margin-bottom: 0 !important;
  }

  .has-no-margin-left-mobile {
    margin-left: 0 !important;
  }
}

@media screen and (width >= 990px) {
  .is-size-4-desktop {
    font-size: 1.5rem !important;
  }

  .has-padding-4 {
    padding: 1.5rem !important;
  }

  .has-padding-top-4 {
    padding-top: 1.5rem !important;
  }

  .has-padding-right-4 {
    padding-right: 1.5rem !important;
  }

  .has-padding-bottom-4 {
    padding-bottom: 1.5rem !important;
  }

  .has-padding-left-4 {
    padding-left: 1.5rem !important;
  }

  .has-margin-4 {
    margin: 1.5rem !important;
  }

  .has-margin-top-4 {
    margin-top: 1.5rem !important;
  }

  .has-margin-right-4 {
    margin-right: 1.5rem !important;
  }

  .has-margin-bottom-4 {
    margin-bottom: 1.5rem !important;
  }

  .has-margin-left-4 {
    margin-left: 1.5rem !important;
  }
}

@media screen and (width >= 990px) and (width <= 767px) {
  .has-padding-4-mobile {
    padding: 1.5rem !important;
  }

  .has-padding-top-4-mobile {
    padding-top: 1.5rem !important;
  }

  .has-padding-right-4-mobile {
    padding-right: 1.5rem !important;
  }

  .has-padding-bottom-4-mobile {
    padding-bottom: 1.5rem !important;
  }

  .has-padding-left-4-mobile {
    padding-left: 1.5rem !important;
  }

  .has-no-padding-mobile {
    padding: 0 !important;
  }

  .has-no-padding-top-mobile {
    padding-top: 0 !important;
  }

  .has-no-padding-right-mobile {
    padding-right: 0 !important;
  }

  .has-no-padding-bottom-mobile {
    padding-bottom: 0 !important;
  }

  .has-no-padding-left-mobile {
    padding-left: 0 !important;
  }

  .has-margin-4-mobile {
    margin: 1.5rem !important;
  }

  .has-margin-top-4-mobile {
    margin-top: 1.5rem !important;
  }

  .has-margin-right-4-mobile {
    margin-right: 1.5rem !important;
  }

  .has-margin-bottom-4-mobile {
    margin-bottom: 1.5rem !important;
  }

  .has-margin-left-4-mobile {
    margin-left: 1.5rem !important;
  }

  .has-no-margin-mobile {
    margin: 0 !important;
  }

  .has-no-margin-top-mobile {
    margin-top: 0 !important;
  }

  .has-no-margin-right-mobile {
    margin-right: 0 !important;
  }

  .has-no-margin-bottom-mobile {
    margin-bottom: 0 !important;
  }

  .has-no-margin-left-mobile {
    margin-left: 0 !important;
  }
}

@media screen and (width >= 990px) {
  .is-size-5-desktop {
    font-size: 1.25rem !important;
  }

  .has-padding-5 {
    padding: 1.25rem !important;
  }

  .has-padding-top-5 {
    padding-top: 1.25rem !important;
  }

  .has-padding-right-5 {
    padding-right: 1.25rem !important;
  }

  .has-padding-bottom-5 {
    padding-bottom: 1.25rem !important;
  }

  .has-padding-left-5 {
    padding-left: 1.25rem !important;
  }

  .has-margin-5 {
    margin: 1.25rem !important;
  }

  .has-margin-top-5 {
    margin-top: 1.25rem !important;
  }

  .has-margin-right-5 {
    margin-right: 1.25rem !important;
  }

  .has-margin-bottom-5 {
    margin-bottom: 1.25rem !important;
  }

  .has-margin-left-5 {
    margin-left: 1.25rem !important;
  }
}

@media screen and (width >= 990px) and (width <= 767px) {
  .has-padding-5-mobile {
    padding: 1.25rem !important;
  }

  .has-padding-top-5-mobile {
    padding-top: 1.25rem !important;
  }

  .has-padding-right-5-mobile {
    padding-right: 1.25rem !important;
  }

  .has-padding-bottom-5-mobile {
    padding-bottom: 1.25rem !important;
  }

  .has-padding-left-5-mobile {
    padding-left: 1.25rem !important;
  }

  .has-no-padding-mobile {
    padding: 0 !important;
  }

  .has-no-padding-top-mobile {
    padding-top: 0 !important;
  }

  .has-no-padding-right-mobile {
    padding-right: 0 !important;
  }

  .has-no-padding-bottom-mobile {
    padding-bottom: 0 !important;
  }

  .has-no-padding-left-mobile {
    padding-left: 0 !important;
  }

  .has-margin-5-mobile {
    margin: 1.25rem !important;
  }

  .has-margin-top-5-mobile {
    margin-top: 1.25rem !important;
  }

  .has-margin-right-5-mobile {
    margin-right: 1.25rem !important;
  }

  .has-margin-bottom-5-mobile {
    margin-bottom: 1.25rem !important;
  }

  .has-margin-left-5-mobile {
    margin-left: 1.25rem !important;
  }

  .has-no-margin-mobile {
    margin: 0 !important;
  }

  .has-no-margin-top-mobile {
    margin-top: 0 !important;
  }

  .has-no-margin-right-mobile {
    margin-right: 0 !important;
  }

  .has-no-margin-bottom-mobile {
    margin-bottom: 0 !important;
  }

  .has-no-margin-left-mobile {
    margin-left: 0 !important;
  }
}

@media screen and (width >= 990px) {
  .is-size-6-desktop {
    font-size: 1rem !important;
  }

  .has-padding-6 {
    padding: 1rem !important;
  }

  .has-padding-top-6 {
    padding-top: 1rem !important;
  }

  .has-padding-right-6 {
    padding-right: 1rem !important;
  }

  .has-padding-bottom-6 {
    padding-bottom: 1rem !important;
  }

  .has-padding-left-6 {
    padding-left: 1rem !important;
  }

  .has-margin-6 {
    margin: 1rem !important;
  }

  .has-margin-top-6 {
    margin-top: 1rem !important;
  }

  .has-margin-right-6 {
    margin-right: 1rem !important;
  }

  .has-margin-bottom-6 {
    margin-bottom: 1rem !important;
  }

  .has-margin-left-6 {
    margin-left: 1rem !important;
  }
}

@media screen and (width >= 990px) and (width <= 767px) {
  .has-padding-6-mobile {
    padding: 1rem !important;
  }

  .has-padding-top-6-mobile {
    padding-top: 1rem !important;
  }

  .has-padding-right-6-mobile {
    padding-right: 1rem !important;
  }

  .has-padding-bottom-6-mobile {
    padding-bottom: 1rem !important;
  }

  .has-padding-left-6-mobile {
    padding-left: 1rem !important;
  }

  .has-no-padding-mobile {
    padding: 0 !important;
  }

  .has-no-padding-top-mobile {
    padding-top: 0 !important;
  }

  .has-no-padding-right-mobile {
    padding-right: 0 !important;
  }

  .has-no-padding-bottom-mobile {
    padding-bottom: 0 !important;
  }

  .has-no-padding-left-mobile {
    padding-left: 0 !important;
  }

  .has-margin-6-mobile {
    margin: 1rem !important;
  }

  .has-margin-top-6-mobile {
    margin-top: 1rem !important;
  }

  .has-margin-right-6-mobile {
    margin-right: 1rem !important;
  }

  .has-margin-bottom-6-mobile {
    margin-bottom: 1rem !important;
  }

  .has-margin-left-6-mobile {
    margin-left: 1rem !important;
  }

  .has-no-margin-mobile {
    margin: 0 !important;
  }

  .has-no-margin-top-mobile {
    margin-top: 0 !important;
  }

  .has-no-margin-right-mobile {
    margin-right: 0 !important;
  }

  .has-no-margin-bottom-mobile {
    margin-bottom: 0 !important;
  }

  .has-no-margin-left-mobile {
    margin-left: 0 !important;
  }
}

@media screen and (width >= 990px) {
  .is-size-7-desktop {
    font-size: .75rem !important;
  }

  .has-padding-7 {
    padding: .75rem !important;
  }

  .has-padding-top-7 {
    padding-top: .75rem !important;
  }

  .has-padding-right-7 {
    padding-right: .75rem !important;
  }

  .has-padding-bottom-7 {
    padding-bottom: .75rem !important;
  }

  .has-padding-left-7 {
    padding-left: .75rem !important;
  }

  .has-margin-7 {
    margin: .75rem !important;
  }

  .has-margin-top-7 {
    margin-top: .75rem !important;
  }

  .has-margin-right-7 {
    margin-right: .75rem !important;
  }

  .has-margin-bottom-7 {
    margin-bottom: .75rem !important;
  }

  .has-margin-left-7 {
    margin-left: .75rem !important;
  }
}

@media screen and (width >= 990px) and (width <= 767px) {
  .has-padding-7-mobile {
    padding: .75rem !important;
  }

  .has-padding-top-7-mobile {
    padding-top: .75rem !important;
  }

  .has-padding-right-7-mobile {
    padding-right: .75rem !important;
  }

  .has-padding-bottom-7-mobile {
    padding-bottom: .75rem !important;
  }

  .has-padding-left-7-mobile {
    padding-left: .75rem !important;
  }

  .has-no-padding-mobile {
    padding: 0 !important;
  }

  .has-no-padding-top-mobile {
    padding-top: 0 !important;
  }

  .has-no-padding-right-mobile {
    padding-right: 0 !important;
  }

  .has-no-padding-bottom-mobile {
    padding-bottom: 0 !important;
  }

  .has-no-padding-left-mobile {
    padding-left: 0 !important;
  }

  .has-margin-7-mobile {
    margin: .75rem !important;
  }

  .has-margin-top-7-mobile {
    margin-top: .75rem !important;
  }

  .has-margin-right-7-mobile {
    margin-right: .75rem !important;
  }

  .has-margin-bottom-7-mobile {
    margin-bottom: .75rem !important;
  }

  .has-margin-left-7-mobile {
    margin-left: .75rem !important;
  }

  .has-no-margin-mobile {
    margin: 0 !important;
  }

  .has-no-margin-top-mobile {
    margin-top: 0 !important;
  }

  .has-no-margin-right-mobile {
    margin-right: 0 !important;
  }

  .has-no-margin-bottom-mobile {
    margin-bottom: 0 !important;
  }

  .has-no-margin-left-mobile {
    margin-left: 0 !important;
  }
}

@media screen and (width >= 990px) {
  .is-size-8-desktop {
    font-size: .5rem !important;
  }

  .has-padding-8 {
    padding: .5rem !important;
  }

  .has-padding-top-8 {
    padding-top: .5rem !important;
  }

  .has-padding-right-8 {
    padding-right: .5rem !important;
  }

  .has-padding-bottom-8 {
    padding-bottom: .5rem !important;
  }

  .has-padding-left-8 {
    padding-left: .5rem !important;
  }

  .has-margin-8 {
    margin: .5rem !important;
  }

  .has-margin-top-8 {
    margin-top: .5rem !important;
  }

  .has-margin-right-8 {
    margin-right: .5rem !important;
  }

  .has-margin-bottom-8 {
    margin-bottom: .5rem !important;
  }

  .has-margin-left-8 {
    margin-left: .5rem !important;
  }
}

@media screen and (width >= 990px) and (width <= 767px) {
  .has-padding-8-mobile {
    padding: .5rem !important;
  }

  .has-padding-top-8-mobile {
    padding-top: .5rem !important;
  }

  .has-padding-right-8-mobile {
    padding-right: .5rem !important;
  }

  .has-padding-bottom-8-mobile {
    padding-bottom: .5rem !important;
  }

  .has-padding-left-8-mobile {
    padding-left: .5rem !important;
  }

  .has-no-padding-mobile {
    padding: 0 !important;
  }

  .has-no-padding-top-mobile {
    padding-top: 0 !important;
  }

  .has-no-padding-right-mobile {
    padding-right: 0 !important;
  }

  .has-no-padding-bottom-mobile {
    padding-bottom: 0 !important;
  }

  .has-no-padding-left-mobile {
    padding-left: 0 !important;
  }

  .has-margin-8-mobile {
    margin: .5rem !important;
  }

  .has-margin-top-8-mobile {
    margin-top: .5rem !important;
  }

  .has-margin-right-8-mobile {
    margin-right: .5rem !important;
  }

  .has-margin-bottom-8-mobile {
    margin-bottom: .5rem !important;
  }

  .has-margin-left-8-mobile {
    margin-left: .5rem !important;
  }

  .has-no-margin-mobile {
    margin: 0 !important;
  }

  .has-no-margin-top-mobile {
    margin-top: 0 !important;
  }

  .has-no-margin-right-mobile {
    margin-right: 0 !important;
  }

  .has-no-margin-bottom-mobile {
    margin-bottom: 0 !important;
  }

  .has-no-margin-left-mobile {
    margin-left: 0 !important;
  }
}

@media screen and (width >= 990px) {
  .is-size-9-desktop {
    font-size: .2rem !important;
  }

  .has-padding-9 {
    padding: .2rem !important;
  }

  .has-padding-top-9 {
    padding-top: .2rem !important;
  }

  .has-padding-right-9 {
    padding-right: .2rem !important;
  }

  .has-padding-bottom-9 {
    padding-bottom: .2rem !important;
  }

  .has-padding-left-9 {
    padding-left: .2rem !important;
  }

  .has-margin-9 {
    margin: .2rem !important;
  }

  .has-margin-top-9 {
    margin-top: .2rem !important;
  }

  .has-margin-right-9 {
    margin-right: .2rem !important;
  }

  .has-margin-bottom-9 {
    margin-bottom: .2rem !important;
  }

  .has-margin-left-9 {
    margin-left: .2rem !important;
  }
}

@media screen and (width >= 990px) and (width <= 767px) {
  .has-padding-9-mobile {
    padding: .2rem !important;
  }

  .has-padding-top-9-mobile {
    padding-top: .2rem !important;
  }

  .has-padding-right-9-mobile {
    padding-right: .2rem !important;
  }

  .has-padding-bottom-9-mobile {
    padding-bottom: .2rem !important;
  }

  .has-padding-left-9-mobile {
    padding-left: .2rem !important;
  }

  .has-no-padding-mobile {
    padding: 0 !important;
  }

  .has-no-padding-top-mobile {
    padding-top: 0 !important;
  }

  .has-no-padding-right-mobile {
    padding-right: 0 !important;
  }

  .has-no-padding-bottom-mobile {
    padding-bottom: 0 !important;
  }

  .has-no-padding-left-mobile {
    padding-left: 0 !important;
  }

  .has-margin-9-mobile {
    margin: .2rem !important;
  }

  .has-margin-top-9-mobile {
    margin-top: .2rem !important;
  }

  .has-margin-right-9-mobile {
    margin-right: .2rem !important;
  }

  .has-margin-bottom-9-mobile {
    margin-bottom: .2rem !important;
  }

  .has-margin-left-9-mobile {
    margin-left: .2rem !important;
  }

  .has-no-margin-mobile {
    margin: 0 !important;
  }

  .has-no-margin-top-mobile {
    margin-top: 0 !important;
  }

  .has-no-margin-right-mobile {
    margin-right: 0 !important;
  }

  .has-no-margin-bottom-mobile {
    margin-bottom: 0 !important;
  }

  .has-no-margin-left-mobile {
    margin-left: 0 !important;
  }
}

@media screen and (width >= 1182px) {
  .is-size-1-widescreen {
    font-size: 4rem !important;
  }

  .has-padding-1 {
    padding: 4rem !important;
  }

  .has-padding-top-1 {
    padding-top: 4rem !important;
  }

  .has-padding-right-1 {
    padding-right: 4rem !important;
  }

  .has-padding-bottom-1 {
    padding-bottom: 4rem !important;
  }

  .has-padding-left-1 {
    padding-left: 4rem !important;
  }

  .has-margin-1 {
    margin: 4rem !important;
  }

  .has-margin-top-1 {
    margin-top: 4rem !important;
  }

  .has-margin-right-1 {
    margin-right: 4rem !important;
  }

  .has-margin-bottom-1 {
    margin-bottom: 4rem !important;
  }

  .has-margin-left-1 {
    margin-left: 4rem !important;
  }
}

@media screen and (width >= 1182px) and (width <= 767px) {
  .has-padding-1-mobile {
    padding: 4rem !important;
  }

  .has-padding-top-1-mobile {
    padding-top: 4rem !important;
  }

  .has-padding-right-1-mobile {
    padding-right: 4rem !important;
  }

  .has-padding-bottom-1-mobile {
    padding-bottom: 4rem !important;
  }

  .has-padding-left-1-mobile {
    padding-left: 4rem !important;
  }

  .has-no-padding-mobile {
    padding: 0 !important;
  }

  .has-no-padding-top-mobile {
    padding-top: 0 !important;
  }

  .has-no-padding-right-mobile {
    padding-right: 0 !important;
  }

  .has-no-padding-bottom-mobile {
    padding-bottom: 0 !important;
  }

  .has-no-padding-left-mobile {
    padding-left: 0 !important;
  }

  .has-margin-1-mobile {
    margin: 4rem !important;
  }

  .has-margin-top-1-mobile {
    margin-top: 4rem !important;
  }

  .has-margin-right-1-mobile {
    margin-right: 4rem !important;
  }

  .has-margin-bottom-1-mobile {
    margin-bottom: 4rem !important;
  }

  .has-margin-left-1-mobile {
    margin-left: 4rem !important;
  }

  .has-no-margin-mobile {
    margin: 0 !important;
  }

  .has-no-margin-top-mobile {
    margin-top: 0 !important;
  }

  .has-no-margin-right-mobile {
    margin-right: 0 !important;
  }

  .has-no-margin-bottom-mobile {
    margin-bottom: 0 !important;
  }

  .has-no-margin-left-mobile {
    margin-left: 0 !important;
  }
}

@media screen and (width >= 1182px) {
  .is-size-2-widescreen {
    font-size: 3rem !important;
  }

  .has-padding-2 {
    padding: 3rem !important;
  }

  .has-padding-top-2 {
    padding-top: 3rem !important;
  }

  .has-padding-right-2 {
    padding-right: 3rem !important;
  }

  .has-padding-bottom-2 {
    padding-bottom: 3rem !important;
  }

  .has-padding-left-2 {
    padding-left: 3rem !important;
  }

  .has-margin-2 {
    margin: 3rem !important;
  }

  .has-margin-top-2 {
    margin-top: 3rem !important;
  }

  .has-margin-right-2 {
    margin-right: 3rem !important;
  }

  .has-margin-bottom-2 {
    margin-bottom: 3rem !important;
  }

  .has-margin-left-2 {
    margin-left: 3rem !important;
  }
}

@media screen and (width >= 1182px) and (width <= 767px) {
  .has-padding-2-mobile {
    padding: 3rem !important;
  }

  .has-padding-top-2-mobile {
    padding-top: 3rem !important;
  }

  .has-padding-right-2-mobile {
    padding-right: 3rem !important;
  }

  .has-padding-bottom-2-mobile {
    padding-bottom: 3rem !important;
  }

  .has-padding-left-2-mobile {
    padding-left: 3rem !important;
  }

  .has-no-padding-mobile {
    padding: 0 !important;
  }

  .has-no-padding-top-mobile {
    padding-top: 0 !important;
  }

  .has-no-padding-right-mobile {
    padding-right: 0 !important;
  }

  .has-no-padding-bottom-mobile {
    padding-bottom: 0 !important;
  }

  .has-no-padding-left-mobile {
    padding-left: 0 !important;
  }

  .has-margin-2-mobile {
    margin: 3rem !important;
  }

  .has-margin-top-2-mobile {
    margin-top: 3rem !important;
  }

  .has-margin-right-2-mobile {
    margin-right: 3rem !important;
  }

  .has-margin-bottom-2-mobile {
    margin-bottom: 3rem !important;
  }

  .has-margin-left-2-mobile {
    margin-left: 3rem !important;
  }

  .has-no-margin-mobile {
    margin: 0 !important;
  }

  .has-no-margin-top-mobile {
    margin-top: 0 !important;
  }

  .has-no-margin-right-mobile {
    margin-right: 0 !important;
  }

  .has-no-margin-bottom-mobile {
    margin-bottom: 0 !important;
  }

  .has-no-margin-left-mobile {
    margin-left: 0 !important;
  }
}

@media screen and (width >= 1182px) {
  .is-size-3-widescreen {
    font-size: 2.5rem !important;
  }

  .has-padding-3 {
    padding: 2.5rem !important;
  }

  .has-padding-top-3 {
    padding-top: 2.5rem !important;
  }

  .has-padding-right-3 {
    padding-right: 2.5rem !important;
  }

  .has-padding-bottom-3 {
    padding-bottom: 2.5rem !important;
  }

  .has-padding-left-3 {
    padding-left: 2.5rem !important;
  }

  .has-margin-3 {
    margin: 2.5rem !important;
  }

  .has-margin-top-3 {
    margin-top: 2.5rem !important;
  }

  .has-margin-right-3 {
    margin-right: 2.5rem !important;
  }

  .has-margin-bottom-3 {
    margin-bottom: 2.5rem !important;
  }

  .has-margin-left-3 {
    margin-left: 2.5rem !important;
  }
}

@media screen and (width >= 1182px) and (width <= 767px) {
  .has-padding-3-mobile {
    padding: 2.5rem !important;
  }

  .has-padding-top-3-mobile {
    padding-top: 2.5rem !important;
  }

  .has-padding-right-3-mobile {
    padding-right: 2.5rem !important;
  }

  .has-padding-bottom-3-mobile {
    padding-bottom: 2.5rem !important;
  }

  .has-padding-left-3-mobile {
    padding-left: 2.5rem !important;
  }

  .has-no-padding-mobile {
    padding: 0 !important;
  }

  .has-no-padding-top-mobile {
    padding-top: 0 !important;
  }

  .has-no-padding-right-mobile {
    padding-right: 0 !important;
  }

  .has-no-padding-bottom-mobile {
    padding-bottom: 0 !important;
  }

  .has-no-padding-left-mobile {
    padding-left: 0 !important;
  }

  .has-margin-3-mobile {
    margin: 2.5rem !important;
  }

  .has-margin-top-3-mobile {
    margin-top: 2.5rem !important;
  }

  .has-margin-right-3-mobile {
    margin-right: 2.5rem !important;
  }

  .has-margin-bottom-3-mobile {
    margin-bottom: 2.5rem !important;
  }

  .has-margin-left-3-mobile {
    margin-left: 2.5rem !important;
  }

  .has-no-margin-mobile {
    margin: 0 !important;
  }

  .has-no-margin-top-mobile {
    margin-top: 0 !important;
  }

  .has-no-margin-right-mobile {
    margin-right: 0 !important;
  }

  .has-no-margin-bottom-mobile {
    margin-bottom: 0 !important;
  }

  .has-no-margin-left-mobile {
    margin-left: 0 !important;
  }
}

@media screen and (width >= 1182px) {
  .is-size-4-widescreen {
    font-size: 1.5rem !important;
  }

  .has-padding-4 {
    padding: 1.5rem !important;
  }

  .has-padding-top-4 {
    padding-top: 1.5rem !important;
  }

  .has-padding-right-4 {
    padding-right: 1.5rem !important;
  }

  .has-padding-bottom-4 {
    padding-bottom: 1.5rem !important;
  }

  .has-padding-left-4 {
    padding-left: 1.5rem !important;
  }

  .has-margin-4 {
    margin: 1.5rem !important;
  }

  .has-margin-top-4 {
    margin-top: 1.5rem !important;
  }

  .has-margin-right-4 {
    margin-right: 1.5rem !important;
  }

  .has-margin-bottom-4 {
    margin-bottom: 1.5rem !important;
  }

  .has-margin-left-4 {
    margin-left: 1.5rem !important;
  }
}

@media screen and (width >= 1182px) and (width <= 767px) {
  .has-padding-4-mobile {
    padding: 1.5rem !important;
  }

  .has-padding-top-4-mobile {
    padding-top: 1.5rem !important;
  }

  .has-padding-right-4-mobile {
    padding-right: 1.5rem !important;
  }

  .has-padding-bottom-4-mobile {
    padding-bottom: 1.5rem !important;
  }

  .has-padding-left-4-mobile {
    padding-left: 1.5rem !important;
  }

  .has-no-padding-mobile {
    padding: 0 !important;
  }

  .has-no-padding-top-mobile {
    padding-top: 0 !important;
  }

  .has-no-padding-right-mobile {
    padding-right: 0 !important;
  }

  .has-no-padding-bottom-mobile {
    padding-bottom: 0 !important;
  }

  .has-no-padding-left-mobile {
    padding-left: 0 !important;
  }

  .has-margin-4-mobile {
    margin: 1.5rem !important;
  }

  .has-margin-top-4-mobile {
    margin-top: 1.5rem !important;
  }

  .has-margin-right-4-mobile {
    margin-right: 1.5rem !important;
  }

  .has-margin-bottom-4-mobile {
    margin-bottom: 1.5rem !important;
  }

  .has-margin-left-4-mobile {
    margin-left: 1.5rem !important;
  }

  .has-no-margin-mobile {
    margin: 0 !important;
  }

  .has-no-margin-top-mobile {
    margin-top: 0 !important;
  }

  .has-no-margin-right-mobile {
    margin-right: 0 !important;
  }

  .has-no-margin-bottom-mobile {
    margin-bottom: 0 !important;
  }

  .has-no-margin-left-mobile {
    margin-left: 0 !important;
  }
}

@media screen and (width >= 1182px) {
  .is-size-5-widescreen {
    font-size: 1.25rem !important;
  }

  .has-padding-5 {
    padding: 1.25rem !important;
  }

  .has-padding-top-5 {
    padding-top: 1.25rem !important;
  }

  .has-padding-right-5 {
    padding-right: 1.25rem !important;
  }

  .has-padding-bottom-5 {
    padding-bottom: 1.25rem !important;
  }

  .has-padding-left-5 {
    padding-left: 1.25rem !important;
  }

  .has-margin-5 {
    margin: 1.25rem !important;
  }

  .has-margin-top-5 {
    margin-top: 1.25rem !important;
  }

  .has-margin-right-5 {
    margin-right: 1.25rem !important;
  }

  .has-margin-bottom-5 {
    margin-bottom: 1.25rem !important;
  }

  .has-margin-left-5 {
    margin-left: 1.25rem !important;
  }
}

@media screen and (width >= 1182px) and (width <= 767px) {
  .has-padding-5-mobile {
    padding: 1.25rem !important;
  }

  .has-padding-top-5-mobile {
    padding-top: 1.25rem !important;
  }

  .has-padding-right-5-mobile {
    padding-right: 1.25rem !important;
  }

  .has-padding-bottom-5-mobile {
    padding-bottom: 1.25rem !important;
  }

  .has-padding-left-5-mobile {
    padding-left: 1.25rem !important;
  }

  .has-no-padding-mobile {
    padding: 0 !important;
  }

  .has-no-padding-top-mobile {
    padding-top: 0 !important;
  }

  .has-no-padding-right-mobile {
    padding-right: 0 !important;
  }

  .has-no-padding-bottom-mobile {
    padding-bottom: 0 !important;
  }

  .has-no-padding-left-mobile {
    padding-left: 0 !important;
  }

  .has-margin-5-mobile {
    margin: 1.25rem !important;
  }

  .has-margin-top-5-mobile {
    margin-top: 1.25rem !important;
  }

  .has-margin-right-5-mobile {
    margin-right: 1.25rem !important;
  }

  .has-margin-bottom-5-mobile {
    margin-bottom: 1.25rem !important;
  }

  .has-margin-left-5-mobile {
    margin-left: 1.25rem !important;
  }

  .has-no-margin-mobile {
    margin: 0 !important;
  }

  .has-no-margin-top-mobile {
    margin-top: 0 !important;
  }

  .has-no-margin-right-mobile {
    margin-right: 0 !important;
  }

  .has-no-margin-bottom-mobile {
    margin-bottom: 0 !important;
  }

  .has-no-margin-left-mobile {
    margin-left: 0 !important;
  }
}

@media screen and (width >= 1182px) {
  .is-size-6-widescreen {
    font-size: 1rem !important;
  }

  .has-padding-6 {
    padding: 1rem !important;
  }

  .has-padding-top-6 {
    padding-top: 1rem !important;
  }

  .has-padding-right-6 {
    padding-right: 1rem !important;
  }

  .has-padding-bottom-6 {
    padding-bottom: 1rem !important;
  }

  .has-padding-left-6 {
    padding-left: 1rem !important;
  }

  .has-margin-6 {
    margin: 1rem !important;
  }

  .has-margin-top-6 {
    margin-top: 1rem !important;
  }

  .has-margin-right-6 {
    margin-right: 1rem !important;
  }

  .has-margin-bottom-6 {
    margin-bottom: 1rem !important;
  }

  .has-margin-left-6 {
    margin-left: 1rem !important;
  }
}

@media screen and (width >= 1182px) and (width <= 767px) {
  .has-padding-6-mobile {
    padding: 1rem !important;
  }

  .has-padding-top-6-mobile {
    padding-top: 1rem !important;
  }

  .has-padding-right-6-mobile {
    padding-right: 1rem !important;
  }

  .has-padding-bottom-6-mobile {
    padding-bottom: 1rem !important;
  }

  .has-padding-left-6-mobile {
    padding-left: 1rem !important;
  }

  .has-no-padding-mobile {
    padding: 0 !important;
  }

  .has-no-padding-top-mobile {
    padding-top: 0 !important;
  }

  .has-no-padding-right-mobile {
    padding-right: 0 !important;
  }

  .has-no-padding-bottom-mobile {
    padding-bottom: 0 !important;
  }

  .has-no-padding-left-mobile {
    padding-left: 0 !important;
  }

  .has-margin-6-mobile {
    margin: 1rem !important;
  }

  .has-margin-top-6-mobile {
    margin-top: 1rem !important;
  }

  .has-margin-right-6-mobile {
    margin-right: 1rem !important;
  }

  .has-margin-bottom-6-mobile {
    margin-bottom: 1rem !important;
  }

  .has-margin-left-6-mobile {
    margin-left: 1rem !important;
  }

  .has-no-margin-mobile {
    margin: 0 !important;
  }

  .has-no-margin-top-mobile {
    margin-top: 0 !important;
  }

  .has-no-margin-right-mobile {
    margin-right: 0 !important;
  }

  .has-no-margin-bottom-mobile {
    margin-bottom: 0 !important;
  }

  .has-no-margin-left-mobile {
    margin-left: 0 !important;
  }
}

@media screen and (width >= 1182px) {
  .is-size-7-widescreen {
    font-size: .75rem !important;
  }

  .has-padding-7 {
    padding: .75rem !important;
  }

  .has-padding-top-7 {
    padding-top: .75rem !important;
  }

  .has-padding-right-7 {
    padding-right: .75rem !important;
  }

  .has-padding-bottom-7 {
    padding-bottom: .75rem !important;
  }

  .has-padding-left-7 {
    padding-left: .75rem !important;
  }

  .has-margin-7 {
    margin: .75rem !important;
  }

  .has-margin-top-7 {
    margin-top: .75rem !important;
  }

  .has-margin-right-7 {
    margin-right: .75rem !important;
  }

  .has-margin-bottom-7 {
    margin-bottom: .75rem !important;
  }

  .has-margin-left-7 {
    margin-left: .75rem !important;
  }
}

@media screen and (width >= 1182px) and (width <= 767px) {
  .has-padding-7-mobile {
    padding: .75rem !important;
  }

  .has-padding-top-7-mobile {
    padding-top: .75rem !important;
  }

  .has-padding-right-7-mobile {
    padding-right: .75rem !important;
  }

  .has-padding-bottom-7-mobile {
    padding-bottom: .75rem !important;
  }

  .has-padding-left-7-mobile {
    padding-left: .75rem !important;
  }

  .has-no-padding-mobile {
    padding: 0 !important;
  }

  .has-no-padding-top-mobile {
    padding-top: 0 !important;
  }

  .has-no-padding-right-mobile {
    padding-right: 0 !important;
  }

  .has-no-padding-bottom-mobile {
    padding-bottom: 0 !important;
  }

  .has-no-padding-left-mobile {
    padding-left: 0 !important;
  }

  .has-margin-7-mobile {
    margin: .75rem !important;
  }

  .has-margin-top-7-mobile {
    margin-top: .75rem !important;
  }

  .has-margin-right-7-mobile {
    margin-right: .75rem !important;
  }

  .has-margin-bottom-7-mobile {
    margin-bottom: .75rem !important;
  }

  .has-margin-left-7-mobile {
    margin-left: .75rem !important;
  }

  .has-no-margin-mobile {
    margin: 0 !important;
  }

  .has-no-margin-top-mobile {
    margin-top: 0 !important;
  }

  .has-no-margin-right-mobile {
    margin-right: 0 !important;
  }

  .has-no-margin-bottom-mobile {
    margin-bottom: 0 !important;
  }

  .has-no-margin-left-mobile {
    margin-left: 0 !important;
  }
}

@media screen and (width >= 1182px) {
  .is-size-8-widescreen {
    font-size: .5rem !important;
  }

  .has-padding-8 {
    padding: .5rem !important;
  }

  .has-padding-top-8 {
    padding-top: .5rem !important;
  }

  .has-padding-right-8 {
    padding-right: .5rem !important;
  }

  .has-padding-bottom-8 {
    padding-bottom: .5rem !important;
  }

  .has-padding-left-8 {
    padding-left: .5rem !important;
  }

  .has-margin-8 {
    margin: .5rem !important;
  }

  .has-margin-top-8 {
    margin-top: .5rem !important;
  }

  .has-margin-right-8 {
    margin-right: .5rem !important;
  }

  .has-margin-bottom-8 {
    margin-bottom: .5rem !important;
  }

  .has-margin-left-8 {
    margin-left: .5rem !important;
  }
}

@media screen and (width >= 1182px) and (width <= 767px) {
  .has-padding-8-mobile {
    padding: .5rem !important;
  }

  .has-padding-top-8-mobile {
    padding-top: .5rem !important;
  }

  .has-padding-right-8-mobile {
    padding-right: .5rem !important;
  }

  .has-padding-bottom-8-mobile {
    padding-bottom: .5rem !important;
  }

  .has-padding-left-8-mobile {
    padding-left: .5rem !important;
  }

  .has-no-padding-mobile {
    padding: 0 !important;
  }

  .has-no-padding-top-mobile {
    padding-top: 0 !important;
  }

  .has-no-padding-right-mobile {
    padding-right: 0 !important;
  }

  .has-no-padding-bottom-mobile {
    padding-bottom: 0 !important;
  }

  .has-no-padding-left-mobile {
    padding-left: 0 !important;
  }

  .has-margin-8-mobile {
    margin: .5rem !important;
  }

  .has-margin-top-8-mobile {
    margin-top: .5rem !important;
  }

  .has-margin-right-8-mobile {
    margin-right: .5rem !important;
  }

  .has-margin-bottom-8-mobile {
    margin-bottom: .5rem !important;
  }

  .has-margin-left-8-mobile {
    margin-left: .5rem !important;
  }

  .has-no-margin-mobile {
    margin: 0 !important;
  }

  .has-no-margin-top-mobile {
    margin-top: 0 !important;
  }

  .has-no-margin-right-mobile {
    margin-right: 0 !important;
  }

  .has-no-margin-bottom-mobile {
    margin-bottom: 0 !important;
  }

  .has-no-margin-left-mobile {
    margin-left: 0 !important;
  }
}

@media screen and (width >= 1182px) {
  .is-size-9-widescreen {
    font-size: .2rem !important;
  }

  .has-padding-9 {
    padding: .2rem !important;
  }

  .has-padding-top-9 {
    padding-top: .2rem !important;
  }

  .has-padding-right-9 {
    padding-right: .2rem !important;
  }

  .has-padding-bottom-9 {
    padding-bottom: .2rem !important;
  }

  .has-padding-left-9 {
    padding-left: .2rem !important;
  }

  .has-margin-9 {
    margin: .2rem !important;
  }

  .has-margin-top-9 {
    margin-top: .2rem !important;
  }

  .has-margin-right-9 {
    margin-right: .2rem !important;
  }

  .has-margin-bottom-9 {
    margin-bottom: .2rem !important;
  }

  .has-margin-left-9 {
    margin-left: .2rem !important;
  }
}

@media screen and (width >= 1182px) and (width <= 767px) {
  .has-padding-9-mobile {
    padding: .2rem !important;
  }

  .has-padding-top-9-mobile {
    padding-top: .2rem !important;
  }

  .has-padding-right-9-mobile {
    padding-right: .2rem !important;
  }

  .has-padding-bottom-9-mobile {
    padding-bottom: .2rem !important;
  }

  .has-padding-left-9-mobile {
    padding-left: .2rem !important;
  }

  .has-no-padding-mobile {
    padding: 0 !important;
  }

  .has-no-padding-top-mobile {
    padding-top: 0 !important;
  }

  .has-no-padding-right-mobile {
    padding-right: 0 !important;
  }

  .has-no-padding-bottom-mobile {
    padding-bottom: 0 !important;
  }

  .has-no-padding-left-mobile {
    padding-left: 0 !important;
  }

  .has-margin-9-mobile {
    margin: .2rem !important;
  }

  .has-margin-top-9-mobile {
    margin-top: .2rem !important;
  }

  .has-margin-right-9-mobile {
    margin-right: .2rem !important;
  }

  .has-margin-bottom-9-mobile {
    margin-bottom: .2rem !important;
  }

  .has-margin-left-9-mobile {
    margin-left: .2rem !important;
  }

  .has-no-margin-mobile {
    margin: 0 !important;
  }

  .has-no-margin-top-mobile {
    margin-top: 0 !important;
  }

  .has-no-margin-right-mobile {
    margin-right: 0 !important;
  }

  .has-no-margin-bottom-mobile {
    margin-bottom: 0 !important;
  }

  .has-no-margin-left-mobile {
    margin-left: 0 !important;
  }
}

@media screen and (width >= 1374px) {
  .is-size-1-fullhd {
    font-size: 4rem !important;
  }

  .has-padding-1 {
    padding: 4rem !important;
  }

  .has-padding-top-1 {
    padding-top: 4rem !important;
  }

  .has-padding-right-1 {
    padding-right: 4rem !important;
  }

  .has-padding-bottom-1 {
    padding-bottom: 4rem !important;
  }

  .has-padding-left-1 {
    padding-left: 4rem !important;
  }

  .has-margin-1 {
    margin: 4rem !important;
  }

  .has-margin-top-1 {
    margin-top: 4rem !important;
  }

  .has-margin-right-1 {
    margin-right: 4rem !important;
  }

  .has-margin-bottom-1 {
    margin-bottom: 4rem !important;
  }

  .has-margin-left-1 {
    margin-left: 4rem !important;
  }
}

@media screen and (width >= 1374px) and (width <= 767px) {
  .has-padding-1-mobile {
    padding: 4rem !important;
  }

  .has-padding-top-1-mobile {
    padding-top: 4rem !important;
  }

  .has-padding-right-1-mobile {
    padding-right: 4rem !important;
  }

  .has-padding-bottom-1-mobile {
    padding-bottom: 4rem !important;
  }

  .has-padding-left-1-mobile {
    padding-left: 4rem !important;
  }

  .has-no-padding-mobile {
    padding: 0 !important;
  }

  .has-no-padding-top-mobile {
    padding-top: 0 !important;
  }

  .has-no-padding-right-mobile {
    padding-right: 0 !important;
  }

  .has-no-padding-bottom-mobile {
    padding-bottom: 0 !important;
  }

  .has-no-padding-left-mobile {
    padding-left: 0 !important;
  }

  .has-margin-1-mobile {
    margin: 4rem !important;
  }

  .has-margin-top-1-mobile {
    margin-top: 4rem !important;
  }

  .has-margin-right-1-mobile {
    margin-right: 4rem !important;
  }

  .has-margin-bottom-1-mobile {
    margin-bottom: 4rem !important;
  }

  .has-margin-left-1-mobile {
    margin-left: 4rem !important;
  }

  .has-no-margin-mobile {
    margin: 0 !important;
  }

  .has-no-margin-top-mobile {
    margin-top: 0 !important;
  }

  .has-no-margin-right-mobile {
    margin-right: 0 !important;
  }

  .has-no-margin-bottom-mobile {
    margin-bottom: 0 !important;
  }

  .has-no-margin-left-mobile {
    margin-left: 0 !important;
  }
}

@media screen and (width >= 1374px) {
  .is-size-2-fullhd {
    font-size: 3rem !important;
  }

  .has-padding-2 {
    padding: 3rem !important;
  }

  .has-padding-top-2 {
    padding-top: 3rem !important;
  }

  .has-padding-right-2 {
    padding-right: 3rem !important;
  }

  .has-padding-bottom-2 {
    padding-bottom: 3rem !important;
  }

  .has-padding-left-2 {
    padding-left: 3rem !important;
  }

  .has-margin-2 {
    margin: 3rem !important;
  }

  .has-margin-top-2 {
    margin-top: 3rem !important;
  }

  .has-margin-right-2 {
    margin-right: 3rem !important;
  }

  .has-margin-bottom-2 {
    margin-bottom: 3rem !important;
  }

  .has-margin-left-2 {
    margin-left: 3rem !important;
  }
}

@media screen and (width >= 1374px) and (width <= 767px) {
  .has-padding-2-mobile {
    padding: 3rem !important;
  }

  .has-padding-top-2-mobile {
    padding-top: 3rem !important;
  }

  .has-padding-right-2-mobile {
    padding-right: 3rem !important;
  }

  .has-padding-bottom-2-mobile {
    padding-bottom: 3rem !important;
  }

  .has-padding-left-2-mobile {
    padding-left: 3rem !important;
  }

  .has-no-padding-mobile {
    padding: 0 !important;
  }

  .has-no-padding-top-mobile {
    padding-top: 0 !important;
  }

  .has-no-padding-right-mobile {
    padding-right: 0 !important;
  }

  .has-no-padding-bottom-mobile {
    padding-bottom: 0 !important;
  }

  .has-no-padding-left-mobile {
    padding-left: 0 !important;
  }

  .has-margin-2-mobile {
    margin: 3rem !important;
  }

  .has-margin-top-2-mobile {
    margin-top: 3rem !important;
  }

  .has-margin-right-2-mobile {
    margin-right: 3rem !important;
  }

  .has-margin-bottom-2-mobile {
    margin-bottom: 3rem !important;
  }

  .has-margin-left-2-mobile {
    margin-left: 3rem !important;
  }

  .has-no-margin-mobile {
    margin: 0 !important;
  }

  .has-no-margin-top-mobile {
    margin-top: 0 !important;
  }

  .has-no-margin-right-mobile {
    margin-right: 0 !important;
  }

  .has-no-margin-bottom-mobile {
    margin-bottom: 0 !important;
  }

  .has-no-margin-left-mobile {
    margin-left: 0 !important;
  }
}

@media screen and (width >= 1374px) {
  .is-size-3-fullhd {
    font-size: 2.5rem !important;
  }

  .has-padding-3 {
    padding: 2.5rem !important;
  }

  .has-padding-top-3 {
    padding-top: 2.5rem !important;
  }

  .has-padding-right-3 {
    padding-right: 2.5rem !important;
  }

  .has-padding-bottom-3 {
    padding-bottom: 2.5rem !important;
  }

  .has-padding-left-3 {
    padding-left: 2.5rem !important;
  }

  .has-margin-3 {
    margin: 2.5rem !important;
  }

  .has-margin-top-3 {
    margin-top: 2.5rem !important;
  }

  .has-margin-right-3 {
    margin-right: 2.5rem !important;
  }

  .has-margin-bottom-3 {
    margin-bottom: 2.5rem !important;
  }

  .has-margin-left-3 {
    margin-left: 2.5rem !important;
  }
}

@media screen and (width >= 1374px) and (width <= 767px) {
  .has-padding-3-mobile {
    padding: 2.5rem !important;
  }

  .has-padding-top-3-mobile {
    padding-top: 2.5rem !important;
  }

  .has-padding-right-3-mobile {
    padding-right: 2.5rem !important;
  }

  .has-padding-bottom-3-mobile {
    padding-bottom: 2.5rem !important;
  }

  .has-padding-left-3-mobile {
    padding-left: 2.5rem !important;
  }

  .has-no-padding-mobile {
    padding: 0 !important;
  }

  .has-no-padding-top-mobile {
    padding-top: 0 !important;
  }

  .has-no-padding-right-mobile {
    padding-right: 0 !important;
  }

  .has-no-padding-bottom-mobile {
    padding-bottom: 0 !important;
  }

  .has-no-padding-left-mobile {
    padding-left: 0 !important;
  }

  .has-margin-3-mobile {
    margin: 2.5rem !important;
  }

  .has-margin-top-3-mobile {
    margin-top: 2.5rem !important;
  }

  .has-margin-right-3-mobile {
    margin-right: 2.5rem !important;
  }

  .has-margin-bottom-3-mobile {
    margin-bottom: 2.5rem !important;
  }

  .has-margin-left-3-mobile {
    margin-left: 2.5rem !important;
  }

  .has-no-margin-mobile {
    margin: 0 !important;
  }

  .has-no-margin-top-mobile {
    margin-top: 0 !important;
  }

  .has-no-margin-right-mobile {
    margin-right: 0 !important;
  }

  .has-no-margin-bottom-mobile {
    margin-bottom: 0 !important;
  }

  .has-no-margin-left-mobile {
    margin-left: 0 !important;
  }
}

@media screen and (width >= 1374px) {
  .is-size-4-fullhd {
    font-size: 1.5rem !important;
  }

  .has-padding-4 {
    padding: 1.5rem !important;
  }

  .has-padding-top-4 {
    padding-top: 1.5rem !important;
  }

  .has-padding-right-4 {
    padding-right: 1.5rem !important;
  }

  .has-padding-bottom-4 {
    padding-bottom: 1.5rem !important;
  }

  .has-padding-left-4 {
    padding-left: 1.5rem !important;
  }

  .has-margin-4 {
    margin: 1.5rem !important;
  }

  .has-margin-top-4 {
    margin-top: 1.5rem !important;
  }

  .has-margin-right-4 {
    margin-right: 1.5rem !important;
  }

  .has-margin-bottom-4 {
    margin-bottom: 1.5rem !important;
  }

  .has-margin-left-4 {
    margin-left: 1.5rem !important;
  }
}

@media screen and (width >= 1374px) and (width <= 767px) {
  .has-padding-4-mobile {
    padding: 1.5rem !important;
  }

  .has-padding-top-4-mobile {
    padding-top: 1.5rem !important;
  }

  .has-padding-right-4-mobile {
    padding-right: 1.5rem !important;
  }

  .has-padding-bottom-4-mobile {
    padding-bottom: 1.5rem !important;
  }

  .has-padding-left-4-mobile {
    padding-left: 1.5rem !important;
  }

  .has-no-padding-mobile {
    padding: 0 !important;
  }

  .has-no-padding-top-mobile {
    padding-top: 0 !important;
  }

  .has-no-padding-right-mobile {
    padding-right: 0 !important;
  }

  .has-no-padding-bottom-mobile {
    padding-bottom: 0 !important;
  }

  .has-no-padding-left-mobile {
    padding-left: 0 !important;
  }

  .has-margin-4-mobile {
    margin: 1.5rem !important;
  }

  .has-margin-top-4-mobile {
    margin-top: 1.5rem !important;
  }

  .has-margin-right-4-mobile {
    margin-right: 1.5rem !important;
  }

  .has-margin-bottom-4-mobile {
    margin-bottom: 1.5rem !important;
  }

  .has-margin-left-4-mobile {
    margin-left: 1.5rem !important;
  }

  .has-no-margin-mobile {
    margin: 0 !important;
  }

  .has-no-margin-top-mobile {
    margin-top: 0 !important;
  }

  .has-no-margin-right-mobile {
    margin-right: 0 !important;
  }

  .has-no-margin-bottom-mobile {
    margin-bottom: 0 !important;
  }

  .has-no-margin-left-mobile {
    margin-left: 0 !important;
  }
}

@media screen and (width >= 1374px) {
  .is-size-5-fullhd {
    font-size: 1.25rem !important;
  }

  .has-padding-5 {
    padding: 1.25rem !important;
  }

  .has-padding-top-5 {
    padding-top: 1.25rem !important;
  }

  .has-padding-right-5 {
    padding-right: 1.25rem !important;
  }

  .has-padding-bottom-5 {
    padding-bottom: 1.25rem !important;
  }

  .has-padding-left-5 {
    padding-left: 1.25rem !important;
  }

  .has-margin-5 {
    margin: 1.25rem !important;
  }

  .has-margin-top-5 {
    margin-top: 1.25rem !important;
  }

  .has-margin-right-5 {
    margin-right: 1.25rem !important;
  }

  .has-margin-bottom-5 {
    margin-bottom: 1.25rem !important;
  }

  .has-margin-left-5 {
    margin-left: 1.25rem !important;
  }
}

@media screen and (width >= 1374px) and (width <= 767px) {
  .has-padding-5-mobile {
    padding: 1.25rem !important;
  }

  .has-padding-top-5-mobile {
    padding-top: 1.25rem !important;
  }

  .has-padding-right-5-mobile {
    padding-right: 1.25rem !important;
  }

  .has-padding-bottom-5-mobile {
    padding-bottom: 1.25rem !important;
  }

  .has-padding-left-5-mobile {
    padding-left: 1.25rem !important;
  }

  .has-no-padding-mobile {
    padding: 0 !important;
  }

  .has-no-padding-top-mobile {
    padding-top: 0 !important;
  }

  .has-no-padding-right-mobile {
    padding-right: 0 !important;
  }

  .has-no-padding-bottom-mobile {
    padding-bottom: 0 !important;
  }

  .has-no-padding-left-mobile {
    padding-left: 0 !important;
  }

  .has-margin-5-mobile {
    margin: 1.25rem !important;
  }

  .has-margin-top-5-mobile {
    margin-top: 1.25rem !important;
  }

  .has-margin-right-5-mobile {
    margin-right: 1.25rem !important;
  }

  .has-margin-bottom-5-mobile {
    margin-bottom: 1.25rem !important;
  }

  .has-margin-left-5-mobile {
    margin-left: 1.25rem !important;
  }

  .has-no-margin-mobile {
    margin: 0 !important;
  }

  .has-no-margin-top-mobile {
    margin-top: 0 !important;
  }

  .has-no-margin-right-mobile {
    margin-right: 0 !important;
  }

  .has-no-margin-bottom-mobile {
    margin-bottom: 0 !important;
  }

  .has-no-margin-left-mobile {
    margin-left: 0 !important;
  }
}

@media screen and (width >= 1374px) {
  .is-size-6-fullhd {
    font-size: 1rem !important;
  }

  .has-padding-6 {
    padding: 1rem !important;
  }

  .has-padding-top-6 {
    padding-top: 1rem !important;
  }

  .has-padding-right-6 {
    padding-right: 1rem !important;
  }

  .has-padding-bottom-6 {
    padding-bottom: 1rem !important;
  }

  .has-padding-left-6 {
    padding-left: 1rem !important;
  }

  .has-margin-6 {
    margin: 1rem !important;
  }

  .has-margin-top-6 {
    margin-top: 1rem !important;
  }

  .has-margin-right-6 {
    margin-right: 1rem !important;
  }

  .has-margin-bottom-6 {
    margin-bottom: 1rem !important;
  }

  .has-margin-left-6 {
    margin-left: 1rem !important;
  }
}

@media screen and (width >= 1374px) and (width <= 767px) {
  .has-padding-6-mobile {
    padding: 1rem !important;
  }

  .has-padding-top-6-mobile {
    padding-top: 1rem !important;
  }

  .has-padding-right-6-mobile {
    padding-right: 1rem !important;
  }

  .has-padding-bottom-6-mobile {
    padding-bottom: 1rem !important;
  }

  .has-padding-left-6-mobile {
    padding-left: 1rem !important;
  }

  .has-no-padding-mobile {
    padding: 0 !important;
  }

  .has-no-padding-top-mobile {
    padding-top: 0 !important;
  }

  .has-no-padding-right-mobile {
    padding-right: 0 !important;
  }

  .has-no-padding-bottom-mobile {
    padding-bottom: 0 !important;
  }

  .has-no-padding-left-mobile {
    padding-left: 0 !important;
  }

  .has-margin-6-mobile {
    margin: 1rem !important;
  }

  .has-margin-top-6-mobile {
    margin-top: 1rem !important;
  }

  .has-margin-right-6-mobile {
    margin-right: 1rem !important;
  }

  .has-margin-bottom-6-mobile {
    margin-bottom: 1rem !important;
  }

  .has-margin-left-6-mobile {
    margin-left: 1rem !important;
  }

  .has-no-margin-mobile {
    margin: 0 !important;
  }

  .has-no-margin-top-mobile {
    margin-top: 0 !important;
  }

  .has-no-margin-right-mobile {
    margin-right: 0 !important;
  }

  .has-no-margin-bottom-mobile {
    margin-bottom: 0 !important;
  }

  .has-no-margin-left-mobile {
    margin-left: 0 !important;
  }
}

@media screen and (width >= 1374px) {
  .is-size-7-fullhd {
    font-size: .75rem !important;
  }

  .has-padding-7 {
    padding: .75rem !important;
  }

  .has-padding-top-7 {
    padding-top: .75rem !important;
  }

  .has-padding-right-7 {
    padding-right: .75rem !important;
  }

  .has-padding-bottom-7 {
    padding-bottom: .75rem !important;
  }

  .has-padding-left-7 {
    padding-left: .75rem !important;
  }

  .has-margin-7 {
    margin: .75rem !important;
  }

  .has-margin-top-7 {
    margin-top: .75rem !important;
  }

  .has-margin-right-7 {
    margin-right: .75rem !important;
  }

  .has-margin-bottom-7 {
    margin-bottom: .75rem !important;
  }

  .has-margin-left-7 {
    margin-left: .75rem !important;
  }
}

@media screen and (width >= 1374px) and (width <= 767px) {
  .has-padding-7-mobile {
    padding: .75rem !important;
  }

  .has-padding-top-7-mobile {
    padding-top: .75rem !important;
  }

  .has-padding-right-7-mobile {
    padding-right: .75rem !important;
  }

  .has-padding-bottom-7-mobile {
    padding-bottom: .75rem !important;
  }

  .has-padding-left-7-mobile {
    padding-left: .75rem !important;
  }

  .has-no-padding-mobile {
    padding: 0 !important;
  }

  .has-no-padding-top-mobile {
    padding-top: 0 !important;
  }

  .has-no-padding-right-mobile {
    padding-right: 0 !important;
  }

  .has-no-padding-bottom-mobile {
    padding-bottom: 0 !important;
  }

  .has-no-padding-left-mobile {
    padding-left: 0 !important;
  }

  .has-margin-7-mobile {
    margin: .75rem !important;
  }

  .has-margin-top-7-mobile {
    margin-top: .75rem !important;
  }

  .has-margin-right-7-mobile {
    margin-right: .75rem !important;
  }

  .has-margin-bottom-7-mobile {
    margin-bottom: .75rem !important;
  }

  .has-margin-left-7-mobile {
    margin-left: .75rem !important;
  }

  .has-no-margin-mobile {
    margin: 0 !important;
  }

  .has-no-margin-top-mobile {
    margin-top: 0 !important;
  }

  .has-no-margin-right-mobile {
    margin-right: 0 !important;
  }

  .has-no-margin-bottom-mobile {
    margin-bottom: 0 !important;
  }

  .has-no-margin-left-mobile {
    margin-left: 0 !important;
  }
}

@media screen and (width >= 1374px) {
  .is-size-8-fullhd {
    font-size: .5rem !important;
  }

  .has-padding-8 {
    padding: .5rem !important;
  }

  .has-padding-top-8 {
    padding-top: .5rem !important;
  }

  .has-padding-right-8 {
    padding-right: .5rem !important;
  }

  .has-padding-bottom-8 {
    padding-bottom: .5rem !important;
  }

  .has-padding-left-8 {
    padding-left: .5rem !important;
  }

  .has-margin-8 {
    margin: .5rem !important;
  }

  .has-margin-top-8 {
    margin-top: .5rem !important;
  }

  .has-margin-right-8 {
    margin-right: .5rem !important;
  }

  .has-margin-bottom-8 {
    margin-bottom: .5rem !important;
  }

  .has-margin-left-8 {
    margin-left: .5rem !important;
  }
}

@media screen and (width >= 1374px) and (width <= 767px) {
  .has-padding-8-mobile {
    padding: .5rem !important;
  }

  .has-padding-top-8-mobile {
    padding-top: .5rem !important;
  }

  .has-padding-right-8-mobile {
    padding-right: .5rem !important;
  }

  .has-padding-bottom-8-mobile {
    padding-bottom: .5rem !important;
  }

  .has-padding-left-8-mobile {
    padding-left: .5rem !important;
  }

  .has-no-padding-mobile {
    padding: 0 !important;
  }

  .has-no-padding-top-mobile {
    padding-top: 0 !important;
  }

  .has-no-padding-right-mobile {
    padding-right: 0 !important;
  }

  .has-no-padding-bottom-mobile {
    padding-bottom: 0 !important;
  }

  .has-no-padding-left-mobile {
    padding-left: 0 !important;
  }

  .has-margin-8-mobile {
    margin: .5rem !important;
  }

  .has-margin-top-8-mobile {
    margin-top: .5rem !important;
  }

  .has-margin-right-8-mobile {
    margin-right: .5rem !important;
  }

  .has-margin-bottom-8-mobile {
    margin-bottom: .5rem !important;
  }

  .has-margin-left-8-mobile {
    margin-left: .5rem !important;
  }

  .has-no-margin-mobile {
    margin: 0 !important;
  }

  .has-no-margin-top-mobile {
    margin-top: 0 !important;
  }

  .has-no-margin-right-mobile {
    margin-right: 0 !important;
  }

  .has-no-margin-bottom-mobile {
    margin-bottom: 0 !important;
  }

  .has-no-margin-left-mobile {
    margin-left: 0 !important;
  }
}

@media screen and (width >= 1374px) {
  .is-size-9-fullhd {
    font-size: .2rem !important;
  }

  .has-padding-9 {
    padding: .2rem !important;
  }

  .has-padding-top-9 {
    padding-top: .2rem !important;
  }

  .has-padding-right-9 {
    padding-right: .2rem !important;
  }

  .has-padding-bottom-9 {
    padding-bottom: .2rem !important;
  }

  .has-padding-left-9 {
    padding-left: .2rem !important;
  }

  .has-margin-9 {
    margin: .2rem !important;
  }

  .has-margin-top-9 {
    margin-top: .2rem !important;
  }

  .has-margin-right-9 {
    margin-right: .2rem !important;
  }

  .has-margin-bottom-9 {
    margin-bottom: .2rem !important;
  }

  .has-margin-left-9 {
    margin-left: .2rem !important;
  }
}

@media screen and (width >= 1374px) and (width <= 767px) {
  .has-padding-9-mobile {
    padding: .2rem !important;
  }

  .has-padding-top-9-mobile {
    padding-top: .2rem !important;
  }

  .has-padding-right-9-mobile {
    padding-right: .2rem !important;
  }

  .has-padding-bottom-9-mobile {
    padding-bottom: .2rem !important;
  }

  .has-padding-left-9-mobile {
    padding-left: .2rem !important;
  }

  .has-no-padding-mobile {
    padding: 0 !important;
  }

  .has-no-padding-top-mobile {
    padding-top: 0 !important;
  }

  .has-no-padding-right-mobile {
    padding-right: 0 !important;
  }

  .has-no-padding-bottom-mobile {
    padding-bottom: 0 !important;
  }

  .has-no-padding-left-mobile {
    padding-left: 0 !important;
  }

  .has-margin-9-mobile {
    margin: .2rem !important;
  }

  .has-margin-top-9-mobile {
    margin-top: .2rem !important;
  }

  .has-margin-right-9-mobile {
    margin-right: .2rem !important;
  }

  .has-margin-bottom-9-mobile {
    margin-bottom: .2rem !important;
  }

  .has-margin-left-9-mobile {
    margin-left: .2rem !important;
  }

  .has-no-margin-mobile {
    margin: 0 !important;
  }

  .has-no-margin-top-mobile {
    margin-top: 0 !important;
  }

  .has-no-margin-right-mobile {
    margin-right: 0 !important;
  }

  .has-no-margin-bottom-mobile {
    margin-bottom: 0 !important;
  }

  .has-no-margin-left-mobile {
    margin-left: 0 !important;
  }
}

.is-sans-regular {
  font-family: Calibre Regular, Arial, Helvetica, sans-serif !important;
}

.is-sans-semibold {
  font-family: Calibre Semibold, Arial, Helvetica, sans-serif !important;
}

.is-sans-medium {
  font-family: Calibre Medium, Arial, Helvetica, sans-serif !important;
}

.is-serif {
  font-family: Archer A, Archer B, Garamond, serif !important;
}

.is-calibre {
  font-family: Calibre Regular !important;
}

.is-financier-regular {
  font-family: Financier Regular, sans-serif !important;
}

.is-financier-semibold {
  font-family: Financier Semibold, sans-serif !important;
}

.has-text-centered {
  text-align: center !important;
}

@media screen and (width <= 767px) {
  .has-text-centered-mobile {
    text-align: center !important;
  }
}

@media screen and (width >= 768px), print {
  .has-text-centered-tablet {
    text-align: center !important;
  }
}

@media screen and (width >= 768px) and (width <= 989px) {
  .has-text-centered-tablet-only {
    text-align: center !important;
  }
}

@media screen and (width <= 989px) {
  .has-text-centered-touch {
    text-align: center !important;
  }
}

@media screen and (width >= 990px) {
  .has-text-centered-desktop {
    text-align: center !important;
  }
}

@media screen and (width >= 990px) and (width <= 1181px) {
  .has-text-centered-desktop-only {
    text-align: center !important;
  }
}

@media screen and (width >= 1182px) {
  .has-text-centered-widescreen {
    text-align: center !important;
  }
}

@media screen and (width >= 1182px) and (width <= 1373px) {
  .has-text-centered-widescreen-only {
    text-align: center !important;
  }
}

@media screen and (width >= 1374px) {
  .has-text-centered-fullhd {
    text-align: center !important;
  }
}

.has-text-justified {
  text-align: justify !important;
}

@media screen and (width <= 767px) {
  .has-text-justified-mobile {
    text-align: justify !important;
  }
}

@media screen and (width >= 768px), print {
  .has-text-justified-tablet {
    text-align: justify !important;
  }
}

@media screen and (width >= 768px) and (width <= 989px) {
  .has-text-justified-tablet-only {
    text-align: justify !important;
  }
}

@media screen and (width <= 989px) {
  .has-text-justified-touch {
    text-align: justify !important;
  }
}

@media screen and (width >= 990px) {
  .has-text-justified-desktop {
    text-align: justify !important;
  }
}

@media screen and (width >= 990px) and (width <= 1181px) {
  .has-text-justified-desktop-only {
    text-align: justify !important;
  }
}

@media screen and (width >= 1182px) {
  .has-text-justified-widescreen {
    text-align: justify !important;
  }
}

@media screen and (width >= 1182px) and (width <= 1373px) {
  .has-text-justified-widescreen-only {
    text-align: justify !important;
  }
}

@media screen and (width >= 1374px) {
  .has-text-justified-fullhd {
    text-align: justify !important;
  }
}

.has-text-left {
  text-align: left !important;
}

@media screen and (width <= 767px) {
  .has-text-left-mobile {
    text-align: left !important;
  }
}

@media screen and (width >= 768px), print {
  .has-text-left-tablet {
    text-align: left !important;
  }
}

@media screen and (width >= 768px) and (width <= 989px) {
  .has-text-left-tablet-only {
    text-align: left !important;
  }
}

@media screen and (width <= 989px) {
  .has-text-left-touch {
    text-align: left !important;
  }
}

@media screen and (width >= 990px) {
  .has-text-left-desktop {
    text-align: left !important;
  }
}

@media screen and (width >= 990px) and (width <= 1181px) {
  .has-text-left-desktop-only {
    text-align: left !important;
  }
}

@media screen and (width >= 1182px) {
  .has-text-left-widescreen {
    text-align: left !important;
  }
}

@media screen and (width >= 1182px) and (width <= 1373px) {
  .has-text-left-widescreen-only {
    text-align: left !important;
  }
}

@media screen and (width >= 1374px) {
  .has-text-left-fullhd {
    text-align: left !important;
  }
}

.has-text-right {
  text-align: right !important;
}

@media screen and (width <= 767px) {
  .has-text-right-mobile {
    text-align: right !important;
  }
}

@media screen and (width >= 768px), print {
  .has-text-right-tablet {
    text-align: right !important;
  }
}

@media screen and (width >= 768px) and (width <= 989px) {
  .has-text-right-tablet-only {
    text-align: right !important;
  }
}

@media screen and (width <= 989px) {
  .has-text-right-touch {
    text-align: right !important;
  }
}

@media screen and (width >= 990px) {
  .has-text-right-desktop {
    text-align: right !important;
  }
}

@media screen and (width >= 990px) and (width <= 1181px) {
  .has-text-right-desktop-only {
    text-align: right !important;
  }
}

@media screen and (width >= 1182px) {
  .has-text-right-widescreen {
    text-align: right !important;
  }
}

@media screen and (width >= 1182px) and (width <= 1373px) {
  .has-text-right-widescreen-only {
    text-align: right !important;
  }
}

@media screen and (width >= 1374px) {
  .has-text-right-fullhd {
    text-align: right !important;
  }
}

.is-capitalized {
  text-transform: capitalize !important;
}

.is-lowercase {
  text-transform: lowercase !important;
}

.is-uppercase {
  text-transform: uppercase !important;
}

.has-text-white {
  color: #fcfcfc !important;
}

a.has-text-white:hover, a.has-text-white:focus, .has-text-white.is-hoverable:hover, .has-text-white.is-hoverable:focus {
  color: #e3e3e3 !important;
}

.has-text-black {
  color: #1a1a1a !important;
}

a.has-text-black:hover, a.has-text-black:focus, .has-text-black.is-hoverable:hover, .has-text-black.is-hoverable:focus {
  color: #010101 !important;
}

a.has-text-light:hover, a.has-text-light:focus, .has-text-light.is-hoverable:hover, .has-text-light.is-hoverable:focus {
  color: #d6d6d6 !important;
}

.has-text-dark {
  color: #434a48 !important;
}

a.has-text-dark:hover, a.has-text-dark:focus, .has-text-dark.is-hoverable:hover, .has-text-dark.is-hoverable:focus {
  color: #2b2f2e !important;
}

.has-text-primary {
  color: #003f2d !important;
}

a.has-text-primary:hover, a.has-text-primary:focus, .has-text-primary.is-hoverable:hover, .has-text-primary.is-hoverable:focus {
  color: #000c09 !important;
}

.has-text-secondary {
  color: #d6e3df !important;
}

a.has-text-secondary:hover, a.has-text-secondary:focus, .has-text-secondary.is-hoverable:hover, .has-text-secondary.is-hoverable:focus {
  color: #b8cec7 !important;
}

.has-text-tertiary {
  color: #0d8070 !important;
}

a.has-text-tertiary:hover, a.has-text-tertiary:focus, .has-text-tertiary.is-hoverable:hover, .has-text-tertiary.is-hoverable:focus {
  color: #085247 !important;
}

.has-text-primary-invert {
  color: #fcfcfc !important;
}

a.has-text-primary-invert:hover, a.has-text-primary-invert:focus, .has-text-primary-invert.is-hoverable:hover, .has-text-primary-invert.is-hoverable:focus {
  color: #e3e3e3 !important;
}

.has-text-link {
  color: #003f2d !important;
}

a.has-text-link:hover, a.has-text-link:focus, .has-text-link.is-hoverable:hover, .has-text-link.is-hoverable:focus {
  color: #000c09 !important;
}

.has-text-info {
  color: #00b2dd !important;
}

a.has-text-info:hover, a.has-text-info:focus, .has-text-info.is-hoverable:hover, .has-text-info.is-hoverable:focus {
  color: #0089aa !important;
}

.has-text-pale-green {
  color: #d6e3df !important;
}

a.has-text-pale-green:hover, a.has-text-pale-green:focus, .has-text-pale-green.is-hoverable:hover, .has-text-pale-green.is-hoverable:focus {
  color: #b8cec7 !important;
}

.has-text-success {
  color: #50e3c2 !important;
}

a.has-text-success:hover, a.has-text-success:focus, .has-text-success.is-hoverable:hover, .has-text-success.is-hoverable:focus {
  color: #24dcb3 !important;
}

.has-text-warning {
  color: #ffdd57 !important;
}

a.has-text-warning:hover, a.has-text-warning:focus, .has-text-warning.is-hoverable:hover, .has-text-warning.is-hoverable:focus {
  color: #ffd324 !important;
}

.has-text-danger {
  color: #fc5328 !important;
}

a.has-text-danger:hover, a.has-text-danger:focus, .has-text-danger.is-hoverable:hover, .has-text-danger.is-hoverable:focus {
  color: #ee3303 !important;
}

.has-text-me {
  color: #00d2a0 !important;
}

a.has-text-me:hover, a.has-text-me:focus, .has-text-me.is-hoverable:hover, .has-text-me.is-hoverable:focus {
  color: #009f79 !important;
}

.has-text-we {
  color: #00b2dd !important;
}

a.has-text-we:hover, a.has-text-we:focus, .has-text-we.is-hoverable:hover, .has-text-we.is-hoverable:focus {
  color: #0089aa !important;
}

.has-text-amenity {
  color: #7d7ccf !important;
}

a.has-text-amenity:hover, a.has-text-amenity:focus, .has-text-amenity.is-hoverable:hover, .has-text-amenity.is-hoverable:focus {
  color: #5857c1 !important;
}

.has-text-light-pale-green {
  color: #eaf1ef !important;
}

a.has-text-light-pale-green:hover, a.has-text-light-pale-green:focus, .has-text-light-pale-green.is-hoverable:hover, .has-text-light-pale-green.is-hoverable:focus {
  color: #cbddd8 !important;
}

.has-text-green-forest-darker {
  color: #1d4837 !important;
}

a.has-text-green-forest-darker:hover, a.has-text-green-forest-darker:focus, .has-text-green-forest-darker.is-hoverable:hover, .has-text-green-forest-darker.is-hoverable:focus {
  color: #0e241b !important;
}

.has-text-green-forest-dark {
  color: #003f2d !important;
}

a.has-text-green-forest-dark:hover, a.has-text-green-forest-dark:focus, .has-text-green-forest-dark.is-hoverable:hover, .has-text-green-forest-dark.is-hoverable:focus {
  color: #000c09 !important;
}

.has-text-grey-darker {
  color: #434a48 !important;
}

.has-text-grey-dark {
  color: #4a4a4a !important;
}

.has-text-grey {
  color: #666 !important;
}

.has-text-grey-light {
  color: #b5b5b5 !important;
}

.has-text-grey-lighter {
  color: #dbdbdb !important;
}

.has-text-grey-lightest {
  color: #f3f3f3 !important;
}

.has-text-white-ter {
  color: #f0f0f0 !important;
}

.has-text-white-bis {
  color: #fafafa !important;
}

.has-text-light {
  color: #666 !important;
}

.has-text-weight-light {
  font-weight: 300 !important;
}

.has-text-weight-normal {
  font-weight: 400 !important;
}

.has-text-weight-medium {
  font-weight: 500 !important;
}

.has-text-weight-semibold {
  font-weight: 600 !important;
}

.has-text-weight-bold {
  font-weight: 700 !important;
}

.text-on-is-me, .text-on-is-we, .text-on-is-amenity {
  color: #fcfcfc !important;
}

.is-me {
  background-color: #00d2a0;
}

.is-we {
  background-color: #00b2dd;
}

.is-amenity {
  background-color: #7d7ccf;
}

.is-grey-lighter {
  background-color: #dbdbdb;
}

.is-pale-green {
  background-color: #dbdbdb33;
}

.is-light-gray {
  background-color: #e6eaea;
}

.is-pale-green-new {
  background-color: #c0d4cb;
}

.is-white {
  background-color: #f5f7f7;
}

.is-block {
  display: block !important;
}

@media screen and (width <= 767px) {
  .is-block-mobile {
    display: block !important;
  }
}

@media screen and (width >= 768px), print {
  .is-block-tablet {
    display: block !important;
  }
}

@media screen and (width >= 768px) and (width <= 989px) {
  .is-block-tablet-only {
    display: block !important;
  }
}

@media screen and (width <= 989px) {
  .is-block-touch {
    display: block !important;
  }
}

@media screen and (width >= 990px) {
  .is-block-desktop {
    display: block !important;
  }
}

@media screen and (width >= 990px) and (width <= 1181px) {
  .is-block-desktop-only {
    display: block !important;
  }
}

@media screen and (width >= 1182px) {
  .is-block-widescreen {
    display: block !important;
  }
}

@media screen and (width >= 1182px) and (width <= 1373px) {
  .is-block-widescreen-only {
    display: block !important;
  }
}

@media screen and (width >= 1374px) {
  .is-block-fullhd {
    display: block !important;
  }
}

.is-flex {
  display: flex !important;
}

@media screen and (width <= 767px) {
  .is-flex-mobile {
    display: flex !important;
  }
}

@media screen and (width >= 768px), print {
  .is-flex-tablet {
    display: flex !important;
  }
}

@media screen and (width >= 768px) and (width <= 989px) {
  .is-flex-tablet-only {
    display: flex !important;
  }
}

@media screen and (width <= 989px) {
  .is-flex-touch {
    display: flex !important;
  }
}

@media screen and (width >= 990px) {
  .is-flex-desktop {
    display: flex !important;
  }
}

@media screen and (width >= 990px) and (width <= 1181px) {
  .is-flex-desktop-only {
    display: flex !important;
  }
}

@media screen and (width >= 1182px) {
  .is-flex-widescreen {
    display: flex !important;
  }
}

@media screen and (width >= 1182px) and (width <= 1373px) {
  .is-flex-widescreen-only {
    display: flex !important;
  }
}

@media screen and (width >= 1374px) {
  .is-flex-fullhd {
    display: flex !important;
  }
}

.is-inline {
  display: inline !important;
}

@media screen and (width <= 767px) {
  .is-inline-mobile {
    display: inline !important;
  }
}

@media screen and (width >= 768px), print {
  .is-inline-tablet {
    display: inline !important;
  }
}

@media screen and (width >= 768px) and (width <= 989px) {
  .is-inline-tablet-only {
    display: inline !important;
  }
}

@media screen and (width <= 989px) {
  .is-inline-touch {
    display: inline !important;
  }
}

@media screen and (width >= 990px) {
  .is-inline-desktop {
    display: inline !important;
  }
}

@media screen and (width >= 990px) and (width <= 1181px) {
  .is-inline-desktop-only {
    display: inline !important;
  }
}

@media screen and (width >= 1182px) {
  .is-inline-widescreen {
    display: inline !important;
  }
}

@media screen and (width >= 1182px) and (width <= 1373px) {
  .is-inline-widescreen-only {
    display: inline !important;
  }
}

@media screen and (width >= 1374px) {
  .is-inline-fullhd {
    display: inline !important;
  }
}

.is-inline-block {
  display: inline-block !important;
}

@media screen and (width <= 767px) {
  .is-inline-block-mobile {
    display: inline-block !important;
  }
}

@media screen and (width >= 768px), print {
  .is-inline-block-tablet {
    display: inline-block !important;
  }
}

@media screen and (width >= 768px) and (width <= 989px) {
  .is-inline-block-tablet-only {
    display: inline-block !important;
  }
}

@media screen and (width <= 989px) {
  .is-inline-block-touch {
    display: inline-block !important;
  }
}

@media screen and (width >= 990px) {
  .is-inline-block-desktop {
    display: inline-block !important;
  }
}

@media screen and (width >= 990px) and (width <= 1181px) {
  .is-inline-block-desktop-only {
    display: inline-block !important;
  }
}

@media screen and (width >= 1182px) {
  .is-inline-block-widescreen {
    display: inline-block !important;
  }
}

@media screen and (width >= 1182px) and (width <= 1373px) {
  .is-inline-block-widescreen-only {
    display: inline-block !important;
  }
}

@media screen and (width >= 1374px) {
  .is-inline-block-fullhd {
    display: inline-block !important;
  }
}

.is-inline-flex {
  display: inline-flex !important;
}

@media screen and (width <= 767px) {
  .is-inline-flex-mobile {
    display: inline-flex !important;
  }
}

@media screen and (width >= 768px), print {
  .is-inline-flex-tablet {
    display: inline-flex !important;
  }
}

@media screen and (width >= 768px) and (width <= 989px) {
  .is-inline-flex-tablet-only {
    display: inline-flex !important;
  }
}

@media screen and (width <= 989px) {
  .is-inline-flex-touch {
    display: inline-flex !important;
  }
}

@media screen and (width >= 990px) {
  .is-inline-flex-desktop {
    display: inline-flex !important;
  }
}

@media screen and (width >= 990px) and (width <= 1181px) {
  .is-inline-flex-desktop-only {
    display: inline-flex !important;
  }
}

@media screen and (width >= 1182px) {
  .is-inline-flex-widescreen {
    display: inline-flex !important;
  }
}

@media screen and (width >= 1182px) and (width <= 1373px) {
  .is-inline-flex-widescreen-only {
    display: inline-flex !important;
  }
}

@media screen and (width >= 1374px) {
  .is-inline-flex-fullhd {
    display: inline-flex !important;
  }
}

.is-hidden {
  display: none !important;
}

.is-invisible {
  visibility: hidden;
}

@media screen and (width <= 767px) {
  .is-hidden-mobile {
    display: none !important;
  }
}

@media print {
  .is-hidden-print {
    display: none !important;
  }
}

.is-print-only {
  display: none;
}

@media print {
  .is-print-only {
    display: block;
  }
}

@media screen and (width >= 768px), print {
  .is-hidden-tablet {
    display: none !important;
  }
}

@media screen and (width >= 768px) and (width <= 989px) {
  .is-hidden-tablet-only {
    display: none !important;
  }
}

@media screen and (width <= 989px) {
  .is-hidden-touch {
    display: none !important;
  }
}

@media screen and (width >= 990px) {
  .is-hidden-desktop {
    display: none !important;
  }
}

@media screen and (width >= 990px) and (width <= 1181px) {
  .is-hidden-desktop-only {
    display: none !important;
  }
}

@media screen and (width >= 1182px) {
  .is-hidden-widescreen {
    display: none !important;
  }
}

@media screen and (width >= 1182px) and (width <= 1373px) {
  .is-hidden-widescreen-only {
    display: none !important;
  }
}

@media screen and (width >= 1374px) {
  .is-hidden-fullhd {
    display: none !important;
  }
}

.is-position-relative {
  position: relative !important;
}

.is-position-absolute {
  position: absolute !important;
}

.is-position-fixed {
  position: fixed !important;
}

.is-position-inherit {
  position: inherit !important;
}

.is-position-initial {
  position: initial !important;
}

.is-position-sticky {
  position: sticky !important;
}

.break-after {
  break-after: page;
}

.break-before {
  break-before: page;
}

.break-inside-avoid {
  break-inside: avoid;
}

.no-outline:focus {
  outline: none;
}

.no-pointer {
  pointer-events: none !important;
}

.is-marginless {
  margin: 0 !important;
}

.is-paddingless {
  padding: 0 !important;
}

.is-radiusless {
  border-radius: 0 !important;
}

.is-shadowless {
  box-shadow: none !important;
}

.is-unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
}

.is-borderless {
  border: none !important;
}

.is-scroll-link-target {
  color: inherit;
}

.has-margin-0-auto {
  margin: 0 auto !important;
}

.has-margin-left-auto {
  margin-left: auto;
}

.has-margin-right-auto {
  margin-right: auto;
}

.is-selectable {
  cursor: pointer;
}

.fade-in {
  visibility: visible;
  opacity: 1;
  transition: visibility linear, opacity .5s;
}

.fade-out {
  visibility: hidden;
  opacity: 0;
  transition: visibility 500s linear, opacity .5s, left 0s .5s;
  left: -9999px !important;
}

.is-invisible-to-be-faded-in {
  visibility: hidden;
  opacity: 0;
}

.visually-hidden {
  clip: rect(0 0 0 0);
  white-space: nowrap;
  border: 0;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

@media screen and (width <= 767px) {
  .column.is-narrow-mobile {
    flex: none;
  }

  .column.is-full-mobile {
    flex: none;
    width: 100%;
  }

  .column.is-three-quarters-mobile {
    flex: none;
    width: 75%;
  }

  .column.is-two-thirds-mobile {
    flex: none;
    width: 66.6666%;
  }

  .column.is-half-mobile {
    flex: none;
    width: 50%;
  }

  .column.is-one-third-mobile {
    flex: none;
    width: 33.3333%;
  }

  .column.is-one-quarter-mobile {
    flex: none;
    width: 25%;
  }

  .column.is-one-fifth-mobile {
    flex: none;
    width: 20%;
  }

  .column.is-two-fifths-mobile {
    flex: none;
    width: 40%;
  }

  .column.is-three-fifths-mobile {
    flex: none;
    width: 60%;
  }

  .column.is-four-fifths-mobile {
    flex: none;
    width: 80%;
  }

  .column.is-offset-three-quarters-mobile {
    margin-left: 75%;
  }

  .column.is-offset-two-thirds-mobile {
    margin-left: 66.6666%;
  }

  .column.is-offset-half-mobile {
    margin-left: 50%;
  }

  .column.is-offset-one-third-mobile {
    margin-left: 33.3333%;
  }

  .column.is-offset-one-quarter-mobile {
    margin-left: 25%;
  }

  .column.is-offset-one-fifth-mobile {
    margin-left: 20%;
  }

  .column.is-offset-two-fifths-mobile {
    margin-left: 40%;
  }

  .column.is-offset-three-fifths-mobile {
    margin-left: 60%;
  }

  .column.is-offset-four-fifths-mobile {
    margin-left: 80%;
  }

  .column.is-1-mobile {
    flex: none;
    width: 8.33333%;
  }

  .column.is-offset-1-mobile {
    margin-left: 8.33333%;
  }

  .column.is-2-mobile {
    flex: none;
    width: 16.6667%;
  }

  .column.is-offset-2-mobile {
    margin-left: 16.6667%;
  }

  .column.is-3-mobile {
    flex: none;
    width: 25%;
  }

  .column.is-offset-3-mobile {
    margin-left: 25%;
  }

  .column.is-4-mobile {
    flex: none;
    width: 33.3333%;
  }

  .column.is-offset-4-mobile {
    margin-left: 33.3333%;
  }

  .column.is-5-mobile {
    flex: none;
    width: 41.6667%;
  }

  .column.is-offset-5-mobile {
    margin-left: 41.6667%;
  }

  .column.is-6-mobile {
    flex: none;
    width: 50%;
  }

  .column.is-offset-6-mobile {
    margin-left: 50%;
  }

  .column.is-7-mobile {
    flex: none;
    width: 58.3333%;
  }

  .column.is-offset-7-mobile {
    margin-left: 58.3333%;
  }

  .column.is-8-mobile {
    flex: none;
    width: 66.6667%;
  }

  .column.is-offset-8-mobile {
    margin-left: 66.6667%;
  }

  .column.is-9-mobile {
    flex: none;
    width: 75%;
  }

  .column.is-offset-9-mobile {
    margin-left: 75%;
  }

  .column.is-10-mobile {
    flex: none;
    width: 83.3333%;
  }

  .column.is-offset-10-mobile {
    margin-left: 83.3333%;
  }

  .column.is-11-mobile {
    flex: none;
    width: 91.6667%;
  }

  .column.is-offset-11-mobile {
    margin-left: 91.6667%;
  }

  .column.is-12-mobile {
    flex: none;
    width: 100%;
  }

  .column.is-offset-12-mobile {
    margin-left: 100%;
  }
}

@media screen and (width >= 768px), print {
  .column.is-narrow, .column.is-narrow-tablet {
    flex: none;
  }

  .column.is-full, .column.is-full-tablet {
    flex: none;
    width: 100%;
  }

  .column.is-three-quarters, .column.is-three-quarters-tablet {
    flex: none;
    width: 75%;
  }

  .column.is-two-thirds, .column.is-two-thirds-tablet {
    flex: none;
    width: 66.6666%;
  }

  .column.is-half, .column.is-half-tablet {
    flex: none;
    width: 50%;
  }

  .column.is-one-third, .column.is-one-third-tablet {
    flex: none;
    width: 33.3333%;
  }

  .column.is-one-quarter, .column.is-one-quarter-tablet {
    flex: none;
    width: 25%;
  }

  .column.is-one-fifth, .column.is-one-fifth-tablet {
    flex: none;
    width: 20%;
  }

  .column.is-two-fifths, .column.is-two-fifths-tablet {
    flex: none;
    width: 40%;
  }

  .column.is-three-fifths, .column.is-three-fifths-tablet {
    flex: none;
    width: 60%;
  }

  .column.is-four-fifths, .column.is-four-fifths-tablet {
    flex: none;
    width: 80%;
  }

  .column.is-offset-three-quarters, .column.is-offset-three-quarters-tablet {
    margin-left: 75%;
  }

  .column.is-offset-two-thirds, .column.is-offset-two-thirds-tablet {
    margin-left: 66.6666%;
  }

  .column.is-offset-half, .column.is-offset-half-tablet {
    margin-left: 50%;
  }

  .column.is-offset-one-third, .column.is-offset-one-third-tablet {
    margin-left: 33.3333%;
  }

  .column.is-offset-one-quarter, .column.is-offset-one-quarter-tablet {
    margin-left: 25%;
  }

  .column.is-offset-one-fifth, .column.is-offset-one-fifth-tablet {
    margin-left: 20%;
  }

  .column.is-offset-two-fifths, .column.is-offset-two-fifths-tablet {
    margin-left: 40%;
  }

  .column.is-offset-three-fifths, .column.is-offset-three-fifths-tablet {
    margin-left: 60%;
  }

  .column.is-offset-four-fifths, .column.is-offset-four-fifths-tablet {
    margin-left: 80%;
  }

  .column.is-1, .column.is-1-tablet {
    flex: none;
    width: 8.33333%;
  }

  .column.is-offset-1, .column.is-offset-1-tablet {
    margin-left: 8.33333%;
  }

  .column.is-2, .column.is-2-tablet {
    flex: none;
    width: 16.6667%;
  }

  .column.is-offset-2, .column.is-offset-2-tablet {
    margin-left: 16.6667%;
  }

  .column.is-3, .column.is-3-tablet {
    flex: none;
    width: 25%;
  }

  .column.is-offset-3, .column.is-offset-3-tablet {
    margin-left: 25%;
  }

  .column.is-4, .column.is-4-tablet {
    flex: none;
    width: 33.3333%;
  }

  .column.is-offset-4, .column.is-offset-4-tablet {
    margin-left: 33.3333%;
  }

  .column.is-5, .column.is-5-tablet {
    flex: none;
    width: 41.6667%;
  }

  .column.is-offset-5, .column.is-offset-5-tablet {
    margin-left: 41.6667%;
  }

  .column.is-6, .column.is-6-tablet {
    flex: none;
    width: 50%;
  }

  .column.is-offset-6, .column.is-offset-6-tablet {
    margin-left: 50%;
  }

  .column.is-7, .column.is-7-tablet {
    flex: none;
    width: 58.3333%;
  }

  .column.is-offset-7, .column.is-offset-7-tablet {
    margin-left: 58.3333%;
  }

  .column.is-8, .column.is-8-tablet {
    flex: none;
    width: 66.6667%;
  }

  .column.is-offset-8, .column.is-offset-8-tablet {
    margin-left: 66.6667%;
  }

  .column.is-9, .column.is-9-tablet {
    flex: none;
    width: 75%;
  }

  .column.is-offset-9, .column.is-offset-9-tablet {
    margin-left: 75%;
  }

  .column.is-10, .column.is-10-tablet {
    flex: none;
    width: 83.3333%;
  }

  .column.is-offset-10, .column.is-offset-10-tablet {
    margin-left: 83.3333%;
  }

  .column.is-11, .column.is-11-tablet {
    flex: none;
    width: 91.6667%;
  }

  .column.is-offset-11, .column.is-offset-11-tablet {
    margin-left: 91.6667%;
  }

  .column.is-12, .column.is-12-tablet {
    flex: none;
    width: 100%;
  }

  .column.is-offset-12, .column.is-offset-12-tablet {
    margin-left: 100%;
  }
}

@media screen and (width <= 989px) {
  .column.is-narrow-touch {
    flex: none;
  }

  .column.is-full-touch {
    flex: none;
    width: 100%;
  }

  .column.is-three-quarters-touch {
    flex: none;
    width: 75%;
  }

  .column.is-two-thirds-touch {
    flex: none;
    width: 66.6666%;
  }

  .column.is-half-touch {
    flex: none;
    width: 50%;
  }

  .column.is-one-third-touch {
    flex: none;
    width: 33.3333%;
  }

  .column.is-one-quarter-touch {
    flex: none;
    width: 25%;
  }

  .column.is-one-fifth-touch {
    flex: none;
    width: 20%;
  }

  .column.is-two-fifths-touch {
    flex: none;
    width: 40%;
  }

  .column.is-three-fifths-touch {
    flex: none;
    width: 60%;
  }

  .column.is-four-fifths-touch {
    flex: none;
    width: 80%;
  }

  .column.is-offset-three-quarters-touch {
    margin-left: 75%;
  }

  .column.is-offset-two-thirds-touch {
    margin-left: 66.6666%;
  }

  .column.is-offset-half-touch {
    margin-left: 50%;
  }

  .column.is-offset-one-third-touch {
    margin-left: 33.3333%;
  }

  .column.is-offset-one-quarter-touch {
    margin-left: 25%;
  }

  .column.is-offset-one-fifth-touch {
    margin-left: 20%;
  }

  .column.is-offset-two-fifths-touch {
    margin-left: 40%;
  }

  .column.is-offset-three-fifths-touch {
    margin-left: 60%;
  }

  .column.is-offset-four-fifths-touch {
    margin-left: 80%;
  }

  .column.is-1-touch {
    flex: none;
    width: 8.33333%;
  }

  .column.is-offset-1-touch {
    margin-left: 8.33333%;
  }

  .column.is-2-touch {
    flex: none;
    width: 16.6667%;
  }

  .column.is-offset-2-touch {
    margin-left: 16.6667%;
  }

  .column.is-3-touch {
    flex: none;
    width: 25%;
  }

  .column.is-offset-3-touch {
    margin-left: 25%;
  }

  .column.is-4-touch {
    flex: none;
    width: 33.3333%;
  }

  .column.is-offset-4-touch {
    margin-left: 33.3333%;
  }

  .column.is-5-touch {
    flex: none;
    width: 41.6667%;
  }

  .column.is-offset-5-touch {
    margin-left: 41.6667%;
  }

  .column.is-6-touch {
    flex: none;
    width: 50%;
  }

  .column.is-offset-6-touch {
    margin-left: 50%;
  }

  .column.is-7-touch {
    flex: none;
    width: 58.3333%;
  }

  .column.is-offset-7-touch {
    margin-left: 58.3333%;
  }

  .column.is-8-touch {
    flex: none;
    width: 66.6667%;
  }

  .column.is-offset-8-touch {
    margin-left: 66.6667%;
  }

  .column.is-9-touch {
    flex: none;
    width: 75%;
  }

  .column.is-offset-9-touch {
    margin-left: 75%;
  }

  .column.is-10-touch {
    flex: none;
    width: 83.3333%;
  }

  .column.is-offset-10-touch {
    margin-left: 83.3333%;
  }

  .column.is-11-touch {
    flex: none;
    width: 91.6667%;
  }

  .column.is-offset-11-touch {
    margin-left: 91.6667%;
  }

  .column.is-12-touch {
    flex: none;
    width: 100%;
  }

  .column.is-offset-12-touch {
    margin-left: 100%;
  }
}

@media screen and (width >= 990px) {
  .column.is-narrow-desktop {
    flex: none;
  }

  .column.is-full-desktop {
    flex: none;
    width: 100%;
  }

  .column.is-three-quarters-desktop {
    flex: none;
    width: 75%;
  }

  .column.is-two-thirds-desktop {
    flex: none;
    width: 66.6666%;
  }

  .column.is-half-desktop {
    flex: none;
    width: 50%;
  }

  .column.is-one-third-desktop {
    flex: none;
    width: 33.3333%;
  }

  .column.is-one-quarter-desktop {
    flex: none;
    width: 25%;
  }

  .column.is-one-fifth-desktop {
    flex: none;
    width: 20%;
  }

  .column.is-two-fifths-desktop {
    flex: none;
    width: 40%;
  }

  .column.is-three-fifths-desktop {
    flex: none;
    width: 60%;
  }

  .column.is-four-fifths-desktop {
    flex: none;
    width: 80%;
  }

  .column.is-offset-three-quarters-desktop {
    margin-left: 75%;
  }

  .column.is-offset-two-thirds-desktop {
    margin-left: 66.6666%;
  }

  .column.is-offset-half-desktop {
    margin-left: 50%;
  }

  .column.is-offset-one-third-desktop {
    margin-left: 33.3333%;
  }

  .column.is-offset-one-quarter-desktop {
    margin-left: 25%;
  }

  .column.is-offset-one-fifth-desktop {
    margin-left: 20%;
  }

  .column.is-offset-two-fifths-desktop {
    margin-left: 40%;
  }

  .column.is-offset-three-fifths-desktop {
    margin-left: 60%;
  }

  .column.is-offset-four-fifths-desktop {
    margin-left: 80%;
  }

  .column.is-1-desktop {
    flex: none;
    width: 8.33333%;
  }

  .column.is-offset-1-desktop {
    margin-left: 8.33333%;
  }

  .column.is-2-desktop {
    flex: none;
    width: 16.6667%;
  }

  .column.is-offset-2-desktop {
    margin-left: 16.6667%;
  }

  .column.is-3-desktop {
    flex: none;
    width: 25%;
  }

  .column.is-offset-3-desktop {
    margin-left: 25%;
  }

  .column.is-4-desktop {
    flex: none;
    width: 33.3333%;
  }

  .column.is-offset-4-desktop {
    margin-left: 33.3333%;
  }

  .column.is-5-desktop {
    flex: none;
    width: 41.6667%;
  }

  .column.is-offset-5-desktop {
    margin-left: 41.6667%;
  }

  .column.is-6-desktop {
    flex: none;
    width: 50%;
  }

  .column.is-offset-6-desktop {
    margin-left: 50%;
  }

  .column.is-7-desktop {
    flex: none;
    width: 58.3333%;
  }

  .column.is-offset-7-desktop {
    margin-left: 58.3333%;
  }

  .column.is-8-desktop {
    flex: none;
    width: 66.6667%;
  }

  .column.is-offset-8-desktop {
    margin-left: 66.6667%;
  }

  .column.is-9-desktop {
    flex: none;
    width: 75%;
  }

  .column.is-offset-9-desktop {
    margin-left: 75%;
  }

  .column.is-10-desktop {
    flex: none;
    width: 83.3333%;
  }

  .column.is-offset-10-desktop {
    margin-left: 83.3333%;
  }

  .column.is-11-desktop {
    flex: none;
    width: 91.6667%;
  }

  .column.is-offset-11-desktop {
    margin-left: 91.6667%;
  }

  .column.is-12-desktop {
    flex: none;
    width: 100%;
  }

  .column.is-offset-12-desktop {
    margin-left: 100%;
  }
}

@media screen and (width >= 1182px) {
  .column.is-narrow-widescreen {
    flex: none;
  }

  .column.is-full-widescreen {
    flex: none;
    width: 100%;
  }

  .column.is-three-quarters-widescreen {
    flex: none;
    width: 75%;
  }

  .column.is-two-thirds-widescreen {
    flex: none;
    width: 66.6666%;
  }

  .column.is-half-widescreen {
    flex: none;
    width: 50%;
  }

  .column.is-one-third-widescreen {
    flex: none;
    width: 33.3333%;
  }

  .column.is-one-quarter-widescreen {
    flex: none;
    width: 25%;
  }

  .column.is-one-fifth-widescreen {
    flex: none;
    width: 20%;
  }

  .column.is-two-fifths-widescreen {
    flex: none;
    width: 40%;
  }

  .column.is-three-fifths-widescreen {
    flex: none;
    width: 60%;
  }

  .column.is-four-fifths-widescreen {
    flex: none;
    width: 80%;
  }

  .column.is-offset-three-quarters-widescreen {
    margin-left: 75%;
  }

  .column.is-offset-two-thirds-widescreen {
    margin-left: 66.6666%;
  }

  .column.is-offset-half-widescreen {
    margin-left: 50%;
  }

  .column.is-offset-one-third-widescreen {
    margin-left: 33.3333%;
  }

  .column.is-offset-one-quarter-widescreen {
    margin-left: 25%;
  }

  .column.is-offset-one-fifth-widescreen {
    margin-left: 20%;
  }

  .column.is-offset-two-fifths-widescreen {
    margin-left: 40%;
  }

  .column.is-offset-three-fifths-widescreen {
    margin-left: 60%;
  }

  .column.is-offset-four-fifths-widescreen {
    margin-left: 80%;
  }

  .column.is-1-widescreen {
    flex: none;
    width: 8.33333%;
  }

  .column.is-offset-1-widescreen {
    margin-left: 8.33333%;
  }

  .column.is-2-widescreen {
    flex: none;
    width: 16.6667%;
  }

  .column.is-offset-2-widescreen {
    margin-left: 16.6667%;
  }

  .column.is-3-widescreen {
    flex: none;
    width: 25%;
  }

  .column.is-offset-3-widescreen {
    margin-left: 25%;
  }

  .column.is-4-widescreen {
    flex: none;
    width: 33.3333%;
  }

  .column.is-offset-4-widescreen {
    margin-left: 33.3333%;
  }

  .column.is-5-widescreen {
    flex: none;
    width: 41.6667%;
  }

  .column.is-offset-5-widescreen {
    margin-left: 41.6667%;
  }

  .column.is-6-widescreen {
    flex: none;
    width: 50%;
  }

  .column.is-offset-6-widescreen {
    margin-left: 50%;
  }

  .column.is-7-widescreen {
    flex: none;
    width: 58.3333%;
  }

  .column.is-offset-7-widescreen {
    margin-left: 58.3333%;
  }

  .column.is-8-widescreen {
    flex: none;
    width: 66.6667%;
  }

  .column.is-offset-8-widescreen {
    margin-left: 66.6667%;
  }

  .column.is-9-widescreen {
    flex: none;
    width: 75%;
  }

  .column.is-offset-9-widescreen {
    margin-left: 75%;
  }

  .column.is-10-widescreen {
    flex: none;
    width: 83.3333%;
  }

  .column.is-offset-10-widescreen {
    margin-left: 83.3333%;
  }

  .column.is-11-widescreen {
    flex: none;
    width: 91.6667%;
  }

  .column.is-offset-11-widescreen {
    margin-left: 91.6667%;
  }

  .column.is-12-widescreen {
    flex: none;
    width: 100%;
  }

  .column.is-offset-12-widescreen {
    margin-left: 100%;
  }
}

@media screen and (width >= 1374px) {
  .column.is-narrow-fullhd {
    flex: none;
  }

  .column.is-full-fullhd {
    flex: none;
    width: 100%;
  }

  .column.is-three-quarters-fullhd {
    flex: none;
    width: 75%;
  }

  .column.is-two-thirds-fullhd {
    flex: none;
    width: 66.6666%;
  }

  .column.is-half-fullhd {
    flex: none;
    width: 50%;
  }

  .column.is-one-third-fullhd {
    flex: none;
    width: 33.3333%;
  }

  .column.is-one-quarter-fullhd {
    flex: none;
    width: 25%;
  }

  .column.is-one-fifth-fullhd {
    flex: none;
    width: 20%;
  }

  .column.is-two-fifths-fullhd {
    flex: none;
    width: 40%;
  }

  .column.is-three-fifths-fullhd {
    flex: none;
    width: 60%;
  }

  .column.is-four-fifths-fullhd {
    flex: none;
    width: 80%;
  }

  .column.is-offset-three-quarters-fullhd {
    margin-left: 75%;
  }

  .column.is-offset-two-thirds-fullhd {
    margin-left: 66.6666%;
  }

  .column.is-offset-half-fullhd {
    margin-left: 50%;
  }

  .column.is-offset-one-third-fullhd {
    margin-left: 33.3333%;
  }

  .column.is-offset-one-quarter-fullhd {
    margin-left: 25%;
  }

  .column.is-offset-one-fifth-fullhd {
    margin-left: 20%;
  }

  .column.is-offset-two-fifths-fullhd {
    margin-left: 40%;
  }

  .column.is-offset-three-fifths-fullhd {
    margin-left: 60%;
  }

  .column.is-offset-four-fifths-fullhd {
    margin-left: 80%;
  }

  .column.is-1-fullhd {
    flex: none;
    width: 8.33333%;
  }

  .column.is-offset-1-fullhd {
    margin-left: 8.33333%;
  }

  .column.is-2-fullhd {
    flex: none;
    width: 16.6667%;
  }

  .column.is-offset-2-fullhd {
    margin-left: 16.6667%;
  }

  .column.is-3-fullhd {
    flex: none;
    width: 25%;
  }

  .column.is-offset-3-fullhd {
    margin-left: 25%;
  }

  .column.is-4-fullhd {
    flex: none;
    width: 33.3333%;
  }

  .column.is-offset-4-fullhd {
    margin-left: 33.3333%;
  }

  .column.is-5-fullhd {
    flex: none;
    width: 41.6667%;
  }

  .column.is-offset-5-fullhd {
    margin-left: 41.6667%;
  }

  .column.is-6-fullhd {
    flex: none;
    width: 50%;
  }

  .column.is-offset-6-fullhd {
    margin-left: 50%;
  }

  .column.is-7-fullhd {
    flex: none;
    width: 58.3333%;
  }

  .column.is-offset-7-fullhd {
    margin-left: 58.3333%;
  }

  .column.is-8-fullhd {
    flex: none;
    width: 66.6667%;
  }

  .column.is-offset-8-fullhd {
    margin-left: 66.6667%;
  }

  .column.is-9-fullhd {
    flex: none;
    width: 75%;
  }

  .column.is-offset-9-fullhd {
    margin-left: 75%;
  }

  .column.is-10-fullhd {
    flex: none;
    width: 83.3333%;
  }

  .column.is-offset-10-fullhd {
    margin-left: 83.3333%;
  }

  .column.is-11-fullhd {
    flex: none;
    width: 91.6667%;
  }

  .column.is-offset-11-fullhd {
    margin-left: 91.6667%;
  }

  .column.is-12-fullhd {
    flex: none;
    width: 100%;
  }

  .column.is-offset-12-fullhd {
    margin-left: 100%;
  }
}

@media screen and (width >= 768px), print {
  .columns:not(.is-desktop) {
    display: flex;
  }
}

@media screen and (width >= 990px) {
  .columns.is-desktop {
    display: flex;
  }
}

.navbar {
  background-color: #fff;
  height: 4.25rem;
  padding: 0 1.5rem;
  transition: box-shadow .75s cubic-bezier(.23, 1, .32, 1);
  position: relative;
}

.navbar.is-white {
  color: #1a1a1a;
  background-color: #fcfcfc;
}

.navbar.is-white .navbar-brand > .navbar-item, .navbar.is-white .navbar-brand .navbar-link {
  color: #1a1a1a;
}

.navbar.is-white .navbar-brand > a.navbar-item:hover, .navbar.is-white .navbar-brand > a.navbar-item.is-active, .navbar.is-white .navbar-brand .navbar-link:hover, .navbar.is-white .navbar-brand .navbar-link.is-active {
  color: #1a1a1a;
  background-color: #0000;
}

.navbar.is-white .navbar-brand .navbar-link:after {
  border-color: #1a1a1a;
}

@media screen and (width >= 990px) {
  .navbar.is-white .navbar-start > .navbar-item, .navbar.is-white .navbar-start .navbar-link, .navbar.is-white .navbar-end > .navbar-item, .navbar.is-white .navbar-end .navbar-link {
    color: #1a1a1a;
  }

  .navbar.is-white .navbar-start > a.navbar-item:hover, .navbar.is-white .navbar-start > a.navbar-item.is-active, .navbar.is-white .navbar-start .navbar-link:hover, .navbar.is-white .navbar-start .navbar-link.is-active, .navbar.is-white .navbar-end > a.navbar-item:hover, .navbar.is-white .navbar-end > a.navbar-item.is-active, .navbar.is-white .navbar-end .navbar-link:hover, .navbar.is-white .navbar-end .navbar-link.is-active {
    color: #1a1a1a;
    background-color: #efefef;
  }

  .navbar.is-white .navbar-start .navbar-link:after, .navbar.is-white .navbar-end .navbar-link:after {
    border-color: #1a1a1a;
  }

  .navbar.is-white .navbar-item.has-dropdown:hover .navbar-link, .navbar.is-white .navbar-item.has-dropdown.is-active .navbar-link {
    color: #1a1a1a;
    background-color: #efefef;
  }

  .navbar.is-white .navbar-dropdown a.navbar-item.is-active {
    color: #1a1a1a;
    background-color: #fcfcfc;
  }
}

.navbar.is-black {
  color: #fcfcfc;
  background-color: #1a1a1a;
}

.navbar.is-black .navbar-brand > .navbar-item, .navbar.is-black .navbar-brand .navbar-link {
  color: #fcfcfc;
}

.navbar.is-black .navbar-brand > a.navbar-item:hover, .navbar.is-black .navbar-brand > a.navbar-item.is-active, .navbar.is-black .navbar-brand .navbar-link:hover, .navbar.is-black .navbar-brand .navbar-link.is-active {
  color: #fcfcfc;
  background-color: #0000;
}

.navbar.is-black .navbar-brand .navbar-link:after {
  border-color: #fcfcfc;
}

@media screen and (width >= 990px) {
  .navbar.is-black .navbar-start > .navbar-item, .navbar.is-black .navbar-start .navbar-link, .navbar.is-black .navbar-end > .navbar-item, .navbar.is-black .navbar-end .navbar-link {
    color: #fcfcfc;
  }

  .navbar.is-black .navbar-start > a.navbar-item:hover, .navbar.is-black .navbar-start > a.navbar-item.is-active, .navbar.is-black .navbar-start .navbar-link:hover, .navbar.is-black .navbar-start .navbar-link.is-active, .navbar.is-black .navbar-end > a.navbar-item:hover, .navbar.is-black .navbar-end > a.navbar-item.is-active, .navbar.is-black .navbar-end .navbar-link:hover, .navbar.is-black .navbar-end .navbar-link.is-active {
    color: #fcfcfc;
    background-color: #0d0d0d;
  }

  .navbar.is-black .navbar-start .navbar-link:after, .navbar.is-black .navbar-end .navbar-link:after {
    border-color: #fcfcfc;
  }

  .navbar.is-black .navbar-item.has-dropdown:hover .navbar-link, .navbar.is-black .navbar-item.has-dropdown.is-active .navbar-link {
    color: #fcfcfc;
    background-color: #0d0d0d;
  }

  .navbar.is-black .navbar-dropdown a.navbar-item.is-active {
    color: #fcfcfc;
    background-color: #1a1a1a;
  }
}

.navbar.is-light {
  color: #434a48;
  background-color: #f0f0f0;
}

.navbar.is-light .navbar-brand > .navbar-item, .navbar.is-light .navbar-brand .navbar-link {
  color: #434a48;
}

.navbar.is-light .navbar-brand > a.navbar-item:hover, .navbar.is-light .navbar-brand > a.navbar-item.is-active, .navbar.is-light .navbar-brand .navbar-link:hover, .navbar.is-light .navbar-brand .navbar-link.is-active {
  color: #434a48;
  background-color: #0000;
}

.navbar.is-light .navbar-brand .navbar-link:after {
  border-color: #434a48;
}

@media screen and (width >= 990px) {
  .navbar.is-light .navbar-start > .navbar-item, .navbar.is-light .navbar-start .navbar-link, .navbar.is-light .navbar-end > .navbar-item, .navbar.is-light .navbar-end .navbar-link {
    color: #434a48;
  }

  .navbar.is-light .navbar-start > a.navbar-item:hover, .navbar.is-light .navbar-start > a.navbar-item.is-active, .navbar.is-light .navbar-start .navbar-link:hover, .navbar.is-light .navbar-start .navbar-link.is-active, .navbar.is-light .navbar-end > a.navbar-item:hover, .navbar.is-light .navbar-end > a.navbar-item.is-active, .navbar.is-light .navbar-end .navbar-link:hover, .navbar.is-light .navbar-end .navbar-link.is-active {
    color: #434a48;
    background-color: #e3e3e3;
  }

  .navbar.is-light .navbar-start .navbar-link:after, .navbar.is-light .navbar-end .navbar-link:after {
    border-color: #434a48;
  }

  .navbar.is-light .navbar-item.has-dropdown:hover .navbar-link, .navbar.is-light .navbar-item.has-dropdown.is-active .navbar-link {
    color: #434a48;
    background-color: #e3e3e3;
  }

  .navbar.is-light .navbar-dropdown a.navbar-item.is-active {
    color: #434a48;
    background-color: #f0f0f0;
  }
}

.navbar.is-dark {
  color: #f0f0f0;
  background-color: #434a48;
}

.navbar.is-dark .navbar-brand > .navbar-item, .navbar.is-dark .navbar-brand .navbar-link {
  color: #f0f0f0;
}

.navbar.is-dark .navbar-brand > a.navbar-item:hover, .navbar.is-dark .navbar-brand > a.navbar-item.is-active, .navbar.is-dark .navbar-brand .navbar-link:hover, .navbar.is-dark .navbar-brand .navbar-link.is-active {
  color: #f0f0f0;
  background-color: #0000;
}

.navbar.is-dark .navbar-brand .navbar-link:after {
  border-color: #f0f0f0;
}

@media screen and (width >= 990px) {
  .navbar.is-dark .navbar-start > .navbar-item, .navbar.is-dark .navbar-start .navbar-link, .navbar.is-dark .navbar-end > .navbar-item, .navbar.is-dark .navbar-end .navbar-link {
    color: #f0f0f0;
  }

  .navbar.is-dark .navbar-start > a.navbar-item:hover, .navbar.is-dark .navbar-start > a.navbar-item.is-active, .navbar.is-dark .navbar-start .navbar-link:hover, .navbar.is-dark .navbar-start .navbar-link.is-active, .navbar.is-dark .navbar-end > a.navbar-item:hover, .navbar.is-dark .navbar-end > a.navbar-item.is-active, .navbar.is-dark .navbar-end .navbar-link:hover, .navbar.is-dark .navbar-end .navbar-link.is-active {
    color: #f0f0f0;
    background-color: #373d3b;
  }

  .navbar.is-dark .navbar-start .navbar-link:after, .navbar.is-dark .navbar-end .navbar-link:after {
    border-color: #f0f0f0;
  }

  .navbar.is-dark .navbar-item.has-dropdown:hover .navbar-link, .navbar.is-dark .navbar-item.has-dropdown.is-active .navbar-link {
    color: #f0f0f0;
    background-color: #373d3b;
  }

  .navbar.is-dark .navbar-dropdown a.navbar-item.is-active {
    color: #f0f0f0;
    background-color: #434a48;
  }
}

.navbar.is-primary {
  color: #fcfcfc;
  background-color: #003f2d;
}

.navbar.is-primary .navbar-brand > .navbar-item, .navbar.is-primary .navbar-brand .navbar-link {
  color: #fcfcfc;
}

.navbar.is-primary .navbar-brand > a.navbar-item:hover, .navbar.is-primary .navbar-brand > a.navbar-item.is-active, .navbar.is-primary .navbar-brand .navbar-link:hover, .navbar.is-primary .navbar-brand .navbar-link.is-active {
  color: #fcfcfc;
  background-color: #0000;
}

.navbar.is-primary .navbar-brand .navbar-link:after {
  border-color: #fcfcfc;
}

@media screen and (width >= 990px) {
  .navbar.is-primary .navbar-start > .navbar-item, .navbar.is-primary .navbar-start .navbar-link, .navbar.is-primary .navbar-end > .navbar-item, .navbar.is-primary .navbar-end .navbar-link {
    color: #fcfcfc;
  }

  .navbar.is-primary .navbar-start > a.navbar-item:hover, .navbar.is-primary .navbar-start > a.navbar-item.is-active, .navbar.is-primary .navbar-start .navbar-link:hover, .navbar.is-primary .navbar-start .navbar-link.is-active, .navbar.is-primary .navbar-end > a.navbar-item:hover, .navbar.is-primary .navbar-end > a.navbar-item.is-active, .navbar.is-primary .navbar-end .navbar-link:hover, .navbar.is-primary .navbar-end .navbar-link.is-active {
    color: #fcfcfc;
    background-color: #00261b;
  }

  .navbar.is-primary .navbar-start .navbar-link:after, .navbar.is-primary .navbar-end .navbar-link:after {
    border-color: #fcfcfc;
  }

  .navbar.is-primary .navbar-item.has-dropdown:hover .navbar-link, .navbar.is-primary .navbar-item.has-dropdown.is-active .navbar-link {
    color: #fcfcfc;
    background-color: #00261b;
  }

  .navbar.is-primary .navbar-dropdown a.navbar-item.is-active {
    color: #fcfcfc;
    background-color: #003f2d;
  }
}

.navbar.is-secondary {
  color: #1a1a1a;
  background-color: #d6e3df;
}

.navbar.is-secondary .navbar-brand > .navbar-item, .navbar.is-secondary .navbar-brand .navbar-link {
  color: #1a1a1a;
}

.navbar.is-secondary .navbar-brand > a.navbar-item:hover, .navbar.is-secondary .navbar-brand > a.navbar-item.is-active, .navbar.is-secondary .navbar-brand .navbar-link:hover, .navbar.is-secondary .navbar-brand .navbar-link.is-active {
  color: #1a1a1a;
  background-color: #0000;
}

.navbar.is-secondary .navbar-brand .navbar-link:after {
  border-color: #1a1a1a;
}

@media screen and (width >= 990px) {
  .navbar.is-secondary .navbar-start > .navbar-item, .navbar.is-secondary .navbar-start .navbar-link, .navbar.is-secondary .navbar-end > .navbar-item, .navbar.is-secondary .navbar-end .navbar-link {
    color: #1a1a1a;
  }

  .navbar.is-secondary .navbar-start > a.navbar-item:hover, .navbar.is-secondary .navbar-start > a.navbar-item.is-active, .navbar.is-secondary .navbar-start .navbar-link:hover, .navbar.is-secondary .navbar-start .navbar-link.is-active, .navbar.is-secondary .navbar-end > a.navbar-item:hover, .navbar.is-secondary .navbar-end > a.navbar-item.is-active, .navbar.is-secondary .navbar-end .navbar-link:hover, .navbar.is-secondary .navbar-end .navbar-link.is-active {
    color: #1a1a1a;
    background-color: #c7d9d3;
  }

  .navbar.is-secondary .navbar-start .navbar-link:after, .navbar.is-secondary .navbar-end .navbar-link:after {
    border-color: #1a1a1a;
  }

  .navbar.is-secondary .navbar-item.has-dropdown:hover .navbar-link, .navbar.is-secondary .navbar-item.has-dropdown.is-active .navbar-link {
    color: #1a1a1a;
    background-color: #c7d9d3;
  }

  .navbar.is-secondary .navbar-dropdown a.navbar-item.is-active {
    color: #1a1a1a;
    background-color: #d6e3df;
  }
}

.navbar.is-tertiary {
  color: #fcfcfc;
  background-color: #0d8070;
}

.navbar.is-tertiary .navbar-brand > .navbar-item, .navbar.is-tertiary .navbar-brand .navbar-link {
  color: #fcfcfc;
}

.navbar.is-tertiary .navbar-brand > a.navbar-item:hover, .navbar.is-tertiary .navbar-brand > a.navbar-item.is-active, .navbar.is-tertiary .navbar-brand .navbar-link:hover, .navbar.is-tertiary .navbar-brand .navbar-link.is-active {
  color: #fcfcfc;
  background-color: #0000;
}

.navbar.is-tertiary .navbar-brand .navbar-link:after {
  border-color: #fcfcfc;
}

@media screen and (width >= 990px) {
  .navbar.is-tertiary .navbar-start > .navbar-item, .navbar.is-tertiary .navbar-start .navbar-link, .navbar.is-tertiary .navbar-end > .navbar-item, .navbar.is-tertiary .navbar-end .navbar-link {
    color: #fcfcfc;
  }

  .navbar.is-tertiary .navbar-start > a.navbar-item:hover, .navbar.is-tertiary .navbar-start > a.navbar-item.is-active, .navbar.is-tertiary .navbar-start .navbar-link:hover, .navbar.is-tertiary .navbar-start .navbar-link.is-active, .navbar.is-tertiary .navbar-end > a.navbar-item:hover, .navbar.is-tertiary .navbar-end > a.navbar-item.is-active, .navbar.is-tertiary .navbar-end .navbar-link:hover, .navbar.is-tertiary .navbar-end .navbar-link.is-active {
    color: #fcfcfc;
    background-color: #0b695c;
  }

  .navbar.is-tertiary .navbar-start .navbar-link:after, .navbar.is-tertiary .navbar-end .navbar-link:after {
    border-color: #fcfcfc;
  }

  .navbar.is-tertiary .navbar-item.has-dropdown:hover .navbar-link, .navbar.is-tertiary .navbar-item.has-dropdown.is-active .navbar-link {
    color: #fcfcfc;
    background-color: #0b695c;
  }

  .navbar.is-tertiary .navbar-dropdown a.navbar-item.is-active {
    color: #fcfcfc;
    background-color: #0d8070;
  }
}

.navbar.is-primary-invert {
  color: #003f2d;
  background-color: #fcfcfc;
}

.navbar.is-primary-invert .navbar-brand > .navbar-item, .navbar.is-primary-invert .navbar-brand .navbar-link {
  color: #003f2d;
}

.navbar.is-primary-invert .navbar-brand > a.navbar-item:hover, .navbar.is-primary-invert .navbar-brand > a.navbar-item.is-active, .navbar.is-primary-invert .navbar-brand .navbar-link:hover, .navbar.is-primary-invert .navbar-brand .navbar-link.is-active {
  color: #003f2d;
  background-color: #0000;
}

.navbar.is-primary-invert .navbar-brand .navbar-link:after {
  border-color: #003f2d;
}

@media screen and (width >= 990px) {
  .navbar.is-primary-invert .navbar-start > .navbar-item, .navbar.is-primary-invert .navbar-start .navbar-link, .navbar.is-primary-invert .navbar-end > .navbar-item, .navbar.is-primary-invert .navbar-end .navbar-link {
    color: #003f2d;
  }

  .navbar.is-primary-invert .navbar-start > a.navbar-item:hover, .navbar.is-primary-invert .navbar-start > a.navbar-item.is-active, .navbar.is-primary-invert .navbar-start .navbar-link:hover, .navbar.is-primary-invert .navbar-start .navbar-link.is-active, .navbar.is-primary-invert .navbar-end > a.navbar-item:hover, .navbar.is-primary-invert .navbar-end > a.navbar-item.is-active, .navbar.is-primary-invert .navbar-end .navbar-link:hover, .navbar.is-primary-invert .navbar-end .navbar-link.is-active {
    color: #003f2d;
    background-color: #efefef;
  }

  .navbar.is-primary-invert .navbar-start .navbar-link:after, .navbar.is-primary-invert .navbar-end .navbar-link:after {
    border-color: #003f2d;
  }

  .navbar.is-primary-invert .navbar-item.has-dropdown:hover .navbar-link, .navbar.is-primary-invert .navbar-item.has-dropdown.is-active .navbar-link {
    color: #003f2d;
    background-color: #efefef;
  }

  .navbar.is-primary-invert .navbar-dropdown a.navbar-item.is-active {
    color: #003f2d;
    background-color: #fcfcfc;
  }
}

.navbar.is-link {
  color: #fcfcfc;
  background-color: #003f2d;
}

.navbar.is-link .navbar-brand > .navbar-item, .navbar.is-link .navbar-brand .navbar-link {
  color: #fcfcfc;
}

.navbar.is-link .navbar-brand > a.navbar-item:hover, .navbar.is-link .navbar-brand > a.navbar-item.is-active, .navbar.is-link .navbar-brand .navbar-link:hover, .navbar.is-link .navbar-brand .navbar-link.is-active {
  color: #fcfcfc;
  background-color: #0000;
}

.navbar.is-link .navbar-brand .navbar-link:after {
  border-color: #fcfcfc;
}

@media screen and (width >= 990px) {
  .navbar.is-link .navbar-start > .navbar-item, .navbar.is-link .navbar-start .navbar-link, .navbar.is-link .navbar-end > .navbar-item, .navbar.is-link .navbar-end .navbar-link {
    color: #fcfcfc;
  }

  .navbar.is-link .navbar-start > a.navbar-item:hover, .navbar.is-link .navbar-start > a.navbar-item.is-active, .navbar.is-link .navbar-start .navbar-link:hover, .navbar.is-link .navbar-start .navbar-link.is-active, .navbar.is-link .navbar-end > a.navbar-item:hover, .navbar.is-link .navbar-end > a.navbar-item.is-active, .navbar.is-link .navbar-end .navbar-link:hover, .navbar.is-link .navbar-end .navbar-link.is-active {
    color: #fcfcfc;
    background-color: #00261b;
  }

  .navbar.is-link .navbar-start .navbar-link:after, .navbar.is-link .navbar-end .navbar-link:after {
    border-color: #fcfcfc;
  }

  .navbar.is-link .navbar-item.has-dropdown:hover .navbar-link, .navbar.is-link .navbar-item.has-dropdown.is-active .navbar-link {
    color: #fcfcfc;
    background-color: #00261b;
  }

  .navbar.is-link .navbar-dropdown a.navbar-item.is-active {
    color: #fcfcfc;
    background-color: #003f2d;
  }
}

.navbar.is-info {
  color: #fcfcfc;
  background-color: #00b2dd;
}

.navbar.is-info .navbar-brand > .navbar-item, .navbar.is-info .navbar-brand .navbar-link {
  color: #fcfcfc;
}

.navbar.is-info .navbar-brand > a.navbar-item:hover, .navbar.is-info .navbar-brand > a.navbar-item.is-active, .navbar.is-info .navbar-brand .navbar-link:hover, .navbar.is-info .navbar-brand .navbar-link.is-active {
  color: #fcfcfc;
  background-color: #0000;
}

.navbar.is-info .navbar-brand .navbar-link:after {
  border-color: #fcfcfc;
}

@media screen and (width >= 990px) {
  .navbar.is-info .navbar-start > .navbar-item, .navbar.is-info .navbar-start .navbar-link, .navbar.is-info .navbar-end > .navbar-item, .navbar.is-info .navbar-end .navbar-link {
    color: #fcfcfc;
  }

  .navbar.is-info .navbar-start > a.navbar-item:hover, .navbar.is-info .navbar-start > a.navbar-item.is-active, .navbar.is-info .navbar-start .navbar-link:hover, .navbar.is-info .navbar-start .navbar-link.is-active, .navbar.is-info .navbar-end > a.navbar-item:hover, .navbar.is-info .navbar-end > a.navbar-item.is-active, .navbar.is-info .navbar-end .navbar-link:hover, .navbar.is-info .navbar-end .navbar-link.is-active {
    color: #fcfcfc;
    background-color: #009dc4;
  }

  .navbar.is-info .navbar-start .navbar-link:after, .navbar.is-info .navbar-end .navbar-link:after {
    border-color: #fcfcfc;
  }

  .navbar.is-info .navbar-item.has-dropdown:hover .navbar-link, .navbar.is-info .navbar-item.has-dropdown.is-active .navbar-link {
    color: #fcfcfc;
    background-color: #009dc4;
  }

  .navbar.is-info .navbar-dropdown a.navbar-item.is-active {
    color: #fcfcfc;
    background-color: #00b2dd;
  }
}

.navbar.is-pale-green {
  color: #1a1a1a;
  background-color: #d6e3df;
}

.navbar.is-pale-green .navbar-brand > .navbar-item, .navbar.is-pale-green .navbar-brand .navbar-link {
  color: #1a1a1a;
}

.navbar.is-pale-green .navbar-brand > a.navbar-item:hover, .navbar.is-pale-green .navbar-brand > a.navbar-item.is-active, .navbar.is-pale-green .navbar-brand .navbar-link:hover, .navbar.is-pale-green .navbar-brand .navbar-link.is-active {
  color: #1a1a1a;
  background-color: #0000;
}

.navbar.is-pale-green .navbar-brand .navbar-link:after {
  border-color: #1a1a1a;
}

@media screen and (width >= 990px) {
  .navbar.is-pale-green .navbar-start > .navbar-item, .navbar.is-pale-green .navbar-start .navbar-link, .navbar.is-pale-green .navbar-end > .navbar-item, .navbar.is-pale-green .navbar-end .navbar-link {
    color: #1a1a1a;
  }

  .navbar.is-pale-green .navbar-start > a.navbar-item:hover, .navbar.is-pale-green .navbar-start > a.navbar-item.is-active, .navbar.is-pale-green .navbar-start .navbar-link:hover, .navbar.is-pale-green .navbar-start .navbar-link.is-active, .navbar.is-pale-green .navbar-end > a.navbar-item:hover, .navbar.is-pale-green .navbar-end > a.navbar-item.is-active, .navbar.is-pale-green .navbar-end .navbar-link:hover, .navbar.is-pale-green .navbar-end .navbar-link.is-active {
    color: #1a1a1a;
    background-color: #c7d9d3;
  }

  .navbar.is-pale-green .navbar-start .navbar-link:after, .navbar.is-pale-green .navbar-end .navbar-link:after {
    border-color: #1a1a1a;
  }

  .navbar.is-pale-green .navbar-item.has-dropdown:hover .navbar-link, .navbar.is-pale-green .navbar-item.has-dropdown.is-active .navbar-link {
    color: #1a1a1a;
    background-color: #c7d9d3;
  }

  .navbar.is-pale-green .navbar-dropdown a.navbar-item.is-active {
    color: #1a1a1a;
    background-color: #d6e3df;
  }
}

.navbar.is-success {
  color: #fcfcfc;
  background-color: #50e3c2;
}

.navbar.is-success .navbar-brand > .navbar-item, .navbar.is-success .navbar-brand .navbar-link {
  color: #fcfcfc;
}

.navbar.is-success .navbar-brand > a.navbar-item:hover, .navbar.is-success .navbar-brand > a.navbar-item.is-active, .navbar.is-success .navbar-brand .navbar-link:hover, .navbar.is-success .navbar-brand .navbar-link.is-active {
  color: #fcfcfc;
  background-color: #0000;
}

.navbar.is-success .navbar-brand .navbar-link:after {
  border-color: #fcfcfc;
}

@media screen and (width >= 990px) {
  .navbar.is-success .navbar-start > .navbar-item, .navbar.is-success .navbar-start .navbar-link, .navbar.is-success .navbar-end > .navbar-item, .navbar.is-success .navbar-end .navbar-link {
    color: #fcfcfc;
  }

  .navbar.is-success .navbar-start > a.navbar-item:hover, .navbar.is-success .navbar-start > a.navbar-item.is-active, .navbar.is-success .navbar-start .navbar-link:hover, .navbar.is-success .navbar-start .navbar-link.is-active, .navbar.is-success .navbar-end > a.navbar-item:hover, .navbar.is-success .navbar-end > a.navbar-item.is-active, .navbar.is-success .navbar-end .navbar-link:hover, .navbar.is-success .navbar-end .navbar-link.is-active {
    color: #fcfcfc;
    background-color: #3adfba;
  }

  .navbar.is-success .navbar-start .navbar-link:after, .navbar.is-success .navbar-end .navbar-link:after {
    border-color: #fcfcfc;
  }

  .navbar.is-success .navbar-item.has-dropdown:hover .navbar-link, .navbar.is-success .navbar-item.has-dropdown.is-active .navbar-link {
    color: #fcfcfc;
    background-color: #3adfba;
  }

  .navbar.is-success .navbar-dropdown a.navbar-item.is-active {
    color: #fcfcfc;
    background-color: #50e3c2;
  }
}

.navbar.is-warning {
  color: #1a1a1ab3;
  background-color: #ffdd57;
}

.navbar.is-warning .navbar-brand > .navbar-item, .navbar.is-warning .navbar-brand .navbar-link {
  color: #1a1a1ab3;
}

.navbar.is-warning .navbar-brand > a.navbar-item:hover, .navbar.is-warning .navbar-brand > a.navbar-item.is-active, .navbar.is-warning .navbar-brand .navbar-link:hover, .navbar.is-warning .navbar-brand .navbar-link.is-active {
  color: #1a1a1ab3;
  background-color: #0000;
}

.navbar.is-warning .navbar-brand .navbar-link:after {
  border-color: #1a1a1ab3;
}

@media screen and (width >= 990px) {
  .navbar.is-warning .navbar-start > .navbar-item, .navbar.is-warning .navbar-start .navbar-link, .navbar.is-warning .navbar-end > .navbar-item, .navbar.is-warning .navbar-end .navbar-link {
    color: #1a1a1ab3;
  }

  .navbar.is-warning .navbar-start > a.navbar-item:hover, .navbar.is-warning .navbar-start > a.navbar-item.is-active, .navbar.is-warning .navbar-start .navbar-link:hover, .navbar.is-warning .navbar-start .navbar-link.is-active, .navbar.is-warning .navbar-end > a.navbar-item:hover, .navbar.is-warning .navbar-end > a.navbar-item.is-active, .navbar.is-warning .navbar-end .navbar-link:hover, .navbar.is-warning .navbar-end .navbar-link.is-active {
    color: #1a1a1ab3;
    background-color: #ffd83d;
  }

  .navbar.is-warning .navbar-start .navbar-link:after, .navbar.is-warning .navbar-end .navbar-link:after {
    border-color: #1a1a1ab3;
  }

  .navbar.is-warning .navbar-item.has-dropdown:hover .navbar-link, .navbar.is-warning .navbar-item.has-dropdown.is-active .navbar-link {
    color: #1a1a1ab3;
    background-color: #ffd83d;
  }

  .navbar.is-warning .navbar-dropdown a.navbar-item.is-active {
    color: #1a1a1ab3;
    background-color: #ffdd57;
  }
}

.navbar.is-danger {
  color: #fcfcfc;
  background-color: #fc5328;
}

.navbar.is-danger .navbar-brand > .navbar-item, .navbar.is-danger .navbar-brand .navbar-link {
  color: #fcfcfc;
}

.navbar.is-danger .navbar-brand > a.navbar-item:hover, .navbar.is-danger .navbar-brand > a.navbar-item.is-active, .navbar.is-danger .navbar-brand .navbar-link:hover, .navbar.is-danger .navbar-brand .navbar-link.is-active {
  color: #fcfcfc;
  background-color: #0000;
}

.navbar.is-danger .navbar-brand .navbar-link:after {
  border-color: #fcfcfc;
}

@media screen and (width >= 990px) {
  .navbar.is-danger .navbar-start > .navbar-item, .navbar.is-danger .navbar-start .navbar-link, .navbar.is-danger .navbar-end > .navbar-item, .navbar.is-danger .navbar-end .navbar-link {
    color: #fcfcfc;
  }

  .navbar.is-danger .navbar-start > a.navbar-item:hover, .navbar.is-danger .navbar-start > a.navbar-item.is-active, .navbar.is-danger .navbar-start .navbar-link:hover, .navbar.is-danger .navbar-start .navbar-link.is-active, .navbar.is-danger .navbar-end > a.navbar-item:hover, .navbar.is-danger .navbar-end > a.navbar-item.is-active, .navbar.is-danger .navbar-end .navbar-link:hover, .navbar.is-danger .navbar-end .navbar-link.is-active {
    color: #fcfcfc;
    background-color: #fc3f0f;
  }

  .navbar.is-danger .navbar-start .navbar-link:after, .navbar.is-danger .navbar-end .navbar-link:after {
    border-color: #fcfcfc;
  }

  .navbar.is-danger .navbar-item.has-dropdown:hover .navbar-link, .navbar.is-danger .navbar-item.has-dropdown.is-active .navbar-link {
    color: #fcfcfc;
    background-color: #fc3f0f;
  }

  .navbar.is-danger .navbar-dropdown a.navbar-item.is-active {
    color: #fcfcfc;
    background-color: #fc5328;
  }
}

.navbar.is-me {
  color: #fcfcfc;
  background-color: #00d2a0;
}

.navbar.is-me .navbar-brand > .navbar-item, .navbar.is-me .navbar-brand .navbar-link {
  color: #fcfcfc;
}

.navbar.is-me .navbar-brand > a.navbar-item:hover, .navbar.is-me .navbar-brand > a.navbar-item.is-active, .navbar.is-me .navbar-brand .navbar-link:hover, .navbar.is-me .navbar-brand .navbar-link.is-active {
  color: #fcfcfc;
  background-color: #0000;
}

.navbar.is-me .navbar-brand .navbar-link:after {
  border-color: #fcfcfc;
}

@media screen and (width >= 990px) {
  .navbar.is-me .navbar-start > .navbar-item, .navbar.is-me .navbar-start .navbar-link, .navbar.is-me .navbar-end > .navbar-item, .navbar.is-me .navbar-end .navbar-link {
    color: #fcfcfc;
  }

  .navbar.is-me .navbar-start > a.navbar-item:hover, .navbar.is-me .navbar-start > a.navbar-item.is-active, .navbar.is-me .navbar-start .navbar-link:hover, .navbar.is-me .navbar-start .navbar-link.is-active, .navbar.is-me .navbar-end > a.navbar-item:hover, .navbar.is-me .navbar-end > a.navbar-item.is-active, .navbar.is-me .navbar-end .navbar-link:hover, .navbar.is-me .navbar-end .navbar-link.is-active {
    color: #fcfcfc;
    background-color: #00b98d;
  }

  .navbar.is-me .navbar-start .navbar-link:after, .navbar.is-me .navbar-end .navbar-link:after {
    border-color: #fcfcfc;
  }

  .navbar.is-me .navbar-item.has-dropdown:hover .navbar-link, .navbar.is-me .navbar-item.has-dropdown.is-active .navbar-link {
    color: #fcfcfc;
    background-color: #00b98d;
  }

  .navbar.is-me .navbar-dropdown a.navbar-item.is-active {
    color: #fcfcfc;
    background-color: #00d2a0;
  }
}

.navbar.is-we {
  color: #fcfcfc;
  background-color: #00b2dd;
}

.navbar.is-we .navbar-brand > .navbar-item, .navbar.is-we .navbar-brand .navbar-link {
  color: #fcfcfc;
}

.navbar.is-we .navbar-brand > a.navbar-item:hover, .navbar.is-we .navbar-brand > a.navbar-item.is-active, .navbar.is-we .navbar-brand .navbar-link:hover, .navbar.is-we .navbar-brand .navbar-link.is-active {
  color: #fcfcfc;
  background-color: #0000;
}

.navbar.is-we .navbar-brand .navbar-link:after {
  border-color: #fcfcfc;
}

@media screen and (width >= 990px) {
  .navbar.is-we .navbar-start > .navbar-item, .navbar.is-we .navbar-start .navbar-link, .navbar.is-we .navbar-end > .navbar-item, .navbar.is-we .navbar-end .navbar-link {
    color: #fcfcfc;
  }

  .navbar.is-we .navbar-start > a.navbar-item:hover, .navbar.is-we .navbar-start > a.navbar-item.is-active, .navbar.is-we .navbar-start .navbar-link:hover, .navbar.is-we .navbar-start .navbar-link.is-active, .navbar.is-we .navbar-end > a.navbar-item:hover, .navbar.is-we .navbar-end > a.navbar-item.is-active, .navbar.is-we .navbar-end .navbar-link:hover, .navbar.is-we .navbar-end .navbar-link.is-active {
    color: #fcfcfc;
    background-color: #009dc4;
  }

  .navbar.is-we .navbar-start .navbar-link:after, .navbar.is-we .navbar-end .navbar-link:after {
    border-color: #fcfcfc;
  }

  .navbar.is-we .navbar-item.has-dropdown:hover .navbar-link, .navbar.is-we .navbar-item.has-dropdown.is-active .navbar-link {
    color: #fcfcfc;
    background-color: #009dc4;
  }

  .navbar.is-we .navbar-dropdown a.navbar-item.is-active {
    color: #fcfcfc;
    background-color: #00b2dd;
  }
}

.navbar.is-amenity {
  color: #fcfcfc;
  background-color: #7d7ccf;
}

.navbar.is-amenity .navbar-brand > .navbar-item, .navbar.is-amenity .navbar-brand .navbar-link {
  color: #fcfcfc;
}

.navbar.is-amenity .navbar-brand > a.navbar-item:hover, .navbar.is-amenity .navbar-brand > a.navbar-item.is-active, .navbar.is-amenity .navbar-brand .navbar-link:hover, .navbar.is-amenity .navbar-brand .navbar-link.is-active {
  color: #fcfcfc;
  background-color: #0000;
}

.navbar.is-amenity .navbar-brand .navbar-link:after {
  border-color: #fcfcfc;
}

@media screen and (width >= 990px) {
  .navbar.is-amenity .navbar-start > .navbar-item, .navbar.is-amenity .navbar-start .navbar-link, .navbar.is-amenity .navbar-end > .navbar-item, .navbar.is-amenity .navbar-end .navbar-link {
    color: #fcfcfc;
  }

  .navbar.is-amenity .navbar-start > a.navbar-item:hover, .navbar.is-amenity .navbar-start > a.navbar-item.is-active, .navbar.is-amenity .navbar-start .navbar-link:hover, .navbar.is-amenity .navbar-start .navbar-link.is-active, .navbar.is-amenity .navbar-end > a.navbar-item:hover, .navbar.is-amenity .navbar-end > a.navbar-item.is-active, .navbar.is-amenity .navbar-end .navbar-link:hover, .navbar.is-amenity .navbar-end .navbar-link.is-active {
    color: #fcfcfc;
    background-color: #6a69c8;
  }

  .navbar.is-amenity .navbar-start .navbar-link:after, .navbar.is-amenity .navbar-end .navbar-link:after {
    border-color: #fcfcfc;
  }

  .navbar.is-amenity .navbar-item.has-dropdown:hover .navbar-link, .navbar.is-amenity .navbar-item.has-dropdown.is-active .navbar-link {
    color: #fcfcfc;
    background-color: #6a69c8;
  }

  .navbar.is-amenity .navbar-dropdown a.navbar-item.is-active {
    color: #fcfcfc;
    background-color: #7d7ccf;
  }
}

.navbar.is-light-pale-green {
  color: #1a1a1a;
  background-color: #eaf1ef;
}

.navbar.is-light-pale-green .navbar-brand > .navbar-item, .navbar.is-light-pale-green .navbar-brand .navbar-link {
  color: #1a1a1a;
}

.navbar.is-light-pale-green .navbar-brand > a.navbar-item:hover, .navbar.is-light-pale-green .navbar-brand > a.navbar-item.is-active, .navbar.is-light-pale-green .navbar-brand .navbar-link:hover, .navbar.is-light-pale-green .navbar-brand .navbar-link.is-active {
  color: #1a1a1a;
  background-color: #0000;
}

.navbar.is-light-pale-green .navbar-brand .navbar-link:after {
  border-color: #1a1a1a;
}

@media screen and (width >= 990px) {
  .navbar.is-light-pale-green .navbar-start > .navbar-item, .navbar.is-light-pale-green .navbar-start .navbar-link, .navbar.is-light-pale-green .navbar-end > .navbar-item, .navbar.is-light-pale-green .navbar-end .navbar-link {
    color: #1a1a1a;
  }

  .navbar.is-light-pale-green .navbar-start > a.navbar-item:hover, .navbar.is-light-pale-green .navbar-start > a.navbar-item.is-active, .navbar.is-light-pale-green .navbar-start .navbar-link:hover, .navbar.is-light-pale-green .navbar-start .navbar-link.is-active, .navbar.is-light-pale-green .navbar-end > a.navbar-item:hover, .navbar.is-light-pale-green .navbar-end > a.navbar-item.is-active, .navbar.is-light-pale-green .navbar-end .navbar-link:hover, .navbar.is-light-pale-green .navbar-end .navbar-link.is-active {
    color: #1a1a1a;
    background-color: #dbe7e3;
  }

  .navbar.is-light-pale-green .navbar-start .navbar-link:after, .navbar.is-light-pale-green .navbar-end .navbar-link:after {
    border-color: #1a1a1a;
  }

  .navbar.is-light-pale-green .navbar-item.has-dropdown:hover .navbar-link, .navbar.is-light-pale-green .navbar-item.has-dropdown.is-active .navbar-link {
    color: #1a1a1a;
    background-color: #dbe7e3;
  }

  .navbar.is-light-pale-green .navbar-dropdown a.navbar-item.is-active {
    color: #1a1a1a;
    background-color: #eaf1ef;
  }
}

.navbar.is-green-forest-darker {
  color: #fcfcfc;
  background-color: #1d4837;
}

.navbar.is-green-forest-darker .navbar-brand > .navbar-item, .navbar.is-green-forest-darker .navbar-brand .navbar-link {
  color: #fcfcfc;
}

.navbar.is-green-forest-darker .navbar-brand > a.navbar-item:hover, .navbar.is-green-forest-darker .navbar-brand > a.navbar-item.is-active, .navbar.is-green-forest-darker .navbar-brand .navbar-link:hover, .navbar.is-green-forest-darker .navbar-brand .navbar-link.is-active {
  color: #fcfcfc;
  background-color: #0000;
}

.navbar.is-green-forest-darker .navbar-brand .navbar-link:after {
  border-color: #fcfcfc;
}

@media screen and (width >= 990px) {
  .navbar.is-green-forest-darker .navbar-start > .navbar-item, .navbar.is-green-forest-darker .navbar-start .navbar-link, .navbar.is-green-forest-darker .navbar-end > .navbar-item, .navbar.is-green-forest-darker .navbar-end .navbar-link {
    color: #fcfcfc;
  }

  .navbar.is-green-forest-darker .navbar-start > a.navbar-item:hover, .navbar.is-green-forest-darker .navbar-start > a.navbar-item.is-active, .navbar.is-green-forest-darker .navbar-start .navbar-link:hover, .navbar.is-green-forest-darker .navbar-start .navbar-link.is-active, .navbar.is-green-forest-darker .navbar-end > a.navbar-item:hover, .navbar.is-green-forest-darker .navbar-end > a.navbar-item.is-active, .navbar.is-green-forest-darker .navbar-end .navbar-link:hover, .navbar.is-green-forest-darker .navbar-end .navbar-link.is-active {
    color: #fcfcfc;
    background-color: #163629;
  }

  .navbar.is-green-forest-darker .navbar-start .navbar-link:after, .navbar.is-green-forest-darker .navbar-end .navbar-link:after {
    border-color: #fcfcfc;
  }

  .navbar.is-green-forest-darker .navbar-item.has-dropdown:hover .navbar-link, .navbar.is-green-forest-darker .navbar-item.has-dropdown.is-active .navbar-link {
    color: #fcfcfc;
    background-color: #163629;
  }

  .navbar.is-green-forest-darker .navbar-dropdown a.navbar-item.is-active {
    color: #fcfcfc;
    background-color: #1d4837;
  }
}

.navbar.is-green-forest-dark {
  color: #fcfcfc;
  background-color: #003f2d;
}

.navbar.is-green-forest-dark .navbar-brand > .navbar-item, .navbar.is-green-forest-dark .navbar-brand .navbar-link {
  color: #fcfcfc;
}

.navbar.is-green-forest-dark .navbar-brand > a.navbar-item:hover, .navbar.is-green-forest-dark .navbar-brand > a.navbar-item.is-active, .navbar.is-green-forest-dark .navbar-brand .navbar-link:hover, .navbar.is-green-forest-dark .navbar-brand .navbar-link.is-active {
  color: #fcfcfc;
  background-color: #0000;
}

.navbar.is-green-forest-dark .navbar-brand .navbar-link:after {
  border-color: #fcfcfc;
}

@media screen and (width >= 990px) {
  .navbar.is-green-forest-dark .navbar-start > .navbar-item, .navbar.is-green-forest-dark .navbar-start .navbar-link, .navbar.is-green-forest-dark .navbar-end > .navbar-item, .navbar.is-green-forest-dark .navbar-end .navbar-link {
    color: #fcfcfc;
  }

  .navbar.is-green-forest-dark .navbar-start > a.navbar-item:hover, .navbar.is-green-forest-dark .navbar-start > a.navbar-item.is-active, .navbar.is-green-forest-dark .navbar-start .navbar-link:hover, .navbar.is-green-forest-dark .navbar-start .navbar-link.is-active, .navbar.is-green-forest-dark .navbar-end > a.navbar-item:hover, .navbar.is-green-forest-dark .navbar-end > a.navbar-item.is-active, .navbar.is-green-forest-dark .navbar-end .navbar-link:hover, .navbar.is-green-forest-dark .navbar-end .navbar-link.is-active {
    color: #fcfcfc;
    background-color: #00261b;
  }

  .navbar.is-green-forest-dark .navbar-start .navbar-link:after, .navbar.is-green-forest-dark .navbar-end .navbar-link:after {
    border-color: #fcfcfc;
  }

  .navbar.is-green-forest-dark .navbar-item.has-dropdown:hover .navbar-link, .navbar.is-green-forest-dark .navbar-item.has-dropdown.is-active .navbar-link {
    color: #fcfcfc;
    background-color: #00261b;
  }

  .navbar.is-green-forest-dark .navbar-dropdown a.navbar-item.is-active {
    color: #fcfcfc;
    background-color: #003f2d;
  }
}

.navbar > .container {
  align-items: stretch;
  width: 100%;
  height: 4.25rem;
  display: flex;
}

.navbar.has-shadow {
  box-shadow: 0 4px 4px #0000001a;
}

.navbar.is-fixed-bottom, .navbar.is-fixed-top {
  z-index: 35;
  position: fixed;
  left: 0;
  right: 0;
}

.navbar.is-fixed-bottom.navbar-overlay, .navbar.is-fixed-top.navbar-overlay {
  z-index: 40;
  transition: all .75s cubic-bezier(.23, 1, .32, 1);
}

.navbar.is-fixed-bottom.navbar-overlay.is-offscreen, .navbar.is-fixed-top.navbar-overlay.is-offscreen {
  visibility: hidden;
  top: -4.25rem;
}

.navbar.is-fixed-bottom {
  bottom: 0;
}

.navbar.is-fixed-bottom.has-shadow {
  box-shadow: 0 -2px 3px #1a1a1a1a;
}

.navbar.is-fixed-top {
  top: 0;
}

.navbar.is-fixed-top .navbar-brand, .navbar.is-fixed-top .navbar-item, .navbar.is-fixed-bottom .navbar-brand, .navbar.is-fixed-bottom .navbar-item {
  pointer-events: auto;
  white-space: nowrap;
  color: #003f2d;
}

.navbar-brand, .navbar-tabs {
  flex-shrink: 0;
  align-items: center;
  height: 4.25rem;
  display: flex;
}

.navbar-tabs {
  -webkit-overflow-scrolling: touch;
  max-width: 100vw;
  overflow: auto hidden;
}

.navbar-burger {
  cursor: pointer;
  width: 4.25rem;
  height: 4.25rem;
  margin-left: auto;
  display: block;
  position: relative;
}

.navbar-burger span {
  background-color: currentColor;
  width: 15px;
  height: 1px;
  margin-left: -7px;
  transition: background 86ms ease-out, left 86ms ease-out, opacity 86ms ease-out, transform 86ms ease-out;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
}

.navbar-burger span:first-child {
  margin-top: -6px;
}

.navbar-burger span:nth-child(2) {
  margin-top: -1px;
}

.navbar-burger span:nth-child(3) {
  margin-top: 4px;
}

.navbar-burger:hover {
  background-color: #0000000d;
}

.navbar-burger.is-active span:first-child {
  transform-origin: 0 0;
  margin-left: -5px;
  transform: rotate(45deg);
}

.navbar-burger.is-active span:nth-child(2) {
  opacity: 0;
}

.navbar-burger.is-active span:nth-child(3) {
  transform-origin: 0 100%;
  margin-left: -5px;
  transform: rotate(-45deg);
}

.navbar-menu {
  display: none;
}

.navbar-item, .navbar-link {
  color: #000;
  cursor: pointer;
  background-color: #0000;
  border: none;
  padding: .5rem 1rem;
  font-family: Calibre Semibold, Arial, Helvetica, sans-serif;
  font-size: .75rem;
  line-height: 1.5;
  transition: all .75s cubic-bezier(.23, 1, .32, 1);
  display: block;
  position: relative;
}

.navbar-item:hover, .navbar-item.is-active, .navbar-link:hover, .navbar-link.is-active {
  background-color: #0000;
  color: #17e88f !important;
}

.navbar-menu .navbar-item {
  padding-left: 1.5rem;
  padding-right: 0;
}

.navbar-menu .navbar-item svg {
  margin-left: 8px;
}

.language-dropdown-top .underline-input, .language-dropdown-top-result .underline-input {
  border-bottom: none !important;
}

.navbar.is-transparent .language-dropdown-top-result .underline-input {
  color: #003f2d;
  border-bottom: none !important;
}

.navbar.is-transparent .language-dropdown-top-result .underline-input:hover {
  color: #17e88f;
}

.navbar.is-transparent {
  background-color: #0000;
}

.navbar.is-transparent .navbar-item, .navbar.is-transparent .navbar-item a, .navbar.is-pale-green .navbar-item, .navbar.is-pale-green .navbar-item a {
  color: #003f2d;
}

.navbar.is-transparent .navbar-item:hover, .navbar.is-transparent .navbar-item a:hover, .navbar.is-pale-green .navbar-item:hover, .navbar.is-pale-green .navbar-item a:hover {
  color: #12b8a2;
}

.navbar.in-quiz {
  background-color: #003f2d;
}

.navbar-item {
  flex-grow: 0;
  flex-shrink: 0;
}

.navbar-item img {
  max-height: 2.75rem;
}

.navbar-item.has-dropdown {
  padding: 0;
}

.navbar-item.is-tab {
  border-bottom: 1px solid #0000;
  height: 4.25rem;
  padding-bottom: calc(.5rem - 1px);
}

.navbar-item.is-tab:hover {
  background-color: #0000;
  border-bottom-color: #003f2d;
}

.navbar-item.is-tab.is-active {
  color: #003f2d;
  background-color: #0000;
  border-bottom: 3px solid #003f2d;
  padding-bottom: calc(.5rem - 3px);
}

.navbar-content {
  flex-grow: 1;
  flex-shrink: 1;
}

.navbar-link {
  padding-right: 2.5em;
}

.navbar-dropdown {
  padding-top: .5rem;
  padding-bottom: .5rem;
  font-size: .875rem;
}

.navbar-dropdown .navbar-item {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.navbar-divider {
  background-color: #dbdbdb;
  border: none;
  height: 1px;
  margin: .5rem 0;
  display: none;
}

.navbar, .navbar-menu, .navbar-start, .navbar-end {
  align-items: stretch;
  display: flex;
}

.navbar {
  height: 4.25rem;
}

.navbar.is-transparent a.navbar-item:hover, .navbar.is-transparent a.navbar-item.is-active, .navbar.is-transparent .navbar-link:hover, .navbar.is-transparent .navbar-link.is-active, .navbar.is-transparent .navbar-item.has-dropdown.is-active .navbar-link, .navbar.is-transparent .navbar-item.has-dropdown.is-hoverable:hover .navbar-link {
  background-color: #0000 !important;
}

.navbar.is-transparent .navbar-dropdown a.navbar-item:hover {
  color: #1a1a1a;
  background-color: #fcfcfc;
}

.navbar.is-transparent .navbar-dropdown a.navbar-item.is-active {
  color: #003f2d;
  background-color: #fcfcfc;
}

.navbar-burger {
  display: none;
}

.navbar-item, .navbar-link {
  align-items: center;
  display: flex;
}

.navbar-item.flex-align-items-start {
  align-items: start;
}

.navbar-item.has-dropdown {
  align-items: stretch;
}

.navbar-item.is-active .navbar-dropdown, .navbar-item.is-hoverable:hover .navbar-dropdown {
  display: block;
}

.navbar-item.is-active .navbar-dropdown.is-boxed, .navbar-item.is-hoverable:hover .navbar-dropdown.is-boxed {
  opacity: 1;
  pointer-events: auto;
  transform: translateY(0);
}

.navbar-link:after {
  content: " ";
  pointer-events: none;
  border-top: 0;
  border-bottom: 1px solid #003f2d;
  border-left: 1px solid #003f2d;
  border-right: 0;
  width: .5em;
  height: .5em;
  margin-top: -.375em;
  display: block;
  position: absolute;
  top: 50%;
  right: 1.125em;
  transform: rotate(-45deg);
}

.navbar-menu {
  flex-grow: 1;
  flex-shrink: 0;
}

.navbar-start {
  justify-content: flex-start;
  margin-right: auto;
}

.navbar-end {
  justify-content: flex-end;
  margin-left: auto;
}

.navbar-dropdown {
  z-index: 20;
  background-color: #fcfcfc;
  border-top: 1px solid #dbdbdb;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  min-width: 100%;
  font-size: .875rem;
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  box-shadow: 0 8px 8px #1a1a1a1a;
}

.navbar-dropdown .navbar-item {
  white-space: nowrap;
  padding: .375rem 1rem;
}

.navbar-dropdown a.navbar-item {
  padding-right: 3rem;
}

.navbar-dropdown a.navbar-item:hover {
  color: #1a1a1a;
  background-color: #fcfcfc;
}

.navbar-dropdown a.navbar-item.is-active {
  color: #003f2d;
  background-color: #fcfcfc;
}

.navbar-dropdown.is-boxed {
  opacity: 0;
  pointer-events: none;
  border-top: none;
  border-radius: 5px;
  transition-property: opacity, transform;
  transition-duration: 86ms;
  display: block;
  top: calc(100% - 4px);
  transform: translateY(-5px);
  box-shadow: 0 8px 8px #1a1a1a1a, 0 0 0 1px #1a1a1a1a;
}

.navbar-dropdown.is-right {
  left: auto;
  right: 0;
}

.navbar-divider {
  display: block;
}

.container > .navbar .navbar-brand {
  margin-left: -1rem;
}

.container > .navbar .navbar-menu {
  margin-right: -1rem;
}

a.navbar-item.is-active, .navbar-link.is-active {
  color: #1a1a1a;
}

a.navbar-item.is-active:not(:hover), .navbar-link.is-active:not(:hover), .navbar-item.has-dropdown:hover .navbar-link, .navbar-item.has-dropdown.is-active .navbar-link {
  background-color: #0000;
}

.has-navbar-fixed-top {
  padding-top: 4.25rem;
}

.has-navbar-fixed-bottom {
  padding-bottom: 4.25rem;
}

.navbar-end .cta-button {
  padding: 1.5rem 2.5rem;
}

@media screen and (width >= 768px) {
  .navbar-menu .navbar-item.is-mobile-only {
    display: none;
  }
}

@media screen and (width >= 768px) and (width <= 989px) {
  .language-dropdown-top, .language-dropdown-top-result {
    display: none;
  }
}

@media screen and (width <= 767px) {
  .navbar, .navbar > .container {
    height: 3.5rem;
  }

  .navbar.is-transparent .navbar-item .cta-button {
    display: none;
  }

  .navbar.is-fixed-top.navbar-overlay.is-offscreen {
    top: -3.5rem;
  }

  .navbar-menu .navbar-item:not(.is-mobile) {
    display: none;
  }

  .navbar-item.is-mobile .button {
    font-size: .75rem;
  }

  .navbar-item {
    padding: 0 !important;
  }

  .navbar-item .button {
    font-size: .75rem;
  }

  .navbar-item .button.cta-button {
    min-width: unset;
    padding: 1rem;
  }

  .navbar-brand {
    height: 3.5rem;
  }

  .navbar-brand .navbar-item {
    padding: 0;
  }

  .navbar-end .navbar-item {
    padding-right: 0;
  }

  .has-navbar-fixed-top {
    padding-top: 3.5rem;
  }
}

.cbre-spacer-logo {
  color: #003f2d;
  font-size: 20px;
  font-weight: 500;
  text-decoration: none;
}

.cbre-spacer-logo:hover {
  color: #003f2d !important;
}

.navbar-brand .cbre-spacer-logo:focus {
  background-color: #0000 !important;
}

.blx-buttonblx-primary:hover {
  background-color: #17e88f;
  border-color: #17e88f;
}

.level {
  justify-content: space-between;
  align-items: center;
}

.level:not(:last-child) {
  margin-bottom: 1.5rem;
}

.level code {
  border-radius: 4px;
}

.level img {
  vertical-align: top;
  display: inline-block;
}

.level.is-mobile, .level.is-mobile .level-left, .level.is-mobile .level-right {
  display: flex;
}

.level.is-mobile .level-left + .level-right {
  margin-top: 0;
}

.level.is-mobile .level-item {
  margin-right: .75rem;
}

.level.is-mobile .level-item:not(:last-child) {
  margin-bottom: 0;
}

.level.is-mobile .level-item:not(.is-narrow) {
  flex-grow: 1;
}

@media screen and (width >= 768px), print {
  .level {
    display: flex;
  }

  .level > .level-item:not(.is-narrow) {
    flex-grow: 1;
  }
}

.level-item {
  flex: none;
  justify-content: center;
  align-items: center;
  display: flex;
}

.level-item .title, .level-item .subtitle {
  margin-bottom: 0;
}

@media screen and (width <= 767px) {
  .level-item:not(:last-child) {
    margin-bottom: .75rem;
  }
}

.level-left, .level-right {
  flex: none;
}

.level-left .level-item.is-flexible, .level-right .level-item.is-flexible {
  flex-grow: 1;
}

@media screen and (width >= 768px), print {
  .level-left .level-item:not(:last-child), .level-right .level-item:not(:last-child) {
    margin-right: .75rem;
  }
}

.level-left {
  justify-content: flex-start;
  align-items: center;
}

@media screen and (width <= 767px) {
  .level-left + .level-right {
    margin-top: 1.5rem;
  }
}

@media screen and (width >= 768px), print {
  .level-left {
    display: flex;
  }
}

.level-right {
  justify-content: flex-end;
  align-items: center;
}

@media screen and (width >= 768px), print {
  .level-right {
    display: flex;
  }
}

.loading-page {
  background-color: #d6e3df;
  min-height: 100vh;
}

.loading-page .loader {
  width: 5rem;
  height: 5rem;
  position: absolute;
  top: calc(50% - 2.5rem);
  left: calc(50% - 2.5rem);
}

.container {
  width: 100%;
  max-width: calc(100% - 64px);
  margin-right: 25px;
  position: relative;
}

.container.is-fluid {
  width: auto;
  max-width: none;
  margin-left: 32px;
  margin-right: 32px;
}

.block:not(:last-child) {
  margin-bottom: 1.5rem;
}

.delete {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  cursor: pointer;
  vertical-align: -10%;
  background-color: #0000;
  border: none;
  border-radius: 50%;
  outline: none;
  flex-grow: 0;
  flex-shrink: 0;
  width: 20px;
  min-width: 20px;
  max-width: 20px;
  height: 20px;
  min-height: 20px;
  max-height: 20px;
  font-size: 0;
  display: inline-block;
  position: relative;
}

.delete:before, .delete:after {
  content: "";
  transform-origin: center;
  background-color: #fcfcfc;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%)translateY(-50%)rotate(45deg);
}

.delete:before {
  width: 50%;
  height: 1px;
}

.delete:after {
  width: 1px;
  height: 50%;
}

.delete:hover, .delete:focus, .delete:active {
  background-color: #1a1a1a4d;
}

.delete.is-small {
  width: 16px;
  min-width: 16px;
  max-width: 16px;
  height: 16px;
  min-height: 16px;
  max-height: 16px;
}

.delete.is-medium {
  width: 24px;
  min-width: 24px;
  max-width: 24px;
  height: 24px;
  min-height: 24px;
  max-height: 24px;
}

.delete.is-large {
  width: 32px;
  min-width: 32px;
  max-width: 32px;
  height: 32px;
  min-height: 32px;
  max-height: 32px;
}

.heading {
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-bottom: 5px;
  font-size: 11px;
  display: block;
}

.highlight {
  max-width: 100%;
  padding: 0;
  font-weight: 400;
  overflow: hidden;
}

.highlight:not(:last-child) {
  margin-bottom: 1.5rem;
}

.highlight pre {
  max-width: 100%;
  overflow: auto;
}

.loader {
  content: "";
  border: 2px solid #0000;
  border-color: #0000 #0000 #003f2d #003f2d;
  border-radius: 290486px;
  width: 1em;
  height: 1em;
  animation: .7s linear infinite spinAround;
  display: block;
  position: relative;
}

.loader.light {
  border: 2px solid #0000;
  border-color: #0000 #0000 #d6e3df #d6e3df;
}

.number {
  text-align: center;
  vertical-align: top;
  background-color: #fcfcfc;
  border-radius: 290486px;
  justify-content: center;
  align-items: center;
  min-width: 2.5em;
  height: 2em;
  margin-right: 1.5rem;
  padding: .25rem .5rem;
  font-size: 1.25rem;
  display: inline-flex;
}

.notification {
  background-color: #fcfcfc;
  border-radius: 4px;
  padding: 1.25rem 2.5rem 1.25rem 1.5rem;
  position: relative;
}

.notification:not(:last-child) {
  margin-bottom: 1.5rem;
}

.notification a:not(.button) {
  color: currentColor;
  text-decoration: underline;
}

.notification strong {
  color: currentColor;
}

.notification code, .notification pre {
  background: #fcfcfc;
}

.notification pre code {
  background: none;
}

.notification > .delete {
  position: absolute;
  top: .5em;
  right: .5em;
}

.notification .title, .notification .subtitle, .notification .content {
  color: currentColor;
}

.notification.is-white {
  color: #1a1a1a;
  background-color: #fcfcfc;
}

.notification.is-black {
  color: #fcfcfc;
  background-color: #1a1a1a;
}

.notification.is-light {
  color: #434a48;
  background-color: #f0f0f0;
}

.notification.is-dark {
  color: #f0f0f0;
  background-color: #434a48;
}

.notification.is-primary {
  color: #fcfcfc;
  background-color: #003f2d;
}

.notification.is-secondary {
  color: #1a1a1a;
  background-color: #d6e3df;
}

.notification.is-tertiary {
  color: #fcfcfc;
  background-color: #0d8070;
}

.notification.is-primary-invert {
  color: #003f2d;
  background-color: #fcfcfc;
}

.notification.is-link {
  color: #fcfcfc;
  background-color: #003f2d;
}

.notification.is-info {
  color: #fcfcfc;
  background-color: #00b2dd;
}

.notification.is-pale-green {
  color: #1a1a1a;
  background-color: #d6e3df;
}

.notification.is-success {
  color: #fcfcfc;
  background-color: #50e3c2;
}

.notification.is-warning {
  color: #1a1a1ab3;
  background-color: #ffdd57;
}

.notification.is-danger {
  color: #fcfcfc;
  background-color: #fc5328;
}

.notification.is-me {
  color: #fcfcfc;
  background-color: #00d2a0;
}

.notification.is-we {
  color: #fcfcfc;
  background-color: #00b2dd;
}

.notification.is-amenity {
  color: #fcfcfc;
  background-color: #7d7ccf;
}

.notification.is-light-pale-green {
  color: #1a1a1a;
  background-color: #eaf1ef;
}

.notification.is-green-forest-darker {
  color: #fcfcfc;
  background-color: #1d4837;
}

.notification.is-green-forest-dark {
  color: #fcfcfc;
  background-color: #003f2d;
}

.column {
  flex: 1 1 0;
  padding: .75rem;
  display: block;
}

.columns.is-mobile > .column.is-narrow {
  flex: none;
}

.columns.is-mobile > .column.is-full {
  flex: none;
  width: 100%;
}

.columns.is-mobile > .column.is-three-quarters {
  flex: none;
  width: 75%;
}

.columns.is-mobile > .column.is-two-thirds {
  flex: none;
  width: 66.6666%;
}

.columns.is-mobile > .column.is-half {
  flex: none;
  width: 50%;
}

.columns.is-mobile > .column.is-one-third {
  flex: none;
  width: 33.3333%;
}

.columns.is-mobile > .column.is-one-quarter {
  flex: none;
  width: 25%;
}

.columns.is-mobile > .column.is-one-fifth {
  flex: none;
  width: 20%;
}

.columns.is-mobile > .column.is-two-fifths {
  flex: none;
  width: 40%;
}

.columns.is-mobile > .column.is-three-fifths {
  flex: none;
  width: 60%;
}

.columns.is-mobile > .column.is-four-fifths {
  flex: none;
  width: 80%;
}

.columns.is-mobile > .column.is-offset-three-quarters {
  margin-left: 75%;
}

.columns.is-mobile > .column.is-offset-two-thirds {
  margin-left: 66.6666%;
}

.columns.is-mobile > .column.is-offset-half {
  margin-left: 50%;
}

.columns.is-mobile > .column.is-offset-one-third {
  margin-left: 33.3333%;
}

.columns.is-mobile > .column.is-offset-one-quarter {
  margin-left: 25%;
}

.columns.is-mobile > .column.is-offset-one-fifth {
  margin-left: 20%;
}

.columns.is-mobile > .column.is-offset-two-fifths {
  margin-left: 40%;
}

.columns.is-mobile > .column.is-offset-three-fifths {
  margin-left: 60%;
}

.columns.is-mobile > .column.is-offset-four-fifths {
  margin-left: 80%;
}

.columns.is-mobile > .column.is-1 {
  flex: none;
  width: 8.33333%;
}

.columns.is-mobile > .column.is-offset-1 {
  margin-left: 8.33333%;
}

.columns.is-mobile > .column.is-2 {
  flex: none;
  width: 16.6667%;
}

.columns.is-mobile > .column.is-offset-2 {
  margin-left: 16.6667%;
}

.columns.is-mobile > .column.is-3 {
  flex: none;
  width: 25%;
}

.columns.is-mobile > .column.is-offset-3 {
  margin-left: 25%;
}

.columns.is-mobile > .column.is-4 {
  flex: none;
  width: 33.3333%;
}

.columns.is-mobile > .column.is-offset-4 {
  margin-left: 33.3333%;
}

.columns.is-mobile > .column.is-5 {
  flex: none;
  width: 41.6667%;
}

.columns.is-mobile > .column.is-offset-5 {
  margin-left: 41.6667%;
}

.columns.is-mobile > .column.is-6 {
  flex: none;
  width: 50%;
}

.columns.is-mobile > .column.is-offset-6 {
  margin-left: 50%;
}

.columns.is-mobile > .column.is-7 {
  flex: none;
  width: 58.3333%;
}

.columns.is-mobile > .column.is-offset-7 {
  margin-left: 58.3333%;
}

.columns.is-mobile > .column.is-8 {
  flex: none;
  width: 66.6667%;
}

.columns.is-mobile > .column.is-offset-8 {
  margin-left: 66.6667%;
}

.columns.is-mobile > .column.is-9 {
  flex: none;
  width: 75%;
}

.columns.is-mobile > .column.is-offset-9 {
  margin-left: 75%;
}

.columns.is-mobile > .column.is-10 {
  flex: none;
  width: 83.3333%;
}

.columns.is-mobile > .column.is-offset-10 {
  margin-left: 83.3333%;
}

.columns.is-mobile > .column.is-11 {
  flex: none;
  width: 91.6667%;
}

.columns.is-mobile > .column.is-offset-11 {
  margin-left: 91.6667%;
}

.columns.is-mobile > .column.is-12 {
  flex: none;
  width: 100%;
}

.columns.is-mobile > .column.is-offset-12 {
  margin-left: 100%;
}

@media screen and (width <= 767px) {
  .column.is-narrow-mobile {
    flex: none;
  }

  .column.is-full-mobile {
    flex: none;
    width: 100%;
  }

  .column.is-three-quarters-mobile {
    flex: none;
    width: 75%;
  }

  .column.is-two-thirds-mobile {
    flex: none;
    width: 66.6666%;
  }

  .column.is-half-mobile {
    flex: none;
    width: 50%;
  }

  .column.is-one-third-mobile {
    flex: none;
    width: 33.3333%;
  }

  .column.is-one-quarter-mobile {
    flex: none;
    width: 25%;
  }

  .column.is-one-fifth-mobile {
    flex: none;
    width: 20%;
  }

  .column.is-two-fifths-mobile {
    flex: none;
    width: 40%;
  }

  .column.is-three-fifths-mobile {
    flex: none;
    width: 60%;
  }

  .column.is-four-fifths-mobile {
    flex: none;
    width: 80%;
  }

  .column.is-offset-three-quarters-mobile {
    margin-left: 75%;
  }

  .column.is-offset-two-thirds-mobile {
    margin-left: 66.6666%;
  }

  .column.is-offset-half-mobile {
    margin-left: 50%;
  }

  .column.is-offset-one-third-mobile {
    margin-left: 33.3333%;
  }

  .column.is-offset-one-quarter-mobile {
    margin-left: 25%;
  }

  .column.is-offset-one-fifth-mobile {
    margin-left: 20%;
  }

  .column.is-offset-two-fifths-mobile {
    margin-left: 40%;
  }

  .column.is-offset-three-fifths-mobile {
    margin-left: 60%;
  }

  .column.is-offset-four-fifths-mobile {
    margin-left: 80%;
  }

  .column.is-1-mobile {
    flex: none;
    width: 8.33333%;
  }

  .column.is-offset-1-mobile {
    margin-left: 8.33333%;
  }

  .column.is-2-mobile {
    flex: none;
    width: 16.6667%;
  }

  .column.is-offset-2-mobile {
    margin-left: 16.6667%;
  }

  .column.is-3-mobile {
    flex: none;
    width: 25%;
  }

  .column.is-offset-3-mobile {
    margin-left: 25%;
  }

  .column.is-4-mobile {
    flex: none;
    width: 33.3333%;
  }

  .column.is-offset-4-mobile {
    margin-left: 33.3333%;
  }

  .column.is-5-mobile {
    flex: none;
    width: 41.6667%;
  }

  .column.is-offset-5-mobile {
    margin-left: 41.6667%;
  }

  .column.is-6-mobile {
    flex: none;
    width: 50%;
  }

  .column.is-offset-6-mobile {
    margin-left: 50%;
  }

  .column.is-7-mobile {
    flex: none;
    width: 58.3333%;
  }

  .column.is-offset-7-mobile {
    margin-left: 58.3333%;
  }

  .column.is-8-mobile {
    flex: none;
    width: 66.6667%;
  }

  .column.is-offset-8-mobile {
    margin-left: 66.6667%;
  }

  .column.is-9-mobile {
    flex: none;
    width: 75%;
  }

  .column.is-offset-9-mobile {
    margin-left: 75%;
  }

  .column.is-10-mobile {
    flex: none;
    width: 83.3333%;
  }

  .column.is-offset-10-mobile {
    margin-left: 83.3333%;
  }

  .column.is-11-mobile {
    flex: none;
    width: 91.6667%;
  }

  .column.is-offset-11-mobile {
    margin-left: 91.6667%;
  }

  .column.is-12-mobile {
    flex: none;
    width: 100%;
  }

  .column.is-offset-12-mobile {
    margin-left: 100%;
  }
}

@media screen and (width >= 768px), print {
  .column.is-narrow, .column.is-narrow-tablet {
    flex: none;
  }

  .column.is-full, .column.is-full-tablet {
    flex: none;
    width: 100%;
  }

  .column.is-three-quarters, .column.is-three-quarters-tablet {
    flex: none;
    width: 75%;
  }

  .column.is-two-thirds, .column.is-two-thirds-tablet {
    flex: none;
    width: 66.6666%;
  }

  .column.is-half, .column.is-half-tablet {
    flex: none;
    width: 50%;
  }

  .column.is-one-third, .column.is-one-third-tablet {
    flex: none;
    width: 33.3333%;
  }

  .column.is-one-quarter, .column.is-one-quarter-tablet {
    flex: none;
    width: 25%;
  }

  .column.is-one-fifth, .column.is-one-fifth-tablet {
    flex: none;
    width: 20%;
  }

  .column.is-two-fifths, .column.is-two-fifths-tablet {
    flex: none;
    width: 40%;
  }

  .column.is-three-fifths, .column.is-three-fifths-tablet {
    flex: none;
    width: 60%;
  }

  .column.is-four-fifths, .column.is-four-fifths-tablet {
    flex: none;
    width: 80%;
  }

  .column.is-offset-three-quarters, .column.is-offset-three-quarters-tablet {
    margin-left: 75%;
  }

  .column.is-offset-two-thirds, .column.is-offset-two-thirds-tablet {
    margin-left: 66.6666%;
  }

  .column.is-offset-half, .column.is-offset-half-tablet {
    margin-left: 50%;
  }

  .column.is-offset-one-third, .column.is-offset-one-third-tablet {
    margin-left: 33.3333%;
  }

  .column.is-offset-one-quarter, .column.is-offset-one-quarter-tablet {
    margin-left: 25%;
  }

  .column.is-offset-one-fifth, .column.is-offset-one-fifth-tablet {
    margin-left: 20%;
  }

  .column.is-offset-two-fifths, .column.is-offset-two-fifths-tablet {
    margin-left: 40%;
  }

  .column.is-offset-three-fifths, .column.is-offset-three-fifths-tablet {
    margin-left: 60%;
  }

  .column.is-offset-four-fifths, .column.is-offset-four-fifths-tablet {
    margin-left: 80%;
  }

  .column.is-1, .column.is-1-tablet {
    flex: none;
    width: 8.33333%;
  }

  .column.is-offset-1, .column.is-offset-1-tablet {
    margin-left: 8.33333%;
  }

  .column.is-2, .column.is-2-tablet {
    flex: none;
    width: 16.6667%;
  }

  .column.is-offset-2, .column.is-offset-2-tablet {
    margin-left: 16.6667%;
  }

  .column.is-3, .column.is-3-tablet {
    flex: none;
    width: 25%;
  }

  .column.is-offset-3, .column.is-offset-3-tablet {
    margin-left: 25%;
  }

  .column.is-4, .column.is-4-tablet {
    flex: none;
    width: 33.3333%;
  }

  .column.is-offset-4, .column.is-offset-4-tablet {
    margin-left: 33.3333%;
  }

  .column.is-5, .column.is-5-tablet {
    flex: none;
    width: 41.6667%;
  }

  .column.is-offset-5, .column.is-offset-5-tablet {
    margin-left: 41.6667%;
  }

  .column.is-6, .column.is-6-tablet {
    flex: none;
    width: 50%;
  }

  .column.is-offset-6, .column.is-offset-6-tablet {
    margin-left: 50%;
  }

  .column.is-7, .column.is-7-tablet {
    flex: none;
    width: 58.3333%;
  }

  .column.is-offset-7, .column.is-offset-7-tablet {
    margin-left: 58.3333%;
  }

  .column.is-8, .column.is-8-tablet {
    flex: none;
    width: 66.6667%;
  }

  .column.is-offset-8, .column.is-offset-8-tablet {
    margin-left: 66.6667%;
  }

  .column.is-9, .column.is-9-tablet {
    flex: none;
    width: 75%;
  }

  .column.is-offset-9, .column.is-offset-9-tablet {
    margin-left: 75%;
  }

  .column.is-10, .column.is-10-tablet {
    flex: none;
    width: 83.3333%;
  }

  .column.is-offset-10, .column.is-offset-10-tablet {
    margin-left: 83.3333%;
  }

  .column.is-11, .column.is-11-tablet {
    flex: none;
    width: 91.6667%;
  }

  .column.is-offset-11, .column.is-offset-11-tablet {
    margin-left: 91.6667%;
  }

  .column.is-12, .column.is-12-tablet {
    flex: none;
    width: 100%;
  }

  .column.is-offset-12, .column.is-offset-12-tablet {
    margin-left: 100%;
  }
}

@media screen and (width <= 989px) {
  .column.is-narrow-touch {
    flex: none;
  }

  .column.is-full-touch {
    flex: none;
    width: 100%;
  }

  .column.is-three-quarters-touch {
    flex: none;
    width: 75%;
  }

  .column.is-two-thirds-touch {
    flex: none;
    width: 66.6666%;
  }

  .column.is-half-touch {
    flex: none;
    width: 50%;
  }

  .column.is-one-third-touch {
    flex: none;
    width: 33.3333%;
  }

  .column.is-one-quarter-touch {
    flex: none;
    width: 25%;
  }

  .column.is-one-fifth-touch {
    flex: none;
    width: 20%;
  }

  .column.is-two-fifths-touch {
    flex: none;
    width: 40%;
  }

  .column.is-three-fifths-touch {
    flex: none;
    width: 60%;
  }

  .column.is-four-fifths-touch {
    flex: none;
    width: 80%;
  }

  .column.is-offset-three-quarters-touch {
    margin-left: 75%;
  }

  .column.is-offset-two-thirds-touch {
    margin-left: 66.6666%;
  }

  .column.is-offset-half-touch {
    margin-left: 50%;
  }

  .column.is-offset-one-third-touch {
    margin-left: 33.3333%;
  }

  .column.is-offset-one-quarter-touch {
    margin-left: 25%;
  }

  .column.is-offset-one-fifth-touch {
    margin-left: 20%;
  }

  .column.is-offset-two-fifths-touch {
    margin-left: 40%;
  }

  .column.is-offset-three-fifths-touch {
    margin-left: 60%;
  }

  .column.is-offset-four-fifths-touch {
    margin-left: 80%;
  }

  .column.is-1-touch {
    flex: none;
    width: 8.33333%;
  }

  .column.is-offset-1-touch {
    margin-left: 8.33333%;
  }

  .column.is-2-touch {
    flex: none;
    width: 16.6667%;
  }

  .column.is-offset-2-touch {
    margin-left: 16.6667%;
  }

  .column.is-3-touch {
    flex: none;
    width: 25%;
  }

  .column.is-offset-3-touch {
    margin-left: 25%;
  }

  .column.is-4-touch {
    flex: none;
    width: 33.3333%;
  }

  .column.is-offset-4-touch {
    margin-left: 33.3333%;
  }

  .column.is-5-touch {
    flex: none;
    width: 41.6667%;
  }

  .column.is-offset-5-touch {
    margin-left: 41.6667%;
  }

  .column.is-6-touch {
    flex: none;
    width: 50%;
  }

  .column.is-offset-6-touch {
    margin-left: 50%;
  }

  .column.is-7-touch {
    flex: none;
    width: 58.3333%;
  }

  .column.is-offset-7-touch {
    margin-left: 58.3333%;
  }

  .column.is-8-touch {
    flex: none;
    width: 66.6667%;
  }

  .column.is-offset-8-touch {
    margin-left: 66.6667%;
  }

  .column.is-9-touch {
    flex: none;
    width: 75%;
  }

  .column.is-offset-9-touch {
    margin-left: 75%;
  }

  .column.is-10-touch {
    flex: none;
    width: 83.3333%;
  }

  .column.is-offset-10-touch {
    margin-left: 83.3333%;
  }

  .column.is-11-touch {
    flex: none;
    width: 91.6667%;
  }

  .column.is-offset-11-touch {
    margin-left: 91.6667%;
  }

  .column.is-12-touch {
    flex: none;
    width: 100%;
  }

  .column.is-offset-12-touch {
    margin-left: 100%;
  }
}

@media screen and (width >= 990px) {
  .column.is-narrow-desktop {
    flex: none;
  }

  .column.is-full-desktop {
    flex: none;
    width: 100%;
  }

  .column.is-three-quarters-desktop {
    flex: none;
    width: 75%;
  }

  .column.is-two-thirds-desktop {
    flex: none;
    width: 66.6666%;
  }

  .column.is-half-desktop {
    flex: none;
    width: 50%;
  }

  .column.is-one-third-desktop {
    flex: none;
    width: 33.3333%;
  }

  .column.is-one-quarter-desktop {
    flex: none;
    width: 25%;
  }

  .column.is-one-fifth-desktop {
    flex: none;
    width: 20%;
  }

  .column.is-two-fifths-desktop {
    flex: none;
    width: 40%;
  }

  .column.is-three-fifths-desktop {
    flex: none;
    width: 60%;
  }

  .column.is-four-fifths-desktop {
    flex: none;
    width: 80%;
  }

  .column.is-offset-three-quarters-desktop {
    margin-left: 75%;
  }

  .column.is-offset-two-thirds-desktop {
    margin-left: 66.6666%;
  }

  .column.is-offset-half-desktop {
    margin-left: 50%;
  }

  .column.is-offset-one-third-desktop {
    margin-left: 33.3333%;
  }

  .column.is-offset-one-quarter-desktop {
    margin-left: 25%;
  }

  .column.is-offset-one-fifth-desktop {
    margin-left: 20%;
  }

  .column.is-offset-two-fifths-desktop {
    margin-left: 40%;
  }

  .column.is-offset-three-fifths-desktop {
    margin-left: 60%;
  }

  .column.is-offset-four-fifths-desktop {
    margin-left: 80%;
  }

  .column.is-1-desktop {
    flex: none;
    width: 8.33333%;
  }

  .column.is-offset-1-desktop {
    margin-left: 8.33333%;
  }

  .column.is-2-desktop {
    flex: none;
    width: 16.6667%;
  }

  .column.is-offset-2-desktop {
    margin-left: 16.6667%;
  }

  .column.is-3-desktop {
    flex: none;
    width: 25%;
  }

  .column.is-offset-3-desktop {
    margin-left: 25%;
  }

  .column.is-4-desktop {
    flex: none;
    width: 33.3333%;
  }

  .column.is-offset-4-desktop {
    margin-left: 33.3333%;
  }

  .column.is-5-desktop {
    flex: none;
    width: 41.6667%;
  }

  .column.is-offset-5-desktop {
    margin-left: 41.6667%;
  }

  .column.is-6-desktop {
    flex: none;
    width: 50%;
  }

  .column.is-offset-6-desktop {
    margin-left: 50%;
  }

  .column.is-7-desktop {
    flex: none;
    width: 58.3333%;
  }

  .column.is-offset-7-desktop {
    margin-left: 58.3333%;
  }

  .column.is-8-desktop {
    flex: none;
    width: 66.6667%;
  }

  .column.is-offset-8-desktop {
    margin-left: 66.6667%;
  }

  .column.is-9-desktop {
    flex: none;
    width: 75%;
  }

  .column.is-offset-9-desktop {
    margin-left: 75%;
  }

  .column.is-10-desktop {
    flex: none;
    width: 83.3333%;
  }

  .column.is-offset-10-desktop {
    margin-left: 83.3333%;
  }

  .column.is-11-desktop {
    flex: none;
    width: 91.6667%;
  }

  .column.is-offset-11-desktop {
    margin-left: 91.6667%;
  }

  .column.is-12-desktop {
    flex: none;
    width: 100%;
  }

  .column.is-offset-12-desktop {
    margin-left: 100%;
  }
}

@media screen and (width >= 1182px) {
  .column.is-narrow-widescreen {
    flex: none;
  }

  .column.is-full-widescreen {
    flex: none;
    width: 100%;
  }

  .column.is-three-quarters-widescreen {
    flex: none;
    width: 75%;
  }

  .column.is-two-thirds-widescreen {
    flex: none;
    width: 66.6666%;
  }

  .column.is-half-widescreen {
    flex: none;
    width: 50%;
  }

  .column.is-one-third-widescreen {
    flex: none;
    width: 33.3333%;
  }

  .column.is-one-quarter-widescreen {
    flex: none;
    width: 25%;
  }

  .column.is-one-fifth-widescreen {
    flex: none;
    width: 20%;
  }

  .column.is-two-fifths-widescreen {
    flex: none;
    width: 40%;
  }

  .column.is-three-fifths-widescreen {
    flex: none;
    width: 60%;
  }

  .column.is-four-fifths-widescreen {
    flex: none;
    width: 80%;
  }

  .column.is-offset-three-quarters-widescreen {
    margin-left: 75%;
  }

  .column.is-offset-two-thirds-widescreen {
    margin-left: 66.6666%;
  }

  .column.is-offset-half-widescreen {
    margin-left: 50%;
  }

  .column.is-offset-one-third-widescreen {
    margin-left: 33.3333%;
  }

  .column.is-offset-one-quarter-widescreen {
    margin-left: 25%;
  }

  .column.is-offset-one-fifth-widescreen {
    margin-left: 20%;
  }

  .column.is-offset-two-fifths-widescreen {
    margin-left: 40%;
  }

  .column.is-offset-three-fifths-widescreen {
    margin-left: 60%;
  }

  .column.is-offset-four-fifths-widescreen {
    margin-left: 80%;
  }

  .column.is-1-widescreen {
    flex: none;
    width: 8.33333%;
  }

  .column.is-offset-1-widescreen {
    margin-left: 8.33333%;
  }

  .column.is-2-widescreen {
    flex: none;
    width: 16.6667%;
  }

  .column.is-offset-2-widescreen {
    margin-left: 16.6667%;
  }

  .column.is-3-widescreen {
    flex: none;
    width: 25%;
  }

  .column.is-offset-3-widescreen {
    margin-left: 25%;
  }

  .column.is-4-widescreen {
    flex: none;
    width: 33.3333%;
  }

  .column.is-offset-4-widescreen {
    margin-left: 33.3333%;
  }

  .column.is-5-widescreen {
    flex: none;
    width: 41.6667%;
  }

  .column.is-offset-5-widescreen {
    margin-left: 41.6667%;
  }

  .column.is-6-widescreen {
    flex: none;
    width: 50%;
  }

  .column.is-offset-6-widescreen {
    margin-left: 50%;
  }

  .column.is-7-widescreen {
    flex: none;
    width: 58.3333%;
  }

  .column.is-offset-7-widescreen {
    margin-left: 58.3333%;
  }

  .column.is-8-widescreen {
    flex: none;
    width: 66.6667%;
  }

  .column.is-offset-8-widescreen {
    margin-left: 66.6667%;
  }

  .column.is-9-widescreen {
    flex: none;
    width: 75%;
  }

  .column.is-offset-9-widescreen {
    margin-left: 75%;
  }

  .column.is-10-widescreen {
    flex: none;
    width: 83.3333%;
  }

  .column.is-offset-10-widescreen {
    margin-left: 83.3333%;
  }

  .column.is-11-widescreen {
    flex: none;
    width: 91.6667%;
  }

  .column.is-offset-11-widescreen {
    margin-left: 91.6667%;
  }

  .column.is-12-widescreen {
    flex: none;
    width: 100%;
  }

  .column.is-offset-12-widescreen {
    margin-left: 100%;
  }
}

@media screen and (width >= 1374px) {
  .column.is-narrow-fullhd {
    flex: none;
  }

  .column.is-full-fullhd {
    flex: none;
    width: 100%;
  }

  .column.is-three-quarters-fullhd {
    flex: none;
    width: 75%;
  }

  .column.is-two-thirds-fullhd {
    flex: none;
    width: 66.6666%;
  }

  .column.is-half-fullhd {
    flex: none;
    width: 50%;
  }

  .column.is-one-third-fullhd {
    flex: none;
    width: 33.3333%;
  }

  .column.is-one-quarter-fullhd {
    flex: none;
    width: 25%;
  }

  .column.is-one-fifth-fullhd {
    flex: none;
    width: 20%;
  }

  .column.is-two-fifths-fullhd {
    flex: none;
    width: 40%;
  }

  .column.is-three-fifths-fullhd {
    flex: none;
    width: 60%;
  }

  .column.is-four-fifths-fullhd {
    flex: none;
    width: 80%;
  }

  .column.is-offset-three-quarters-fullhd {
    margin-left: 75%;
  }

  .column.is-offset-two-thirds-fullhd {
    margin-left: 66.6666%;
  }

  .column.is-offset-half-fullhd {
    margin-left: 50%;
  }

  .column.is-offset-one-third-fullhd {
    margin-left: 33.3333%;
  }

  .column.is-offset-one-quarter-fullhd {
    margin-left: 25%;
  }

  .column.is-offset-one-fifth-fullhd {
    margin-left: 20%;
  }

  .column.is-offset-two-fifths-fullhd {
    margin-left: 40%;
  }

  .column.is-offset-three-fifths-fullhd {
    margin-left: 60%;
  }

  .column.is-offset-four-fifths-fullhd {
    margin-left: 80%;
  }

  .column.is-1-fullhd {
    flex: none;
    width: 8.33333%;
  }

  .column.is-offset-1-fullhd {
    margin-left: 8.33333%;
  }

  .column.is-2-fullhd {
    flex: none;
    width: 16.6667%;
  }

  .column.is-offset-2-fullhd {
    margin-left: 16.6667%;
  }

  .column.is-3-fullhd {
    flex: none;
    width: 25%;
  }

  .column.is-offset-3-fullhd {
    margin-left: 25%;
  }

  .column.is-4-fullhd {
    flex: none;
    width: 33.3333%;
  }

  .column.is-offset-4-fullhd {
    margin-left: 33.3333%;
  }

  .column.is-5-fullhd {
    flex: none;
    width: 41.6667%;
  }

  .column.is-offset-5-fullhd {
    margin-left: 41.6667%;
  }

  .column.is-6-fullhd {
    flex: none;
    width: 50%;
  }

  .column.is-offset-6-fullhd {
    margin-left: 50%;
  }

  .column.is-7-fullhd {
    flex: none;
    width: 58.3333%;
  }

  .column.is-offset-7-fullhd {
    margin-left: 58.3333%;
  }

  .column.is-8-fullhd {
    flex: none;
    width: 66.6667%;
  }

  .column.is-offset-8-fullhd {
    margin-left: 66.6667%;
  }

  .column.is-9-fullhd {
    flex: none;
    width: 75%;
  }

  .column.is-offset-9-fullhd {
    margin-left: 75%;
  }

  .column.is-10-fullhd {
    flex: none;
    width: 83.3333%;
  }

  .column.is-offset-10-fullhd {
    margin-left: 83.3333%;
  }

  .column.is-11-fullhd {
    flex: none;
    width: 91.6667%;
  }

  .column.is-offset-11-fullhd {
    margin-left: 91.6667%;
  }

  .column.is-12-fullhd {
    flex: none;
    width: 100%;
  }

  .column.is-offset-12-fullhd {
    margin-left: 100%;
  }
}

.column.align-center {
  align-self: center;
}

.columns {
  margin-top: -.75rem;
  margin-left: -.75rem;
  margin-right: -.75rem;
}

.columns:last-child {
  margin-bottom: -.75rem;
}

.columns:not(:last-child) {
  margin-bottom: .75rem;
}

.columns.is-centered {
  justify-content: center;
}

.columns.is-gapless {
  margin-top: 0;
  margin-left: 0;
  margin-right: 0;
}

.columns.is-gapless > .column {
  margin: 0;
  padding: 0 !important;
}

.columns.is-gapless:not(:last-child) {
  margin-bottom: 1.5rem;
}

.columns.is-gapless:last-child {
  margin-bottom: 0;
}

.columns.is-mobile {
  display: flex;
}

.columns.is-multiline {
  flex-wrap: wrap;
}

.columns.is-vcentered {
  align-items: center;
}

@media screen and (width >= 768px), print {
  .columns:not(.is-desktop) {
    display: flex;
  }
}

@media screen and (width >= 990px) {
  .columns.is-desktop {
    display: flex;
  }
}

.columns.is-variable {
  --columnGap: .75rem;
  margin-left: calc(-1 * var(--columnGap));
  margin-right: calc(-1 * var(--columnGap));
}

.columns.is-variable .column {
  padding-left: var(--columnGap);
  padding-right: var(--columnGap);
}

.columns.is-variable.is-0 {
  --columnGap: 0 * .25rem;
}

.columns.is-variable.is-1 {
  --columnGap: 1 * .25rem;
}

.columns.is-variable.is-2 {
  --columnGap: 2 * .25rem;
}

.columns.is-variable.is-3 {
  --columnGap: 3 * .25rem;
}

.columns.is-variable.is-4 {
  --columnGap: 4 * .25rem;
}

.columns.is-variable.is-5 {
  --columnGap: 5 * .25rem;
}

.columns.is-variable.is-6 {
  --columnGap: 6 * .25rem;
}

.columns.is-variable.is-7 {
  --columnGap: 7 * .25rem;
}

.columns.is-variable.is-8 {
  --columnGap: 8 * .25rem;
}

.tooltip {
  padding: 0 .25rem;
  position: relative;
}

.tooltip.has-icon {
  display: inline-flex;
}

.tooltip:hover:not(.is-loading):after, .tooltip.is-tooltip-active:not(.is-loading):after, .tooltip:hover:before, .tooltip.is-tooltip-active:before {
  z-index: 20;
  pointer-events: none;
  display: inline-block;
  position: absolute;
}

.tooltip:hover:not(.is-loading):after, .tooltip.is-tooltip-active:not(.is-loading):after {
  content: "";
  border-style: solid;
  border-width: .5rem;
}

.tooltip:hover:before, .tooltip.is-tooltip-active:before {
  opacity: 0;
  content: attr(data-tooltip);
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #fcfcfc;
  background: #4a4a4a;
  border-radius: 4px;
  max-width: 24rem;
  padding: .4rem .8rem;
  font-size: .75rem;
  overflow: hidden;
  transform: translate(-50%, 1rem);
}

.tooltip:focus:not(.is-loading):after, .tooltip:hover:not(.is-loading):after, .tooltip.is-tooltip-active:not(.is-loading):after {
  opacity: 1;
  border-color: #4a4a4a #0000 #0000;
  margin-top: -.5rem;
  margin-left: -.5rem;
  top: 0;
  bottom: auto;
  left: 50%;
}

.tooltip:focus:before, .tooltip:hover:before, .tooltip.is-tooltip-active:before {
  opacity: 1;
  top: auto;
  bottom: 100%;
  left: 50%;
  transform: translate(-50%, -.5rem);
}

.tooltip.is-tooltip-right:before {
  inset: auto auto 50% 100%;
  transform: translate(-1rem, 50%);
}

.tooltip.is-tooltip-right:focus:not(.is-loading):after, .tooltip.is-tooltip-right:hover:not(.is-loading):after, .tooltip.is-tooltip-right.is-tooltip-active:not(.is-loading):after {
  border-color: #0000 #4a4a4a #0000 #0000;
  top: 50%;
  left: 100%;
  right: auto;
}

.tooltip.is-tooltip-right:focus:before, .tooltip.is-tooltip-right:hover:before, .tooltip.is-tooltip-right.is-tooltip-active:before {
  transform: translate(.5rem, 50%);
}

.tooltip.is-tooltip-bottom:before {
  inset: 100% auto auto 50%;
  transform: translate(-50%, -1rem);
}

.tooltip.is-tooltip-bottom:focus:not(.is-loading):after, .tooltip.is-tooltip-bottom:hover:not(.is-loading):after, .tooltip.is-tooltip-bottom.is-tooltip-active:not(.is-loading):after {
  border-color: #0000 #0000 #4a4a4a;
  inset: 100% auto auto 50%;
}

.tooltip.is-tooltip-bottom:focus:before, .tooltip.is-tooltip-bottom:hover:before, .tooltip.is-tooltip-bottom.is-tooltip-active:before {
  transform: translate(-50%, .5rem);
}

.tooltip.is-tooltip-left:before {
  inset: auto 100% 50% auto;
  transform: translate(1rem, 50%);
}

.tooltip.is-tooltip-left:focus:not(.is-loading):after, .tooltip.is-tooltip-left:hover:not(.is-loading):after, .tooltip.is-tooltip-left.is-tooltip-active:not(.is-loading):after {
  border-color: #0000 #0000 #0000 #4a4a4a;
  top: 50%;
  left: auto;
  right: calc(100% - .5rem);
}

.tooltip.is-tooltip-left:focus:before, .tooltip.is-tooltip-left:hover:before, .tooltip.is-tooltip-left.is-tooltip-active:before {
  transform: translate(-.5rem, 50%);
}

.tooltip.is-tooltip-multiline:before {
  text-overflow: clip;
  white-space: normal;
  word-break: keep-all;
  min-width: 24rem;
}

.tooltip.is-tooltip-white:not(.is-loading):after {
  border-color: #fcfcfc #0000 #0000;
}

.tooltip.is-tooltip-white.is-tooltip-right:not(.is-loading):after {
  border-color: #0000 #fcfcfc #0000 #0000;
}

.tooltip.is-tooltip-white.is-tooltip-bottom:not(.is-loading):after {
  border-color: #0000 #0000 #fcfcfc;
}

.tooltip.is-tooltip-white.is-tooltip-left:not(.is-loading):after {
  border-color: #0000 #0000 #0000 #fcfcfc;
}

.tooltip.is-tooltip-white:before {
  color: #1a1a1a;
  background: #fcfcfc;
}

.tooltip.is-tooltip-black:not(.is-loading):after {
  border-color: #1a1a1a #0000 #0000;
}

.tooltip.is-tooltip-black.is-tooltip-right:not(.is-loading):after {
  border-color: #0000 #1a1a1a #0000 #0000;
}

.tooltip.is-tooltip-black.is-tooltip-bottom:not(.is-loading):after {
  border-color: #0000 #0000 #1a1a1a;
}

.tooltip.is-tooltip-black.is-tooltip-left:not(.is-loading):after {
  border-color: #0000 #0000 #0000 #1a1a1a;
}

.tooltip.is-tooltip-black:before {
  color: #fcfcfc;
  background: #1a1a1a;
}

.tooltip.is-tooltip-light:not(.is-loading):after {
  border-color: #f0f0f0 #0000 #0000;
}

.tooltip.is-tooltip-light.is-tooltip-right:not(.is-loading):after {
  border-color: #0000 #f0f0f0 #0000 #0000;
}

.tooltip.is-tooltip-light.is-tooltip-bottom:not(.is-loading):after {
  border-color: #0000 #0000 #f0f0f0;
}

.tooltip.is-tooltip-light.is-tooltip-left:not(.is-loading):after {
  border-color: #0000 #0000 #0000 #f0f0f0;
}

.tooltip.is-tooltip-light:before {
  color: #434a48;
  background: #f0f0f0;
}

.tooltip.is-tooltip-dark:not(.is-loading):after {
  border-color: #434a48 #0000 #0000;
}

.tooltip.is-tooltip-dark.is-tooltip-right:not(.is-loading):after {
  border-color: #0000 #434a48 #0000 #0000;
}

.tooltip.is-tooltip-dark.is-tooltip-bottom:not(.is-loading):after {
  border-color: #0000 #0000 #434a48;
}

.tooltip.is-tooltip-dark.is-tooltip-left:not(.is-loading):after {
  border-color: #0000 #0000 #0000 #434a48;
}

.tooltip.is-tooltip-dark:before {
  color: #f0f0f0;
  background: #434a48;
}

.tooltip.is-tooltip-primary:not(.is-loading):after {
  border-color: #003f2d #0000 #0000;
}

.tooltip.is-tooltip-primary.is-tooltip-right:not(.is-loading):after {
  border-color: #0000 #003f2d #0000 #0000;
}

.tooltip.is-tooltip-primary.is-tooltip-bottom:not(.is-loading):after {
  border-color: #0000 #0000 #003f2d;
}

.tooltip.is-tooltip-primary.is-tooltip-left:not(.is-loading):after {
  border-color: #0000 #0000 #0000 #003f2d;
}

.tooltip.is-tooltip-primary:before {
  color: #fcfcfc;
  background: #003f2d;
}

.tooltip.is-tooltip-secondary:not(.is-loading):after {
  border-color: #d6e3df #0000 #0000;
}

.tooltip.is-tooltip-secondary.is-tooltip-right:not(.is-loading):after {
  border-color: #0000 #d6e3df #0000 #0000;
}

.tooltip.is-tooltip-secondary.is-tooltip-bottom:not(.is-loading):after {
  border-color: #0000 #0000 #d6e3df;
}

.tooltip.is-tooltip-secondary.is-tooltip-left:not(.is-loading):after {
  border-color: #0000 #0000 #0000 #d6e3df;
}

.tooltip.is-tooltip-secondary:before {
  color: #1a1a1a;
  background: #d6e3df;
}

.tooltip.is-tooltip-tertiary:not(.is-loading):after {
  border-color: #0d8070 #0000 #0000;
}

.tooltip.is-tooltip-tertiary.is-tooltip-right:not(.is-loading):after {
  border-color: #0000 #0d8070 #0000 #0000;
}

.tooltip.is-tooltip-tertiary.is-tooltip-bottom:not(.is-loading):after {
  border-color: #0000 #0000 #0d8070;
}

.tooltip.is-tooltip-tertiary.is-tooltip-left:not(.is-loading):after {
  border-color: #0000 #0000 #0000 #0d8070;
}

.tooltip.is-tooltip-tertiary:before {
  color: #fcfcfc;
  background: #0d8070;
}

.tooltip.is-tooltip-primary-invert:not(.is-loading):after {
  border-color: #fcfcfc #0000 #0000;
}

.tooltip.is-tooltip-primary-invert.is-tooltip-right:not(.is-loading):after {
  border-color: #0000 #fcfcfc #0000 #0000;
}

.tooltip.is-tooltip-primary-invert.is-tooltip-bottom:not(.is-loading):after {
  border-color: #0000 #0000 #fcfcfc;
}

.tooltip.is-tooltip-primary-invert.is-tooltip-left:not(.is-loading):after {
  border-color: #0000 #0000 #0000 #fcfcfc;
}

.tooltip.is-tooltip-primary-invert:before {
  color: #003f2d;
  background: #fcfcfc;
}

.tooltip.is-tooltip-link:not(.is-loading):after {
  border-color: #003f2d #0000 #0000;
}

.tooltip.is-tooltip-link.is-tooltip-right:not(.is-loading):after {
  border-color: #0000 #003f2d #0000 #0000;
}

.tooltip.is-tooltip-link.is-tooltip-bottom:not(.is-loading):after {
  border-color: #0000 #0000 #003f2d;
}

.tooltip.is-tooltip-link.is-tooltip-left:not(.is-loading):after {
  border-color: #0000 #0000 #0000 #003f2d;
}

.tooltip.is-tooltip-link:before {
  color: #fcfcfc;
  background: #003f2d;
}

.tooltip.is-tooltip-info:not(.is-loading):after {
  border-color: #00b2dd #0000 #0000;
}

.tooltip.is-tooltip-info.is-tooltip-right:not(.is-loading):after {
  border-color: #0000 #00b2dd #0000 #0000;
}

.tooltip.is-tooltip-info.is-tooltip-bottom:not(.is-loading):after {
  border-color: #0000 #0000 #00b2dd;
}

.tooltip.is-tooltip-info.is-tooltip-left:not(.is-loading):after {
  border-color: #0000 #0000 #0000 #00b2dd;
}

.tooltip.is-tooltip-info:before {
  color: #fcfcfc;
  background: #00b2dd;
}

.tooltip.is-tooltip-pale-green:not(.is-loading):after {
  border-color: #d6e3df #0000 #0000;
}

.tooltip.is-tooltip-pale-green.is-tooltip-right:not(.is-loading):after {
  border-color: #0000 #d6e3df #0000 #0000;
}

.tooltip.is-tooltip-pale-green.is-tooltip-bottom:not(.is-loading):after {
  border-color: #0000 #0000 #d6e3df;
}

.tooltip.is-tooltip-pale-green.is-tooltip-left:not(.is-loading):after {
  border-color: #0000 #0000 #0000 #d6e3df;
}

.tooltip.is-tooltip-pale-green:before {
  color: #1a1a1a;
  background: #d6e3df;
}

.tooltip.is-tooltip-success:not(.is-loading):after {
  border-color: #50e3c2 #0000 #0000;
}

.tooltip.is-tooltip-success.is-tooltip-right:not(.is-loading):after {
  border-color: #0000 #50e3c2 #0000 #0000;
}

.tooltip.is-tooltip-success.is-tooltip-bottom:not(.is-loading):after {
  border-color: #0000 #0000 #50e3c2;
}

.tooltip.is-tooltip-success.is-tooltip-left:not(.is-loading):after {
  border-color: #0000 #0000 #0000 #50e3c2;
}

.tooltip.is-tooltip-success:before {
  color: #fcfcfc;
  background: #50e3c2;
}

.tooltip.is-tooltip-warning:not(.is-loading):after {
  border-color: #ffdd57 #0000 #0000;
}

.tooltip.is-tooltip-warning.is-tooltip-right:not(.is-loading):after {
  border-color: #0000 #ffdd57 #0000 #0000;
}

.tooltip.is-tooltip-warning.is-tooltip-bottom:not(.is-loading):after {
  border-color: #0000 #0000 #ffdd57;
}

.tooltip.is-tooltip-warning.is-tooltip-left:not(.is-loading):after {
  border-color: #0000 #0000 #0000 #ffdd57;
}

.tooltip.is-tooltip-warning:before {
  color: #1a1a1ab3;
  background: #ffdd57;
}

.tooltip.is-tooltip-danger:not(.is-loading):after {
  border-color: #fc5328 #0000 #0000;
}

.tooltip.is-tooltip-danger.is-tooltip-right:not(.is-loading):after {
  border-color: #0000 #fc5328 #0000 #0000;
}

.tooltip.is-tooltip-danger.is-tooltip-bottom:not(.is-loading):after {
  border-color: #0000 #0000 #fc5328;
}

.tooltip.is-tooltip-danger.is-tooltip-left:not(.is-loading):after {
  border-color: #0000 #0000 #0000 #fc5328;
}

.tooltip.is-tooltip-danger:before {
  color: #fcfcfc;
  background: #fc5328;
}

.tooltip.is-tooltip-me:not(.is-loading):after {
  border-color: #00d2a0 #0000 #0000;
}

.tooltip.is-tooltip-me.is-tooltip-right:not(.is-loading):after {
  border-color: #0000 #00d2a0 #0000 #0000;
}

.tooltip.is-tooltip-me.is-tooltip-bottom:not(.is-loading):after {
  border-color: #0000 #0000 #00d2a0;
}

.tooltip.is-tooltip-me.is-tooltip-left:not(.is-loading):after {
  border-color: #0000 #0000 #0000 #00d2a0;
}

.tooltip.is-tooltip-me:before {
  color: #fcfcfc;
  background: #00d2a0;
}

.tooltip.is-tooltip-we:not(.is-loading):after {
  border-color: #00b2dd #0000 #0000;
}

.tooltip.is-tooltip-we.is-tooltip-right:not(.is-loading):after {
  border-color: #0000 #00b2dd #0000 #0000;
}

.tooltip.is-tooltip-we.is-tooltip-bottom:not(.is-loading):after {
  border-color: #0000 #0000 #00b2dd;
}

.tooltip.is-tooltip-we.is-tooltip-left:not(.is-loading):after {
  border-color: #0000 #0000 #0000 #00b2dd;
}

.tooltip.is-tooltip-we:before {
  color: #fcfcfc;
  background: #00b2dd;
}

.tooltip.is-tooltip-amenity:not(.is-loading):after {
  border-color: #7d7ccf #0000 #0000;
}

.tooltip.is-tooltip-amenity.is-tooltip-right:not(.is-loading):after {
  border-color: #0000 #7d7ccf #0000 #0000;
}

.tooltip.is-tooltip-amenity.is-tooltip-bottom:not(.is-loading):after {
  border-color: #0000 #0000 #7d7ccf;
}

.tooltip.is-tooltip-amenity.is-tooltip-left:not(.is-loading):after {
  border-color: #0000 #0000 #0000 #7d7ccf;
}

.tooltip.is-tooltip-amenity:before {
  color: #fcfcfc;
  background: #7d7ccf;
}

.tooltip.is-tooltip-light-pale-green:not(.is-loading):after {
  border-color: #eaf1ef #0000 #0000;
}

.tooltip.is-tooltip-light-pale-green.is-tooltip-right:not(.is-loading):after {
  border-color: #0000 #eaf1ef #0000 #0000;
}

.tooltip.is-tooltip-light-pale-green.is-tooltip-bottom:not(.is-loading):after {
  border-color: #0000 #0000 #eaf1ef;
}

.tooltip.is-tooltip-light-pale-green.is-tooltip-left:not(.is-loading):after {
  border-color: #0000 #0000 #0000 #eaf1ef;
}

.tooltip.is-tooltip-light-pale-green:before {
  color: #1a1a1a;
  background: #eaf1ef;
}

.tooltip.is-tooltip-green-forest-darker:not(.is-loading):after {
  border-color: #1d4837 #0000 #0000;
}

.tooltip.is-tooltip-green-forest-darker.is-tooltip-right:not(.is-loading):after {
  border-color: #0000 #1d4837 #0000 #0000;
}

.tooltip.is-tooltip-green-forest-darker.is-tooltip-bottom:not(.is-loading):after {
  border-color: #0000 #0000 #1d4837;
}

.tooltip.is-tooltip-green-forest-darker.is-tooltip-left:not(.is-loading):after {
  border-color: #0000 #0000 #0000 #1d4837;
}

.tooltip.is-tooltip-green-forest-darker:before {
  color: #fcfcfc;
  background: #1d4837;
}

.tooltip.is-tooltip-green-forest-dark:not(.is-loading):after {
  border-color: #003f2d #0000 #0000;
}

.tooltip.is-tooltip-green-forest-dark.is-tooltip-right:not(.is-loading):after {
  border-color: #0000 #003f2d #0000 #0000;
}

.tooltip.is-tooltip-green-forest-dark.is-tooltip-bottom:not(.is-loading):after {
  border-color: #0000 #0000 #003f2d;
}

.tooltip.is-tooltip-green-forest-dark.is-tooltip-left:not(.is-loading):after {
  border-color: #0000 #0000 #0000 #003f2d;
}

.tooltip.is-tooltip-green-forest-dark:before {
  color: #fcfcfc;
  background: #003f2d;
}

blockquote, body, dd, dl, dt, fieldset, figure, h1, h2, h3, h4, h5, h6, hr, html, iframe, legend, li, ol, p, pre, textarea, ul {
  margin: 0;
  padding: 0;
}

h1, h2, h3, h4, h5, h6 {
  font-size: 100%;
  font-weight: 400;
}

ul {
  list-style: none;
}

button, input, select, textarea {
  margin: 0;
}

* {
  box-sizing: border-box;
}

audio, embed, img, object, video {
  max-width: 100%;
}

iframe {
  border: 0;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

td, th {
  text-align: left;
  padding: 0;
}

@font-face {
  font-family: Graphik Web;
  src: url("Graphik-Bold-Web.2e018b4e.eot");
  src: url("Graphik-Bold-Web.2e018b4e.eot#iefix") format("embedded-opentype"), url("Graphik-Bold-Web.3b2b616d.woff2") format("woff2"), url("Graphik-Bold-Web.022fa487.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
}

.Graphik-Bold-Web {
  font-family: Graphik Web;
  font-style: normal;
  font-weight: 700;
  font-stretch: normal;
}

@font-face {
  font-family: Graphik Web;
  src: url("Graphik-Medium-Web.22bebff1.eot");
  src: url("Graphik-Medium-Web.22bebff1.eot#iefix") format("embedded-opentype"), url("Graphik-Medium-Web.22135a45.woff2") format("woff2"), url("Graphik-Medium-Web.ae04d7b4.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
}

.Graphik-Medium-Web {
  font-family: Graphik Web;
  font-style: normal;
  font-weight: 500;
  font-stretch: normal;
}

@font-face {
  font-family: Graphik Web;
  src: url("Graphik-RegularItalic-Web.3ab5d679.eot");
  src: url("Graphik-RegularItalic-Web.3ab5d679.eot#iefix") format("embedded-opentype"), url("Graphik-RegularItalic-Web.a0883b88.woff2") format("woff2"), url("Graphik-RegularItalic-Web.e74eb8dc.woff") format("woff");
  font-weight: 400;
  font-style: italic;
  font-stretch: normal;
}

.Graphik-RegularItalic-Web {
  font-style: italic;
}

@font-face {
  font-family: Graphik Web;
  src: url("Graphik-Regular-Web.1c169639.eot");
  src: url("Graphik-Regular-Web.1c169639.eot#iefix") format("embedded-opentype"), url("Graphik-Regular-Web.76e2f995.woff2") format("woff2"), url("Graphik-Regular-Web.ac3a4f39.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
}

.Graphik-Regular-Web, .Graphik-RegularItalic-Web {
  font-family: Graphik Web;
  font-weight: 400;
  font-stretch: normal;
}

.Graphik-Regular-Web {
  font-style: normal;
}

@font-face {
  font-family: Graphik Web;
  src: url("Graphik-Light-Web.babdca72.eot");
  src: url("Graphik-Light-Web.babdca72.eot#iefix") format("embedded-opentype"), url("Graphik-Light-Web.b091700a.woff2") format("woff2"), url("Graphik-Light-Web.7b1d24d5.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
}

.Graphik-Light-Web {
  font-family: Graphik Web;
  font-style: normal;
  font-weight: 300;
  font-stretch: normal;
}

.blx-label, .blx-p, label, p {
  letter-spacing: 0;
  font-family: Graphik Web;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
}

.blx-label.blx-with-margin, .blx-p.blx-with-margin, label.blx-with-margin, p.blx-with-margin {
  margin-top: 0;
  margin-bottom: 6px;
}

.blx-link, a {
  letter-spacing: 0;
  color: var(--primary-00, #009f6f);
  cursor: pointer;
  text-align: left;
  background: none;
  border: none;
  padding: 0;
  font-family: Graphik Web;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  text-decoration: underline;
}

a:hover, .blx-link:hover, .blx-link.blx-hover, a.blx-hover {
  color: var(--primary-01, #008b61);
}

a:focus, .blx-link:focus, .blx-link.blx-focus, a.blx-focus {
  color: #fff;
  background-color: #49a1ff;
  background-color: var(--focus, #49a1ff);
  outline: none;
}

.blx-link.blx-disabled, a.blx-disabled {
  opacity: .3;
  pointer-events: none;
}

.blx-link.blx-with-margin, a.blx-with-margin {
  margin-top: 0;
  margin-bottom: 6px;
}

h1.blx-XL {
  letter-spacing: -3.25px;
  font-family: Graphik Web;
  font-size: 96px;
  font-weight: 700;
  line-height: 96px;
}

h1.blx-XL.blx-with-margin {
  margin-top: 108px;
  margin-bottom: 36px;
}

.blx-h1, h1 {
  letter-spacing: -2px;
  font-family: Graphik Web;
  font-size: 65px;
  font-weight: 700;
  line-height: 72px;
}

.blx-h1.blx-with-margin, h1.blx-with-margin {
  margin-top: 108px;
  margin-bottom: 36px;
}

.blx-h2, h2 {
  letter-spacing: -.081px;
  font-family: Graphik Web;
  font-size: 39px;
  font-weight: 700;
  line-height: 42px;
}

.blx-h2.blx-with-margin, h2.blx-with-margin {
  margin-top: 42px;
  margin-bottom: 24px;
}

.blx-h3.blx-with-margin, h3.blx-with-margin {
  margin-top: 24px;
  margin-bottom: 12px;
}

.blx-h4, h4 {
  letter-spacing: .17px;
  font-family: Graphik Web;
  font-size: 16px;
  font-weight: 700;
  line-height: 18px;
}

.blx-h4.blx-with-margin, h4.blx-with-margin {
  margin-top: 12px;
  margin-bottom: 6px;
}

.blx-h5, h5 {
  letter-spacing: 0;
  font-family: Graphik Web;
  font-size: 16px;
  font-weight: 500;
  line-height: 18px;
}

.blx-h5.blx-with-margin, h5.blx-with-margin {
  margin-top: 12px;
  margin-bottom: 6px;
}

.blx-ui-text {
  letter-spacing: 0;
  font-family: Graphik Web;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
}

.blx-ui-text.blx-with-margin {
  margin-top: 0;
  margin-bottom: 6px;
}

.blx-ui-caps {
  text-transform: uppercase;
  letter-spacing: .8px;
  font-family: Graphik Web;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
}

.blx-ui-caps.blx-with-margin {
  margin-top: 0;
  margin-bottom: 12px;
}

.blx-ui-small {
  letter-spacing: .15px;
  font-family: Graphik Web;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
}

.blx-ui-small.blx-with-margin {
  margin-top: 0;
  margin-bottom: 6px;
}

.blx-ui-small-caps {
  text-transform: uppercase;
  letter-spacing: 1.65px;
  font-family: Graphik Web;
  font-size: 11px;
  font-weight: 500;
  line-height: 18px;
}

.blx-ui-small-caps.blx-with-margin {
  margin-top: 12px;
  margin-bottom: 6px;
}

.blx-capitalize {
  text-transform: uppercase;
}

.blx-interactive {
  color: var(--primary-00, #009f6f);
}

.blx-inactive, .blx-placeholder, .blx-unselected {
  color: var(--dark-02, #7c8c88);
}

.blx-light {
  color: var(--secondary-00, #fff);
}

.blx-dark {
  color: var(--dark-00, #2b3836);
}

.blx-success {
  color: var(--success, #39cf94);
}

.blx-warning {
  color: var(--warning, #fdb100);
}

.blx-error {
  color: var(--danger-00, #eb5534);
}

.blx-information {
  color: var(--information, #6f6bf8);
}

.blx-invalid-input-message {
  color: var(--danger-00, #eb5534);
  font-size: 12px;
  line-height: 16px;
}

.blx-input-helper-message {
  letter-spacing: .15px;
  color: var(--light-02, #aab7b2);
  font-family: Graphik Web;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
}

html {
  font-family: Graphik Web;
  font-weight: 400;
  line-height: 18px;
}

body, html {
  letter-spacing: 0;
  color: var(--dark-00, #2b3836);
  font-size: 14px;
}

body {
  font-family: Graphik Web;
  font-weight: 400;
  line-height: 18px;
}

html {
  --focus: #49a1ff;
  --primary-00: #009f6f;
  --primary-01: #008b61;
  --primary-02: #00704f;
  --primary-03: #009f6f59;
  --secondary-00: #fff;
  --secondary-01: #ecf5f1;
  --secondary-02: #d5f0e5;
  --shadow: #aab7b299;
  --light-00: #f6f8f6;
  --light-01: #d9e1e0;
  --light-02: #aab7b2;
  --dark-00: #2b3836;
  --dark-01: #505958;
  --dark-02: #7c8c88;
  --dark-03: #2b383659;
  --danger-00: #eb5534;
  --danger-01: #db5030;
  --danger-02: #ad4626;
  --danger-03: #eb553459;
  --warning: #fdb100;
  --success: #39cf94;
  --information: #6f6bf8;
  --brand-00: #a2ffbb;
  --brand-01: #0f4;
}

.blx-accordion-section {
  padding: 2px;
  position: relative;
  overflow: hidden;
}

.blx-accordion-trigger {
  cursor: pointer;
  background-color: #0000;
  align-items: center;
  height: 48px;
  padding: 0 16px;
  font-weight: 500;
  line-height: 48px;
  display: flex;
  position: relative;
}

.blx-accordion-trigger .blx-accordion-arrow {
  margin-right: 12px;
  transition: transform .2s, -webkit-transform .2s;
  transform: rotate(0);
}

.blx-accordion-content {
  background-color: #0000;
  max-height: 0;
  transition: max-height .25s;
  display: none;
  overflow: hidden;
}

input[type="checkbox"][name="blx-accordion-tab"] {
  opacity: 0;
  z-index: -1;
  position: absolute;
}

input[type="checkbox"][name="blx-accordion-tab"]:focus + .blx-accordion-trigger, input[type="checkbox"][name="blx-accordion-tab"].blx-focus + .blx-accordion-trigger {
  outline: 1px solid #49a1ff;
  outline: 1px solid var(--focus, #49a1ff);
  color: var(--dark-00, #2b3836);
}

input[type="checkbox"][name="blx-accordion-tab"]:active + .blx-accordion-trigger, input[type="checkbox"][name="blx-accordion-tab"].blx-active + .blx-accordion-trigger {
  color: var(--primary-00, #009f6f);
}

input[type="checkbox"][name="blx-accordion-tab"]:hover + .blx-accordion-trigger, input[type="checkbox"][name="blx-accordion-tab"].blx-hover + .blx-accordion-trigger {
  color: var(--primary-01, #008b61);
}

input[type="checkbox"][name="blx-accordion-tab"]:checked + .blx-accordion-trigger .blx-accordion-arrow {
  transform-origin: center;
  transition: transform .2s, -webkit-transform .2s;
  transform: rotate(90deg);
}

input[type="checkbox"][name="blx-accordion-tab"]:checked ~ .blx-accordion-content {
  padding: 2px;
  display: block;
}

.blx-alert {
  background-color: #fff;
  background-color: var(--secondary-00, #fff);
  box-shadow: 0 1px 2px #aab7b299;
  box-shadow: 0 1px 2px 0 var(--shadow, #aab7b299);
  border-top: 3px solid;
  border-radius: 0 0 7px 7px;
  width: 90%;
  min-width: 392px;
  max-width: 762px;
  min-height: 80px;
  max-height: 222px;
  display: flex;
  position: relative;
}

.blx-alert-icon-container {
  background-color: #d9e1e0;
  background-color: var(--light-01, #d9e1e0);
  border-radius: 50%;
  flex: none;
  width: 32px;
  height: 32px;
  margin: 16px;
  position: relative;
}

.blx-alert-icon-container .blx-alert-icon {
  border-radius: 50%;
  position: absolute;
  top: 6px;
  left: 6px;
}

.blx-alert-icon-container .blx-alert-icon-background {
  border-radius: 50%;
  width: 20px;
  height: 20px;
  position: absolute;
  top: 6px;
  left: 6px;
}

.blx-alert-text {
  padding: 24px 32px 24px 0;
}

.blx-alert-text h4 + p {
  margin-top: 18px;
}

.blx-alert-text p {
  color: var(--dark-00, #2b3836);
  margin-bottom: 18px;
}

.blx-success .blx-alert-icon-background {
  background-color: #39cf94;
  background-color: var(--success, #39cf94);
}

.blx-warning .blx-alert-icon-background {
  background-color: #fdb100;
  background-color: var(--warning, #fdb100);
}

.blx-information .blx-alert-icon-background {
  background-color: #6f6bf8;
  background-color: var(--information, #6f6bf8);
}

.blx-error .blx-alert-icon-background {
  background-color: #eb5534;
  background-color: var(--danger-00, #eb5534);
}

.blx-alert-close {
  cursor: pointer;
  background: none;
  border: none;
  padding: 0;
  position: absolute;
  top: 16px;
  right: 16px;
}

.blx-alert-close svg:hover {
  fill: var(--primary-01, #008b61);
}

.blx-breadcrumbs ul {
  margin: 0;
  padding: 11px 16px 9px;
  list-style-type: none;
  display: flex;
}

.blx-breadcrumbs li {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.blx-breadcrumbs li:before {
  content: "/";
  padding-left: 16px;
  padding-right: 16px;
}

.blx-breadcrumbs li:first-child:before {
  content: "";
  padding: 0;
}

.blx-breadcrumbs a:hover {
  color: var(--information, #6f6bf8);
  text-decoration: none;
}

.blx-button {
  cursor: pointer;
  letter-spacing: 0;
  text-align: center;
  box-shadow: 0 1px 2px #aab7b299;
  box-shadow: 0 1px 2px 0 var(--shadow, #aab7b299);
  border-radius: 7px;
  justify-content: center;
  min-width: 44px;
  max-width: 480px;
  height: 44px;
  margin: 0;
  padding: 8px 18px;
  font-family: Graphik Web;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  text-decoration: none;
  display: flex;
  position: relative;
}

.blx-button.blx-with-margin {
  margin: 12px;
}

.blx-button.blx-focus, .blx-button:focus {
  outline: none;
  text-decoration: none;
}

.blx-button.blx-hover, .blx-button:hover {
  text-decoration: none;
}

.blx-button span {
  white-space: nowrap;
  text-overflow: ellipsis;
  line-height: 20px;
  overflow: hidden;
}

.blx-button span:not(:first-child) {
  margin-left: 12px;
}

.blx-button span:not(:last-child) {
  margin-right: 12px;
}

.blx-icon-button {
  width: 44px;
  padding: 11px;
}

.blx-primary {
  background-color: #009f6f;
  background-color: var(--primary-00, #009f6f);
  border: 2px solid #009f6f;
  border: 2px solid var(--primary-00, #009f6f);
  color: var(--secondary-00, #fff);
}

.blx-primary svg {
  fill: var(--secondary-00, #fff);
}

.blx-primary:focus, .blx-primary.blx-focus {
  border: 2px solid #49a1ff;
  border: 2px solid var(--focus, #49a1ff);
  background-color: #008b61;
  background-color: var(--primary-01, #008b61);
  color: var(--secondary-00, #fff);
}

.blx-primary:focus:before, .blx-primary.blx-focus:before {
  content: "";
  border: 1px solid #fff;
  border: 1px solid var(--secondary-00, #fff);
  border-radius: 5px;
  width: calc(100% - 2px);
  height: calc(100% - 2px);
  position: absolute;
  top: 0;
  left: 0;
}

.blx-primary:hover, .blx-primary.blx-hover {
  background-color: #008b61;
  background-color: var(--primary-01, #008b61);
  border: 2px solid #008b61;
  border: 2px solid var(--primary-01, #008b61);
  color: var(--secondary-00, #fff);
}

.blx-primary.blx-hover:before, .blx-primary:hover:before {
  content: none;
}

.blx-primary:active, .blx-primary.blx-active {
  background-color: #00704f;
  background-color: var(--primary-02, #00704f);
  border: 2px solid #00704f;
  border: 2px solid var(--primary-02, #00704f);
  color: var(--secondary-00, #fff);
}

.blx-primary.blx-active:before, .blx-primary:active:before {
  content: none;
}

.blx-secondary {
  background-color: #fff;
  background-color: var(--secondary-00, #fff);
  border: 2px solid #fff;
  border: 2px solid var(--secondary-00, #fff);
  color: var(--primary-00, #009f6f);
}

.blx-secondary svg {
  fill: var(--primary-00, #009f6f);
}

.blx-secondary:focus, .blx-secondary.blx-focus {
  border: 2px solid #49a1ff;
  border: 2px solid var(--focus, #49a1ff);
  background-color: #ecf5f1;
  background-color: var(--secondary-01, #ecf5f1);
  color: var(--primary-00, #009f6f);
}

.blx-secondary:hover, .blx-secondary.blx-hover {
  background-color: #ecf5f1;
  background-color: var(--secondary-01, #ecf5f1);
  border: 2px solid #ecf5f1;
  border: 2px solid var(--secondary-01, #ecf5f1);
  color: var(--primary-00, #009f6f);
}

.blx-secondary.blx-hover:before, .blx-secondary:hover:before {
  content: none;
}

.blx-secondary:active, .blx-secondary.blx-active {
  background-color: #d5f0e5;
  background-color: var(--secondary-02, #d5f0e5);
  border: 2px solid #d5f0e5;
  border: 2px solid var(--secondary-02, #d5f0e5);
  color: var(--primary-00, #009f6f);
}

.blx-secondary.blx-active:before, .blx-secondary:active:before {
  content: none;
}

.blx-danger, .delete-modal .blx-primary {
  background-color: #eb5534;
  background-color: var(--danger-00, #eb5534);
  border: 2px solid #eb5534;
  border: 2px solid var(--danger-00, #eb5534);
  color: var(--secondary-00, #fff);
}

.blx-danger svg, .delete-modal .blx-primary svg {
  fill: var(--secondary-00, #fff);
}

.blx-danger:focus, .delete-modal .blx-primary:focus, .blx-danger.blx-focus, .delete-modal .blx-focus.blx-primary {
  border: 2px solid #49a1ff;
  border: 2px solid var(--focus, #49a1ff);
  background-color: #db5030;
  background-color: var(--danger-01, #db5030);
  color: var(--secondary-00, #fff);
}

.blx-danger:focus:before, .delete-modal .blx-primary:focus:before, .blx-danger.blx-focus:before, .delete-modal .blx-focus.blx-primary:before {
  content: "";
  border: 1px solid #fff;
  border: 1px solid var(--secondary-00, #fff);
  border-radius: 5px;
  width: calc(100% - 2px);
  height: calc(100% - 2px);
  position: absolute;
  top: 0;
  left: 0;
}

.blx-danger:focus svg, .delete-modal .blx-primary:focus svg, .blx-danger.blx-focus svg, .delete-modal .blx-focus.blx-primary svg {
  fill: var(--secondary-00, #fff);
}

.blx-danger:hover, .delete-modal .blx-primary:hover, .blx-danger.blx-hover, .delete-modal .blx-hover.blx-primary {
  background-color: #db5030;
  background-color: var(--danger-01, #db5030);
  border: 2px solid #db5030;
  border: 2px solid var(--danger-01, #db5030);
  color: var(--secondary-00, #fff);
}

.blx-danger.blx-hover:before, .delete-modal .blx-hover.blx-primary:before, .blx-danger:hover:before, .delete-modal .blx-primary:hover:before {
  content: none;
}

.blx-danger:hover svg, .delete-modal .blx-primary:hover svg, .blx-danger.blx-hover svg, .delete-modal .blx-hover.blx-primary svg {
  fill: var(--secondary-00, #fff);
}

.blx-danger:active, .delete-modal .blx-primary:active, .blx-danger.blx-active, .delete-modal .blx-active.blx-primary {
  background-color: #ad4626;
  background-color: var(--danger-02, #ad4626);
  border: 2px solid #ad4626;
  border: 2px solid var(--danger-02, #ad4626);
  color: var(--secondary-00, #fff);
}

.blx-danger.blx-active:before, .delete-modal .blx-active.blx-primary:before, .blx-danger:active:before, .delete-modal .blx-primary:active:before {
  content: none;
}

.blx-danger:active svg, .delete-modal .blx-primary:active svg, .blx-danger.blx-active svg, .delete-modal .blx-active.blx-primary svg {
  fill: var(--secondary-00, #fff);
}

.blx-dark {
  background-color: #2b3836;
  background-color: var(--dark-00, #2b3836);
  border: 2px solid #2b3836;
  border: 2px solid var(--dark-00, #2b3836);
  color: var(--secondary-00, #fff);
}

.blx-dark .blx-icon {
  background-color: #fff;
  background-color: var(--secondary-00, #fff);
}

.blx-dark:focus, .blx-dark.blx-focus {
  border: 2px solid #49a1ff;
  border: 2px solid var(--focus, #49a1ff);
  background-color: #505958;
  background-color: var(--dark-01, #505958);
  color: var(--secondary-00, #fff);
}

.blx-dark:focus:before, .blx-dark.blx-focus:before {
  content: "";
  border: 1px solid #fff;
  border: 1px solid var(--secondary-00, #fff);
  border-radius: 5px;
  width: calc(100% - 2px);
  height: calc(100% - 2px);
  position: absolute;
  top: 0;
  left: 0;
}

.blx-dark:focus .blx-icon, .blx-dark.blx-focus .blx-icon {
  background-color: #fff;
  background-color: var(--secondary-00, #fff);
}

.blx-dark:hover, .blx-dark.blx-hover {
  background-color: #505958;
  background-color: var(--dark-01, #505958);
  border: 2px solid #505958;
  border: 2px solid var(--dark-01, #505958);
  color: var(--secondary-00, #fff);
}

.blx-dark.blx-hover:before, .blx-dark:hover:before {
  content: none;
}

.blx-dark:hover .blx-icon, .blx-dark.blx-hover .blx-icon {
  background-color: #fff;
  background-color: var(--secondary-00, #fff);
}

.blx-dark:active, .blx-dark.blx-active {
  background-color: #7c8c88;
  background-color: var(--dark-02, #7c8c88);
  border: 2px solid #7c8c88;
  border: 2px solid var(--dark-02, #7c8c88);
  color: var(--secondary-00, #fff);
}

.blx-dark.blx-active:before, .blx-dark:active:before {
  content: none;
}

.blx-dark:active .blx-icon, .blx-dark.blx-active .blx-icon {
  background-color: #fff;
  background-color: var(--secondary-00, #fff);
}

a.blx-button {
  align-items: center;
  display: inline-flex;
}

.blx-button-group .blx-button {
  margin-bottom: 12px;
  margin-right: 10px;
}

.blx-button-group .blx-button:last-child {
  margin-right: 0;
}

.blx-h-button-group {
  flex-direction: row;
  min-height: 46px;
  display: flex;
}

.blx-h-button-group .blx-button {
  margin-left: 0;
  margin-right: 10px;
}

.blx-h-button-group .blx-button:first-child {
  margin-left: 20px;
}

.blx-v-button-group {
  flex-direction: column;
  display: flex;
}

.blx-v-button-group .blx-button {
  margin: 0 10px 12px;
}

.blx-v-button-group .blx-button:first-child {
  margin-top: 24px;
}

.blx-v-button-group .blx-button:last-child {
  margin-bottom: 24px;
}

.blx-checkbox {
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  padding-left: 34px;
  display: block;
  position: relative;
}

.blx-checkbox .blx-with-margin {
  margin: 8px 0;
}

.blx-checkbox .blx-label {
  line-height: 20px;
}

.blx-checkbox .blx-checkmark {
  background-color: #fff;
  background-color: var(--secondary-00, #fff);
  border: 1px solid #d9e1e0;
  border: 1px solid var(--light-01, #d9e1e0);
  border-radius: 7px;
  width: 18px;
  height: 18px;
  margin: 3px 0;
  position: absolute;
  top: -2px;
  left: 0;
}

.blx-checkbox .blx-checkmark:after {
  box-sizing: content-box;
  content: "";
  border: solid #fff;
  border: solid var(--secondary-00, #fff);
  border-width: 0 2px 2px 0;
  width: 3px;
  height: 7px;
  display: none;
  position: absolute;
  top: 6px;
  left: 6px;
  transform: rotate(45deg);
}

.blx-checkbox input[type="checkbox"] {
  opacity: 0;
  cursor: pointer;
  position: absolute;
}

.blx-checkbox input[type="checkbox"]:checked ~ .blx-checkmark {
  background-color: #009f6f;
  background-color: var(--primary-00, #009f6f);
  border: 1px solid #009f6f;
  border: 1px solid var(--primary-00, #009f6f);
}

.blx-checkbox input[type="checkbox"]:checked ~ .blx-checkmark:after {
  display: block;
  top: 3px;
  left: 6px;
}

.blx-checkbox input[type="checkbox"]:checked:focus + .blx-checkmark, .blx-checkbox input[type="checkbox"]:checked.blx-focus + .blx-checkmark {
  background-color: #009f6f;
  background-color: var(--primary-00, #009f6f);
}

.blx-checkbox input[type="checkbox"]:focus + .blx-checkmark, .blx-checkbox input[type="checkbox"].blx-focus + .blx-checkmark {
  border: 2px solid #49a1ff;
  border: 2px solid var(--focus, #49a1ff);
  background-color: #ecf5f1;
  background-color: var(--secondary-01, #ecf5f1);
  outline: none;
}

.blx-checkbox input[type="checkbox"]:focus + .blx-checkmark:before, .blx-checkbox input[type="checkbox"].blx-focus + .blx-checkmark:before {
  content: "";
  border: 1px solid #fff;
  border: 1px solid var(--secondary-00, #fff);
  border-radius: 5px;
  width: calc(100% - 2px);
  height: calc(100% - 2px);
  position: absolute;
  top: 0;
  left: 0;
}

.blx-checkbox input[type="checkbox"].blx-focus + .blx-checkmark:after, .blx-checkbox input[type="checkbox"]:focus + .blx-checkmark:after {
  top: 2px;
  left: 5px;
}

.blx-checkbox:hover input[type="checkbox"] ~ .blx-checkmark, .blx-checkbox.blx-hover input[type="checkbox"] ~ .blx-checkmark {
  background-color: #ecf5f1;
  background-color: var(--secondary-01, #ecf5f1);
  border: 1px solid #aab7b2;
  border: 1px solid var(--light-02, #aab7b2);
}

.blx-checkbox.blx-hover input[type="checkbox"] ~ .blx-checkmark:before, .blx-checkbox:hover input[type="checkbox"] ~ .blx-checkmark:before {
  display: none;
}

.blx-checkbox.blx-hover input[type="checkbox"] ~ .blx-checkmark:after, .blx-checkbox:hover input[type="checkbox"] ~ .blx-checkmark:after {
  top: 3px;
  left: 6px;
}

.blx-checkbox:hover input[type="checkbox"]:checked ~ .blx-checkmark, .blx-checkbox.blx-hover input[type="checkbox"]:checked ~ .blx-checkmark {
  background-color: #008b61;
  background-color: var(--primary-01, #008b61);
  border: 1px solid #008b61;
  border: 1px solid var(--primary-01, #008b61);
}

.blx-checkbox.blx-hover input[type="checkbox"]:checked ~ .blx-checkmark:before, .blx-checkbox:hover input[type="checkbox"]:checked ~ .blx-checkmark:before {
  display: none;
}

.blx-checkbox.blx-hover input[type="checkbox"]:checked ~ .blx-checkmark:after, .blx-checkbox:hover input[type="checkbox"]:checked ~ .blx-checkmark:after {
  top: 3px;
  left: 6px;
}

.blx-checkbox:active input[type="checkbox"] ~ .blx-checkmark, .blx-checkbox.blx-active input[type="checkbox"] ~ .blx-checkmark {
  background-color: #d5f0e5;
  background-color: var(--secondary-02, #d5f0e5);
  border: 1px solid #aab7b2;
  border: 1px solid var(--light-02, #aab7b2);
}

.blx-checkbox.blx-active input[type="checkbox"] ~ .blx-checkmark:before, .blx-checkbox:active input[type="checkbox"] ~ .blx-checkmark:before {
  display: none;
}

.blx-checkbox.blx-active input[type="checkbox"] ~ .blx-checkmark:after, .blx-checkbox:active input[type="checkbox"] ~ .blx-checkmark:after {
  top: 3px;
  left: 6px;
}

.blx-checkbox:active input[type="checkbox"]:checked ~ .blx-checkmark, .blx-checkbox.blx-active input[type="checkbox"]:checked ~ .blx-checkmark {
  background-color: #00704f;
  background-color: var(--primary-02, #00704f);
  border: 1px solid #00704f;
  border: 1px solid var(--primary-02, #00704f);
}

.blx-checkbox.blx-active input[type="checkbox"]:checked ~ .blx-checkmark:before, .blx-checkbox:active input[type="checkbox"]:checked ~ .blx-checkmark:before {
  display: none;
}

.blx-checkbox.blx-active input[type="checkbox"]:checked ~ .blx-checkmark:after, .blx-checkbox:active input[type="checkbox"]:checked ~ .blx-checkmark:after {
  top: 3px;
  left: 6px;
}

.blx-checkbox.blx-disabled {
  cursor: pointer;
}

.blx-dropdown {
  flex-direction: column;
  min-width: 150px;
  max-width: 300px;
  display: flex;
  position: relative;
}

.blx-dropdown label {
  margin-bottom: 6px;
}

.blx-dropdown-trigger {
  border: 1px solid #d9e1e0;
  border: 1px solid var(--light-01, #d9e1e0);
  background-color: #fff;
  background-color: var(--secondary-00, #fff);
  cursor: pointer;
  border-radius: 7px;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  margin: 0;
  padding: 0 16px;
  display: flex;
  position: relative;
}

.blx-dropdown-trigger .blx-dropdown-arrow {
  background-color: #0000;
  background-image: url("icon-down.e6dcbd23.svg");
  border: none;
  flex: none;
  width: 20px;
  height: 20px;
  transition: transform .2s, -webkit-transform .2s;
  transform: rotate(0);
}

.blx-dropdown-trigger .blx-dropdown-placeholder, .blx-dropdown-trigger .blx-dropdown-text {
  white-space: nowrap;
  text-overflow: ellipsis;
  letter-spacing: 0;
  color: var(--light-02, #aab7b2);
  font-family: Graphik Web;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  overflow: hidden;
}

.blx-dropdown-trigger .blx-dropdown-placeholder.blx-with-margin, .blx-dropdown-trigger .blx-dropdown-text.blx-with-margin {
  margin-top: 0;
  margin-bottom: 6px;
}

.blx-dropdown-trigger .blx-dropdown-placeholder:not(:first-child), .blx-dropdown-trigger .blx-dropdown-text:not(:first-child) {
  margin-left: 12px;
}

.blx-dropdown-trigger .blx-dropdown-placeholder:not(:last-child) {
  margin-right: 12px;
}

.blx-dropdown-trigger .blx-dropdown-text {
  color: var(--dark-00, #2b3836);
}

.blx-dropdown-trigger .blx-dropdown-text:not(:last-child) {
  margin-right: 12px;
}

.blx-dropdown-trigger .blx-dropdown-placeholder {
  color: var(--dark-02, #7c8c88);
}

.blx-dropdown-trigger.blx-active {
  border-radius: 7px 7px 0 0;
}

.blx-dropdown-trigger.blx-active .blx-dropdown-arrow {
  transition: transform .2s, -webkit-transform .2s;
  transform: rotate(180deg);
}

.blx-dropdown-trigger.blx-invalid {
  border: 1px solid #eb5534;
  border: 1px solid var(--danger-00, #eb5534);
}

.blx-dropdown-trigger:focus, .blx-dropdown-trigger.blx-focus {
  background-color: #fff;
  background-color: var(--secondary-00, #fff);
  border: 1px solid #49a1ff;
  border: 1px solid var(--focus, #49a1ff);
  color: var(--dark-00, #2b3836);
  outline: none;
  margin: 0;
}

.blx-dropdown-trigger:hover {
  background-color: #fff;
  background-color: var(--secondary-00, #fff);
  border: 1px solid #aab7b2;
  border: 1px solid var(--light-02, #aab7b2);
}

.blx-dropdown-trigger.blx-hover {
  border: 1px solid #aab7b2;
  border: 1px solid var(--light-02, #aab7b2);
}

.blx-dropdown-menu, .blx-dropdown-trigger.blx-hover {
  background-color: #fff;
  background-color: var(--secondary-00, #fff);
}

.blx-dropdown-menu {
  z-index: 1;
  border: 1px solid #d9e1e0;
  border: 1px solid var(--light-01, #d9e1e0);
  box-shadow: 0 1px 2px #aab7b299;
  box-shadow: 0 1px 2px 0 var(--shadow, #aab7b299);
  border-radius: 0 0 7px 7px;
  width: 100%;
  list-style-type: none;
  position: absolute;
  top: 100%;
  overflow: hidden;
}

.blx-dropdown-list.blx-scrollable {
  max-height: 140px;
  overflow-y: auto;
}

.blx-dropdown-item {
  background: none;
  position: relative;
  overflow: hidden;
}

.blx-dropdown-item a, .blx-dropdown-item button {
  text-align: left;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
  color: var(--dark-00, #2b3836);
  background: none;
  border: none;
  width: 100%;
  height: 40px;
  padding: 8px 16px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-decoration: none;
  display: block;
  overflow: hidden;
}

.blx-dropdown-item a:focus, .blx-dropdown-item button:focus, .blx-dropdown-item a.blx-focus, .blx-dropdown-item button.blx-focus, .blx-dropdown-item a:active, .blx-dropdown-item button:active, .blx-dropdown-item a.blx-active, .blx-dropdown-item button.blx-active {
  color: var(--dark-00, #2b3836);
  background-color: #ecf5f1;
  background-color: var(--secondary-01, #ecf5f1);
  outline: none;
}

.blx-dropdown-item.blx-selected a, .blx-dropdown-item.blx-selected button {
  padding-left: 36px;
}

.blx-dropdown-item.blx-selected a:before, .blx-dropdown-item.blx-selected button:before {
  content: "";
  background-color: #009f6f;
  background-color: var(--primary-00, #009f6f);
  border-radius: 50%;
  width: 8px;
  height: 8px;
  margin-left: 16px;
  position: absolute;
  top: 16px;
  left: 0;
}

.blx-dropdown-item:hover, .blx-dropdown-item.blx-hover {
  background-color: #ecf5f1;
  background-color: var(--secondary-01, #ecf5f1);
}

.blx-dropdown-item:hover a, .blx-dropdown-item.blx-hover a, .blx-dropdown-item:hover button, .blx-dropdown-item.blx-hover button {
  color: var(--dark-00, #2b3836);
  text-decoration: none;
}

.blx-dropdown-item:last-child {
  border-radius: 0 0 0 7px;
}

.blx-popover-wrapper {
  align-items: center;
  display: inline-flex;
}

.blx-popover-wrapper .blx-h5 {
  margin: 0 10px 0 0;
}

.blx-popover-trigger {
  cursor: pointer;
  text-align: left;
  background-color: #0000;
  border: 1px solid #0000;
  border-radius: 7px;
  outline: none;
  margin: 0;
  padding: 5px;
  display: flex;
}

.blx-popover-trigger:active svg, .blx-popover-trigger.blx-active svg {
  fill: var(--primary-01, #008b61);
}

.blx-popover-trigger:focus, .blx-popover-trigger.blx-focus {
  border: 1px solid #49a1ff;
  border: 1px solid var(--focus, #49a1ff);
}

.blx-popover-trigger:focus svg, .blx-popover-trigger.blx-focus svg {
  fill: var(--primary-02, #00704f);
}

.blx-popover-trigger:hover, .blx-popover-trigger.blx-hover {
  background-color: #f6f8f6;
  background-color: var(--light-00, #f6f8f6);
}

.blx-popover-trigger:hover svg, .blx-popover-trigger.blx-hover svg {
  fill: var(--primary-02, #00704f);
}

.blx-popover-trigger:disabled svg, .blx-popover-trigger.blx-disabled svg {
  fill: var(--light-02, #aab7b2);
}

.blx-popover {
  min-width: auto;
}

.blx-popover .blx-dropdown-item:first-child {
  border-radius: 7px 0 0;
}

.blx-popover-menu {
  z-index: 1;
  background-color: #fff;
  background-color: var(--secondary-00, #fff);
  border: 1px solid #d9e1e0;
  border: 1px solid var(--light-01, #d9e1e0);
  box-shadow: 0 1px 2px #aab7b299;
  box-shadow: 0 1px 2px 0 var(--shadow, #aab7b299);
  border-radius: 7px;
  width: auto;
  min-width: 136px;
  max-width: 330px;
  list-style-type: none;
  position: absolute;
  top: 100%;
}

.blx-popover-menu:before {
  content: "";
  border-bottom: 8px solid #d9e1e0;
  border-left: 8px solid #0000;
  border-right: 8px solid #0000;
  border-bottom: 8px solid var(--light-01, #d9e1e0);
  width: 0;
  height: 0;
  position: absolute;
  top: -8px;
}

.blx-popover-menu:after {
  content: "";
  border-bottom: 7px solid #fff;
  border-left: 7px solid #0000;
  border-right: 7px solid #0000;
  border-bottom: 7px solid var(--secondary-00, #fff);
  width: 0;
  height: 0;
  position: absolute;
  top: -7px;
}

.blx-popover-menu-wrapper {
  border-radius: 7px;
  width: 100%;
  overflow: hidden;
}

.blx-popover-is-center {
  transform-origin: center;
  left: 0;
  transform: translate(calc(10px - 50%), 12px);
}

.blx-popover-is-center:before {
  left: calc(50% - 1px);
}

.blx-popover-is-center:after {
  left: 50%;
}

.blx-popover-is-left {
  transform-origin: 0;
  left: 0;
  transform: translate(-9px, 12px);
}

.blx-popover-is-left:before {
  left: 15px;
}

.blx-popover-is-left:after {
  left: 16px;
}

.blx-popover-is-right {
  transform-origin: 100%;
  right: 0;
  transform: translate(9px, 12px);
}

.blx-popover-is-right:before {
  right: 15px;
}

.blx-popover-is-right:after {
  right: 16px;
}

.blx-icon {
  background-color: #0000;
  background-repeat: no-repeat;
  border: none;
  flex: none;
  width: 20px;
  height: 20px;
  display: inline-block;
}

.blx-icon-add, .blx-icon-plus {
  background-image: url("icon-add.ada55d8b.svg");
}

.blx-icon-back {
  background-image: url("icon-back.a3381a53.svg");
}

.blx-icon-forward, .blx-icon-next {
  background-image: url("icon-next.1bc0148f.svg");
}

.blx-icon-close {
  background-image: url("icon-close.63cd216e.svg");
}

.blx-icon-more, .blx-icon-more-horizontal {
  background-image: url("icon-more.a35a4422.svg");
}

.blx-icon-more-vertical {
  background-image: url("icon-more.a35a4422.svg");
  transform: rotate(90deg);
}

.blx-icon-success {
  background-image: url("icon-success.ee07aa00.svg");
}

.blx-icon-information {
  background-image: url("icon-information.fa4eee11.svg");
}

.blx-icon-warning {
  background-image: url("icon-warning.0cb09522.svg");
}

.blx-icon-error {
  background-image: url("icon-error.b1eb8b0a.svg");
}

.blx-icon-drag {
  background-image: url("icon-drag.5415b673.svg");
}

.blx-icon-help {
  background-image: url("icon-information.fa4eee11.svg");
}

.blx-modal {
  z-index: 1001;
  border: 1px solid #d9e1e0;
  border: 1px solid var(--light-01, #d9e1e0);
  background-color: #fff;
  background-color: var(--secondary-00, #fff);
  box-shadow: 0 1px 2px #aab7b299;
  box-shadow: 0 1px 2px 0 var(--shadow, #aab7b299);
  border-radius: 3px;
  flex-direction: column;
  width: 600px;
  max-height: 90%;
  padding: 24px 40px;
  display: flex;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.blx-modal .blx-modal-close {
  cursor: pointer;
  background: none;
  border: none;
  padding: 0;
  position: absolute;
  top: 16px;
  right: 16px;
}

.blx-modal .blx-modal-close svg:hover {
  fill: var(--primary-01, #008b61);
}

.blx-action-modal {
  padding: 0;
}

.blx-action-modal .blx-modal-content {
  padding: 24px 40px;
}

.blx-modal-buttons {
  border-top: 1px solid #d9e1e0;
  border-top: 1px solid var(--light-01, #d9e1e0);
  justify-content: flex-end;
  padding: 12px 0 24px;
}

.blx-modal-overlay {
  opacity: .5;
  z-index: 1000;
  background-color: #aab7b2;
  background-color: var(--light-02, #aab7b2);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
}

.blx-modal-content {
  flex-direction: column;
  width: 100%;
  display: flex;
  overflow: hidden;
}

.blx-modal-title {
  align-items: center;
  margin-bottom: 16px;
  display: flex;
}

.blx-modal-icon {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  margin-right: 15px;
}

.blx-success-modal {
  border-top: 4px solid #39cf94;
  border-top: 4px solid var(--success, #39cf94);
}

.blx-success-modal .blx-modal-icon {
  background: #39cf94;
  background: var(--success, #39cf94);
  fill: #fff;
}

.blx-information-modal {
  border-top: 4px solid #6f6bf8;
  border-top: 4px solid var(--information, #6f6bf8);
}

.blx-information-modal .blx-modal-icon {
  background: #6f6bf8;
  background: var(--information, #6f6bf8);
  fill: #fff;
}

.blx-warning-modal {
  border-top: 4px solid #fdb100;
  border-top: 4px solid var(--warning, #fdb100);
}

.blx-warning-modal .blx-modal-icon {
  background: #fdb100;
  background: var(--warning, #fdb100);
  fill: var(--dark-00, #2b3836);
}

.blx-error-modal {
  border-top: 4px solid #eb5534;
  border-top: 4px solid var(--danger-00, #eb5534);
}

.blx-error-modal .blx-modal-icon {
  background: #eb5534;
  background: var(--danger-00, #eb5534);
  fill: #fff;
}

.blx-progress-dots {
  display: flex;
}

.blx-progress-dots .blx-progress-dot-container {
  margin-right: 8px;
  position: relative;
}

.blx-progress-dots .blx-progress-dot-container input {
  opacity: 0;
  cursor: pointer;
  position: absolute;
  left: -9999px;
}

.blx-progress-dots .blx-progress-dot-container input:checked ~ .blx-progress-dot {
  background-color: #009f6f;
  background-color: var(--primary-00, #009f6f);
}

.blx-progress-dots .blx-progress-dot-container input:focus ~ .blx-progress-dot {
  background-color: #49a1ff;
  background-color: var(--focus, #49a1ff);
}

.blx-progress-dots .blx-progress-dot-container .blx-progress-dot {
  background-color: #d9e1e0;
  background-color: var(--light-01, #d9e1e0);
  border-radius: 50%;
  width: 8px;
  height: 8px;
  display: block;
  position: relative;
}

.blx-progress-dots .blx-progress-dot-container .blx-progress-dot:hover {
  cursor: pointer;
  background-color: #49a1ff;
  background-color: var(--focus, #49a1ff);
}

.blx-radio {
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  margin: 0;
  padding-left: 34px;
  display: block;
  position: relative;
}

.blx-radio .blx-with-margin {
  margin: 9px 0;
}

.blx-radio .blx-label {
  line-height: 18px;
}

.blx-radio .blx-radio-check {
  background-color: #fff;
  background-color: var(--secondary-00, #fff);
  border: 1px solid #d9e1e0;
  border: 1px solid var(--light-01, #d9e1e0);
  border-radius: 50%;
  width: 16px;
  height: 16px;
  position: absolute;
  top: 1px;
  left: 1px;
}

.blx-radio .blx-radio-check:after {
  content: "";
  background-color: #009f6f;
  background-color: var(--primary-00, #009f6f);
  border-radius: 50%;
  width: 8px;
  height: 8px;
  display: none;
  position: absolute;
  top: 3px;
  left: 3px;
}

.blx-radio input[type="radio"] {
  opacity: 0;
  cursor: pointer;
  position: absolute;
}

.blx-radio input[type="radio"]:checked ~ .blx-radio-check {
  border: 1px solid #009f6f;
  border: 1px solid var(--primary-00, #009f6f);
}

.blx-radio input[type="radio"]:checked ~ .blx-radio-check:after {
  display: block;
}

.blx-radio input[type="radio"]:checked:focus + .blx-radio-check, .blx-radio input[type="radio"]:checked.blx-focus + .blx-radio-check {
  border: 1px solid #49a1ff;
  border: 1px solid var(--focus, #49a1ff);
  outline: none;
}

.blx-radio input[type="radio"]:checked.blx-focus + .blx-radio-check:before, .blx-radio input[type="radio"]:checked:focus + .blx-radio-check:before {
  display: block;
}

.blx-radio input[type="radio"]:checked:active + .blx-radio-check, .blx-radio input[type="radio"]:checked.blx-active + .blx-radio-check {
  border: 1px solid #009f6f;
  border: 1px solid var(--primary-00, #009f6f);
}

.blx-radio input[type="radio"]:focus + .blx-radio-check, .blx-radio input[type="radio"].blx-focus + .blx-radio-check {
  border: 1px solid #49a1ff;
  border: 1px solid var(--focus, #49a1ff);
  outline: none;
}

.blx-radio input[type="radio"]:active + .blx-radio-check, .blx-radio input[type="radio"].blx-active + .blx-radio-check, .blx-radio:hover input[type="radio"] ~ .blx-radio-check, .blx-radio.blx-hover input[type="radio"] ~ .blx-radio-check {
  background-color: #fff;
  background-color: var(--secondary-00, #fff);
  border: 1px solid #aab7b2;
  border: 1px solid var(--light-02, #aab7b2);
}

.blx-radio:hover input[type="radio"]:checked ~ .blx-radio-check, .blx-radio.blx-hover input[type="radio"]:checked ~ .blx-radio-check {
  background-color: #fff;
  background-color: var(--secondary-00, #fff);
  border: 1px solid #008b61;
  border: 1px solid var(--primary-01, #008b61);
}

.blx-radio:hover input[type="radio"]:checked ~ .blx-radio-check:after, .blx-radio.blx-hover input[type="radio"]:checked ~ .blx-radio-check:after {
  background-color: #008b61;
  background-color: var(--primary-01, #008b61);
}

.blx-radio.blx-disabled {
  box-shadow: none;
  cursor: pointer;
}

.blx-radio.blx-invalid input[type="radio"] ~ .blx-radio-check, .blx-radio.blx-invalid input[type="radio"]:hover ~ .blx-radio-check {
  background-color: #fff;
  background-color: var(--secondary-00, #fff);
  border: 1px solid #eb5534;
  border: 1px solid var(--danger-00, #eb5534);
}

.blx-radio.blx-invalid input[type="radio"]:hover ~ .blx-radio-check:after, .blx-radio.blx-invalid input[type="radio"] ~ .blx-radio-check:after {
  background-color: #eb5534;
  background-color: var(--danger-00, #eb5534);
}

.blx-sortable-list {
  background: none;
  margin: 0;
  padding: 0;
  list-style: none;
}

.blx-sortable-list .blx-sortable-list-item {
  border: 2px solid #0000;
  border-width: 2px 0;
  justify-content: space-between;
  display: flex;
  position: relative;
}

.blx-sortable-list .blx-sortable-list-item.blx-dragging {
  background-color: #49a1ff;
  background-color: var(--focus, #49a1ff);
}

.blx-sortable-list .blx-sortable-list-item.blx-dragover-top {
  border-top: 2px solid #aab7b2;
  border-top: 2px solid var(--light-02, #aab7b2);
}

.blx-sortable-list .blx-sortable-list-item.blx-dragover-bottom {
  border-bottom: 2px solid #aab7b2;
  border-bottom: 2px solid var(--light-02, #aab7b2);
}

.blx-sortable-list .blx-sortable-list-item .blx-icon-drag {
  cursor: move;
  margin: 8px;
  position: relative;
  right: 0;
}

.blx-table {
  border-collapse: collapse;
  color: var(--dark-00, #2b3836);
  border: 1px solid #d9e1e0;
  border: 1px solid var(--light-01, #d9e1e0);
  margin: 12px 16px;
}

.blx-table .blx-table-header-row {
  background-color: #d9e1e0;
  background-color: var(--light-01, #d9e1e0);
  height: 56px;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
}

.blx-table .blx-table-row {
  background-color: #ecf5f1;
  background-color: var(--secondary-01, #ecf5f1);
  border-top: 1px solid #d9e1e0;
  border-top: 1px solid var(--light-01, #d9e1e0);
  height: 48px;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
}

.blx-table .blx-table-row:hover {
  cursor: pointer;
  background-color: #d9e1e0;
  background-color: var(--light-01, #d9e1e0);
  border: 1px solid #009f6f;
  border: 1px solid var(--primary-00, #009f6f);
}

.blx-table .blx-table-cell, .blx-table .blx-table-header-cell {
  text-align: left;
  border: none;
  padding: 0 28px;
}

.blx-table .blx-table-cell:first-child, .blx-table .blx-table-header-cell:first-child {
  padding-left: 24px;
}

.blx-table .blx-table-cell:last-child, .blx-table .blx-table-header-cell:last-child {
  padding-right: 24px;
}

.blx-table .blx-table-cell {
  text-align: left;
}

.blx-table .blx-table-cell.numeric {
  text-align: right;
}

.blx-horizontal-tabs, .blx-vertical-tabs {
  list-style: none;
}

.blx-horizontal-tabs input[type="radio"], .blx-vertical-tabs input[type="radio"] {
  opacity: 0;
  position: absolute;
  left: -9999px;
}

.blx-tab-item {
  -webkit-user-select: none;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
  color: var(--dark-02, #7c8c88);
  background-color: #0000;
  font-size: 14px;
  font-weight: 500;
  line-height: 32px;
  text-decoration: none;
  position: relative;
  overflow: hidden;
}

.blx-tab-item:after {
  content: "";
  background-color: #0000;
  position: absolute;
}

.blx-tab-item:hover {
  color: var(--primary-01, #008b61);
  background-color: #0000;
  text-decoration: none;
}

.blx-tab-item.blx-hover {
  background-color: #0000;
  text-decoration: none;
}

.blx-horizontal-tabs {
  display: inline-flex;
}

.blx-horizontal-tabs .blx-tab-item {
  padding: 10px 16px;
}

.blx-horizontal-tabs .blx-tab-item:after {
  width: calc(100% - 32px);
  height: 2px;
  bottom: 4px;
  left: 16px;
}

.blx-horizontal-tabs input[type="radio"]:focus + .blx-tab-item, .blx-horizontal-tabs .blx-tab-item:focus, .blx-horizontal-tabs .blx-tab-item.blx-focus {
  color: var(--dark-00, #2b3836);
  outline: 2px solid #49a1ff;
  outline: 2px solid var(--focus, #49a1ff);
  background-color: #0000;
}

.blx-vertical-tabs {
  display: inline-block;
}

.blx-vertical-tabs .blx-tab-item {
  height: 48px;
  min-height: 48px;
  padding: 0 16px 0 48px;
  line-height: 48px;
  display: block;
}

.blx-vertical-tabs .blx-tab-item:after {
  color: #0000;
  width: 4px;
  height: 32px;
  top: 8px;
  left: 28px;
}

.blx-vertical-tabs input[type="radio"]:focus + .blx-tab-item, .blx-vertical-tabs .blx-tab-item:focus, .blx-vertical-tabs .blx-tab-item.blx-focus {
  color: var(--dark-00, #2b3836);
  outline: 1px solid #49a1ff;
  outline: 1px solid var(--focus, #49a1ff);
  background-color: #0000;
}

input[type="radio"]:checked + .blx-tab-item, .blx-tab-item.blx-active {
  color: var(--primary-00, #009f6f);
}

input[type="radio"]:checked + .blx-tab-item:after, .blx-tab-item.blx-active:after {
  background-color: #009f6f;
  background-color: var(--primary-00, #009f6f);
}

input[type="radio"]:focus + .blx-tab-item, .blx-tab-item:focus, .blx-tab-item.blx-focus {
  outline: 2px solid #49a1ff;
  outline: 2px solid var(--focus, #49a1ff);
  color: var(--dark-00, #2b3836);
  background-color: #0000;
}

.blx-tab-item.blx-focus:after, .blx-tab-item:focus:after, input[type="radio"]:focus + .blx-tab-item:after {
  background-color: #0000;
}

input[type="radio"]:hover + .blx-tab-item, .blx-tab-item.blx-hover {
  color: var(--primary-01, #008b61);
}

input[type="radio"]:hover + .blx-tab-item:after, .blx-tab-item.blx-hover:after {
  background-color: #008b61;
  background-color: var(--primary-01, #008b61);
}

.blx-sub-tab {
  color: var(--light-02, #aab7b2);
  cursor: pointer;
  padding: 6px 12px 6px 48px;
  font-size: 14px;
  line-height: 18px;
  text-decoration: none;
  display: block;
}

.blx-sub-tab:hover {
  color: var(--primary-01, #008b61);
}

.blx-sub-tab:active {
  color: var(--primary-00, #009f6f);
}

.blx-sub-tab:focus, .blx-sub-tab.blx-focus {
  outline: 1px solid #49a1ff;
  outline: 1px solid var(--focus, #49a1ff);
  background: none;
}

input[type="radio"]:checked + .blx-sub-tab, .blx-sub-tab.blx-active {
  color: var(--primary-00, #009f6f);
}

input[type="radio"]:focus + .blx-sub-tab, .blx-sub-tab:focus, .blx-sub-tab.blx-focus {
  outline: 2px solid #49a1ff;
  outline: 2px solid var(--focus, #49a1ff);
  color: var(--dark-00, #2b3836);
  background-color: #0000;
}

input[type="radio"]:hover + .blx-sub-tab, .blx-sub-tab.blx-hover {
  color: var(--primary-01, #008b61);
}

.blx-text-field-container {
  display: flex;
  position: relative;
}

.blx-text-field {
  flex-direction: column;
  width: 300px;
  min-width: 150px;
  max-width: 484px;
  display: inline-flex;
  position: relative;
}

.blx-text-field.blx-with-margin {
  margin-bottom: 40px;
  margin-right: 24px;
}

.blx-text-field label {
  margin-bottom: 6px;
}

.blx-text-field input {
  white-space: nowrap;
  text-overflow: ellipsis;
  height: 40px;
  overflow: hidden;
}

.blx-text-field input::placeholder {
  letter-spacing: 0;
  color: var(--light-02, #aab7b2);
  font-family: Graphik Web;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
}

.blx-text-field input::placeholder {
  letter-spacing: 0;
  color: var(--light-02, #aab7b2);
  font-family: Graphik Web;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
}

.blx-text-field input::placeholder {
  letter-spacing: 0;
  color: var(--light-02, #aab7b2);
  font-family: Graphik Web;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
}

.blx-text-field input.blx-with-margin::placeholder {
  margin-top: 0;
  margin-bottom: 6px;
}

.blx-text-field input.blx-with-margin::placeholder {
  margin-top: 0;
  margin-bottom: 6px;
}

.blx-text-field input.blx-with-margin::placeholder {
  margin-top: 0;
  margin-bottom: 6px;
}

.blx-text-field input[type="date"] {
  width: 176px;
  position: relative;
}

.blx-text-field input[type="date"]:after {
  content: "";
  pointer-events: none;
  height: 16px;
  position: absolute;
}

.blx-text-field input[type="date"]:before {
  content: "";
  pointer-events: none;
  background-color: #fff;
  background-color: var(--secondary-00, #fff);
  width: 20px;
  height: 16px;
  position: absolute;
  right: 16px;
}

.blx-text-field input[type="date"]:after {
  background-image: url("icon-calendar.800917c3.svg");
  background-repeat: no-repeat;
  width: 20px;
  height: 20px;
  right: 18px;
}

.blx-text-field textarea {
  resize: none;
  overflow: hidden;
}

.blx-text-field textarea::placeholder {
  letter-spacing: 0;
  color: var(--light-02, #aab7b2);
  font-family: Graphik Web;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
}

.blx-text-field textarea::placeholder {
  letter-spacing: 0;
  color: var(--light-02, #aab7b2);
  font-family: Graphik Web;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
}

.blx-text-field textarea::placeholder {
  letter-spacing: 0;
  color: var(--light-02, #aab7b2);
  font-family: Graphik Web;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
}

.blx-text-field textarea.blx-with-margin::placeholder {
  margin-top: 0;
  margin-bottom: 6px;
}

.blx-text-field textarea.blx-with-margin::placeholder {
  margin-top: 0;
  margin-bottom: 6px;
}

.blx-text-field textarea.blx-with-margin::placeholder {
  margin-top: 0;
  margin-bottom: 6px;
}

.blx-text-field input, .blx-text-field textarea {
  letter-spacing: 0;
  color: var(--dark-00, #2b3836);
  background-color: #fff;
  background-color: var(--secondary-00, #fff);
  border: 1px solid #d9e1e0;
  border: 1px solid var(--light-01, #d9e1e0);
  border-radius: 7px;
  width: 100%;
  padding: 11px 16px;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
}

.blx-text-field input:hover, .blx-text-field textarea:hover, .blx-text-field input.blx-hover, .blx-text-field textarea.blx-hover {
  border: 1px solid #aab7b2;
  border: 1px solid var(--light-02, #aab7b2);
}

.blx-text-field input:focus, .blx-text-field textarea:focus, .blx-text-field input.blx-focus, .blx-text-field textarea.blx-focus {
  border: 1px solid #49a1ff;
  border: 1px solid var(--focus, #49a1ff);
  outline: none;
}

.blx-text-field input.blx-invalid, .blx-text-field textarea.blx-invalid {
  border: 1px solid #eb5534;
  border: 1px solid var(--danger-00, #eb5534);
}

:-moz-any(.blx-text-field input:-moz-read-only, .blx-text-field textarea:-moz-read-only, .blx-text-field input:read-only, .blx-text-field textarea:read-only) {
  pointer-events: none;
}

:is(.blx-text-field input:read-only, .blx-text-field textarea:read-only, .blx-text-field input:read-only, .blx-text-field textarea:read-only) {
  pointer-events: none;
}

.blx-text-field input.blx-disabled, .blx-text-field textarea.blx-disabled {
  opacity: .5;
  pointer-events: none;
}

.blx-text-field .blx-text-field-icon, .blx-text-field .blx-text-field-prefix, .blx-text-field .blx-text-field-suffix {
  color: var(--dark-00, #2b3836);
  padding-top: 11px;
  padding-bottom: 9px;
  font-size: 16px;
  line-height: 20px;
}

.blx-text-field .blx-text-field-prefix {
  -webkit-user-select: none;
  user-select: none;
  position: absolute;
  left: 16px;
}

.blx-text-field .blx-text-field-prefix ~ input {
  padding-left: 40px;
}

.blx-text-field .blx-text-field-suffix {
  -webkit-user-select: none;
  user-select: none;
  position: absolute;
  right: 8px;
}

.blx-text-field .blx-text-field-suffix ~ input {
  padding-right: 40px;
}

.blx-text-field .blx-text-field-icon {
  margin-left: 10px;
}

.blx-text-field .blx-text-field-helper-text {
  color: var(--dark-02, #7c8c88);
  margin: 5px 16px 0;
  font-size: 12px;
}

.blx-header-text-field input, .blx-header-text-field textarea {
  font-weight: inherit;
  font-size: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  color: inherit;
  background-color: inherit;
  border: 1px solid #0000;
}

.blx-header-text-field input:hover, .blx-header-text-field textarea:hover, .blx-header-text-field input.blx-hover, .blx-header-text-field textarea.blx-hover {
  border: 1px solid #d9e1e0;
  border: 1px solid var(--light-01, #d9e1e0);
}

.blx-header-text-field input:focus, .blx-header-text-field textarea:focus, .blx-header-text-field input.blx-focus, .blx-header-text-field textarea.blx-focus {
  border: 1px solid #d9e1e0;
  border: 1px solid var(--light-01, #d9e1e0);
  background-color: #fff;
  background-color: var(--secondary-00, #fff);
}

.blx-text-field-with-dropdown {
  align-items: flex-end;
  width: 100%;
  margin-bottom: 19px;
  margin-right: 20px;
  display: flex;
}

.blx-text-field-with-dropdown input {
  border-radius: 7px 0 0 7px;
}

.blx-text-field-with-dropdown .blx-text-field {
  margin-bottom: 0;
  margin-right: 0;
}

.blx-text-field-with-dropdown .blx-dropdown {
  min-width: 0;
  display: inline-block;
}

.blx-text-field-with-dropdown .blx-dropdown-trigger {
  border-radius: 0 7px 7px 0;
}

.blx-text-field-with-dropdown .blx-dropdown-trigger.blx-active {
  border-radius: 0 7px 0 0;
}

.blx-toggle {
  height: 40px;
  font-size: 14px;
  line-height: 40px;
}

.blx-toggle.blx-with-margin {
  margin: 12px 16px;
}

.blx-toggle.blx-disabled {
  opacity: .5;
  pointer-events: none;
}

.blx-toggle .blx-toggle-container {
  position: relative;
}

.blx-toggle .blx-toggle-container .blx-toggle-text {
  cursor: pointer;
  border: 1px solid #d9e1e0;
  border: 1px solid var(--light-01, #d9e1e0);
  background-color: #fff;
  background-color: var(--secondary-00, #fff);
  box-shadow: 0 1px 2px #aab7b299;
  box-shadow: 0 1px 2px 0 var(--shadow, #aab7b299);
  padding: 10px 30px;
  position: relative;
}

.blx-toggle .blx-toggle-container input {
  opacity: 0;
  cursor: pointer;
  position: absolute;
  left: -9999px;
}

.blx-toggle .blx-toggle-container input:checked ~ .blx-toggle-text {
  background-color: #009f6f;
  background-color: var(--primary-00, #009f6f);
  border: 1px solid #009f6f;
  border: 1px solid var(--primary-00, #009f6f);
  color: var(--secondary-00, #fff);
}

.blx-toggle .blx-toggle-container input:focus ~ .blx-toggle-text, .blx-toggle .blx-toggle-container input.blx-focus ~ .blx-toggle-text {
  background-color: #008b61;
  background-color: var(--primary-01, #008b61);
  border: 2px solid #49a1ff;
  border: 2px solid var(--focus, #49a1ff);
  color: var(--secondary-00, #fff);
  padding: 10px 29px;
}

.blx-toggle .blx-toggle-container input:focus ~ .blx-toggle-text:after, .blx-toggle .blx-toggle-container input.blx-focus ~ .blx-toggle-text:after {
  content: "";
  border: 1px solid #fff;
  border: 1px solid var(--secondary-00, #fff);
  width: calc(100% - 2px);
  height: calc(100% - 2px);
  position: absolute;
  top: 0;
  left: 0;
}

.blx-toggle .blx-toggle-container input:hover ~ .blx-toggle-text, .blx-toggle .blx-toggle-container input.blx-hover ~ .blx-toggle-text {
  background-color: #008b61;
  background-color: var(--primary-01, #008b61);
  border: 1px solid #008b61;
  border: 1px solid var(--primary-01, #008b61);
  color: var(--secondary-00, #fff);
  box-shadow: none;
  padding: 10px 30px;
}

.blx-toggle .blx-toggle-container input.blx-hover ~ .blx-toggle-text:after, .blx-toggle .blx-toggle-container input:hover ~ .blx-toggle-text:after {
  display: none;
}

.blx-toggle .blx-toggle-container input:active ~ .blx-toggle-text, .blx-toggle .blx-toggle-container input.blx-active ~ .blx-toggle-text {
  background-color: #00704f;
  background-color: var(--primary-02, #00704f);
  border: 1px solid #00704f;
  border: 1px solid var(--primary-02, #00704f);
  color: var(--secondary-00, #fff);
  box-shadow: none;
  padding: 10px 30px;
}

.blx-toggle .blx-toggle-container input.blx-active ~ .blx-toggle-text:after, .blx-toggle .blx-toggle-container input:active ~ .blx-toggle-text:after {
  display: none;
}

.blx-toggle .blx-toggle-container:first-of-type .blx-toggle-text {
  border-right: 1px solid #0000;
  border-radius: 7px 0 0 7px;
}

.blx-toggle .blx-toggle-container:first-of-type .blx-toggle-text:after {
  border-radius: 7px 0 0 7px;
}

.blx-toggle .blx-toggle-container:last-of-type .blx-toggle-text {
  border-left: 1px solid #0000;
  border-radius: 0 7px 7px 0;
}

.blx-toggle .blx-toggle-container:last-of-type .blx-toggle-text:after {
  border-radius: 0 7px 7px 0;
}

.blx-tooltip {
  text-align: left;
  display: inline-block;
  position: relative;
}

.blx-tooltip .blx-tooltip-message, .blx-tooltip .blx-tooltip-titled-message {
  z-index: 1;
  position: absolute;
}

.blx-tooltip-trigger {
  cursor: pointer;
  background: none;
  border: 2px solid #0000;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  padding: 0;
}

.blx-tooltip-trigger:focus, .blx-tooltip-trigger.blx-focus {
  border: 2px solid #49a1ff;
  border: 2px solid var(--focus, #49a1ff);
  outline: none;
}

.blx-tooltip-trigger::-moz-focus-inner {
  border: 0;
}

.blx-tooltip-message, .blx-tooltip-titled-message {
  border-radius: 7px;
  padding: 8px 12px;
}

.blx-tooltip-message {
  width: max-content;
  min-width: 32px;
  max-width: 265px;
  min-height: 37px;
  max-height: 108px;
}

.blx-tooltip-titled-message {
  width: 265px;
  min-height: 60px;
  max-height: 150px;
}

.blx-tooltip-titled-message-title {
  padding-bottom: 8px;
}

.blx-tooltip-bottom {
  top: 100%;
  transform: translate(calc(10px - 50%), 8px);
}

.blx-tooltip-top {
  bottom: 100%;
  transform: translate(calc(10px - 50%), -8px);
}

.blx-tooltip-right {
  left: calc(100% + 8px);
}

.blx-tooltip-left, .blx-tooltip-right {
  top: 50%;
  transform: translateY(-50%);
}

.blx-tooltip-left {
  right: calc(100% + 8px);
}

.blx-hidden {
  display: none;
}

.blx-disabled {
  opacity: .35;
  pointer-events: none;
  cursor: default;
}

.blx-dark-bg {
  background-color: #2b3836;
  background-color: var(--dark-00, #2b3836);
  color: var(--secondary-00, #fff);
}

.blx-light-bg {
  background-color: #fff;
  background-color: var(--secondary-00, #fff);
  color: var(--dark-00, #2b3836);
}

.blx-no-scroll {
  overflow: hidden;
}

.blx-scrollable {
  overflow-y: auto;
}

.blx-alert {
  z-index: 34;
  width: 70%;
  position: fixed;
  top: 4.25rem;
  left: 15%;
}

.blx-dropdown-custom-trigger {
  cursor: pointer;
  background-color: #0000;
  border: none;
  padding: 0;
}

.blx-dropdown-custom-trigger:focus {
  outline: none;
}

.blx-dropdown-arrow {
  background-image: none;
}

.blx-vertical-tabs {
  padding: 20px 20px 0 10px;
}

.blx-vertical-tabs input[type="radio"]:focus + .blx-tab-item, .blx-horizontal-tabs input[type="radio"]:focus + .blx-tab-item, .blx-vertical-tabs input[type="radio"]:checked + .blx-tab-item, .blx-horizontal-tabs input[type="radio"]:checked + .blx-tab-item {
  color: var(--primary-00, #009f6f);
  cursor: pointer;
  outline: none;
}

.blx-horizontal-tabs .blx-tab-item:focus, .blx-vertical-tabs .blx-tab-item:focus, input[type="radio"]:checked + .blx-tab-item, input[type="radio"]:focus + .blx-tab-item {
  outline: none;
}

input[type="radio"]:focus + .blx-tab-item:after, .blx-tab-item:focus:after, .blx-tab-item.blx-focus:after {
  background-color: var(--primary-00, #009f6f);
}

.blx-text-field {
  text-align: start;
}

.blx-tag {
  text-align: center;
  background-color: #778f9c;
  border-radius: 9px;
  height: 18px;
  padding: 0 10px;
  font-size: 10px;
  line-height: 18px;
  color: #fff !important;
}

.blx-popover-trigger .blx-icon-more-vertical {
  background-color: var(--light-02, #aab7b2);
}

.blx-popover-trigger:hover {
  background-color: #0000;
}

.blx-popover-trigger:focus {
  border-color: #0000;
}

.blx-modal-buttons {
  margin-top: 3rem;
}

.blx-text-field-container .blx-text-field {
  margin-bottom: 0;
}

.blx-modal {
  width: 380px;
}

.blx-link {
  padding: 1px 7px 2px;
}

.blx-link:focus {
  color: var(--primary-01, #008b61);
  background-color: #0000;
}

.blx-primary:focus:before {
  width: 100%;
  height: 100%;
}

.blx-vertical-tabs .blx-tab-item {
  font-size: 16px;
  line-height: initial;
  height: auto;
  min-height: auto;
  margin: 1rem 0;
  padding: 0 15px;
}

.blx-vertical-tabs .blx-tab-item:after {
  width: 2px;
  top: 0;
  left: 0;
}

.blx-alert.blx-warning {
  width: 100%;
  position: relative;
  top: 0;
  left: 0;
}

.blx-toggle .blx-toggle-container .blx-toggle-text, .blx-toggle .blx-toggle-container input:hover ~ .blx-toggle-text, .blx-toggle .blx-toggle-container input:focus ~ .blx-toggle-text {
  padding: 10px;
}

.blx-toggle .blx-toggle-container input.checked:not(:hover) ~ .blx-toggle-text {
  background-color: var(--primary-00, #009f6f);
  border: 1px solid var(--primary-00, #009f6f);
  color: var(--secondary-00, #fff);
}

.top-nav .blx-horizontal-tabs input[type="radio"]:focus + .blx-tab-item {
  outline: none;
}

.blx-h-button-group .blx-button:last-child {
  margin-right: 0;
}

.blx-dropdown-wrapper {
  display: inline-flex;
}

.blx-modal-buttons {
  margin-right: 1rem;
}

.navbar {
  background: #fff;
}

.blx-dropdown-item {
  color: #003f2d;
}

.blx-dropdown-item:hover button {
  background: #003f2d;
  color: #fff !important;
}

.blx-dropdown-item.blx-selected button:before, .blx-dropdown-item:hover.blx-selected button:before {
  background: #17e88f;
}

.blx-horizontal-tabs .blx-tab-item {
  color: #6c6d6d;
}

.blx-text-field input, .blx-text-field textarea, .blx-dropdown-trigger {
  border-color: #959595 !important;
}

.blx-checkbox .blx-label {
  color: #1a1a1a;
  font-size: 16px;
}

.is-600 {
  font-weight: 600;
}

.floating-panel-content {
  color: #1a1a1a;
}

.navbar-menu .navbar-item {
  margin-left: 15px;
}

.entity-info-page .entity-name {
  color: #1a1a1a !important;
  font-size: 21px !important;
}

.edit-pencil-icon {
  width: 18px;
  height: 18px;
}

.t2, .modal-name {
  color: #1a1a1a !important;
}

.spacer-pro {
  background-color: #f5f7f7;
}

.auth-page-content {
  -webkit-box-shadow: 0 0 11px #00000040;
  background: #fff;
  width: 380px;
  height: fit-content;
  padding: 16px;
  box-shadow: 0 0 11px;
}

.auth-page-spacer-pro-title {
  color: #003f2d;
  letter-spacing: 5.8px;
  font-size: 1.5rem;
  font-weight: 700;
}

.auth-page-spacer-pro-title-page, .auth-page-content-loginpage-title-page {
  width: 254px;
  height: 18px;
  margin: 24px;
  font-family: Calibre;
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  top: 37px;
  left: 32px;
}

.auth-error {
  color: var(--danger-00, #eb5534);
}

.blx-button {
  color: #fff;
  background: #003f2d !important;
  border-color: #003f2d !important;
}

.user-icon {
  position: relative;
}

.user-icon img {
  width: 18px;
  position: absolute;
  top: 23px;
  right: 24px;
}

.password-icon {
  position: relative;
}

.page-content.containertitlepage {
  background: url("spacer-pro-background.6f191656.png");
}

.underline {
  text-decoration: underline;
}

.error-text {
  text-underline-offset: 3px;
  text-decoration: underline;
  color: #a03530 !important;
}

.password-icon img {
  width: 21px;
  position: absolute;
  top: 28px;
  right: 24px;
}

.auth-page-content-loginpage {
  background: #fff;
  width: 382px;
  height: fit-content;
  margin: 150px auto auto;
  padding: 16px;
  box-shadow: 0 3px 8px #0003;
}

.form-container .columns {
  margin-top: 0;
  margin-bottom: 0;
}

.form-container .columns .column {
  margin-top: 1rem;
  padding-bottom: 0;
}

.form-container .blx-text-field, .form-container .blx-text-field input, .form-container .blx-dropdown-wrapper {
  width: 100%;
}

.form-container .blx-dropdown {
  max-width: unset;
  margin: unset;
  width: 100%;
}

.form-container .blx-text-field {
  min-width: unset;
}

.form-container .blx-text-field label {
  font-size: 1rem;
}

.box-link-display .form-container .blx-text-field {
  min-width: 150px;
}

.box-link-display .form-title {
  margin-left: .5rem;
}

.form-container-narrow-width {
  width: 250px;
}

.form-container-medium-width {
  width: 450px;
}

.form-description {
  margin: 1.5rem 0;
  font-size: 18px;
}

.form-description-login {
  fontWeight: 400px;
  height: 24px;
  margin: 24px;
  font-family: Calibre;
  font-size: 18px;
  line-height: 24px;
  top: 79px;
  left: 32px;
}

.form-section {
  padding-top: 1rem;
}

.form-title {
  margin-bottom: 1rem;
}

.form-error-message {
  margin-top: .75rem;
  font-size: .85rem;
  line-height: 1.5;
}

.form-warning-message-exclamation {
  background: var(--warning, #fdb100);
  color: var(--secondary-01, #ecf5f1);
  text-align: center;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  margin-right: .25rem;
  font-weight: bold;
  display: inline-block;
}

.form-button .blx-button {
  margin: 0;
}

.form-submit-button {
  justify-content: flex-end;
  margin-top: 1.5rem;
  display: flex;
}

.form-submit-button-pair .blx-button {
  margin-left: 1rem;
}

.directory {
  background-color: var(--secondary-00, #fff);
  border-radius: 7px;
  padding-bottom: 20px;
  box-shadow: 0 2px 4px #c9c9c980;
}

.directory-column-header {
  align-items: center;
  display: flex;
}

.directory-column-header h6 {
  text-transform: uppercase;
}

.sort-arrow-icons {
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 1rem;
  padding-right: .5rem;
  display: inline-flex;
}

.sort-arrow-icons * {
  cursor: pointer;
}

.sort-triangle-up {
  border-left: 5px solid #0000;
  border-right: 5px solid #0000;
  border-bottom: 5px solid var(--primary-00, #009f6f);
  background: none;
  width: 0;
  height: 0;
  position: relative;
}

.sort-triangle-up:after {
  content: "";
  position: absolute;
  inset: -.5rem -.75rem;
}

.sort-triangle-down {
  border-left: 5px solid #0000;
  border-right: 5px solid #0000;
  border-top: 5px solid var(--primary-00, #009f6f);
  background: none;
  width: 0;
  height: 0;
  position: relative;
}

.sort-triangle-down:after {
  content: "";
  position: absolute;
  inset: -.5rem -.75rem;
}

.directory-header, .directory-row {
  border-bottom: 1px solid var(--light-01, #d9e1e0);
  justify-content: space-between;
  align-items: center;
  height: 4rem;
  padding: 0 2rem;
  display: flex;
  margin-bottom: 0 !important;
}

.directory-header .column:not(.allow-overflow) *, .directory-row .column:not(.allow-overflow) * {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.directory-header .medium-weight-column *, .directory-row .medium-weight-column * {
  font-weight: 500;
}

.directory-header .medium-weight-column .user-avatar, .directory-row .medium-weight-column .user-avatar {
  font-weight: 400;
}

.directory-row:hover {
  background-color: var(--secondary-01, #ecf5f1);
}

.directory-row-link, .directory-row-link:active, .directory-row-link:hover {
  color: inherit;
  text-decoration: none;
}

.user-avatar-list {
  display: flex;
}

.user-avatar-list .user-avatar {
  position: unset;
  margin-right: .5rem;
}

.directory-action-popover {
  justify-content: flex-end;
  align-items: center;
  height: 30px;
  margin-left: -2rem;
  display: flex;
}

.directory-empty-placeholder {
  color: var(--light-02, #aab7b2);
  text-align: center;
  min-height: 10rem;
  line-height: 10rem;
}

.blx-dark {
  color: #fff;
  background: #003f2d;
  border-color: #003f2d;
}

.project-directory {
  color: #003f2d;
  margin-left: -32px;
  font-family: Calibre Regular;
  font-weight: 400;
}

.spacer-pro-logo {
  text-transform: uppercase;
  letter-spacing: 2px;
  align-items: flex-start;
  font-weight: 500;
  display: flex;
}

.spacer-pro-logo .pro {
  font-size: .5rem;
}

.modal-overlay {
  position: fixed;
}

.delete-modal .blx-button span {
  overflow: initial;
}

@media print {
  body {
    background-color: #fff;
  }

  .MuiBackdrop-root.MuiModal-backdrop {
    display: none;
  }
}

.floating-panel-content {
  background-color: var(--secondary-00, #fff);
  border-radius: 3px;
  padding: 2rem;
  box-shadow: 0 2px 4px #c9c9c980;
}

.floating-panel-content h3 {
  margin-top: 0;
}

.page-content {
  min-height: calc(100vh - 4.25rem);
  margin-top: 4.25rem;
  display: flex;
  position: relative;
}

.left-nav-style .left-nav .blx-vertical-tabs {
  padding: 26px 20px 0 10px;
}

.left-nav-style .left-nav .blx-vertical-tabs .blx-active {
  color: #003f2d;
}

.main-page-content {
  flex-grow: 1;
}

.editor-content {
  margin-left: 7rem;
  margin-right: -3.2rem;
  padding: 1rem;
}

@media print {
  .editor-content {
    margin-left: 2rem;
    margin-right: 2rem;
  }
}

.project-page .left-nav {
  position: initial;
}

.project-page .main-page-content {
  margin-left: 0;
}

.left-nav {
  width: 10rem;
}

@media print {
  .left-nav {
    display: none;
  }
}

.page-back-link {
  color: var(--information, #6f6bf8);
  font-size: 14px;
  font-weight: 500;
  text-decoration: none;
  display: flex;
}

.page-back-link:hover {
  color: #144e3e;
}

.page-name {
  max-width: 40rem;
  height: 2.5rem;
  margin: 0 2rem -.25rem 1rem;
}

.small-text {
  text-align: left;
  margin: 0;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
}

.navbar-item {
  transition: unset;
}

.navbar-item:hover .user-avatar-and-name {
  color: initial;
}

.error-page-element {
  margin-bottom: 1rem;
}

.top-nav {
  position: relative;
}

.tooltip.is-tooltip-active.population-section-tooltip:before, .tooltip.is-tooltip-active.population-section-tooltip:after {
  white-space: nowrap;
  min-width: auto;
}

.project-information-breakdown {
  margin-top: -2.5rem;
}

.blx-primary:hover, .blx-danger:hover {
  background-color: #144e3e !important;
  border-color: #144e3e !important;
}

#spacerProLoginContainer .spacer-pro .page-content {
  height: 100vh;
  margin-top: 0 !important;
}

.panel:first-of-type {
  margin-top: 2rem;
}

.panel:last-of-type {
  margin-bottom: 2rem;
}

.panel.is-open:not(:first-of-type) {
  margin-top: 4rem;
}

.panel.is-open:not(:last-of-type) {
  margin-bottom: 4rem;
}

.panel:not(.is-open) + .panel:not(.is-open) {
  border-top: 0;
}

.panel-header {
  cursor: pointer;
  background-color: unset;
  border: none;
  padding: .5rem 0;
}

.panel-header .blx-icon {
  vertical-align: bottom;
}

.panel-title {
  display: unset;
}

.panel-section {
  padding: 1rem 0;
}

.panel-section-border-top, .panel-section:not(.panel-section-highlighted) + .panel-section:not(.panel-section-no-border-top) {
  border-top: 1px solid var(--light-01, #d9e1e0);
}

.panel-section-highlighted {
  background-color: var(--light-00, #f6f8f6);
  border-radius: 7px;
}

.panel-subheader {
  margin: 1.5rem 1.5rem 1rem;
}

.panel-subheader .tooltip {
  margin-left: .5rem;
}

@media print {
  .panel-body-print {
    border: 1px solid var(--light-01, #d9e1e0);
    border-radius: 7px;
  }

  .panel-body-no-print-border {
    border: 0 !important;
  }
}

.panel-section-body, #panel-body-program-section.panel-body .panel-section-body {
  padding: .5rem 2rem;
}

#locations-section .panel-section {
  border-top: 0;
}

.user-avatar-and-name {
  align-items: center;
  display: flex;
  position: relative;
}

.user-avatar-and-name .user-name {
  height: 27px;
  padding-top: .5rem;
  padding-left: 2.5rem;
}

.user-avatar-and-popover-menu {
  margin-bottom: 1.5rem !important;
}

.user-avatar-and-popover-menu .user-avatar-and-name .user-name {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.user-avatar-and-popover-menu .user-name {
  height: auto;
  margin-left: 36px;
  padding: 0;
}

.user-avatar-and-popover-menu .user-avatar-engagement-manager-tag {
  margin-bottom: 0;
  margin-left: 37px;
  padding-left: 0;
}

.user-avatar-and-popover-menu .user-avatar-and-name-directory-field {
  margin-left: 0;
}

.user-avatar {
  color: #1a1a1a;
  background-color: #cbd1d3;
  border-radius: 10%;
  justify-content: center;
  align-items: center;
  width: 27px;
  height: 27px;
  font-size: .75rem;
  line-height: 1.5rem;
  display: flex;
  position: absolute;
}

.engagement-manager-avatar {
  border: 2px solid var(--brand-00, #a2ffbb);
}

.navbar-avatar-and-name .user-avatar-and-name .user-name {
  padding-left: .5rem;
}

.navbar-avatar-and-name .user-avatar {
  position: unset;
}

.navbar-avatar-and-name .blx-dropdown-menu {
  border-radius: 7px;
}

.user-avatar-engagement-manager-tag {
  overflow: unset;
  margin-top: -.25rem;
  padding-left: 2.5rem;
}

.user-avatar-engagement-manager-tag .blx-popover .blx-dropdown-menu {
  min-width: 200px;
}

.column.is-one-fifth .blx-tag {
  width: 140px;
}

.user-avatar-and-name-directory-field {
  margin-left: -1.25rem;
}

.current-state-panel .columns {
  width: 100%;
}

.current-state-panel .scenario-editor-total-row {
  margin-top: initial;
  border-bottom: 0 !important;
}

.current-state-panel .scenario-editor-header-row .column:first-child, .current-state-panel .scenario-editor-total-row .column:first-child {
  margin-left: 16px;
}

.current-state-panel .panel {
  margin-top: 4rem;
}

.current-state-panel .panel-title {
  font-size: 16px;
}

.current-state-panel .panel-body {
  border: initial;
}

.current-state-panel .panel-body .space-analysis-values-container:not(:last-child) {
  border-bottom-left-radius: initial;
  border-bottom-right-radius: initial;
}

.current-state-panel .panel-body .space-analysis-values-container:not(:first-child) {
  border-top: initial;
  border-top-left-radius: initial;
  border-top-right-radius: initial;
}

.current-state-panel li {
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 130px;
  overflow: hidden;
}

.current-state-panel .blx-horizontal-tabs {
  margin-top: .5rem;
}

.current-state-panel button.blx-icon-back, .current-state-panel button.blx-icon-forward {
  margin-top: 1rem;
  margin-left: -.25rem;
}

.current-state-panel .add-button .blx-button.blx-secondary {
  padding: .75rem;
}

.current-state-panel .location-tab-and-delete .blx-tab-item {
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-left: .75rem;
  overflow: hidden;
}

.current-state-panel .location-tab-and-delete .delete-location-button {
  visibility: hidden;
}

.current-state-panel .location-tab-and-delete:hover {
  background-color: var(--light-00, #f6f8f6);
  border-radius: 7px;
}

.current-state-panel .location-tab-and-delete:hover .delete-location-button {
  visibility: visible;
  z-index: 1;
  margin-top: .5rem;
}

.current-state-panel .location-tab-and-delete:hover input[type="radio"]:hover + .blx-tab-item:after {
  background-color: #0000;
}

.current-state-panel .location-tab-and-delete:hover .blx-tab-item {
  color: var(--dark-00, #2b3836);
}

.current-state-panel-name {
  color: var(--light-02, #aab7b2);
  margin-top: -2rem;
}

.current-state-scenario-panel {
  margin-left: 3.6rem;
}

.current-state-scenario-panel .blx-tag {
  margin-top: .5rem;
  font-size: 12px;
}

.edit-current-state-button {
  margin: .5rem 0 0 1rem;
}

.upload-space-analysis-button {
  margin: 3rem 5rem;
}

.population-header-title {
  text-transform: uppercase;
  font-weight: 400;
}

.population-total-row {
  font-weight: 500;
}

.current-state-create-text {
  color: #6c6d6d;
  white-space: pre;
  text-align: center;
  margin: 10% 0;
}

.flex.flex-col.flex-align-items-center .blx-button.blx-primary {
  margin-bottom: 20%;
}

.single-mult-toggle {
  padding: 1rem 0;
}

.single-mult-toggle .blx-toggle {
  margin-top: 1.5rem;
  margin-left: 3rem;
}

.add-population-button .blx-button.blx-secondary {
  margin-top: 0;
  margin-bottom: 0;
}

.population-display-border {
  border-bottom: 1px solid #dbdbdb;
}

.columns.is-marginless.population-display-border {
  line-height: 10px;
}

.current-state-panel {
  padding: 1rem;
}

.current-state-panel h5 {
  font-weight: 400;
}

.space-analysis-values-container {
  border: 1px solid #dbdbdb;
  border-radius: 7px;
  padding: 20px;
}

.space-analysis-values-container .space-analysis-label {
  white-space: nowrap;
  color: var(--light-02, #aab7b2);
  font-size: .9rem;
}

.space-analysis-values-container .space-analysis-value {
  font-size: 1.15rem;
  font-weight: 500;
}

.space-analysis-source-link-wrapper {
  margin-top: 1.5rem;
  margin-bottom: unset;
  font-weight: 400;
}

.space-analysis-source-link {
  font-size: inherit;
  font-weight: inherit;
  overflow-wrap: break-word;
  text-decoration: underline;
}

.space-analysis-updated-at {
  margin-top: unset;
  margin-bottom: 1.5rem;
}

.space-analysis-input-container .blx-text-field {
  width: 100%;
  min-width: 100px;
  margin-bottom: 0;
}

.space-analysis-input-container .blx-text-field input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.space-analysis-input-container .blx-text-field input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

.space-analysis-input-container .blx-text-field .blx-ui-text, .space-analysis-input-container .blx-text-field .blx-invalid-input-message {
  white-space: nowrap;
}

.space-analysis-input-container .blx-text-field .blx-text-field-suffix {
  color: var(--light-02, #aab7b2);
  right: 20px;
}

.space-analysis-input-container .usf-to-seats {
  white-space: nowrap;
  margin-top: .25rem;
  margin-left: 3rem;
}

.space-analysis-input-container hr {
  background-color: #dbdbdb;
  margin-left: -.5rem;
}

.space-analysis-input-container .blx-toggle.blx-with-margin {
  width: 150%;
  margin-left: 0;
}

.open-percentage-input {
  position: relative;
}

.error-asterisk {
  color: var(--danger-02, #ad4626);
}

.sapce-analysis-divider {
  background-color: #bac2be;
  width: 0;
  margin: 2rem 1rem;
  padding-left: 1px;
  position: relative;
}

.sapce-analysis-divider-text {
  color: #bac2be;
  background-color: #fff;
  position: absolute;
  top: 50%;
  right: -8px;
}

.scenario-panel:hover {
  cursor: pointer;
}

.scenario-panel-sortable {
  width: 100%;
  padding: 1.5rem 0;
}

.disabled-list {
  opacity: .5;
  pointer-events: none;
}

.all-scenarios .blx-sortable-list-item:last-of-type {
  margin-bottom: 3rem;
}

.all-scenarios .blx-sortable-list-item:hover .blx-icon-drag {
  visibility: visible;
  cursor: grab;
}

.all-scenarios .blx-sortable-list-item:hover .scenario-panel {
  background-color: var(--secondary-01, #ecf5f1);
}

.all-scenarios .blx-sortable-list-item.blx-dragging {
  background-color: var(--light-00, #f6f8f6);
  border: 2px solid var(--light-01, #d9e1e0);
  padding-right: 2rem;
}

.blx-sortable-list {
  justify-content: flex-end;
}

.blx-sortable-list .blx-sortable-list-item {
  flex-direction: row-reverse;
  justify-content: flex-end;
  align-items: center;
  display: flex;
}

.blx-sortable-list .blx-sortable-list-item .blx-icon-drag {
  visibility: hidden;
  background-image: url("drag-drop.9197f8d2.svg");
  margin-right: 2rem;
}

.all-scenarios {
  width: 90%;
}

.add-scenario-button {
  justify-content: flex-end;
  margin-bottom: 1.5rem;
  display: flex;
}

.scenario-panel-name {
  margin-top: -1.3rem;
}

.scenario-menu {
  margin-top: -1.5rem;
  margin-left: -2rem;
}

.tag-and-edit {
  margin: 1rem 0;
}

.tag-and-edit .blx-tag {
  font-size: 12px;
}

.tag-title {
  margin-top: 1rem;
  margin-left: -1rem;
  font-size: 1rem;
  font-weight: bold;
  position: absolute;
}

.scenario-summary-number {
  font-weight: 500;
}

.rename-scenario-field .blx-text-field {
  max-width: 15rem;
}

.edit-tag-dropdown {
  margin: 1rem 0;
}

.save-edit-button {
  max-width: 3rem;
}

.list-section {
  margin: 10px 0;
}

.list-section .space-breakdown-list-row {
  margin: 0;
}

.list-section .space-breakdown-list-row .scenario-main-summary-analysis:nth-child(2), .list-section .space-breakdown-list-row .scenario-main-summary-analysis:nth-child(3) {
  background-color: #f5f7f7;
  margin: 0 10px;
}

.scenario-main-summary-analysis {
  text-align: center;
  width: 120px;
  padding: 5px;
}

.scenario-main-summary-recommendation {
  width: 33%;
}

.current-recommendation {
  background-color: #f5f7f7;
  padding: 0 1rem .5rem;
  display: inline-block;
}

.summary-checkbox {
  margin-top: 2rem;
}

.tooltip {
  padding: 0;
}

.tooltip:hover:before, .tooltip.is-tooltip-active:before {
  font-size: 14px;
}

.tooltip.has-icon {
  width: 2rem;
}

.text-field-with-tooltip {
  align-items: flex-end;
  display: flex;
}

.text-field-with-tooltip .sharing-ratio-tooltip-wrapper {
  align-self: stretch;
  align-items: center;
  margin-top: 24px;
  display: flex;
}

.text-field-with-tooltip .sharing-ratio-tooltip-wrapper.has-invalid-message-margin {
  margin-bottom: 16px;
}

.text-field-with-tooltip .tooltip {
  margin-left: .8rem;
}

.template-panel {
  margin-top: 2rem;
  padding: 2rem .7rem;
}

.template-panel h4 {
  width: 45%;
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 1rem;
}

.template-panel .blx-modal h4 {
  margin-left: initial;
}

.template-panel .profile-template-row {
  border-top: 1px solid #dbdbdb;
}

.template-panel .profile-template-row:last-of-type {
  border-bottom: 1px solid #dbdbdb;
}

.template-panel .profile-template-row .blx-popover .blx-popover-menu:before, .template-panel .profile-template-row .blx-popover .blx-popover-menu:after {
  content: none;
}

.template-panel .profile-template-row:hover {
  background-color: var(--secondary-01, #ecf5f1);
}

.template-panel .template-metrics-table {
  padding: 0;
}

.template-panel .template-metrics-table .metrics-table {
  border: none;
  margin-top: 0;
}

.template-panel .template-popover-menu {
  float: left;
}

.template-panel .template-title {
  padding-bottom: 0;
}

.template-panel .template-name {
  margin-top: .25rem;
  margin-bottom: 0;
  padding-left: 2.25rem;
}

.template-panel .save-edit-button {
  margin-top: 1rem;
}

.template-panel .location-template-preview {
  width: initial;
  background-color: initial;
  box-shadow: initial;
  border: none;
  margin-top: 1rem;
  margin-left: .2rem;
}

.new-template-content h3, .new-template-content .blx-text-field {
  margin-bottom: 2rem;
}

.new-template-content .blx-button.blx-primary {
  float: right;
}

.page-content.container.template-editor-page-content {
  margin-left: 25px;
  display: grid;
}

#template-metrics-table .panel-body {
  border: none;
}

#template-metrics-table .metrics-table {
  width: 25%;
}

.warning-border input, .warning-border input:hover {
  border: 1px solid var(--warning, #fdb100);
}

.metrics-table, .scenario-editor-table, .location-population-box, .panel-body, .population-table {
  border: 1px solid var(--light-01, #d9e1e0);
  border-radius: 7px;
}

.scenario-editor-page-content .left-nav .blx-vertical-tabs, .template-editor-page-content .left-nav .blx-vertical-tabs {
  position: relative;
  left: 1rem;
}

.scenario-header.container {
  width: 70%;
}

.scenario-header.container .blx-primary {
  height: 30px;
  padding: 0 18px;
}

.autosave-text {
  width: 175px;
}

.autosave-text em {
  white-space: nowrap;
}

.scenario-header .navbar-item:hover, .scenario-header .navbar-item.is-active {
  color: #000;
}

.navbar .scenario-header .navbar-item .blx-primary {
  pointer-events: auto;
}

.navbar-menu .blx-horizontal-tabs {
  border-left: none;
  padding-left: 0;
}

.navbar-menu .navbar-start {
  flex-grow: 1;
  justify-content: center;
}

.navbar-menu .navbar-start .navbar-item {
  padding-left: 0;
}

.navbar-menu .navbar-end {
  width: 175px;
}

.scenario-header-name {
  max-width: 250px;
}

@media print {
  .scenario-header-name {
    max-width: 100%;
  }
}

.units-toggle-label {
  margin-right: 13rem;
}

.template-section .blx-button {
  height: 40px;
  margin-bottom: 0;
}

.assigned-seating-toggle .blx-toggle {
  margin: initial;
  margin-top: .5rem;
  margin-bottom: 1rem;
  margin-left: 0;
}

.scenario-tabs {
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-height: 5rem;
  margin: 0 1rem;
  display: flex;
}

.scenario-tabs li {
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 130px;
  overflow: hidden;
}

.scenario-tabs-border {
  border-bottom: 1px solid var(--light-01, #d9e1e0);
}

.headcount-size-metrics {
  margin: 0 1.5rem 2rem 2rem;
}

.collaboration-ratio-input .blx-text-field-container {
  width: 100%;
}

.population-section-header {
  background-color: var(--light-00, #f6f8f6);
  border: 1px solid var(--light-01, #d9e1e0);
  z-index: 35;
  border-radius: 3px;
  height: 4rem;
  margin-top: 0;
  position: fixed;
  top: calc(4.25rem + 6px);
  left: 0;
  right: 0;
  box-shadow: 0 2px 4px #4a4a4a1a;
}

.population-section-header .population-name {
  margin-top: -.25rem;
  font-size: 1rem;
}

.population-section-header .population-description {
  margin-top: -.25rem;
  font-size: .9rem;
}

.population-section-header .count-column {
  padding-top: .3rem;
}

.population-section-header .count-column-header {
  color: var(--light-02, #aab7b2);
  font-size: .9rem;
}

.population-section-header .count-column-value {
  font-weight: bold;
}

.population-section-header .panel-section .panel-section-body {
  padding: 0;
}

#panel-body-populations-section .panel-section-highlighted .metrics-table {
  width: 35%;
  margin-top: -1.5rem;
  margin-left: 10rem;
}

.location-population-box-container {
  min-height: 160px;
}

.location-population-box {
  padding: 1rem;
}

.location-population-name {
  margin: 0 0 1rem;
}

.unit-additional-info {
  margin: 0 0 0 .5rem;
}

.manage-locations-modal .blx-modal {
  width: 720px;
}

.manage-locations-modal .blx-icon-forward, .manage-locations-modal .blx-icon-back {
  z-index: 1;
  position: relative;
}

.manage-locations-modal .blx-icon-forward {
  top: 2.6rem;
  left: 2.5rem;
}

.manage-locations-modal .blx-icon-back {
  top: 4rem;
  left: .5rem;
}

.manage-locations-modal .blx-text-field {
  width: 85%;
}

.manage-locations-modal .blx-checkmark {
  top: -8px;
}

.manage-locations-modal .scenario-editor-row {
  padding-left: 0;
  border-color: var(--light-01, #d9e1e0) !important;
}

.manage-locations-modal .location-rows {
  max-height: 21.5rem;
  overflow: auto;
}

.manage-locations-modal .delete-row-button {
  margin-top: 8px;
}

.manage-locations-modal .delete-row-button .blx-button {
  border-radius: 12px;
  width: 25px;
  min-width: 8px;
  height: 25px;
  padding: 1px;
}

.manage-locations-modal .delete-row-button .blx-icon-button .blx-icon-close {
  width: 14px;
  height: 14px;
  top: 11px;
  left: 1px;
}

.manage-locations-modal .add-item-button {
  margin-left: 1rem;
}

.manage-locations-modal .blx-modal-buttons {
  margin-top: 0;
}

.scenario-editor-table {
  margin-bottom: 2rem;
  padding: 0 0 1rem;
  position: relative;
}

.template-editor-page-content .template-editor-example-headcount-outline {
  border: 1px solid #7c8c88;
  border-radius: 7px;
  position: absolute;
  top: -10px;
  right: 4rem;
}

.template-editor-page-content .blx-hidden {
  display: initial;
  visibility: hidden;
  height: 0;
}

.scenario-editor-page-content .template-editor-example-headcount-outline {
  display: none;
}

#locations-section.panel.is-open {
  padding-bottom: 22.5rem;
}

.unit-toggle .blx-toggle.blx-with-margin {
  margin-top: 0;
  margin-left: 0;
}

.floor-count-input {
  margin-right: -1rem;
}

.floor-count-input .blx-text-field {
  width: initial;
}

.floor-count-input .blx-text-field .blx-text-field-container {
  width: 6rem;
}

.scenario-editor-row {
  border-bottom: 1px solid var(--light-01, #d9e1e0);
  break-inside: avoid;
  justify-content: space-between;
  align-items: flex-start;
  padding: .7rem 1rem;
  display: flex;
}

.scenario-editor-row .is-thin {
  text-align: center;
  width: 2.5rem;
}

.scenario-editor-row .tinker-link, .scenario-editor-row .tinker-link-broken {
  align-items: center;
  height: 40px;
  display: inline-flex;
}

.scenario-editor-row .tinker-icon {
  background: url("spacer-pro-link-icon.df18d55b.svg") center no-repeat;
  width: 24px;
  height: 24px;
  display: inline-block;
}

.scenario-editor-row .tinker-broken-icon {
  background: url("spacer-pro-link-icon-broken.d1108112.svg") center no-repeat;
  width: 24px;
  height: 24px;
  display: inline-block;
}

.scenario-editor-row .is-name-field .blx-icon-drag {
  background-image: url("drag-drop.9197f8d2.svg");
}

.scenario-editor-row .is-name-field .blx-icon {
  height: 18px;
}

.scenario-editor-row .is-planning-year-dropdown {
  width: 9rem;
}

.scenario-editor-row .blx-dropdown {
  min-width: auto;
}

.scenario-editor-row .is-growth-type-dropdown {
  width: 13rem;
}

.scenario-editor-row .is-growth-type-dropdown .blx-dropdown-wrapper, .scenario-editor-row .is-growth-type-dropdown .blx-dropdown {
  min-width: 100%;
}

.scenario-editor-row .is-text-field-without-label-width {
  width: 4rem;
}

.scenario-editor-row .is-text-field-with-label-width {
  width: 7rem;
}

.scenario-editor-row .is-text-field-without-label-width .total-border {
  width: 100%;
}

.scenario-editor-row .is-text-field-without-label-width .is-text-field-with-label-width {
  width: auto;
}

.scenario-editor-row .is-text-field-with-label-width .blx-text-field .blx-text-field-container input, .scenario-editor-row .is-text-field-without-label-width .blx-text-field .blx-text-field-container input {
  padding: 11px 2px 11px 8px;
}

.scenario-editor-row .is-toggle-width {
  width: 8rem;
}

.scenario-editor-row .is-sample-toggle-width {
  width: 18rem;
}

.scenario-editor-row .is-slider-width {
  width: 15rem;
}

.scenario-editor-row .is-collaboration-ratio-width {
  width: 10rem;
}

.scenario-editor-row .is-calculated-total-display-width {
  width: 15rem;
}

.scenario-editor-row .blx-invalid-input-message {
  position: initial;
}

.scenario-editor-row:not(.scenario-editor-total-row) .grey-info-text {
  margin-left: 1rem;
}

.scenario-editor-row .collaboration-row-ratio .blx-text-field .blx-text-field-prefix, .scenario-editor-row .collaboration-row-ratio .blx-text-field .blx-text-field-suffix {
  color: var(--light-02, #aab7b2);
}

.scenario-editor-row .collaboration-row-ratio .blx-text-field .blx-text-field-prefix ~ input {
  padding-left: 35px;
}

.scenario-editor-row .collaboration-row-ratio .blx-text-field .blx-text-field-prefix, .scenario-editor-row .collaboration-row-ratio .blx-text-field .blx-text-field-suffix {
  font-size: 14px;
}

.scenario-editor-row .collaboration-row-ratio .blx-text-field .blx-text-field-prefix {
  left: 20px;
}

.scenario-editor-row .collaboration-row-ratio .blx-text-field .blx-text-field-suffix {
  right: 20px;
}

.scenario-editor-row .collaboration-row-ratio .blx-text-field input {
  padding-bottom: 10px;
}

.scenario-editor-row .collaboration-row-ratio .blx-text-field input::-webkit-inner-spin-button {
  display: none;
}

.scenario-editor-row:hover .delete-row-button {
  visibility: visible;
}

.scenario-editor-row:hover {
  background-color: var(--secondary-01, #ecf5f1);
}

.scenario-editor-row:hover .delete-template-row-button {
  visibility: visible;
}

.scenario-editor-row:hover .blx-icon-drag {
  visibility: visible;
  cursor: grab;
}

.scenario-editor-row:hover .tinker-icon {
  background: url("spacer-pro-link-icon-hover.807a0fd0.svg") center no-repeat;
  width: 24px;
  height: 24px;
  display: inline-block;
}

.scenario-editor-row:hover .tinker-broken-icon {
  background: url("spacer-pro-link-icon-broken-hover.5b169654.svg") center no-repeat;
  width: 24px;
  height: 24px;
  display: inline-block;
}

.scenario-editor-header-row {
  border-bottom: 1px solid var(--light-01, #d9e1e0);
  background-color: var(--light-00, #f6f8f6);
  align-items: flex-end;
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 1rem;
}

.scenario-editor-header-row .tooltip:before {
  text-transform: none;
  font-weight: 400;
}

.scenario-editor-row.scenario-editor-header-row:hover {
  background-color: var(--light-00, #f6f8f6);
}

.scenario-editor-total-row {
  align-items: center;
  padding-bottom: 1rem;
  font-weight: 500;
}

.space-program-row .blx-text-field, .scenario-editor-row .blx-text-field {
  width: 100%;
  min-width: 65px;
  margin: 0;
}

.delete-row-button {
  visibility: hidden;
  margin-left: 3px;
}

.delete-template-row-button {
  visibility: hidden;
  margin-right: .5rem;
}

.delete-template-row-button input {
  margin-bottom: -.25rem;
}

.blx-icon-drag {
  visibility: hidden;
}

.negative-margin {
  margin-left: -.5rem;
}

.row-toggle .blx-toggle {
  margin: 0;
}

.row-toggle .blx-toggle label {
  line-height: inherit;
}

.row-toggle .blx-toggle .blx-toggle-container {
  font-size: 12px;
}

.row-toggle .blx-toggle .blx-toggle-container input:focus ~ .blx-toggle-text {
  padding: 9px;
}

.hidden-slider-text .slider-wrapper .slider-text {
  visibility: hidden;
  height: 0;
}

.allocation-slider .slider-wrapper .slider-custom {
  -webkit-appearance: none;
  -webkit-user-select: none;
  cursor: pointer;
  border-radius: 20px;
  -webkit-transition: all .2s;
}

.allocation-slider .slider-wrapper .slider-custom:focus {
  outline: none;
}

.allocation-slider .slider-wrapper .slider-custom::-webkit-slider-runnable-track {
  width: 100%;
  height: 5px;
}

.allocation-slider .slider-wrapper .slider-custom::-webkit-slider-thumb {
  -webkit-appearance: none;
  background: #009f6f;
  border-radius: 10px;
  width: 20px;
  height: 20px;
  margin-top: -.5rem;
}

.slider-custom {
  width: 10rem;
}

.allocation-percentage {
  justify-content: left;
  margin-left: .5rem;
}

.allocation-percentage .blx-text-field {
  min-width: 48px;
}

.allocation-percentage .blx-text-field .blx-text-field-container input {
  padding: 11px 2px 11px 8px;
}

.allocation-percentage .percent-symbol {
  margin-left: .25rem;
}

.total-border {
  border: solid 1px var(--light-02, #aab7b2);
  vertical-align: middle;
  border-radius: 7px;
  justify-content: space-between;
  align-items: center;
  padding: .5rem 1rem;
  display: inline-flex;
}

.sf-label {
  white-space: pre;
  margin-left: .3rem;
}

.scenario-editor-total-row .is-slider-width {
  text-align: end;
}

.delete-location-button {
  margin-left: .3rem;
  position: absolute;
}

.delete-location-button .blx-button .blx-icon {
  margin-top: -.7rem;
  left: 3px;
}

.location-editor-row {
  align-items: baseline;
  width: 100%;
  position: relative;
}

.location-name-text-field-container {
  margin-left: 1.8rem;
}

.location-name-text-field-container .blx-text-field {
  min-width: auto;
  margin-right: 0;
}

.size-field input.blx-invalid {
  border: 1px solid #fc5328;
}

.size-field.tooltip:before {
  min-width: 16rem;
  max-width: 16rem;
}

.add-row-button {
  margin-left: 1.7rem;
}

#growth-section .is-text-field-without-label-width {
  width: 7rem;
}

.growth-rate-input.blx-text-field input {
  padding-right: 45px;
}

.temporary-resource-row .scenario-editor-row {
  justify-content: initial;
}

.temporary-resource-row .scenario-editor-row .is-toggle-width {
  margin-left: 2.75rem;
}

.dropdown-row-name-field {
  width: 95%;
}

.dropdown-row-name-field .blx-dropdown-custom-trigger .blx-text-field {
  max-width: initial;
  width: 260px;
}

.dropdown-row-name-field .blx-dropdown-item:last-child button {
  color: #aab7b2;
}

.temp-name-field .blx-text-field {
  max-width: initial;
  width: 20rem;
}

#growth-section .is-text-field-with-label-width .blx-text-field .blx-text-field-container input, .space-program-summary-table .is-text-field-with-label-width .blx-text-field .blx-text-field-container input {
  padding: 11px 8px;
}

#growth-section .is-text-field-with-label-width .blx-text-field:hover .blx-text-field-suffix, #growth-section .is-text-field-with-label-width .blx-text-field:focus-within .blx-text-field-suffix, .space-program-summary-table .is-text-field-with-label-width .blx-text-field:hover .blx-text-field-suffix, .space-program-summary-table .is-text-field-with-label-width .blx-text-field:focus-within .blx-text-field-suffix {
  visibility: hidden;
}

#growth-section .scenario-editor-total-row {
  margin-top: 0;
}

.scenario-options {
  align-items: center;
  display: flex;
  position: absolute;
  top: 32px;
  right: 0;
}

.scenario-positive-margin {
  width: 240px;
  padding-left: 20px;
}

.temporary-resource-row .is-name-field .scenario-positive-margin .temp-name-field .blx-text-field, .temporary-shared-focus-row .is-name-field .scenario-positive-margin .temp-name-field .blx-text-field {
  width: 240px;
}

.tinker-toggle img {
  height: 16px;
  margin: 0 .5rem 0 0;
}

.tinker-toggle .close-tinker-icon {
  height: 16px;
}

.tinker-toggle .blx-toggle {
  vertical-align: middle;
  display: inline-flex;
}

.tinker-toggle .blx-toggle-text {
  width: 2.5rem;
  display: flex;
}

.tinker-link-broken img {
  vertical-align: text-bottom;
}

.is-tinker-text-field {
  width: 6rem;
  margin-right: .5rem;
  display: flex;
}

.display-quantity-and-size-field {
  background-color: #fff;
  flex-direction: column;
  width: 100%;
  display: flex;
}

.display-tinker-field {
  vertical-align: middle;
  display: inline-flex;
}

.scenario-editor-row .is-me-tinker-section, .scenario-editor-row .is-collab-resource-tinker-section {
  align-items: center;
  width: 16rem;
  display: flex;
}

.scenario-editor-row .is-collab-resource-tinker-section .is-tinker-text-field {
  width: 5rem;
  margin-right: 0;
}

.scenario-editor-row .is-collab-resource-tinker-section .blx-toggle {
  margin-left: .5rem;
}

.scenario-editor-row .is-collab-resource-tinker-section.flex-start-imp {
  align-items: flex-start !important;
}

.template-editor-page-content .is-resource-tinker-section {
  width: 18rem;
  display: flex;
}

.template-delete-btn {
  position: absolute;
  right: 10px;
}

.template-dropdown .blx-dropdown-trigger {
  width: 12.5rem;
}

.template-dropdown .blx-dropdown-list {
  overflow: visible;
}

.template-dropdown .blx-dropdown-list .blx-dropdown-item .template-dropdown-list-container .blx-dropdown-menu {
  visibility: hidden;
}

.template-dropdown .blx-dropdown-list .blx-dropdown-item:hover {
  position: relative;
}

.template-dropdown .blx-dropdown-list .blx-dropdown-item:hover .template-dropdown-list-container .blx-dropdown-menu {
  visibility: visible;
}

.template-dropdown .blx-dropdown-list .blx-dropdown-item:hover .template-dropdown-list-container .blx-dropdown-menu .blx-dropdown-list {
  max-height: none;
}

.template-dropdown .blx-dropdown-list .blx-dropdown-item:hover .template-dropdown-list-container .blx-dropdown-menu .nested-template-dropdown-item .template-preview-wrapper {
  visibility: hidden;
}

.template-dropdown .blx-dropdown-list .blx-dropdown-item:hover .template-dropdown-list-container .blx-dropdown-menu .nested-template-dropdown-item:hover .template-preview-wrapper {
  visibility: visible;
}

.template-preview-wrapper {
  margin-top: -5rem;
  position: absolute;
  left: 105%;
}

.template-preview-wrapper .metrics-table {
  background-color: #fff;
}

.template-dropdown-list-container .blx-dropdown-menu {
  border-radius: 7px;
  min-width: 15rem;
  margin-left: 92%;
  padding: 8px 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.template-dropdown-list-container .blx-dropdown-menu, .template-dropdown-list-container .template-preview-wrapper {
  visibility: hidden;
  transition: visibility;
}

.template-dropdown .blx-dropdown-menu {
  overflow: initial;
}

.template-dropdown .blx-dropdown-item {
  overflow: initial;
  position: static;
}

.template-dropdown .blx-dropdown-item:hover button .template-dropdown-list-container .blx-dropdown-menu {
  visibility: visible;
  transition: visibility;
}

.template-dropdown .blx-dropdown-text {
  min-width: 115px;
  display: flex;
}

.template-dropdown-list-container.open-left .blx-dropdown-menu {
  margin-left: -131%;
}

.template-dropdown-list-container.open-left .blx-dropdown-menu .template-preview-wrapper {
  margin-left: -.5rem;
  left: 0;
  transform: translateX(-100%);
}

.nested-template-dropdown-item:hover {
  background-color: var(--primary-00, #009f6f);
  color: #fff !important;
}

.nested-template-dropdown-item:hover .template-preview-wrapper {
  visibility: visible;
  transition: visibility;
}

.nested-template-dropdown-item .template-preview-wrapper .blx-h5 {
  color: var(--dark-00, #2b3836);
}

.nested-template-dropdown-item .template-preview-wrapper .metrics-table {
  padding: 1rem 3rem;
}

.nested-template-dropdown-item .template-preview-wrapper.last-updated-tag .metrics-table {
  padding: 1rem 3rem 3rem;
}

.nested-template-dropdown-item .template-preview-wrapper.last-updated-tag p {
  margin-top: -2rem;
  padding-right: 1rem;
}

.nested-template-dropdown-item .location-template-preview-wrapper.last-updated-tag p {
  margin-top: initial;
  padding-right: initial;
}

.nested-template-dropdown-item .location-template-preview-wrapper.last-updated-tag p.blx-h5-small.blx-inactive.has-margin-left-2.has-margin-bottom-7 {
  margin-top: -2.75rem;
}

.location-template-preview-wrapper {
  margin-top: -2rem;
  left: 110%;
}

.location-template-preview {
  box-shadow: 0 3px 6px 0 var(--secondary-01, #ecf5f1);
  border: 1px solid var(--secondary-01, #ecf5f1);
  background-color: #fff;
  border-radius: 7px;
  width: 30rem;
  padding: .5rem 2rem 2rem;
  color: var(--dark-00, #2b3836) !important;
}

.location-template-preview .location-template-preview-part-name {
  border-bottom: 1px solid var(--secondary-01, #ecf5f1);
}

.space-rec-val {
  margin-top: 1.5rem;
  margin-bottom: 0;
}

@media print {
  .space-headcount-print-flex-overrides {
    flex-direction: column;
    align-items: flex-start;
  }
}

.scenario-summary-bar-chart {
  height: 50px;
}

.scenario-summary-bar-chart .program-bar-chart-bar {
  justify-content: center;
  align-items: center;
  height: 100%;
  display: flex;
}

.scenario-summary-bar-chart .program-bar-chart-segment-percentage {
  font-size: 1.5rem;
}

.scenario-summary-bar-chart-empty {
  background-color: var(--secondary-01, #ecf5f1);
  border: 1px solid var(--light-02, #aab7b2);
  align-items: center;
  padding: 0 25%;
  display: flex;
}

.scenario-summary-bar-chart-empty p {
  text-align: center;
  color: var(--light-02, #aab7b2);
}

.space-breakdown-list-circle {
  border-radius: 50%;
  width: 8px;
  height: 8px;
  margin: 0 1rem;
}

.space-breakdown-list-row {
  margin: 1rem 0;
}

.metrics-table {
  padding: 1rem;
}

@media print {
  .scenario-editor-page-content {
    max-width: 100%;
  }

  .page-content {
    margin: 0;
  }

  .page-content .editor-content {
    width: 100%;
    margin: 0;
  }

  .scenario-editor-page-content {
    margin-top: 0;
  }

  .scenario-editor-page-content .scenario-summary #summary-section .panel-section-body .scenario-main-summary-all-locations {
    flex-direction: column;
  }

  .scenario-editor-page-content .scenario-summary #summary-section .panel-section-body .scenario-main-summary-all-locations .scenario-main-summary {
    justify-content: space-between;
  }

  .scenario-editor-page-content .scenario-summary #summary-section .panel-section-body .scenario-main-summary-all-locations .scenario-main-summary .compare-current-selection {
    display: none;
  }
}

.grey-info-text {
  color: var(--light-02, #aab7b2);
  white-space: pre;
}

.space-program-summary-table .scenario-editor-row:not(:last-of-type), .space-program-summary-table .space-program-row:not(:last-of-type) {
  text-align: end;
  align-items: center;
}

.space-program-summary-table-label {
  border-bottom: 1px solid var(--light-01, #d9e1e0);
  margin: 0;
  padding: 1.5rem;
}

.scenario-summary .scenario-editor-row {
  padding: .2rem 1rem;
}

.scenario-summary .metrics-table {
  margin-top: 1rem;
}

.floor-count {
  margin-left: 5rem;
}

.entity-info-page .entity-name {
  color: var(--dark-00, #2b3836);
  margin-bottom: 1rem;
}

.entity-info-page hr {
  width: 300%;
  margin-left: -100%;
}

.entity-info-page .entity-info-projects {
  margin-top: 1rem;
}

.entity-info-page .entity-info-projects-title {
  color: var(--primary-00, #009f6f);
  margin: 1.5rem 0;
}

.entity-info-page .form-container .blx-text-field.display-only label {
  color: var(--dark-00, #2b3836);
  font-size: .75rem;
  font-weight: 400;
}

.client-info-page .industry-container {
  margin-bottom: 0;
}

.client-info-page .directory {
  max-width: 80%;
  margin-top: 2rem;
}

.client-info-page .client-contact-email {
  margin-left: 2rem;
}

.client-info-page .spoc-tag {
  margin-left: .7rem;
}

.client-info-page .client-information {
  margin-left: 2rem;
}

.client-info-page hr {
  margin-top: 1rem;
}

.client-info-page .form-container .blx-text-field {
  min-width: 0;
}

.user-info-page .user-subtitle {
  margin-bottom: 1.5rem;
  font-weight: 500;
}

.user-info-page .directory {
  max-width: 95%;
  margin-top: 2rem;
}

.my-profile-content {
  color: #1a1a1a;
  width: 380px;
  margin: 100px auto 0;
}

.my-profile-content .heading-medium {
  margin-bottom: 2rem;
}

.my-profile-content .my-details-block {
  margin-top: 3rem;
}

.my-profile-content .password-button {
  cursor: pointer;
  margin-top: 3rem;
  margin-left: .1rem;
}

.my-profile-content .password-button:focus {
  outline: none;
}

.my-profile-content .icon-up-arrow, .my-profile-content .icon-down-arrow {
  margin-right: .7rem;
}

.my-profile-content .password-button-label {
  margin-left: .5rem;
}

.my-profile-content .password-area .blx-text-field {
  margin-top: 1.5rem;
}

.project-form {
  width: 520px;
  margin: 0 auto 10rem;
}

.project-form .is-half-three-quarters {
  flex: none;
  width: 37.5%;
}

.project-form .form-submit-button {
  justify-content: flex-start;
}

.project-form .project-type-dropdown {
  padding-top: .8rem;
}

.project-form .project-description {
  width: 107%;
}

.project-form .zip-code label {
  width: 130%;
}

.new-client-form .blx-dropdown-menu {
  max-height: 230px;
  overflow: auto;
}

.new-client-modal .blx-modal-content {
  overflow: initial;
}

.blx-button {
  justify-content: center;
  align-items: center;
  font-family: Calibre Regular;
  display: flex;
  position: relative;
}

.blx-button.blx-primary {
  background: #003f2d;
}

.blx-button.blx-secondary {
  color: #003f2d;
  box-shadow: none;
  background-color: #fff !important;
  border: 1px solid #003f2d !important;
}

.blx-button.blx-secondary:hover, .navbar-items.blx-button.blx-secondary:hover {
  background-color: #003f2d14 !important;
}

.blx-link, a {
  color: #003f2d;
  font-size: 16px;
}

.blx-link svg path, a svg path, .blx-dropdown-trigger svg path {
  fill: #003f2d !important;
}

.blx-dropdown-trigger .blx-dropdown-text {
  color: #1a1a1a;
  font-family: Calibre Regular;
}

.spacer-pro-logo {
  letter-spacing: 1px;
  font-family: Calibre Regular;
  font-size: 18px;
  font-weight: 400;
}

.spacer-pro-logo .pro {
  font-family: Calibre Regular;
  font-size: 10px;
  font-weight: 400;
}

.sort-triangle-up {
  border-bottom: 5px solid #003f2d;
}

.sort-triangle-down {
  border-top: 5px solid #003f2d;
}

.blx-ui-small-caps {
  letter-spacing: 1.65px;
  color: #1a1a1a;
  font-family: Calibre Regular;
  font-size: 14px;
  font-weight: 400;
}

.blx-ui-div {
  color: #1a1a1a;
  font-size: 16px;
}

.blx-h5, h5 {
  font-family: Calibre Regular;
  font-size: 16px;
  font-weight: 400;
}

.blx-popover-trigger svg path {
  fill: #000 !important;
}

.blx-modal-overlay {
  background-color: #e5e5e5;
}

.blx-h3, h3 {
  letter-spacing: .005px;
  font-family: Calibre Regular;
  font-size: 23px;
  font-weight: 400;
  line-height: 24px;
}

.blx-modal-close svg path {
  fill: #003f2d !important;
}

.send-invitation {
  margin-left: 138px;
  width: 177px !important;
}

.my-profile {
  text-align: center;
}

.blx-icon-down svg path {
  fill: #003f2d !important;
}

.blx-submit .blx-button {
  width: 250px !important;
}

.blx-vertical-tabs input[type="radio"]:focus + .blx-tab-item, .blx-horizontal-tabs input[type="radio"]:focus + .blx-tab-item, .blx-vertical-tabs input[type="radio"]:checked + .blx-tab-item, .blx-horizontal-tabs input[type="radio"]:checked + .blx-tab-item {
  color: #003f2d;
}

.blx-h4, h4 {
  color: #1a1a1a;
  font-family: Calibre Regular;
  font-weight: 400;
}

.blx-vertical-tabs .blx-tab-item {
  color: #6c6d6d;
}

.left-nav .blx-vertical-tabs .blx-tab-item:focus, .left-nav .blx-vertical-tabs input[type="radio"]:checked + .blx-tab-item, .blx-tab-item:hover {
  color: #003f2d;
}

.blx-toggle .blx-toggle-container input:checked ~ .blx-toggle-text {
  background-color: #003f2d;
}

.is-name-field svg path, .blx-secondary svg {
  fill: #003f2d !important;
}

.blx-tab-item.blx-active:after, .blx-tab-item:hover:after {
  background-color: #003f2d !important;
}

input[type="radio"]:hover + .blx-tab-item {
  color: #003f2d !important;
}

input[type="radio"]:checked + .blx-tab-item:after {
  background-color: #003f2d !important;
}

.is-brand {
  font-family: Calibre Regular;
  text-decoration: none;
}

.is-brand:hover {
  color: #17e88f;
  text-decoration: none;
}

.blx-ui-text {
  font-family: Calibre Regular;
}

.blx-text-field > .blx-ui-text {
  color: #1a1a1a;
  font-size: 16px !important;
}

.project-type-dropdown > .blx-dropdown-wrapper > .blx-dropdown > .blx-ui-text, .form-section > .form-title {
  color: #1a1a1a;
  font-size: 16px;
}

.blx-dropdown-trigger .blx-dropdown-placeholder, .blx-dropdown-trigger .blx-dropdown-text, .blx-text-field input, .blx-text-field textarea {
  font-family: Calibre Regular;
  font-size: 16px;
}

.engagement-manager-avatar {
  text-transform: uppercase;
  border: none;
}

.blx-icon-up {
  background-image: url("up-arrow.0ea03634.svg");
}

.blx-icon-down {
  background-image: url("down-arrow.3a63abd4.svg");
}

.panel-section-highlighted, .panel-section {
  background-color: #fff;
}

.panel-title {
  color: #1a1a1a;
  font-size: 23px;
}

.grey-info-text {
  color: #6c6d6d;
}

.blx-toggle .blx-toggle-container input:hover ~ .blx-toggle-text {
  background-color: #17e88f;
  border: 1px solid #17e88f;
}

.allocation-slider .slider-wrapper .slider-custom {
  background-color: #003f2d !important;
}

.allocation-slider .slider-wrapper .slider-custom::-webkit-slider-thumb {
  background-color: #003f2d !important;
}

.blx-vertical-tabs input[type="radio"]:focus + .blx-tab-item, .blx-horizontal-tabs input[type="radio"]:focus + .blx-tab-item, .blx-vertical-tabs input[type="radio"]:checked + .blx-tab-item, .blx-horizontal-tabs input[type="radio"]:checked + .blx-tab-item {
  font-weight: 600;
}

.blx-text-field input[type="date"]:after {
  width: 23px;
  height: 24px;
  top: 7px;
  background-image: url("calendar_today.5534df20.svg") !important;
}

.form-title-new {
  color: #1a1a1a;
  font-size: 34px;
}

.blx-checkbox:active input[type="checkbox"]:checked ~ .blx-checkmark, .blx-checkbox:focus input[type="checkbox"]:checked ~ .blx-checkmark {
  background-color: #003f2d;
  border-color: #003f2d;
}

.blx-checkbox input[type="checkbox"]:focus + .blx-checkmark:before {
  content: none;
}

.blx-checkbox input[type="checkbox"]:checked:focus + .blx-checkmark {
  background-color: #003f2d;
}

.blx-checkbox input[type="checkbox"]:checked ~ .blx-checkmark {
  background-color: #003f2d;
  border-color: #003f2d;
}

.blx-checkbox:hover input[type="checkbox"]:checked ~ .blx-checkmark {
  background-color: #144e3e;
  border-color: #144e3e;
}

.navbar-brand > .navbar-item > .blx-button {
  background-color: #a03530 !important;
  border-color: #a03530 !important;
}

.navbar-brand > .navbar-item > .blx-button:hover {
  background-color: #ff543e !important;
  border-color: #ff543e !important;
}

.blx-buttonblx-primary :hover {
  background-color: #17e88f !important;
  border-color: #17e88f !important;
}

.autosave-text {
  color: #000;
  margin-top: 1px;
  margin-left: 8px;
}

.autosave-text:hover {
  color: #000 !important;
}

.blx-toggle .blx-toggle-container .blx-toggle-text, .blx-toggle .blx-toggle-container input:checked ~ .blx-toggle-text {
  border-color: #003f2d;
}

.scenario-editor-row {
  color: #1a1a1a;
}

.nested-template-dropdown-item:hover {
  background-color: #003f2d;
}

.panel-section-body > .columns > .column {
  color: #1a1a1a;
}

.metrics-table {
  border-color: #959595;
}

.auth-page-content > .form-container > .columns > .column > .blx-text-field > .blx-text-field-container > input, .auth-page-content > .form-container > .columns > .column > .column > .blx-text-field > .blx-text-field-container > input {
  background-color: #0000;
}

.space-analysis-input-container .blx-text-field .blx-text-field-suffix, .blx-inactive, .blx-placeholder, .blx-unselected {
  color: #6c6d6d;
}

.current-state-panel .location-tab-and-delete:hover {
  background-color: #003f2d14 !important;
}

.delete-location-button {
  margin-left: 7px;
  margin-top: 8px !important;
}

.close-icon {
  height: 11px;
}

.location-tab-and-delete .blx-tab-item:after {
  background-color: #0000 !important;
}

.location-tab-and-delete {
  padding-top: 2px;
}

.space-analysis-input-container .column .flex.flex-col {
  margin-left: 0;
}

.blx-checkbox .blx-label {
  font-family: Calibre Regular;
}

.user-name {
  text-transform: capitalize;
  line-height: 24px;
  padding-top: 0 !important;
}

.user-avatar {
  text-transform: uppercase;
}

.blx-dropdown .user-avatar-and-name .user-name {
  font-size: 11px;
}

.user-avatar-and-name-directory-field > .user-avatar-and-name {
  flex-wrap: wrap;
}

.blx-alert-icon-container {
  background-color: #e3e3e3;
}

.blx-warning .blx-alert-icon-background {
  background-color: #ff9742;
}

.blx-alert-icon-container .blx-alert-icon {
  fill: #000 !important;
}

.blx-warning .blx-alert-text h4 {
  color: #ff9742;
}

.blx-warning .blx-alert-text p {
  color: #1a1a1a;
}

.blx-alert-close svg, .blx-alert-close svg:hover {
  fill: #003f2d;
}

.blx-warning {
  border-top: 3px solid #ff9742;
}

@media screen and (width >= 1408px) {
  .editor-content {
    width: 95%;
    margin-left: 3rem;
    margin-right: 3rem;
  }
}

@media screen and (width >= 1024px) {
  .editor-content {
    width: 90%;
    max-width: 90%;
  }
}

.navbar-avatar-and-name .blx-dropdown-item:hover {
  color: #fff;
  background-color: #003f2d !important;
}

.navbar-avatar-and-name .blx-dropdown-item a {
  color: #1a1a1a;
}

.navbar-avatar-and-name .blx-dropdown-item:hover a {
  color: #fff;
}

.box-link-display .blx-link:hover {
  color: #17e88f;
}

.blx-dropdown-custom-trigger .user-avatar-and-name {
  justify-content: end;
}

.editorcontent .unittoggle .blz-toggle .blx-with-margin {
  margin-top: 5px;
}

.elementstyle {
  padding: 0 5rem;
}

.scenario-editor-row .is-name-field {
  width: 260px;
}

.editor-content .scenario-editor-header-row > * {
  font-size: 12px;
}

.navbar.is-fixed-top {
  max-width: 100vw;
  top: 0;
}

.scenario-header.container {
  margin: auto;
}

.flex-align-items-end {
  align-items: flex-end;
}

.Scenario-Save {
  color: #fff;
  box-shadow: none;
  border-radius: 8px;
  width: 63px;
  height: 41px;
  margin-left: 16px;
  padding: .5rem 1rem;
  font-size: 17px;
  background-color: #003f2d !important;
  border: 1px solid #003f2d !important;
}

.Scenario-Share {
  color: #fff;
  box-shadow: none;
  cursor: pointer;
  border-radius: 6px;
  height: 41px;
  margin-top: -5px;
  margin-left: 18px;
  padding: 12px;
  font-size: 17px;
  background-color: #003f2d !important;
}

.Scenario-Share.hidden {
  display: none !important;
}

.share {
  align-items: center;
  display: flex;
}

.delete-share {
  justify-content: flex-end;
  align-items: center;
  margin-top: 20px;
  font-size: 17px;
  display: flex;
}

.sharetext {
  margin-top: 20px;
}

.input {
  margin-top: 20px;
  margin-left: 15px;
}

.done {
  color: #fff;
  cursor: pointer;
  border-radius: 8px;
  width: 72px;
  height: 41px;
  margin-left: 12px;
  background-color: #003f2d !important;
}

.sharedelete {
  color: #000;
  border-radius: 8px;
  width: 121px;
  height: 41px;
  margin-right: 27px;
  background-color: #fff !important;
  border-color: #228b22 !important;
}

.sharing-representation {
  align-items: center;
  margin-top: 0;
  padding: 35px;
  display: flex;
}

.sharedtag {
  margin-left: 10px;
}

.Shareddelete {
  color: #fff;
  border-top-width: "3px";
  cursor: pointer;
  border-radius: 8px;
  width: 74px;
  height: 41px;
  margin-right: 16px;
  background-color: #a03530 !important;
  border-color: #a03530 !important;
}

.buttonIn {
  width: 470px;
  position: relative;
}

.copy-button {
  z-index: 2;
  cursor: pointer;
  color: #fff;
  background-color: #003f2d;
  border: none;
  border-radius: 5px;
  width: 55px;
  height: 32px;
  position: absolute;
  top: auto;
  right: 8px;
  transform: translateX(2px);
}

.inputbox {
  border-radius: 5px;
  outline: none;
  width: 100%;
  height: 30px;
  margin: 0;
  padding: 0;
}

.share-tooltip:hover:before {
  max-width: 382px;
}

.buttonIn.share-link {
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

.buttonIn.share-link .blx-text-field-container input {
  padding-right: 60px;
}

html, body {
  font-size: 12px !important;
}

@media screen and (width >= 1024px) {
  html, body {
    font-size: 14px !important;
  }
}

@media screen and (width >= 1440px) {
  html, body {
    font-size: 16px !important;
  }
}

.grid {
  display: grid;
}

.line-clamp {
  -webkit-line-clamp: 3;
  word-break: break-all;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

.truncate {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.leading-lg {
  line-height: 1.5rem;
}

.w-full {
  width: 100%;
}

.w-auto {
  width: auto;
}

.h-full {
  height: 100%;
}

.space-x-0 > * + * {
  margin-left: 0 !important;
}

.px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.mx-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.space-x-1 > * + * {
  margin-left: .25rem !important;
}

.px-1 {
  padding-left: .25rem !important;
  padding-right: .25rem !important;
}

.mx-1 {
  margin-left: .25rem !important;
  margin-right: .25rem !important;
}

.space-x-2 > * + * {
  margin-left: .5rem !important;
}

.px-2 {
  padding-left: .5rem !important;
  padding-right: .5rem !important;
}

.mx-2 {
  margin-left: .5rem !important;
  margin-right: .5rem !important;
}

.space-x-3 > * + * {
  margin-left: .75rem !important;
}

.px-3 {
  padding-left: .75rem !important;
  padding-right: .75rem !important;
}

.mx-3 {
  margin-left: .75rem !important;
  margin-right: .75rem !important;
}

.space-x-4 > * + * {
  margin-left: 1rem !important;
}

.px-4 {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.mx-4 {
  margin-left: 1rem !important;
  margin-right: 1rem !important;
}

.space-x-5 > * + * {
  margin-left: 1.25rem !important;
}

.px-5 {
  padding-left: 1.25rem !important;
  padding-right: 1.25rem !important;
}

.mx-5 {
  margin-left: 1.25rem !important;
  margin-right: 1.25rem !important;
}

.space-x-6 > * + * {
  margin-left: 1.5rem !important;
}

.px-6 {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}

.mx-6 {
  margin-left: 1.5rem !important;
  margin-right: 1.5rem !important;
}

.space-x-7 > * + * {
  margin-left: 1.75rem !important;
}

.px-7 {
  padding-left: 1.75rem !important;
  padding-right: 1.75rem !important;
}

.mx-7 {
  margin-left: 1.75rem !important;
  margin-right: 1.75rem !important;
}

.space-x-8 > * + * {
  margin-left: 2rem !important;
}

.px-8 {
  padding-left: 2rem !important;
  padding-right: 2rem !important;
}

.mx-8 {
  margin-left: 2rem !important;
  margin-right: 2rem !important;
}

.space-x-9 > * + * {
  margin-left: 2.25rem !important;
}

.px-9 {
  padding-left: 2.25rem !important;
  padding-right: 2.25rem !important;
}

.mx-9 {
  margin-left: 2.25rem !important;
  margin-right: 2.25rem !important;
}

.space-x-10 > * + * {
  margin-left: 2.5rem !important;
}

.px-10 {
  padding-left: 2.5rem !important;
  padding-right: 2.5rem !important;
}

.mx-10 {
  margin-left: 2.5rem !important;
  margin-right: 2.5rem !important;
}

.space-y-0 > * + * {
  margin-top: 0 !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.space-y-1 > * + * {
  margin-top: .25rem !important;
}

.py-1 {
  padding-top: .25rem !important;
  padding-bottom: .25rem !important;
}

.my-1 {
  margin-top: .25rem !important;
  margin-bottom: .25rem !important;
}

.space-y-2 > * + * {
  margin-top: .5rem !important;
}

.py-2 {
  padding-top: .5rem !important;
  padding-bottom: .5rem !important;
}

.my-2 {
  margin-top: .5rem !important;
  margin-bottom: .5rem !important;
}

.space-y-3 > * + * {
  margin-top: .75rem !important;
}

.py-3 {
  padding-top: .75rem !important;
  padding-bottom: .75rem !important;
}

.my-3 {
  margin-top: .75rem !important;
  margin-bottom: .75rem !important;
}

.space-y-4 > * + * {
  margin-top: 1rem !important;
}

.py-4 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.my-4 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.space-y-5 > * + * {
  margin-top: 1.25rem !important;
}

.py-5 {
  padding-top: 1.25rem !important;
  padding-bottom: 1.25rem !important;
}

.my-5 {
  margin-top: 1.25rem !important;
  margin-bottom: 1.25rem !important;
}

.space-y-6 > * + * {
  margin-top: 1.5rem !important;
}

.py-6 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.my-6 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.space-y-7 > * + * {
  margin-top: 1.75rem !important;
}

.py-7 {
  padding-top: 1.75rem !important;
  padding-bottom: 1.75rem !important;
}

.my-7 {
  margin-top: 1.75rem !important;
  margin-bottom: 1.75rem !important;
}

.space-y-8 > * + * {
  margin-top: 2rem !important;
}

.py-8 {
  padding-top: 2rem !important;
  padding-bottom: 2rem !important;
}

.my-8 {
  margin-top: 2rem !important;
  margin-bottom: 2rem !important;
}

.space-y-9 > * + * {
  margin-top: 2.25rem !important;
}

.py-9 {
  padding-top: 2.25rem !important;
  padding-bottom: 2.25rem !important;
}

.my-9 {
  margin-top: 2.25rem !important;
  margin-bottom: 2.25rem !important;
}

.space-y-10 > * + * {
  margin-top: 2.5rem !important;
}

.py-10 {
  padding-top: 2.5rem !important;
  padding-bottom: 2.5rem !important;
}

.my-10 {
  margin-top: 2.5rem !important;
  margin-bottom: 2.5rem !important;
}

.gap-0 {
  gap: 0 !important;
}

.col-gap-0 {
  column-gap: 0 !important;
}

.row-gap-0 {
  row-gap: 0 !important;
}

.gap-1 {
  gap: .25rem !important;
}

.col-gap-1 {
  column-gap: .25rem !important;
}

.row-gap-1 {
  row-gap: .25rem !important;
}

.gap-2 {
  gap: .5rem !important;
}

.col-gap-2 {
  column-gap: .5rem !important;
}

.row-gap-2 {
  row-gap: .5rem !important;
}

.gap-3 {
  gap: .75rem !important;
}

.col-gap-3 {
  column-gap: .75rem !important;
}

.row-gap-3 {
  row-gap: .75rem !important;
}

.gap-4 {
  gap: 1rem !important;
}

.col-gap-4 {
  column-gap: 1rem !important;
}

.row-gap-4 {
  row-gap: 1rem !important;
}

.gap-5 {
  gap: 1.25rem !important;
}

.col-gap-5 {
  column-gap: 1.25rem !important;
}

.row-gap-5 {
  row-gap: 1.25rem !important;
}

.gap-6 {
  gap: 1.5rem !important;
}

.col-gap-6 {
  column-gap: 1.5rem !important;
}

.row-gap-6 {
  row-gap: 1.5rem !important;
}

.gap-7 {
  gap: 1.75rem !important;
}

.col-gap-7 {
  column-gap: 1.75rem !important;
}

.row-gap-7 {
  row-gap: 1.75rem !important;
}

.gap-8 {
  gap: 2rem !important;
}

.col-gap-8 {
  column-gap: 2rem !important;
}

.row-gap-8 {
  row-gap: 2rem !important;
}

.gap-9 {
  gap: 2.25rem !important;
}

.col-gap-9 {
  column-gap: 2.25rem !important;
}

.row-gap-9 {
  row-gap: 2.25rem !important;
}

.gap-10 {
  gap: 2.5rem !important;
}

.col-gap-10 {
  column-gap: 2.5rem !important;
}

.row-gap-10 {
  row-gap: 2.5rem !important;
}

.max-width--10 {
  max-width: 10rem !important;
}

.max-width--15 {
  max-width: 15rem !important;
}

.max-width--20 {
  max-width: 20rem !important;
}

.max-width--25 {
  max-width: 25rem !important;
}

.max-width--30 {
  max-width: 30rem !important;
}

.max-width--35 {
  max-width: 35rem !important;
}

.max-width--40 {
  max-width: 40rem !important;
}

.max-width--45 {
  max-width: 45rem !important;
}

.max-width--50 {
  max-width: 50rem !important;
}

.border-l {
  border-left: 1px solid;
}

.pl-0 {
  padding-left: 0 !important;
}

.ml-0 {
  margin-left: 0 !important;
}

.pl-1 {
  padding-left: .25rem !important;
}

.ml-1 {
  margin-left: .25rem !important;
}

.pl-2 {
  padding-left: .5rem !important;
}

.ml-2 {
  margin-left: .5rem !important;
}

.pl-3 {
  padding-left: .75rem !important;
}

.ml-3 {
  margin-left: .75rem !important;
}

.pl-4 {
  padding-left: 1rem !important;
}

.ml-4 {
  margin-left: 1rem !important;
}

.pl-5 {
  padding-left: 1.25rem !important;
}

.ml-5 {
  margin-left: 1.25rem !important;
}

.pl-6 {
  padding-left: 1.5rem !important;
}

.ml-6 {
  margin-left: 1.5rem !important;
}

.pl-7 {
  padding-left: 1.75rem !important;
}

.ml-7 {
  margin-left: 1.75rem !important;
}

.pl-8 {
  padding-left: 2rem !important;
}

.ml-8 {
  margin-left: 2rem !important;
}

.pl-9 {
  padding-left: 2.25rem !important;
}

.ml-9 {
  margin-left: 2.25rem !important;
}

.pl-10 {
  padding-left: 2.5rem !important;
}

.ml-10 {
  margin-left: 2.5rem !important;
}

.border-r {
  border-right: 1px solid;
}

.pr-0 {
  padding-right: 0 !important;
}

.mr-0 {
  margin-right: 0 !important;
}

.pr-1 {
  padding-right: .25rem !important;
}

.mr-1 {
  margin-right: .25rem !important;
}

.pr-2 {
  padding-right: .5rem !important;
}

.mr-2 {
  margin-right: .5rem !important;
}

.pr-3 {
  padding-right: .75rem !important;
}

.mr-3 {
  margin-right: .75rem !important;
}

.pr-4 {
  padding-right: 1rem !important;
}

.mr-4 {
  margin-right: 1rem !important;
}

.pr-5 {
  padding-right: 1.25rem !important;
}

.mr-5 {
  margin-right: 1.25rem !important;
}

.pr-6 {
  padding-right: 1.5rem !important;
}

.mr-6 {
  margin-right: 1.5rem !important;
}

.pr-7 {
  padding-right: 1.75rem !important;
}

.mr-7 {
  margin-right: 1.75rem !important;
}

.pr-8 {
  padding-right: 2rem !important;
}

.mr-8 {
  margin-right: 2rem !important;
}

.pr-9 {
  padding-right: 2.25rem !important;
}

.mr-9 {
  margin-right: 2.25rem !important;
}

.pr-10 {
  padding-right: 2.5rem !important;
}

.mr-10 {
  margin-right: 2.5rem !important;
}

.border-b {
  border-bottom: 1px solid;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: .25rem !important;
}

.mb-1 {
  margin-bottom: .25rem !important;
}

.pb-2 {
  padding-bottom: .5rem !important;
}

.mb-2 {
  margin-bottom: .5rem !important;
}

.pb-3 {
  padding-bottom: .75rem !important;
}

.mb-3 {
  margin-bottom: .75rem !important;
}

.pb-4 {
  padding-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1rem !important;
}

.pb-5 {
  padding-bottom: 1.25rem !important;
}

.mb-5 {
  margin-bottom: 1.25rem !important;
}

.pb-6 {
  padding-bottom: 1.5rem !important;
}

.mb-6 {
  margin-bottom: 1.5rem !important;
}

.pb-7 {
  padding-bottom: 1.75rem !important;
}

.mb-7 {
  margin-bottom: 1.75rem !important;
}

.pb-8 {
  padding-bottom: 2rem !important;
}

.mb-8 {
  margin-bottom: 2rem !important;
}

.pb-9 {
  padding-bottom: 2.25rem !important;
}

.mb-9 {
  margin-bottom: 2.25rem !important;
}

.pb-10 {
  padding-bottom: 2.5rem !important;
}

.mb-10 {
  margin-bottom: 2.5rem !important;
}

.border-t {
  border-top: 1px solid;
}

.pt-0 {
  padding-top: 0 !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.p-0 {
  padding: 0 !important;
}

.m-0 {
  margin: 0 !important;
}

.pt-1 {
  padding-top: .25rem !important;
}

.mt-1 {
  margin-top: .25rem !important;
}

.p-1 {
  padding: .25rem !important;
}

.m-1 {
  margin: .25rem !important;
}

.pt-2 {
  padding-top: .5rem !important;
}

.mt-2 {
  margin-top: .5rem !important;
}

.p-2 {
  padding: .5rem !important;
}

.m-2 {
  margin: .5rem !important;
}

.pt-3 {
  padding-top: .75rem !important;
}

.mt-3 {
  margin-top: .75rem !important;
}

.p-3 {
  padding: .75rem !important;
}

.m-3 {
  margin: .75rem !important;
}

.pt-4 {
  padding-top: 1rem !important;
}

.mt-4 {
  margin-top: 1rem !important;
}

.p-4 {
  padding: 1rem !important;
}

.m-4 {
  margin: 1rem !important;
}

.pt-5 {
  padding-top: 1.25rem !important;
}

.mt-5 {
  margin-top: 1.25rem !important;
}

.p-5 {
  padding: 1.25rem !important;
}

.m-5 {
  margin: 1.25rem !important;
}

.pt-6 {
  padding-top: 1.5rem !important;
}

.mt-6 {
  margin-top: 1.5rem !important;
}

.p-6 {
  padding: 1.5rem !important;
}

.m-6 {
  margin: 1.5rem !important;
}

.pt-7 {
  padding-top: 1.75rem !important;
}

.mt-7 {
  margin-top: 1.75rem !important;
}

.p-7 {
  padding: 1.75rem !important;
}

.m-7 {
  margin: 1.75rem !important;
}

.pt-8 {
  padding-top: 2rem !important;
}

.mt-8 {
  margin-top: 2rem !important;
}

.p-8 {
  padding: 2rem !important;
}

.m-8 {
  margin: 2rem !important;
}

.pt-9 {
  padding-top: 2.25rem !important;
}

.mt-9 {
  margin-top: 2.25rem !important;
}

.p-9 {
  padding: 2.25rem !important;
}

.m-9 {
  margin: 2.25rem !important;
}

.pt-10 {
  padding-top: 2.5rem !important;
}

.mt-10 {
  margin-top: 2.5rem !important;
}

.p-10 {
  padding: 2.5rem !important;
}

.m-10 {
  margin: 2.5rem !important;
}

.text-lg {
  font-size: 1.25rem;
}

.text-ellipsis {
  text-overflow: ellipsis;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.overflow-y-hidden {
  overflow-y: hidden;
}

.overflow-y-auto {
  overflow-y: auto;
}

.overflow-x-auto {
  overflow-x: auto;
}

.overflow-visible {
  overflow: visible !important;
}

.no-result--text {
  width: 100%;
  margin-top: 0;
  margin-bottom: 1rem;
  font-size: 1rem;
}

.no--events {
  pointer-events: none;
}

.pointer--initial {
  pointer-events: initial;
}

.min-h-120 {
  min-height: 120px !important;
}

.cursor-pointer {
  cursor: pointer;
}

.text-end {
  text-align: end;
}

.text-underline {
  text-decoration: underline;
}

.space-x-80 > * + * {
  margin-left: 80px !important;
}

.space-x-14 > * + * {
  margin-left: 3.5rem !important;
}

.min-h-105 {
  min-height: 105vh;
}

.icon--20 {
  width: 20px;
  height: 20px;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.hover--icon .filled {
  display: none;
}

.hover--icon .outlined, .hover--icon:hover .filled {
  display: block;
}

.hover--icon:hover .outlined {
  display: none;
}

.split--divider {
  border-bottom: 1px solid #ccc;
  margin: .75rem 0;
}

.text-small, .text-small p {
  font-size: .875rem;
}

.shared-modal .MuiBackdrop-root {
  background-color: #ffffffbf;
}

.shared-modal .MuiDialog-paper {
  background-color: #fff;
  border-top: 4px solid #003f2d;
  box-shadow: 0 3px 8px #1a1a1a33;
}

@media print {
  .shared-modal .MuiDialog-paper {
    opacity: 0;
  }
}

.shared-modal .input-container {
  margin-top: 10px;
}

.shared-modal .input-container .share-container {
  align-items: center;
  display: flex;
}

.shared-modal .input-container .share-container .share-link {
  width: 75%;
}

.shared-modal .input-container .share-container .delete-link {
  width: 25%;
  padding-left: 10px;
}

.shared-modal .input-container .MuiOutlinedInput-root {
  border-radius: 4px;
  padding: 4px;
}

.shared-modal .note-text {
  color: #2b3836;
  margin: 5px 0;
  font-size: .75rem;
}

.shared-modal .export-container {
  margin: 10px 0;
}

.shared-modal .export-container .export-btn {
  margin-top: 10px;
}

.shared-modal .export-container .export-btn .spinner {
  color: #fff;
  margin-left: 5px;
  width: 20px !important;
  height: 20px !important;
}

body {
  background-color: #fbfbfa;
}

body * {
  font-family: Calibre Regular;
}
/*# sourceMappingURL=spacerPro.14a7bad4.css.map */
