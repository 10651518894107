html,
body {
  font-size: 12px !important;
  @media screen and (min-width: 1024px) {
    font-size: 14px !important;
  }
  @media screen and (min-width: 1440px) {
    font-size: 16px !important;
  }
}


.grid {
  display: grid;
}
.line-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-break: break-all;
}
.truncate {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.leading-lg {
  line-height: 1.5rem;
}
.w-full {
  width: 100%;
}
.w-auto {
  width: auto;
}
.h-full {
  height: 100%;
}
$directions: (
  "x": "left",
  "y": "top",
);
$sides: (
  "l": "left",
  "r": "right",
  "b": "bottom",
  "t": "top",
);

@each $direction, $style in $directions {
  @for $i from 0 through 10 {
    .space-#{$direction}-#{$i} > * + * {
      margin-#{$style}: calc($i * 0.25rem) !important;
    }
    .p#{$direction}-#{$i} {
      @if $direction == "x" {
        padding-left: calc($i * 0.25rem) !important;
        padding-right: calc($i * 0.25rem) !important;
      } @else {
        padding-top: calc($i * 0.25rem) !important;
        padding-bottom: calc($i * 0.25rem) !important;
      }
    }
    .m#{$direction}-#{$i} {
      @if $direction == "x" {
        margin-left: calc($i * 0.25rem) !important;
        margin-right: calc($i * 0.25rem) !important;
      } @else {
        margin-top: calc($i * 0.25rem) !important;
        margin-bottom: calc($i * 0.25rem) !important;
      }
    }
  }
}
@for $i from 0 through 10 {
  .gap-#{$i} {
    gap: calc($i * 0.25rem) !important;
  }
  .col-gap-#{$i} {
    column-gap: calc($i * 0.25rem) !important;
  }
  .row-gap-#{$i} {
    row-gap: calc($i * 0.25rem) !important;
  }
}
@for $i from 10 through 50 {
  @if $i % 5 == 0 {
    .max-width--#{$i} {
      max-width: #{$i * 1rem} !important;
    }
  }
}

@each $class, $side in $sides {
  .border-#{$class} {
    border-#{$side}: solid 1px;
  }
  @for $i from 0 through 10 {
    .p#{$class}-#{$i} {
      padding-#{$side}: calc($i * 0.25rem) !important;
    }
    .m#{$class}-#{$i} {
      margin-#{$side}: calc($i * 0.25rem) !important;
    }
    .p-#{$i} {
      padding: calc($i * 0.25rem) !important;
    }
    .m-#{$i} {
      margin: calc($i * 0.25rem) !important;
    }
  }
}

.text-lg {
  font-size: 1.25rem;
}
.text-ellipsis {
  text-overflow: ellipsis;
}
.overflow-hidden {
  overflow: hidden;
}
.overflow-x-hidden {
  overflow-x: hidden;
}
.overflow-y-hidden {
  overflow-y: hidden;
}
.overflow-y-auto {
  overflow-y: auto;
}
.overflow-x-auto {
  overflow-x: auto;
}
.overflow-visible {
  overflow: visible !important;
}
.no-result--text {
  font-size: 1rem;
  width: 100%;
  margin-top: 0;
  margin-bottom: 1rem;
}
.no--events {
  pointer-events: none;
}
.pointer--initial {
  pointer-events: initial;
}
.min-h-120 {
  min-height: 120px !important;
}
.cursor-pointer {
  cursor: pointer;
}
.text-end {
  text-align: end;
}
.text-underline {
  text-decoration: underline;
}
.space-x-80 > * + * {
  margin-left: 80px !important;
}
.space-x-14 > * + * {
  margin-left: 14 * 0.25rem !important;
}
.min-h-105 {
  min-height: 105vh;
}
.icon--20 {
  width: 20px;
  height: 20px;
}
.absolute {
  position: absolute;
}
.relative {
  position: relative;
}
.hover--icon {
  .filled {
    display: none;
  }
  .outlined {
    display: block;
  }
  &:hover {
    .filled {
      display: block;
    }
    .outlined {
      display: none;
    }
  }
}
.split--divider {
  margin: 0.75rem 0;
  border-bottom: solid 1px #ccc;
}
.text-small {
  font-size: 0.875rem;
  p {
    font-size: 0.875rem;
  }
}




