.template-dropdown
  .blx-dropdown-trigger
    width: 12.5rem
  .blx-dropdown-list
    overflow: visible
    .blx-dropdown-item
      .template-dropdown-list-container
        .blx-dropdown-menu
          visibility: hidden
      &:hover
        position: relative
        .template-dropdown-list-container
          .blx-dropdown-menu
            visibility: visible
            .blx-dropdown-list
              max-height: none
            .nested-template-dropdown-item
              .template-preview-wrapper
                visibility: hidden
              &:hover
                .template-preview-wrapper
                  visibility: visible


.template-preview-wrapper
  position: absolute
  left: 105%
  margin-top: -5rem
  .metrics-table
    background-color: white

.template-dropdown-list-container
  .blx-dropdown-menu
    position: absolute
    margin-left: 92%
    top: 50%
    transform: translateY(-50%)
    min-width: 15rem
    border-radius: 7px
    padding: 8px 0
  .blx-dropdown-menu,
  .template-preview-wrapper
    transition: visibility 0s
    visibility: hidden

.template-dropdown
  .blx-dropdown-menu
    overflow: initial
  .blx-dropdown-item
    position: static
    overflow: initial
  .blx-dropdown-item:hover button
    .template-dropdown-list-container
      .blx-dropdown-menu
        transition: visibility 0s
        visibility: visible
  .blx-dropdown-text
    min-width: 115px
    display: flex

.template-dropdown-list-container.open-left
  .blx-dropdown-menu
    margin-left: -131%
    .template-preview-wrapper
      left: 0
      transform: translateX(-100%)
      margin-left: -0.5rem

.nested-template-dropdown-item
  &:hover
    color: $white !important
    background-color: $primary-00
    .template-preview-wrapper
      transition: visibility 0s
      visibility: visible
  .template-preview-wrapper
    .blx-h5
      color: $dark-00
    .metrics-table
      padding: 1rem 3rem
  .template-preview-wrapper.last-updated-tag
    .metrics-table
      padding: 1rem 3rem 3rem 3rem
    p
      margin-top: -2rem
      padding-right: 1rem
  .location-template-preview-wrapper.last-updated-tag
    p
      margin-top: initial
      padding-right: initial
    p.blx-h5-small.blx-inactive.has-margin-left-2.has-margin-bottom-7
      margin-top: -2.75rem

.location-template-preview-wrapper
  left: 110%
  margin-top: -2rem

.location-template-preview
  width: 30rem
  color: $dark-00 !important
  background-color: white
  box-shadow: 0 3px 6px 0 $secondary-01
  border: 1px solid $secondary-01
  border-radius: 7px
  padding: 0.5rem 2rem 2rem 2rem
  .location-template-preview-part-name
    border-bottom: 1px solid $secondary-01

