.auth-page-content
   width: 380px
   padding: 16px
   height: fit-content
   background: #fff
   -webkit-box-shadow: 0 0 11px rgba(0,0,0,0.25)
   box-shadow: 0 0 11px 

.auth-page-spacer-pro-title
  font-weight: $bold-weight
  color: $green-forest-dark
  font-size: $size-4
  letter-spacing: 5.8px
.auth-page-spacer-pro-title-page
  width: 254px
  height: 18px
  left: 32px
  top: 37px
  font-family: 'Calibre'
  font-style: normal
  font-weight: 600
  font-size: 36px
  line-height: 28px 
  margin:24px
.auth-page-content-loginpage-title-page
  width: 254px
  height: 18px
  left: 32px
  top: 37px
  font-family: 'Calibre'
  font-style: normal
  font-weight: 600
  font-size: 36px
  line-height: 28px 
  margin:24px
.auth-error
  color: $danger-00

.blx-button
  background: $green-forest-dark !important
  border-color: $green-forest-dark !important
  color: #FFFFFF

.user-icon
 position: relative

.user-icon img
 position: absolute
 top: 23px
 right: 24px
 width: 18px

.password-icon
 position: relative

.page-content.containertitlepage
  background: url('/public/svg/logos/spacer-pro-background.png') 
.underline
   text-decoration: underline
.error-text
    text-decoration: underline
    text-underline-offset: 3px
    color: #A03530 !important
.password-icon img 
  position: absolute
  top: 28px
  right: 24px
  width: 21px
.auth-page-content-loginpage
   margin: auto
   width: 382px
   padding: 16px
   height: fit-content
   margin-top: 150px
   background: #fff
   box-shadow: 0px 3px 8px rgba(0,0,0,0.2)

    

