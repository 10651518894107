.space-rec-val
  margin-bottom: 0
  margin-top: 1.5rem

@media print
  .space-headcount-print-flex-overrides
    flex-direction: column
    align-items: flex-start


.scenario-summary-bar-chart
  height: 50px
  .program-bar-chart-bar
    height: 100%
    display: flex
    justify-content: center
    align-items: center
  .program-bar-chart-segment-percentage
    font-size: $size-4

.scenario-summary-bar-chart-empty
  background-color: $secondary-01
  border: 1px solid $light-02
  padding: 0 25%
  display: flex
  align-items: center
  p
    text-align: center
    color: $light-02

.space-breakdown-list-circle
  width: 8px
  height: 8px
  border-radius: 50%
  margin: 0 1rem

.space-breakdown-list-row
  margin: 1rem 0

.metrics-table
  @extend %outlined-box
  padding: 1rem 1rem

@media print
  .scenario-editor-page-content
    max-width: 100%
  .page-content
    margin: 0
    .editor-content
      width: 100%
      margin: 0
  .scenario-editor-page-content
    margin-top: 0

    .scenario-summary
      #summary-section
        .panel-section-body
          .scenario-main-summary-all-locations
            flex-direction: column
            .scenario-main-summary
              justify-content: space-between
              .compare-current-selection
                display: none





.grey-info-text
  color: $light-02
  white-space: pre

.space-program-summary-table .scenario-editor-row:not(:last-of-type),
.space-program-summary-table .space-program-row:not(:last-of-type)
  text-align: end
  align-items: center

.space-program-summary-table-label
  padding: 1.5rem
  border-bottom: 1px solid $light-01
  margin: 0

.scenario-summary .scenario-editor-row
  padding: 0.2rem 1rem

.scenario-summary .metrics-table
  margin-top: 1rem

.floor-count
  margin-left: 5rem
