body
  background-color: $grey-light
  *
    font-family: $sans-serif-font-family

@media print
  body
    background-color: white
  .MuiBackdrop-root.MuiModal-backdrop
    display: none

.floating-panel-content
  background-color: $secondary-00
  box-shadow: 0 2px 4px 0 rgba(201, 201, 201, 0.5)
  border-radius: 3px
  padding: 2rem

  h3
    margin-top: 0

.page-content
  display: flex
  position: relative
  margin-top: $navbar-height
  min-height: calc(100vh - #{$navbar-height})
 

.left-nav-style
  .left-nav
    .blx-vertical-tabs
      padding: 26px 20px 0px 10px
    .blx-vertical-tabs .blx-active
      color: $green-forest-dark
       

.main-page-content 
    // margin-left: 7rem
    // margin-right: 5rem
    // padding: 1rem
    flex-grow: 1

.editor-content
  margin-left: $leftnav-width + 2rem
  margin-right: -3.2rem
  padding: $size-6
 
@media print
  .editor-content
    margin-left: 2rem
    margin-right: 2rem

.project-page
  .left-nav
    position: initial
  .main-page-content
    margin-left: 0

.left-nav
  width: 10rem
  // position: fixed

@media print
  .left-nav
    display: none

.page-back-link
  font-weight: $medium-weight
  font-size: 14px
  color: $information
  text-decoration: none
  display: flex
  &:hover
    color: $teal-button

.page-name
  max-width: 40rem
  margin: 0 2rem -0.25rem 1rem
  height: 2.5rem

.small-text
  font-weight: $normal-weight
  font-size: 12px
  line-height: 18px
  text-align: left
  margin: 0

.navbar-item
  transition: unset
  &:hover
    .user-avatar-and-name
      color: initial

.error-page-element
  margin-bottom: 1rem

.top-nav
  position: relative

.tooltip.is-tooltip-active.population-section-tooltip
  &::before,
  &::after
    min-width: auto
    white-space: nowrap

.project-information-breakdown
  margin-top: -2.5rem

.blx-primary, .blx-danger
  &:hover
    background-color: $teal-button !important
    border-color: $teal-button !important


#spacerProLoginContainer
  .spacer-pro
    .page-content
      margin-top: 0!important
      height:100vh