$directory-row-height: 4rem

.directory
  background-color: $secondary-00
  box-shadow: 0 2px 4px 0 rgba(201, 201, 201, 0.5)
  border-radius: 7px
  padding-bottom: 20px

.directory-column-header
  display: flex
  align-items: center

  h6
    text-transform: uppercase

.sort-arrow-icons
  display: inline-flex
  flex-direction: column
  justify-content: space-between
  align-items: center
  height: $size-6
  padding-right: 0.5rem

  *
    cursor: pointer

.sort-triangle-up
  position: relative
  width: 1.5rem
  +triangle-up($primary-00, 5px, 5px)

  //Expands the clickable area
  &::after
    content: ''
    position: absolute
    top: -0.5rem
    bottom: -0.5rem
    left: -0.75rem
    right: -0.75rem

.sort-triangle-down
  position: relative
  width: 1.5rem
  +triangle-down($primary-00, 5px, 5px)

  //Expands the clickable area
  &::after
    content: ''
    position: absolute
    top: -0.5rem
    bottom: -0.5rem
    left: -0.75rem
    right: -0.75rem

.directory-header,
.directory-row
  height: $directory-row-height
  border-bottom: 1px solid $light-01
  margin-bottom: 0 !important
  padding: 0 2rem
  display: flex
  justify-content: space-between
  align-items: center
  .column:not(.allow-overflow) *
    white-space: nowrap
    overflow: hidden
    text-overflow: ellipsis

  .medium-weight-column *
    font-weight: $medium-weight

    &.user-avatar
      font-weight: $normal-weight

.directory-row:hover
  background-color: var(--secondary-01, #ecf5f1)

.directory-row-link, .directory-row-link:active, .directory-row-link:hover
  color: inherit
  text-decoration: none

.user-avatar-list
  display: flex
  .user-avatar
    margin-right: $size-8
    position: unset

.directory-action-popover
  display: flex
  justify-content: flex-end
  align-items: center
  height: 30px
  margin-left: -2rem

.directory-empty-placeholder
  color: $light-02
  text-align: center
  min-height: 10rem
  line-height: 10rem // for vertically centering the text

.blx-dark
  background: $green-forest-dark
  border-color: $green-forest-dark
  color: #FFFFFF

.project-directory
   font-family: $family-calibre
   font-weight: 400
   margin-left: -32px
   color: $green-forest-dark

