.blx-button{
    position: relative;
    font-family: $family-calibre;
    display: flex;
    align-items: center;
    justify-content: center;
   

   
    &.blx-primary{
        background : $green-forest-dark;
    }
    &.blx-secondary{
        background-color: #fff !important;
        border: 1px solid $green-forest-dark !important;
        color: $green-forest-dark;
        box-shadow: none;
    }
    &.blx-secondary:hover{
        background-color: rgba(0, 63, 45, 0.08) !important;
    }
}

.navbar-items.blx-button{
&.blx-secondary:hover{
    background-color: rgba(0, 63, 45, 0.08) !important;
}
}
.blx-link, a{
 color : $green-forest-dark;
 font-size: 16px;
 & svg {
    & path {
      fill: $green-forest-dark !important;
    }
 }

}

.px-0{
    padding-left: 0;
    padding-right: 0;


}

.py-0{

    padding-top: 0;
    padding-bottom: 0;
}

.p-0{
    padding: 0;

}
.pl-0{
 padding-left: 0;

}
.pr-0{
    padding-right: 0;
}

.pt-0{
    padding-top: 0;
}

.pb-0{
    padding-bottom: 0;
}

.blx-dropdown-trigger {
    & svg {
        & path {
          fill: $green-forest-dark !important;
        }
    }   

  .blx-dropdown-text {
     color: $black;
     font-family: $family-calibre;
  }
}

//spacer pro logo
.spacer-pro-logo {
 font-weight: $weight-normal;
 font-size: 18px;
 letter-spacing: 1px;
 font-family: $family-calibre;
  .pro{
    font-weight: $weight-normal;
    font-size: 10px;
    font-family: $family-calibre;
  }

}

.sort-triangle-up{
    border-bottom: 5px solid ($green-forest-dark);
}

.sort-triangle-down {
    border-top: 5px solid ($green-forest-dark);
 }

 .blx-ui-small-caps{
    font-family: $family-calibre;
    font-weight: $weight-normal;
    font-size: 14px;
    letter-spacing: 1.65px;
    color: $black;
 }

 .blx-ui-div{
    font-size: 16px;
    color: $black;
 }

 .blx-h5, h5{
     font-family: $family-calibre;
     font-weight: $weight-normal;
     font-size: 16px;
 }

 .blx-popover-trigger{
     & svg {
        & path {
          fill: #000000 !important;
        }
     }
  }

.blx-modal-overlay{
    background-color: $pale-gray;
}

.blx-h3, h3 {
 font-family: $family-calibre;
 font-weight: $weight-normal;
 font-size: 23px;
 line-height: 24px;
 letter-spacing: 0.005px

}

.blx-modal-close{
    & svg {
        & path {
          fill: $green-forest-dark !important;
        }
     }
}

.send-invitation{
    width: 177px !important;
    margin-left: 138px;
}

.my-profile{
    text-align: center;
}

.blx-icon-down{
    & svg {
        & path {
          fill: $green-forest-dark !important;
        }
     }
}

.blx-submit {
    .blx-button{
        width: 250px !important;
    }
  }

.blx-vertical-tabs input[type=radio]:focus + .blx-tab-item, .blx-horizontal-tabs input[type=radio]:focus + .blx-tab-item, .blx-vertical-tabs input[type=radio]:checked + .blx-tab-item, .blx-horizontal-tabs input[type=radio]:checked + .blx-tab-item {
    color: $green-forest-dark;
}

.blx-h4, h4 {
 font-family: $family-calibre;
 font-weight: $weight-normal;
 color: $black;
}

.blx-vertical-tabs .blx-tab-item{
    color: $grey-new;
}

.left-nav .blx-vertical-tabs .blx-tab-item:focus, .left-nav .blx-vertical-tabs input[type=radio]:checked + .blx-tab-item{
    color:  $green-forest-dark;
}

// .blx-tab-item {
//     color: $green-forest-dark;
// }

.blx-tab-item:hover {
    color: $green-forest-dark;
}

.blx-toggle .blx-toggle-container input:checked ~ .blx-toggle-text {
    background-color: $green-forest-dark;

}

.is-name-field {
    & svg {
        & path {
          fill: $green-forest-dark !important;
        }
     }
}

.blx-secondary svg {
    fill: $green-forest-dark !important;
}

.blx-tab-item.blx-active:after{
    background-color:$green-forest-dark !important ;
}

.blx-tab-item:hover::after{
    background-color:$green-forest-dark !important ;
}

input[type=radio]:hover + .blx-tab-item {
    color:$green-forest-dark !important ;
}

input[type=radio]:checked + .blx-tab-item:after {
    background-color : $green-forest-dark !important ;
}

.is-brand{
    text-decoration: none;
    font-family: $family-calibre;
}
.is-brand:hover{
    color: $teal-header;
    text-decoration: none;
}
.blx-ui-text{
    font-family: $family-calibre;
}
.blx-text-field > .blx-ui-text{
    font-size: 16px !important;
    color: $black;
}

.project-type-dropdown > .blx-dropdown-wrapper > .blx-dropdown > .blx-ui-text{
    font-size: 16px;
    color: $black;
}
.form-section > .form-title{
    font-size: 16px;
    color: $black;
}
.blx-dropdown-trigger .blx-dropdown-placeholder, .blx-dropdown-trigger .blx-dropdown-text{
    font-size: 16px;
    font-family: $family-calibre;
}
.blx-text-field input, .blx-text-field textarea{
    font-size: 16px;
    font-family: $family-calibre;
}
.engagement-manager-avatar{
    border: none;
    text-transform: uppercase;
}
.blx-icon-up{
    background-image: url('/public/svg/icons/up-arrow.svg');
}
.blx-icon-down{
    background-image: url('/public/svg/icons/down-arrow.svg');
}
.panel-section-highlighted, .panel-section{
    background-color: white;
}
.panel-title{
    font-size: 23px;
    color: #1A1A1A;
}
.grey-info-text{
    color: $grey-new;
}
.blx-toggle .blx-toggle-container input:hover ~ .blx-toggle-text{
    background-color: #17e88f;
    border: 1px solid #17e88f;
}
.allocation-slider .slider-wrapper .slider-custom{
    background-color: $green-forest-dark !important;
}
.allocation-slider .slider-wrapper .slider-custom::-webkit-slider-thumb {
    background-color: $green-forest-dark !important;
  }

.blx-vertical-tabs input[type=radio]:focus + .blx-tab-item, .blx-horizontal-tabs input[type=radio]:focus + .blx-tab-item, .blx-vertical-tabs input[type=radio]:checked + .blx-tab-item, .blx-horizontal-tabs input[type=radio]:checked + .blx-tab-item{
    font-weight: 600;
}
.blx-text-field input[type=date]:after{
    background-image: url('/public/svg/icons/calendar_today.svg') !important;
    width: 23px;
    height: 24px;
    top:7px;
}
.form-title-new{
    font-size: 34px;
    color: #1A1A1A;
}
.blx-checkbox:active input[type=checkbox]:checked ~ .blx-checkmark{
   background-color: $green-forest-dark; 
   border-color: $green-forest-dark;
}
.blx-checkbox:focus input[type=checkbox]:checked ~ .blx-checkmark{
    background-color: $green-forest-dark; 
    border-color: $green-forest-dark;
 }

 .blx-checkbox input[type=checkbox]:focus + .blx-checkmark:before{
    content: none;
 }
 .blx-checkbox input[type=checkbox]:checked:focus + .blx-checkmark{
    background-color: $green-forest-dark;   
 }
.blx-checkbox input[type=checkbox]:checked ~ .blx-checkmark{
   background-color: $green-forest-dark; 
   border-color: $green-forest-dark;
}
.blx-checkbox:hover input[type=checkbox]:checked ~ .blx-checkmark{
    background-color: $teal-button; 
    border-color: $teal-button;
 }
.navbar-brand > .navbar-item > .blx-button{
    background-color: #A03530 !important;
    border-color: #A03530 !important;
}
.navbar-brand > .navbar-item > .blx-button:hover{
    background-color: #FF543E !important;
    border-color: #FF543E !important;
}
 .blx-buttonblx-primary :hover{
    background-color: #17e88f !important;
    border-color: #17e88f !important;
}

.autosave-text{
    color: #000000;
    margin-top: 1px;
    margin-left: 8px;
}
.autosave-text:hover{
    color: #000000 !important;
}
.blx-toggle .blx-toggle-container .blx-toggle-text{
    border-color: $green-forest-dark;
}
.blx-toggle .blx-toggle-container input:checked ~ .blx-toggle-text{
    border-color: $green-forest-dark;
}
.scenario-editor-row{
    color: #1A1A1A;
}
.nested-template-dropdown-item:hover{
    background-color: $green-forest-dark
}
.panel-section-body > .columns > .column{
    color: #1A1A1A; 
}
.metrics-table{
    border-color: #959595;
}
.auth-page-content > .form-container > .columns > .column > .blx-text-field > .blx-text-field-container > input{
    background-color: transparent;
}
.auth-page-content > .form-container > .columns > .column > .column > .blx-text-field > .blx-text-field-container > input{
    background-color: transparent;
}
.space-analysis-input-container .blx-text-field .blx-text-field-suffix{
    color: $grey-new;
}
.blx-inactive, .blx-placeholder, .blx-unselected{
    color: $grey-new;
}
.current-state-panel .location-tab-and-delete:hover{
    background-color: rgba(0, 63, 45, 0.08) !important;
}
.delete-location-button{
    margin-left: 7px;
    margin-top: 8px !important;
}
.close-icon{
    height: 11px;
}

.location-tab-and-delete .blx-tab-item::after{
    // content: none;
    background-color: transparent !important;
}
.location-tab-and-delete{
    padding-top: 2px;
}
.space-analysis-input-container .column .flex.flex-col{
    margin-left: 0rem;
}
.blx-checkbox .blx-label{
    font-family: $family-calibre;
}
.user-name{
    text-transform: capitalize;
    padding-top: 0 !important;
    line-height: 24px;
}
.user-avatar{
    text-transform: uppercase;
}

.blx-dropdown .user-avatar-and-name .user-name{
    font-size: 11px;
}
.user-avatar-and-name-directory-field > .user-avatar-and-name{
    flex-wrap: wrap;
}
.blx-alert-icon-container{
    background-color: #E3E3E3;
}
.blx-warning .blx-alert-icon-background{
    background-color: #FF9742;
}
.blx-alert-icon-container .blx-alert-icon{
    fill: #000000 !important;
}
.blx-warning .blx-alert-text h4
{
    color:#FF9742;
}
.blx-warning .blx-alert-text p
{
    color: $black;
}
.blx-alert-close svg{
    fill: $green-forest-dark;
}
.blx-alert-close svg:hover{
    fill: $green-forest-dark;
}
.blx-warning{
    border-top: 3px solid #FF9742;
}
@media screen and (min-width: 1408px){
.editor-content{
 width:95%;
 margin-left: 3rem;
 margin-right: 3rem;
}
}
@media screen and (min-width: 1024px){
    .editor-content{
     max-width:90%;
     width:90%;
    }}
   
.navbar-avatar-and-name .blx-dropdown-item:hover{
    background-color: $green-forest-dark !important;
    color: white;
}
.navbar-avatar-and-name .blx-dropdown-item a{
    color: $black;
}
.navbar-avatar-and-name .blx-dropdown-item:hover a{
    color: white;
}
.box-link-display .blx-link:hover{
    color: $teal-header;
}
.blx-dropdown-custom-trigger .user-avatar-and-name{
    justify-content: end;
}
.editorcontent .unittoggle .blz-toggle .blx-with-margin{
    margin-top:5px;
}
.scenario-editor-row .is-name-field {
    width: 10rem;
    }
.elementstyle{
    padding: 0 5rem;
}
.scenario-editor-row .is-name-field {
    width: 260px;
}
.editor-content .scenario-editor-header-row > *{
        font-size: 12px;
}
.navbar.is-fixed-top {
    top: 0;
    max-width: 100vw;
}
.scenario-header.container {
    margin: auto;
}
.flex-align-items-end {
    align-items: flex-end;
}
.Scenario-Save{ 

    margin-left: 16px;
    width: 63px;
    height: 41px;
    border-radius: 8px;
    padding: 0.5rem 1rem;
    background-color: $green-forest-dark  !important; 
    border: 1px solid $green-forest-dark !important;
    color: $green-forest-dark;
    box-shadow: none;
    color: white;
    font-size: 17px;
}

.Scenario-Share{
    margin-left: 18px;
    background-color:$green-forest-dark  !important;
   color: $green-forest-dark;
   height: 41px;
   border-radius: 6px;
   box-shadow: none;
   color: white;
   font-size: 17px;
   margin-top: -5px;
   padding:12px;
   cursor:pointer ;
  &.hidden {
    display: none !important;
  }
}

.share{
    display: flex;
    align-items: center;
    
}
.delete-share{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 20px;
    font-size: 17px;

}
.sharetext{
    margin-top: 20px;
    
}
.input{
    margin-top: 20px;
    margin-left: 15px;
 
   
}
.done{
    background-color:$green-forest-dark  !important; 
   
   color: white;
   width: 72px;
    height: 41px;
    border-radius: 8px;
    margin-left: 12px;
    cursor: pointer;

}
.sharedelete{
    background-color: white !important;
    border-color: forestgreen !important;
    color: black;
    width: 121px;
    height: 41px;
    border-radius: 8px;
    margin-right: 27px;
   
}
.sharing-representation{
    margin-top: 0px;
    display: flex;
    align-items: center;
    padding: 35px;
}
.sharedtag{
    margin-left: 10px;
}
.Shareddelete{
        background-color: #A03530 !important;
        border-color: #A03530 !important;
        color: white;
        width: 74px;
        height: 41px;
        border-radius: 8px;
        margin-right: 16px;
        border-top-width: "3px";
        cursor: pointer;
       
    }
.buttonIn{
    width: 470px;
    position: relative;
}
.copy-button{ 
    position: absolute;
       top:auto;
        border-radius: 5px;
        right: 0px;
        z-index: 2;
        border: none;
       right:8px;
        height: 32px;
        cursor: pointer;
        color: white;
        background-color: $green-forest-dark  ;
        transform: translateX(2px);
        width:55px;
}
.inputbox{
    margin: 0px;
        padding: 0px;
        width: 100%;
        outline: none;
        height: 30px;
        border-radius: 5px;
}
.share-tooltip:hover::before {
    max-width : 382px;
    

 }
.buttonIn.share-link{
    position:relative;
    display:flex;
    align-items: center;
    justify-content: center;
    .blx-text-field-container{
        input{
            padding-right: 60px;
        }
    }
}
    
