%outlined-box
  @include outlined-box

.scenario-editor-page-content,
.template-editor-page-content
  .left-nav
    .blx-vertical-tabs
      position: relative
      left: 1rem

.scenario-header.container
  width: 70%
  .blx-primary
    height: 30px
    padding: 0 18px

.autosave-text
  width: 175px
  em
    white-space: nowrap

.scenario-header
  .navbar-item
    &:hover,
    &.is-active
      color: black

.navbar .scenario-header .navbar-item .blx-primary
    pointer-events: auto

.navbar-menu .blx-horizontal-tabs
  border-left: none
  padding-left: 0

.navbar-menu .navbar-start
  flex-grow: 1
  justify-content: center

.navbar-menu .navbar-start .navbar-item
  padding-left: 0

.navbar-menu .navbar-end
  width: 175px

.scenario-header-name
  max-width: 250px
  @media print
    max-width: 100%

.units-toggle-label
  margin-right: 13rem

.template-section
  .blx-button
    margin-bottom: 0
    height: 40px

.assigned-seating-toggle .blx-toggle
  margin: initial
  margin-left: 0
  margin-top: .5rem
  margin-bottom: 1rem

.scenario-tabs
  width: 100%
  display: flex
  justify-content: space-between
  align-items: center
  min-height: 5rem
  margin: 0 1rem
  li
    white-space: nowrap
    overflow: hidden
    text-overflow: ellipsis
    width: 130px

.scenario-tabs-border
  border-bottom: 1px solid $light-01

.headcount-size-metrics
  margin: 0 1.5rem 2rem 2rem

.collaboration-ratio-input
  .blx-text-field-container
    width: 100%

.population-section-header
  background-color: $light-00
  border: 1px solid $light-01
  box-shadow: 0 2px 4px 0 rgba($grey-dark, 0.1)
  position: fixed
  top: calc(#{$navbar-height} + 6px)
  right: 0
  left: 0
  height: 4rem
  border-radius: 3px
  z-index: 35
  margin-top: 0

  .population-name
    margin-top: -0.25rem
    font-size: 1rem

  .population-description
    font-size: 0.9rem
    margin-top: -0.25rem

  .count-column
    padding-top: 0.3rem

  .count-column-header
    color: $light-02
    font-size: 0.9rem

  .count-column-value
    font-weight: bold

  .panel-section 
   .panel-section-body
    padding: 0

#panel-body-populations-section
  .panel-section-highlighted
    .metrics-table
      margin-top: -1.5rem
      margin-left: 10rem
      width: 35%

.location-population-box-container
  min-height: 160px

.location-population-box
  @extend %outlined-box
  padding: 1rem

.location-population-name
  margin: 0 0 1rem

.unit-additional-info
  margin: 0 0 0 0.5rem

.manage-locations-modal

  .blx-modal
    width: 720px

  .blx-icon-forward, .blx-icon-back
    position: relative
    z-index: 1

  .blx-icon-forward
    top: 2.6rem
    left: 2.5rem

  .blx-icon-back
    left: 0.5rem
    top: 4rem

  .blx-text-field
    width: 85%

  .blx-checkmark
    top: -8px

  .scenario-editor-row
    border-color: $light-01 !important
    padding-left: 0

  .location-rows
    max-height: 21.5rem
    overflow: auto

  .delete-row-button
    margin-top: 8px

  .delete-row-button .blx-button
    min-width: 8px
    width: 25px
    height: 25px
    padding: 1px 1px
    border-radius: 12px

  .delete-row-button  .blx-icon-button .blx-icon-close
    top: 11px
    left: 1px
    height: 14px
    width: 14px

  .add-item-button
    margin-left: 1rem

  .blx-modal-buttons
    margin-top: 0

.scenario-editor-table
  @extend %outlined-box
  padding: 0 0 1rem
  margin-bottom: 2rem
  position: relative

.template-editor-page-content .template-editor-example-headcount-outline
  position: absolute
  right: 4rem
  top: -10px
  border: 1px solid $dark-02-hex
  border-radius: 7px

.template-editor-page-content .blx-hidden
  display: initial
  visibility: hidden
  height: 0

.scenario-editor-page-content .template-editor-example-headcount-outline
  display: none

#locations-section.panel.is-open
  padding-bottom: 22.5rem

.unit-toggle
  .blx-toggle.blx-with-margin
    margin-left: 0
    margin-top: 0

.floor-count-input
  margin-right: -1rem
  .blx-text-field
    width: initial
    .blx-text-field-container
      width: 6rem

