.template-panel
  margin-top: 2rem
  padding: 2rem 0.7rem

  h4
    margin-top: 0
    margin-bottom: 0
    margin-left: 1rem
    width: 45%

  .blx-modal
    h4
      margin-left: initial

  .profile-template-row
    border-top: 1px solid $grey-lighter
    &:last-of-type
      border-bottom: 1px solid $grey-lighter
    .blx-popover .blx-popover-menu::before,
    .blx-popover .blx-popover-menu::after
      content: none

    &:hover
      background-color: $secondary-01

  .template-metrics-table
    padding: 0
    .metrics-table
      border: none
      margin-top: 0

  .template-popover-menu
    float: left

  .template-title
    padding-bottom: 0

  .template-name
    margin-top: 0.25rem
    margin-bottom: 0
    padding-left: 2.25rem

  .save-edit-button
    margin-top: 1rem

  .location-template-preview
    margin-left: 0.2rem
    margin-top: 1rem
    width: initial
    background-color: initial
    box-shadow: initial
    border: none

.new-template-content
  h3,
  .blx-text-field
    margin-bottom: 2rem

  .blx-button.blx-primary
    float: right
.page-content.container.template-editor-page-content
    display: grid
    margin-left: 25px
