.panel
  &:first-of-type
    margin-top: 2rem

  &:last-of-type
    margin-bottom: 2rem

  &.is-open:not(:first-of-type)
    margin-top: 4rem

  &.is-open:not(:last-of-type)
    margin-bottom: 4rem

.panel:not(.is-open) + .panel:not(.is-open) // avoid doubly thick borders between closed panels
  border-top: 0

.panel-header
  cursor: pointer
  padding: 0.5rem 0
  background-color: unset
  border: none
  .blx-icon
    vertical-align: bottom

.panel-title
  display: unset

.panel-section
  padding: 1rem 0

.panel-section-border-top, .panel-section:not(.panel-section-highlighted) + .panel-section:not(.panel-section-no-border-top)
  border-top: 1px solid $light-01

.panel-section-highlighted
  background-color: $light-00
  border-radius: $base-border-radius

.panel-subheader
  margin: 1.5rem 1.5rem 1rem 1.5rem

  .tooltip
    margin-left: 0.5rem

.panel-body
  @extend %outlined-box

@media print
  .panel-body-print
    @include outlined-box

@media print
  .panel-body-no-print-border
    border: 0 !important

.panel-section-body
  padding: 0.5rem 2rem


#panel-body-program-section.panel-body
  .panel-section-body
    padding: 0.5rem 2rem

#locations-section .panel-section
  border-top: 0

