.my-profile-content
  margin: 0 auto
  width: 380px
  color: #1A1A1A
  margin-top:100px

  .heading-medium
    margin-bottom: 2rem

  .my-details-block
    margin-top: 3rem

  .password-button
    margin-top: 3rem
    margin-left: 0.1rem
    cursor: pointer
    &:focus
      outline: none

  .icon-up-arrow, .icon-down-arrow
    margin-right: 0.7rem

  .password-button-label
    margin-left: 0.5rem

  .password-area .blx-text-field
      margin-top: 1.5rem
