.block
  +block

.delete
  +delete($white, transparent, false, rgba($black, 0.3)) // for backwards compatibility // TODO: clean up other delete classes
  font-size: 0

.heading
  display: block
  font-size: 11px
  letter-spacing: 1px
  margin-bottom: 5px
  text-transform: uppercase

.highlight
  +block
  font-weight: $weight-normal
  max-width: 100%
  overflow: hidden
  padding: 0
  pre
    overflow: auto
    max-width: 100%

.loader
  +loader
  &.light
    border: 2px solid $pale-green
    border-right-color: transparent
    border-top-color: transparent

.number
  align-items: center
  background-color: $background
  border-radius: 290486px
  display: inline-flex
  font-size: $size-medium
  height: 2em
  justify-content: center
  margin-right: 1.5rem
  min-width: 2.5em
  padding: 0.25rem 0.5rem
  text-align: center
  vertical-align: top
