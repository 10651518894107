.form-container
  .columns
    margin-bottom: 0
    margin-top:0px
    .column
      padding-bottom: 0
      margin-top: 1rem

  .blx-text-field,
  .blx-text-field input,
  .blx-dropdown-wrapper
    width:100%
  .blx-dropdown
    width: 100%
    max-width: unset
    margin: unset

  .blx-text-field
    min-width: unset

  .blx-text-field label
    font-size: 1rem

.box-link-display
  .form-container .blx-text-field
    min-width: 150px
  .form-title
    margin-left: 0.5rem

.form-container-narrow-width
  width: 250px

.form-container-medium-width
  width: 450px

.form-description
  font-size: 18px
  margin: $size-4 0

.form-description-login
  margin:24px
  fontWeight:400px
  height: 24px
  left: 32px
  top: 79px
  font-size: 18px
  line-height: 24px
  font-family: Calibre
  


.form-section
  padding-top: 1rem

.form-title
  margin-bottom: $size-6

.form-error-message
  font-size: 0.85rem
  line-height: 1.5
  margin-top: $size-7

.form-warning-message-exclamation
  background: $warning
  font-weight: bold
  color: $secondary-01
  width: 18px
  height: 18px
  border-radius: 50%
  display: inline-block
  text-align: center
  margin-right: .25rem

.form-button .blx-button
  margin: 0

.form-submit-button
  margin-top: $size-4
  display: flex
  justify-content: flex-end

.form-submit-button-pair
  .blx-button
    margin-left: 1rem
