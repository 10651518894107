.tooltip
  padding: 0
  &:hover::before,
  &.is-tooltip-active::before
    font-size: 14px

  &.has-icon
    width: 2rem


.text-field-with-tooltip
  display: flex
  align-items: flex-end

  .sharing-ratio-tooltip-wrapper
    align-self: stretch
    display: flex
    align-items: center
    margin-top: 24px
    &.has-invalid-message-margin
      margin-bottom: 16px

  .tooltip
    margin-left: 0.8rem
