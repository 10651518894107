$desktop-max-width: $desktop 
$widescreen-max-width: $widescreen 
$fullhd-max-width: $fullhd 

.container
  margin-right: 25px
  position: relative
  max-width: calc(100% - 64px)
  width:100%
  
  &.is-fluid
    margin-left: $gap
    margin-right: $gap
    max-width: none
    width: auto
    //   +from($desktop)
    // max-width: $desktop-max-width
    // width: $desktop-max-width
  // +until($widescreen)
  //   &.is-widescreen
  //     max-width: $widescreen-max-width
  //     width: auto
  // +until($fullhd)
  //   &.is-fullhd
  //     max-width: $fullhd-max-width
  //     width: auto
  // +from($widescreen)
  //   max-width: $widescreen-max-width
  //   width: $widescreen-max-width
  // +from($fullhd)
  //   max-width: $fullhd-max-width
  //   width: $fullhd-max-width