.user-avatar-and-name
  display: flex
  align-items: center
  position: relative

.user-avatar-and-name .user-name
  padding-left: 2.5rem
  padding-top: 0.5rem
  height: 27px

.user-avatar-and-popover-menu
  margin-bottom: $size-4 !important
  .user-avatar-and-name .user-name
    overflow: hidden
    text-overflow: ellipsis
    white-space: nowrap
  .user-name
    padding: 0
    height: auto
    margin-left: 36px
  .user-avatar-engagement-manager-tag
    padding-left: 0
    margin-left: 37px
    margin-bottom: 0
  .user-avatar-and-name-directory-field
    margin-left: 0

.user-avatar
  position: absolute
  display: flex
  align-items: center
  justify-content: center
  height: 27px
  width: 27px
  background-color: #CBD1D3
  border-radius: 10%
  color: #1A1A1A
  line-height: $size-4
  font-size: $size-7

.engagement-manager-avatar
  border: 2px solid $brand-00

.navbar-avatar-and-name
  .user-avatar-and-name .user-name
    padding-left: 0.5rem
  .user-avatar
    position: unset
  .blx-dropdown-menu
    border-radius: 7px

.user-avatar-engagement-manager-tag
  overflow: unset
  padding-left: 2.5rem
  margin-top: -0.25rem

  .blx-popover .blx-dropdown-menu
    min-width: 200px

.column.is-one-fifth
  .blx-tag
    width: 140px

.user-avatar-and-name-directory-field
  margin-left: -1.25rem

