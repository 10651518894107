// Float

.is-clearfix
  +clearfix

.is-pulled-left
  float: left !important

.is-pulled-right
  float: right !important

// Size
.is-fullheight
  min-height: 100vh !important

// Overflow

.is-clipped
  overflow: hidden !important

.is-ellipsized
  white-space: nowrap
  overflow: hidden
  text-overflow: ellipsis

// Overlay

.is-overlay
  +overlay

// Typography

.has-letter-spacing-small
  letter-spacing: $letter-spacing-small
.has-letter-spacing-medium
  letter-spacing: $letter-spacing-medium
.has-letter-spacing-large
  letter-spacing: $letter-spacing-large

=typography-size($target:'')
  @each $size in $sizes
    $i: index($sizes, $size)
    // font-size
    .is-size-#{$i}#{if($target == '', '', '-' + $target)}
      font-size: $size !important

    // padding
    .has-padding-#{$i}
      padding: $size !important
    .has-padding-top-#{$i}
      padding-top: $size !important
    .has-padding-right-#{$i}
      padding-right: $size !important
    .has-padding-bottom-#{$i}
      padding-bottom: $size !important
    .has-padding-left-#{$i}
      padding-left: $size !important

    // margin
    .has-margin-#{$i}
      margin: $size !important
    .has-margin-top-#{$i}
      margin-top: $size !important
    .has-margin-right-#{$i}
      margin-right: $size !important
    .has-margin-bottom-#{$i}
      margin-bottom: $size !important
    .has-margin-left-#{$i}
      margin-left: $size !important

    +mobile
      // padding mobile
      .has-padding-#{$i}-mobile
        padding: $size !important
      .has-padding-top-#{$i}-mobile
        padding-top: $size !important
      .has-padding-right-#{$i}-mobile
        padding-right: $size !important
      .has-padding-bottom-#{$i}-mobile
        padding-bottom: $size !important
      .has-padding-left-#{$i}-mobile
        padding-left: $size !important
      .has-no-padding-mobile
        padding: 0 !important
      .has-no-padding-top-mobile
        padding-top: 0 !important
      .has-no-padding-right-mobile
        padding-right: 0 !important
      .has-no-padding-bottom-mobile
        padding-bottom: 0 !important
      .has-no-padding-left-mobile
        padding-left: 0 !important

      // margin mobile
      .has-margin-#{$i}-mobile
        margin: $size !important
      .has-margin-top-#{$i}-mobile
        margin-top: $size !important
      .has-margin-right-#{$i}-mobile
        margin-right: $size !important
      .has-margin-bottom-#{$i}-mobile
        margin-bottom: $size !important
      .has-margin-left-#{$i}-mobile
        margin-left: $size !important
      .has-no-margin-mobile
        margin: 0 !important
      .has-no-margin-top-mobile
        margin-top: 0 !important
      .has-no-margin-right-mobile
        margin-right: 0 !important
      .has-no-margin-bottom-mobile
        margin-bottom: 0 !important
      .has-no-margin-left-mobile
        margin-left: 0 !important


+typography-size()

+mobile
  +typography-size('mobile')

+tablet
  +typography-size('tablet')

+touch
  +typography-size('touch')

+desktop
  +typography-size('desktop')

+widescreen
  +typography-size('widescreen')

+fullhd
  +typography-size('fullhd')

.is-sans-regular
  font-family: $family-sans-serif !important

.is-sans-semibold
  font-family: $family-sans-serif-semibold !important

.is-sans-medium
  font-family: $family-sans-serif-medium !important

.is-serif
  font-family: $family-serif !important

.is-calibre
   font-family: $family-calibre !important

.is-financier-regular
   font-family: $family-financier-regular !important

.is-financier-semibold
   font-family: $family-financier-semibold !important

$alignments: ('centered': 'center', 'justified': 'justify', 'left': 'left', 'right': 'right')

@each $alignment, $text-align in $alignments
  .has-text-#{$alignment}
    text-align: #{$text-align} !important
  +mobile
    .has-text-#{$alignment}-mobile
      text-align: #{$text-align} !important
  +tablet
    .has-text-#{$alignment}-tablet
      text-align: #{$text-align} !important
  +tablet-only
    .has-text-#{$alignment}-tablet-only
      text-align: #{$text-align} !important
  +touch
    .has-text-#{$alignment}-touch
      text-align: #{$text-align} !important
  +desktop
    .has-text-#{$alignment}-desktop
      text-align: #{$text-align} !important
  +desktop-only
    .has-text-#{$alignment}-desktop-only
      text-align: #{$text-align} !important
  +widescreen
    .has-text-#{$alignment}-widescreen
      text-align: #{$text-align} !important
  +widescreen-only
    .has-text-#{$alignment}-widescreen-only
      text-align: #{$text-align} !important
  +fullhd
    .has-text-#{$alignment}-fullhd
      text-align: #{$text-align} !important

.is-capitalized
  text-transform: capitalize !important

.is-lowercase
  text-transform: lowercase !important

.is-uppercase
  text-transform: uppercase !important

@each $name, $pair in $colors
  $color: nth($pair, 1)
  .has-text-#{$name}
    color: $color !important
  a.has-text-#{$name},
  .has-text-#{$name}.is-hoverable
    &:hover,
    &:focus
      color: darken($color, 10%) !important

@each $name, $shade in $shades
  .has-text-#{$name}
    color: $shade !important

// overriding the generated class since it uses the color "light" and not the $text-light color
.has-text-light
  color: $text-light !important

.has-text-weight-light
  font-weight: $weight-light !important
.has-text-weight-normal
  font-weight: $weight-normal !important
.has-text-weight-medium
  font-weight: $weight-medium !important
.has-text-weight-semibold
  font-weight: $weight-semibold !important
.has-text-weight-bold
  font-weight: $weight-bold !important

// TODO: these classes can be removed when new space category breakdown is implemented
.text-on-is-me
  color: $me-invert !important

.text-on-is-we
  color: $we-invert !important

.text-on-is-amenity
  color: $amenity-invert !important

// ***************************
// Background Classes
// ***************************

.is-me
  background-color: $me

.is-we
  background-color: $we

.is-amenity
  background-color: $amenity

.is-grey-lighter
  background-color: $grey-lighter

.is-pale-green
  background-color: rgba($grey-lighter, 0.2)

.is-light-gray
  background-color: $light-gray

.is-pale-green-new
  background-color: $pale-green-new

.is-white
  background-color: $pale-white

// Visibility

$displays: 'block' 'flex' 'inline' 'inline-block' 'inline-flex'

@each $display in $displays
  .is-#{$display}
    display: #{$display} !important
  +mobile
    .is-#{$display}-mobile
      display: #{$display} !important
  +tablet
    .is-#{$display}-tablet
      display: #{$display} !important
  +tablet-only
    .is-#{$display}-tablet-only
      display: #{$display} !important
  +touch
    .is-#{$display}-touch
      display: #{$display} !important
  +desktop
    .is-#{$display}-desktop
      display: #{$display} !important
  +desktop-only
    .is-#{$display}-desktop-only
      display: #{$display} !important
  +widescreen
    .is-#{$display}-widescreen
      display: #{$display} !important
  +widescreen-only
    .is-#{$display}-widescreen-only
      display: #{$display} !important
  +fullhd
    .is-#{$display}-fullhd
      display: #{$display} !important

.is-hidden
  display: none !important

.is-invisible
  visibility: hidden

+mobile
  .is-hidden-mobile
    display: none !important

@media print
  .is-hidden-print
    display: none !important

.is-print-only
  display: none
  @media print
    display: block

+tablet
  .is-hidden-tablet
    display: none !important

+tablet-only
  .is-hidden-tablet-only
    display: none !important

+touch
  .is-hidden-touch
    display: none !important

+desktop
  .is-hidden-desktop
    display: none !important

+desktop-only
  .is-hidden-desktop-only
    display: none !important

+widescreen
  .is-hidden-widescreen
    display: none !important

+widescreen-only
  .is-hidden-widescreen-only
    display: none !important

+fullhd
  .is-hidden-fullhd
    display: none !important

// Positions

$positions: 'relative' 'absolute' 'fixed' 'inherit' 'initial' 'sticky'

@each $position in $positions
  .is-position-#{$position}
    position: #{$position} !important


// Other
.break-after
  break-after: page

.break-before
  break-before: page

.break-inside-avoid
  break-inside: avoid

.no-outline:focus
  outline: none

.no-pointer
  pointer-events: none !important

@mixin outlined-box
  border: 1px solid $light-01
  border-radius: $base-border-radius

.is-marginless
  margin: 0 !important

.is-paddingless
  padding: 0 !important

.is-radiusless
  border-radius: 0 !important

.is-shadowless
  box-shadow: none !important

.is-unselectable
  +unselectable

.is-borderless
  border: none !important

.is-scroll-link-target
  color: inherit

.has-margin-0-auto
  margin: 0 auto !important

.has-margin-left-auto
  margin-left: auto

.has-margin-right-auto
  margin-right: auto

.is-selectable
  cursor: pointer

.fade-in
  visibility: visible
  opacity: 1
  transition: visibility 0s linear 0s, opacity 500ms

// Subsequently animate left property so the element is off the page and not blocking anything
.fade-out
  visibility: hidden
  opacity: 0
  left: -9999px !important
  transition: visibility 500s linear 0s, opacity 500ms, left 0s ease 500ms

.is-invisible-to-be-faded-in
  visibility: hidden
  opacity: 0

.visually-hidden
  border: 0
  clip: rect(0 0 0 0)
  height: 1px
  margin: -1px
  overflow: hidden
  padding: 0
  position: absolute
  white-space: nowrap
  width: 1px

