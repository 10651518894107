$blocks-base-assets-path: ".." !default

blockquote, body, dd, dl, dt, fieldset, figure, h1, h2, h3, h4, h5, h6, hr, html, iframe, legend, li, ol, p, pre, textarea, ul
  margin: 0
  padding: 0

h1, h2, h3, h4, h5, h6
  font-size: 100%
  font-weight: 400

ul
  list-style: none

button, input, select, textarea
  margin: 0

*
  box-sizing: border-box

audio, embed, img, object, video
  max-width: 100%

iframe
  border: 0

table
  border-collapse: collapse
  border-spacing: 0

td, th
  padding: 0
  text-align: left

@font-face
  font-family: Graphik Web
  src: url(#{$blocks-base-assets-path}/fonts/Graphik-Bold-Web.eot)
  src: url(#{$blocks-base-assets-path}/fonts/Graphik-Bold-Web.eot?#iefix) format("embedded-opentype"), url(#{$blocks-base-assets-path}/fonts/Graphik-Bold-Web.woff2) format("woff2"), url(#{$blocks-base-assets-path}/fonts/Graphik-Bold-Web.woff) format("woff")
  font-weight: 700
  font-style: normal
  font-stretch: normal

.Graphik-Bold-Web
  font-weight: 700
  font-family: Graphik Web
  font-style: normal
  font-stretch: normal

@font-face
  font-family: Graphik Web
  src: url(#{$blocks-base-assets-path}/fonts/Graphik-Medium-Web.eot)
  src: url(#{$blocks-base-assets-path}/fonts/Graphik-Medium-Web.eot?#iefix) format("embedded-opentype"), url(#{$blocks-base-assets-path}/fonts/Graphik-Medium-Web.woff2) format("woff2"), url(#{$blocks-base-assets-path}/fonts/Graphik-Medium-Web.woff) format("woff")
  font-weight: 500
  font-style: normal
  font-stretch: normal

.Graphik-Medium-Web
  font-family: Graphik Web
  font-style: normal
  font-stretch: normal
  font-weight: 500

@font-face
  font-family: Graphik Web
  src: url(#{$blocks-base-assets-path}/fonts/Graphik-RegularItalic-Web.eot)
  src: url(#{$blocks-base-assets-path}/fonts/Graphik-RegularItalic-Web.eot?#iefix) format("embedded-opentype"), url(#{$blocks-base-assets-path}/fonts/Graphik-RegularItalic-Web.woff2) format("woff2"), url(#{$blocks-base-assets-path}/fonts/Graphik-RegularItalic-Web.woff) format("woff")
  font-weight: 400
  font-style: italic
  font-stretch: normal

.Graphik-RegularItalic-Web
  font-style: italic

@font-face
  font-family: Graphik Web
  src: url(#{$blocks-base-assets-path}/fonts/Graphik-Regular-Web.eot)
  src: url(#{$blocks-base-assets-path}/fonts/Graphik-Regular-Web.eot?#iefix) format("embedded-opentype"), url(#{$blocks-base-assets-path}/fonts/Graphik-Regular-Web.woff2) format("woff2"), url(#{$blocks-base-assets-path}/fonts/Graphik-Regular-Web.woff) format("woff")
  font-weight: 400
  font-style: normal
  font-stretch: normal

.Graphik-Regular-Web, .Graphik-RegularItalic-Web
  font-family: Graphik Web
  font-weight: 400
  font-stretch: normal

.Graphik-Regular-Web
  font-style: normal

@font-face
  font-family: Graphik Web
  src: url(#{$blocks-base-assets-path}/fonts/Graphik-Light-Web.eot)
  src: url(#{$blocks-base-assets-path}/fonts/Graphik-Light-Web.eot?#iefix) format("embedded-opentype"), url(#{$blocks-base-assets-path}/fonts/Graphik-Light-Web.woff2) format("woff2"), url(#{$blocks-base-assets-path}/fonts/Graphik-Light-Web.woff) format("woff")
  font-weight: 300
  font-style: normal
  font-stretch: normal

.Graphik-Light-Web
  font-family: Graphik Web
  font-weight: 300
  font-style: normal
  font-stretch: normal

.blx-label, .blx-p, label, p
  font-family: Graphik Web
  font-weight: 400
  font-size: 14px
  line-height: 18px
  letter-spacing: 0

.blx-label.blx-with-margin, .blx-p.blx-with-margin, label.blx-with-margin, p.blx-with-margin
  margin-top: 0
  margin-bottom: 6px

.blx-link
  font-family: Graphik Web
  font-weight: 400
  font-size: 14px
  line-height: 18px
  letter-spacing: 0
  color: #009f6f
  color: var(--primary-00, #009f6f)
  cursor: pointer
  background: transparent
  border: none
  text-align: left
  text-decoration: underline
  padding: 0

a
  font-family: Graphik Web
  font-weight: 400
  font-size: 14px
  line-height: 18px
  letter-spacing: 0
  color: #009f6f
  color: var(--primary-00, #009f6f)
  cursor: pointer
  background: transparent
  border: none
  text-align: left
  text-decoration: underline
  padding: 0

  &:hover
    color: #008b61
    color: var(--primary-01, #008b61)

.blx-link
  &:hover, &.blx-hover
    color: #008b61
    color: var(--primary-01, #008b61)

a
  &.blx-hover
    color: #008b61
    color: var(--primary-01, #008b61)

  &:focus
    outline: none
    color: #fff
    background-color: #49a1ff
    background-color: var(--focus, #49a1ff)

.blx-link
  &:focus, &.blx-focus
    outline: none
    color: #fff
    background-color: #49a1ff
    background-color: var(--focus, #49a1ff)

a.blx-focus
  outline: none
  color: #fff
  background-color: #49a1ff
  background-color: var(--focus, #49a1ff)

.blx-link.blx-disabled, a.blx-disabled
  opacity: .3
  pointer-events: none

.blx-link.blx-with-margin, a.blx-with-margin
  margin-top: 0
  margin-bottom: 6px

h1.blx-XL
  font-family: Graphik Web
  font-weight: 700
  font-size: 96px
  line-height: 96px
  letter-spacing: -3.25px

  &.blx-with-margin
    margin-top: 108px
    margin-bottom: 36px

.blx-h1, h1
  font-family: Graphik Web
  font-weight: 700
  font-size: 65px
  line-height: 72px
  letter-spacing: -2px

.blx-h1.blx-with-margin, h1.blx-with-margin
  margin-top: 108px
  margin-bottom: 36px

.blx-h2, h2
  font-family: Graphik Web
  font-weight: 700
  font-size: 39px
  line-height: 42px
  letter-spacing: -0.081px

.blx-h2.blx-with-margin, h2.blx-with-margin
  margin-top: 42px
  margin-bottom: 24px

.blx-h3, h3
  font-family: Graphik Web
  font-weight: 700
  font-size: 23px
  line-height: 24px
  letter-spacing: -0.005px

.blx-h3.blx-with-margin, h3.blx-with-margin
  margin-top: 24px
  margin-bottom: 12px

.blx-h4, h4
  font-family: Graphik Web
  font-weight: 700
  font-size: 16px
  line-height: 18px
  letter-spacing: 0.17px

.blx-h4.blx-with-margin, h4.blx-with-margin
  margin-top: 12px
  margin-bottom: 6px

.blx-h5, h5
  font-family: Graphik Web
  font-weight: 500
  font-size: 16px
  line-height: 18px
  letter-spacing: 0

.blx-h5.blx-with-margin, h5.blx-with-margin
  margin-top: 12px
  margin-bottom: 6px

.blx-ui-text
  font-family: Graphik Web
  font-weight: 400
  font-size: 14px
  line-height: 18px
  letter-spacing: 0

  &.blx-with-margin
    margin-top: 0
    margin-bottom: 6px

.blx-ui-caps
  font-family: Graphik Web
  font-weight: 400
  font-size: 14px
  line-height: 24px
  text-transform: uppercase
  letter-spacing: 0.8px

  &.blx-with-margin
    margin-top: 0
    margin-bottom: 12px

.blx-ui-small
  font-family: Graphik Web
  font-weight: 400
  font-size: 12px
  line-height: 18px
  letter-spacing: 0.15px

  &.blx-with-margin
    margin-top: 0
    margin-bottom: 6px

.blx-ui-small-caps
  font-family: Graphik Web
  font-weight: 500
  font-size: 11px
  line-height: 18px
  text-transform: uppercase
  letter-spacing: 1.65px

  &.blx-with-margin
    margin-top: 12px
    margin-bottom: 6px

.blx-capitalize
  text-transform: uppercase

.blx-interactive
  color: #009f6f
  color: var(--primary-00, #009f6f)

.blx-inactive, .blx-placeholder, .blx-unselected
  color: #7c8c88
  color: var(--dark-02, #7c8c88)

.blx-light
  color: #fff
  color: var(--secondary-00, #fff)

.blx-dark
  color: #2b3836
  color: var(--dark-00, #2b3836)

.blx-success
  color: #39cf94
  color: var(--success, #39cf94)

.blx-warning
  color: #fdb100
  color: var(--warning, #fdb100)

.blx-error
  color: #eb5534
  color: var(--danger-00, #eb5534)

.blx-information
  color: #6f6bf8
  color: var(--information, #6f6bf8)

.blx-invalid-input-message
  color: #eb5534
  color: var(--danger-00, #eb5534)
  font-size: 12px
  line-height: 16px

.blx-input-helper-message
  font-size: 12px
  letter-spacing: .15px
  color: #aab7b2
  color: var(--light-02, #aab7b2)
  font-family: Graphik Web
  font-weight: 400
  line-height: 18px

html
  font-family: Graphik Web
  font-weight: 400
  line-height: 18px

body, html
  font-size: 14px
  letter-spacing: 0
  color: #2b3836
  color: var(--dark-00, #2b3836)

body
  font-family: Graphik Web
  font-weight: 400
  line-height: 18px

html
  --focus:#49a1ff
  --primary-00:#009f6f
  --primary-01:#008b61
  --primary-02:#00704f
  --primary-03:rgba(0, 159, 111, 0.35)
  --secondary-00:#fff
  --secondary-01:#ecf5f1
  --secondary-02:#d5f0e5
  --shadow:rgba(170, 183, 178, 0.6)
  --light-00:#f6f8f6
  --light-01:#d9e1e0
  --light-02:#aab7b2
  --dark-00:#2b3836
  --dark-01:#505958
  --dark-02:#7c8c88
  --dark-03:rgba(43, 56, 54, 0.35)
  --danger-00:#eb5534
  --danger-01:#db5030
  --danger-02:#ad4626
  --danger-03:rgba(235, 85, 52, 0.35)
  --warning:#fdb100
  --success:#39cf94
  --information:#6f6bf8
  --brand-00:#a2ffbb
  --brand-01:#0f4

.blx-accordion-section
  position: relative
  overflow: hidden
  padding: 2px

.blx-accordion-trigger
  position: relative
  display: flex
  align-items: center
  height: 48px
  line-height: 48px
  padding: 0 16px
  cursor: pointer
  background-color: transparent
  font-weight: 500

  .blx-accordion-arrow
    margin-right: 12px
    -webkit-transform: rotate(0)
    transform: rotate(0)
    transition: -webkit-transform .2s
    transition: transform .2s
    transition: transform 0.2s, -webkit-transform 0.2s

.blx-accordion-content
  max-height: 0
  display: none
  overflow: hidden
  transition: max-height .25s
  background-color: transparent

input[type=checkbox][name=blx-accordion-tab]
  position: absolute
  opacity: 0
  z-index: -1

  &:focus + .blx-accordion-trigger, &.blx-focus + .blx-accordion-trigger
    outline: 1px solid #49a1ff
    outline: 1px solid var(--focus, #49a1ff)
    color: #2b3836
    color: var(--dark-00, #2b3836)

  &:active + .blx-accordion-trigger, &.blx-active + .blx-accordion-trigger
    color: #009f6f
    color: var(--primary-00, #009f6f)

  &:hover + .blx-accordion-trigger, &.blx-hover + .blx-accordion-trigger
    color: #008b61
    color: var(--primary-01, #008b61)

  &:checked
    + .blx-accordion-trigger .blx-accordion-arrow
      -webkit-transform: rotate(90deg)
      transform: rotate(90deg)
      -webkit-transform-origin: center
      transform-origin: center
      transition: -webkit-transform .2s
      transition: transform .2s
      transition: transform 0.2s, -webkit-transform 0.2s

    ~ .blx-accordion-content
      display: block
      padding: 2px

.blx-alert
  position: relative
  min-width: 392px
  max-width: 762px
  width: 90%
  min-height: 80px
  max-height: 222px
  display: flex
  border-top: 3px solid
  border-radius: 0 0 7px 7px
  background-color: #fff
  background-color: var(--secondary-00, #fff)
  box-shadow: 0 1px 2px 0 rgba(170, 183, 178, 0.6)
  box-shadow: 0 1px 2px 0 var(--shadow, rgba(170, 183, 178, 0.6))

.blx-alert-icon-container
  position: relative
  flex: 0 0 auto
  margin: 16px
  width: 32px
  height: 32px
  border-radius: 50%
  background-color: #d9e1e0
  background-color: var(--light-01, #d9e1e0)

  .blx-alert-icon
    position: absolute
    top: 6px
    left: 6px
    border-radius: 50%

  .blx-alert-icon-background
    position: absolute
    top: 6px
    left: 6px
    border-radius: 50%
    width: 20px
    height: 20px

  .blx-alert-icon
    fill: #fff
    fill: var(--secondary-00, #fff)

.blx-alert-text
  padding: 24px 32px 24px 0

  h4 + p
    margin-top: 18px

  p
    color: #2b3836
    color: var(--dark-00, #2b3836)
    margin-bottom: 18px

.blx-success .blx-alert-icon-background
  background-color: #39cf94
  background-color: var(--success, #39cf94)

.blx-warning .blx-alert-icon-background
  background-color: #fdb100
  background-color: var(--warning, #fdb100)

.blx-information .blx-alert-icon-background
  background-color: #6f6bf8
  background-color: var(--information, #6f6bf8)

.blx-error .blx-alert-icon-background
  background-color: #eb5534
  background-color: var(--danger-00, #eb5534)

.blx-alert-close
  position: absolute
  top: 16px
  right: 16px
  background: transparent
  border: none
  padding: 0
  cursor: pointer

  svg:hover
    fill: #008b61
    fill: var(--primary-01, #008b61)

.blx-breadcrumbs
  ul
    display: flex
    padding: 11px 16px 9px
    list-style-type: none
    margin: 0

  li
    overflow: hidden
    white-space: nowrap
    text-overflow: ellipsis

    &:before
      content: "/"
      padding-left: 16px
      padding-right: 16px

    &:first-child:before
      content: ""
      padding: 0

  a:hover
    text-decoration: none
    color: #6f6bf8
    color: var(--information, #6f6bf8)

.blx-button
  position: relative
  display: flex
  justify-content: center
  height: 44px
  max-width: 480px
  min-width: 44px
  margin: 0
  padding: 8px 18px
  text-decoration: none
  border-radius: 7px
  cursor: pointer
  font-family: Graphik Web
  font-weight: 500
  font-size: 16px
  line-height: 18px
  letter-spacing: 0
  line-height: 20px
  text-align: center
  box-shadow: 0 1px 2px 0 rgba(170, 183, 178, 0.6)
  box-shadow: 0 1px 2px 0 var(--shadow, rgba(170, 183, 178, 0.6))

  &.blx-with-margin
    margin: 12px

  &.blx-focus, &:focus
    outline: none
    text-decoration: none

  &.blx-hover, &:hover
    text-decoration: none

  span
    white-space: nowrap
    overflow: hidden
    text-overflow: ellipsis
    line-height: 20px

    &:not(:first-child)
      margin-left: 12px

    &:not(:last-child)
      margin-right: 12px

.blx-icon-button
  width: 44px
  padding: 11px

.blx-primary
  background-color: #009f6f
  background-color: var(--primary-00, #009f6f)
  border: 2px solid #009f6f
  border: 2px solid var(--primary-00, #009f6f)
  color: #fff
  color: var(--secondary-00, #fff)

  svg
    fill: #fff
    fill: var(--secondary-00, #fff)

  &:focus, &.blx-focus
    border: 2px solid #49a1ff
    border: 2px solid var(--focus, #49a1ff)
    background-color: #008b61
    background-color: var(--primary-01, #008b61)
    color: #fff
    color: var(--secondary-00, #fff)

  &:focus:before, &.blx-focus:before
    content: ""
    position: absolute
    left: 0
    top: 0
    border-radius: 5px
    width: calc(100% - 2px)
    height: calc(100% - 2px)
    border: 1px solid #fff
    border: 1px solid var(--secondary-00, #fff)

  &:hover
    background-color: #008b61
    background-color: var(--primary-01, #008b61)
    border: 2px solid #008b61
    border: 2px solid var(--primary-01, #008b61)
    color: #fff
    color: var(--secondary-00, #fff)

  &.blx-hover
    background-color: #008b61
    background-color: var(--primary-01, #008b61)
    border: 2px solid #008b61
    border: 2px solid var(--primary-01, #008b61)
    color: #fff
    color: var(--secondary-00, #fff)

    &:before
      content: none

  &:hover:before
    content: none

  &:active
    background-color: #00704f
    background-color: var(--primary-02, #00704f)
    border: 2px solid #00704f
    border: 2px solid var(--primary-02, #00704f)
    color: #fff
    color: var(--secondary-00, #fff)

  &.blx-active
    background-color: #00704f
    background-color: var(--primary-02, #00704f)
    border: 2px solid #00704f
    border: 2px solid var(--primary-02, #00704f)
    color: #fff
    color: var(--secondary-00, #fff)

    &:before
      content: none

  &:active:before
    content: none

.blx-secondary
  background-color: #fff
  background-color: var(--secondary-00, #fff)
  border: 2px solid #fff
  border: 2px solid var(--secondary-00, #fff)
  color: #009f6f
  color: var(--primary-00, #009f6f)

  svg
    fill: #009f6f
    fill: var(--primary-00, #009f6f)

  &:focus, &.blx-focus
    border: 2px solid #49a1ff
    border: 2px solid var(--focus, #49a1ff)
    background-color: #ecf5f1
    background-color: var(--secondary-01, #ecf5f1)
    color: #009f6f
    color: var(--primary-00, #009f6f)

  &:hover
    background-color: #ecf5f1
    background-color: var(--secondary-01, #ecf5f1)
    border: 2px solid #ecf5f1
    border: 2px solid var(--secondary-01, #ecf5f1)
    color: #009f6f
    color: var(--primary-00, #009f6f)

  &.blx-hover
    background-color: #ecf5f1
    background-color: var(--secondary-01, #ecf5f1)
    border: 2px solid #ecf5f1
    border: 2px solid var(--secondary-01, #ecf5f1)
    color: #009f6f
    color: var(--primary-00, #009f6f)

    &:before
      content: none

  &:hover:before
    content: none

  &:active
    background-color: #d5f0e5
    background-color: var(--secondary-02, #d5f0e5)
    border: 2px solid #d5f0e5
    border: 2px solid var(--secondary-02, #d5f0e5)
    color: #009f6f
    color: var(--primary-00, #009f6f)

  &.blx-active
    background-color: #d5f0e5
    background-color: var(--secondary-02, #d5f0e5)
    border: 2px solid #d5f0e5
    border: 2px solid var(--secondary-02, #d5f0e5)
    color: #009f6f
    color: var(--primary-00, #009f6f)

    &:before
      content: none

  &:active:before
    content: none

.blx-danger
  background-color: #eb5534
  background-color: var(--danger-00, #eb5534)
  border: 2px solid #eb5534
  border: 2px solid var(--danger-00, #eb5534)
  color: #fff
  color: var(--secondary-00, #fff)

  svg
    fill: #fff
    fill: var(--secondary-00, #fff)

  &:focus, &.blx-focus
    border: 2px solid #49a1ff
    border: 2px solid var(--focus, #49a1ff)
    background-color: #db5030
    background-color: var(--danger-01, #db5030)
    color: #fff
    color: var(--secondary-00, #fff)

  &:focus:before, &.blx-focus:before
    content: ""
    position: absolute
    left: 0
    top: 0
    border-radius: 5px
    width: calc(100% - 2px)
    height: calc(100% - 2px)
    border: 1px solid #fff
    border: 1px solid var(--secondary-00, #fff)

  &:focus svg, &.blx-focus svg
    fill: #fff
    fill: var(--secondary-00, #fff)

  &:hover
    background-color: #db5030
    background-color: var(--danger-01, #db5030)
    border: 2px solid #db5030
    border: 2px solid var(--danger-01, #db5030)
    color: #fff
    color: var(--secondary-00, #fff)

  &.blx-hover
    background-color: #db5030
    background-color: var(--danger-01, #db5030)
    border: 2px solid #db5030
    border: 2px solid var(--danger-01, #db5030)
    color: #fff
    color: var(--secondary-00, #fff)

    &:before
      content: none

  &:hover
    &:before
      content: none

    svg
      fill: #fff
      fill: var(--secondary-00, #fff)

  &.blx-hover svg
    fill: #fff
    fill: var(--secondary-00, #fff)

  &:active
    background-color: #ad4626
    background-color: var(--danger-02, #ad4626)
    border: 2px solid #ad4626
    border: 2px solid var(--danger-02, #ad4626)
    color: #fff
    color: var(--secondary-00, #fff)

  &.blx-active
    background-color: #ad4626
    background-color: var(--danger-02, #ad4626)
    border: 2px solid #ad4626
    border: 2px solid var(--danger-02, #ad4626)
    color: #fff
    color: var(--secondary-00, #fff)

    &:before
      content: none

  &:active
    &:before
      content: none

    svg
      fill: #fff
      fill: var(--secondary-00, #fff)

  &.blx-active svg
    fill: #fff
    fill: var(--secondary-00, #fff)

.blx-dark
  background-color: #2b3836
  background-color: var(--dark-00, #2b3836)
  border: 2px solid #2b3836
  border: 2px solid var(--dark-00, #2b3836)
  color: #fff
  color: var(--secondary-00, #fff)

  .blx-icon
    background-color: #fff
    background-color: var(--secondary-00, #fff)

  &:focus, &.blx-focus
    border: 2px solid #49a1ff
    border: 2px solid var(--focus, #49a1ff)
    background-color: #505958
    background-color: var(--dark-01, #505958)
    color: #fff
    color: var(--secondary-00, #fff)

  &:focus:before, &.blx-focus:before
    content: ""
    position: absolute
    left: 0
    top: 0
    border-radius: 5px
    width: calc(100% - 2px)
    height: calc(100% - 2px)
    border: 1px solid #fff
    border: 1px solid var(--secondary-00, #fff)

  &:focus .blx-icon, &.blx-focus .blx-icon
    background-color: #fff
    background-color: var(--secondary-00, #fff)

  &:hover
    background-color: #505958
    background-color: var(--dark-01, #505958)
    border: 2px solid #505958
    border: 2px solid var(--dark-01, #505958)
    color: #fff
    color: var(--secondary-00, #fff)

  &.blx-hover
    background-color: #505958
    background-color: var(--dark-01, #505958)
    border: 2px solid #505958
    border: 2px solid var(--dark-01, #505958)
    color: #fff
    color: var(--secondary-00, #fff)

    &:before
      content: none

  &:hover
    &:before
      content: none

    .blx-icon
      background-color: #fff
      background-color: var(--secondary-00, #fff)

  &.blx-hover .blx-icon
    background-color: #fff
    background-color: var(--secondary-00, #fff)

  &:active
    background-color: #7c8c88
    background-color: var(--dark-02, #7c8c88)
    border: 2px solid #7c8c88
    border: 2px solid var(--dark-02, #7c8c88)
    color: #fff
    color: var(--secondary-00, #fff)

  &.blx-active
    background-color: #7c8c88
    background-color: var(--dark-02, #7c8c88)
    border: 2px solid #7c8c88
    border: 2px solid var(--dark-02, #7c8c88)
    color: #fff
    color: var(--secondary-00, #fff)

    &:before
      content: none

  &:active
    &:before
      content: none

    .blx-icon
      background-color: #fff
      background-color: var(--secondary-00, #fff)

  &.blx-active .blx-icon
    background-color: #fff
    background-color: var(--secondary-00, #fff)

a.blx-button
  display: inline-flex
  align-items: center

.blx-button-group .blx-button
  margin-right: 10px
  margin-bottom: 12px

  &:last-child
    margin-right: 0

.blx-h-button-group
  display: flex
  flex-direction: row
  min-height: 46px

  .blx-button
    margin-left: 0
    margin-right: 10px

    &:first-child
      margin-left: 20px

    &:last-child
      margin-right: 20px

.blx-v-button-group
  display: flex
  flex-direction: column

  .blx-button
    margin: 0 10px 12px

    &:first-child
      margin-top: 24px

    &:last-child
      margin-bottom: 24px

.blx-checkbox
  display: block
  position: relative
  padding-left: 34px
  cursor: pointer
  -webkit-user-select: none
  -moz-user-select: none
  -ms-user-select: none
  user-select: none

  .blx-with-margin
    margin: 8px 0

  .blx-label
    line-height: 20px

  .blx-checkmark
    position: absolute
    top: -2px
    left: 0
    height: 18px
    width: 18px
    border-radius: 7px
    margin: 3px 0
    background-color: #fff
    background-color: var(--secondary-00, #fff)
    border: 1px solid #d9e1e0
    border: 1px solid var(--light-01, #d9e1e0)

    &:after
      display: none
      box-sizing: content-box
      content: ""
      position: absolute
      left: 6px
      top: 6px
      width: 3px
      height: 7px
      border: solid #fff
      border: solid var(--secondary-00, #fff)
      border-width: 0 2px 2px 0
      -webkit-transform: rotate(45deg)
      transform: rotate(45deg)

  input[type=checkbox]
    position: absolute
    opacity: 0
    cursor: pointer

    &:checked
      ~ .blx-checkmark
        background-color: #009f6f
        background-color: var(--primary-00, #009f6f)
        border: 1px solid #009f6f
        border: 1px solid var(--primary-00, #009f6f)

        &:after
          display: block
          left: 6px
          top: 3px

      &:focus + .blx-checkmark, &.blx-focus + .blx-checkmark
        background-color: #009f6f
        background-color: var(--primary-00, #009f6f)

    &:focus + .blx-checkmark, &.blx-focus + .blx-checkmark
      outline: none
      border: 2px solid #49a1ff
      border: 2px solid var(--focus, #49a1ff)
      background-color: #ecf5f1
      background-color: var(--secondary-01, #ecf5f1)

    &:focus + .blx-checkmark:before
      content: ""
      position: absolute
      left: 0
      top: 0
      border-radius: 5px
      width: calc(100% - 2px)
      height: calc(100% - 2px)
      border: 1px solid #fff
      border: 1px solid var(--secondary-00, #fff)

    &.blx-focus + .blx-checkmark
      &:before
        content: ""
        position: absolute
        left: 0
        top: 0
        border-radius: 5px
        width: calc(100% - 2px)
        height: calc(100% - 2px)
        border: 1px solid #fff
        border: 1px solid var(--secondary-00, #fff)

      &:after
        left: 5px
        top: 2px

    &:focus + .blx-checkmark:after
      left: 5px
      top: 2px

  &:hover input[type=checkbox] ~ .blx-checkmark
    background-color: #ecf5f1
    background-color: var(--secondary-01, #ecf5f1)
    border: 1px solid #aab7b2
    border: 1px solid var(--light-02, #aab7b2)

  &.blx-hover input[type=checkbox] ~ .blx-checkmark
    background-color: #ecf5f1
    background-color: var(--secondary-01, #ecf5f1)
    border: 1px solid #aab7b2
    border: 1px solid var(--light-02, #aab7b2)

    &:before
      display: none

  &:hover input[type=checkbox] ~ .blx-checkmark:before
    display: none

  &.blx-hover input[type=checkbox] ~ .blx-checkmark:after
    left: 6px
    top: 3px

  &:hover input[type=checkbox]
    ~ .blx-checkmark:after
      left: 6px
      top: 3px

    &:checked ~ .blx-checkmark
      background-color: #008b61
      background-color: var(--primary-01, #008b61)
      border: 1px solid #008b61
      border: 1px solid var(--primary-01, #008b61)

  &.blx-hover input[type=checkbox]:checked ~ .blx-checkmark
    background-color: #008b61
    background-color: var(--primary-01, #008b61)
    border: 1px solid #008b61
    border: 1px solid var(--primary-01, #008b61)

    &:before
      display: none

  &:hover input[type=checkbox]:checked ~ .blx-checkmark:before
    display: none

  &.blx-hover input[type=checkbox]:checked ~ .blx-checkmark:after, &:hover input[type=checkbox]:checked ~ .blx-checkmark:after
    left: 6px
    top: 3px

  &:active input[type=checkbox] ~ .blx-checkmark
    background-color: #d5f0e5
    background-color: var(--secondary-02, #d5f0e5)
    border: 1px solid #aab7b2
    border: 1px solid var(--light-02, #aab7b2)

  &.blx-active input[type=checkbox] ~ .blx-checkmark
    background-color: #d5f0e5
    background-color: var(--secondary-02, #d5f0e5)
    border: 1px solid #aab7b2
    border: 1px solid var(--light-02, #aab7b2)

    &:before
      display: none

  &:active input[type=checkbox] ~ .blx-checkmark:before
    display: none

  &.blx-active input[type=checkbox] ~ .blx-checkmark:after
    left: 6px
    top: 3px

  &:active input[type=checkbox]
    ~ .blx-checkmark:after
      left: 6px
      top: 3px

    &:checked ~ .blx-checkmark
      background-color: #00704f
      background-color: var(--primary-02, #00704f)
      border: 1px solid #00704f
      border: 1px solid var(--primary-02, #00704f)

  &.blx-active input[type=checkbox]:checked ~ .blx-checkmark
    background-color: #00704f
    background-color: var(--primary-02, #00704f)
    border: 1px solid #00704f
    border: 1px solid var(--primary-02, #00704f)

    &:before
      display: none

  &:active input[type=checkbox]:checked ~ .blx-checkmark:before
    display: none

  &.blx-active input[type=checkbox]:checked ~ .blx-checkmark:after, &:active input[type=checkbox]:checked ~ .blx-checkmark:after
    left: 6px
    top: 3px

  &.blx-disabled
    cursor: pointer

.blx-dropdown-wrapper
  display: block

.blx-dropdown
  position: relative
  display: flex
  flex-direction: column
  min-width: 150px
  max-width: 300px

  label
    margin-bottom: 6px

.blx-dropdown-trigger
  position: relative
  display: flex
  justify-content: space-between
  align-items: center
  height: 40px
  padding: 0 16px
  border-radius: 7px
  border: 1px solid #d9e1e0
  border: 1px solid var(--light-01, #d9e1e0)
  background-color: #fff
  background-color: var(--secondary-00, #fff)
  margin: 0
  cursor: pointer

  .blx-dropdown-arrow
    width: 20px
    height: 20px
    flex: 0 0 auto
    border: none
    background-color: transparent
    background-image: url(#{$blocks-base-assets-path}/svgs/icon-down.svg)
    -webkit-transform: rotate(0)
    transform: rotate(0)
    transition: -webkit-transform .2s
    transition: transform .2s
    transition: transform 0.2s, -webkit-transform 0.2s

  .blx-dropdown-placeholder, .blx-dropdown-text
    white-space: nowrap
    overflow: hidden
    text-overflow: ellipsis
    font-family: Graphik Web
    font-weight: 400
    font-size: 14px
    line-height: 18px
    letter-spacing: 0
    color: #aab7b2
    color: var(--light-02, #aab7b2)

  .blx-dropdown-placeholder.blx-with-margin, .blx-dropdown-text.blx-with-margin
    margin-top: 0
    margin-bottom: 6px

  .blx-dropdown-placeholder:not(:first-child), .blx-dropdown-text:not(:first-child)
    margin-left: 12px

  .blx-dropdown-placeholder:not(:last-child)
    margin-right: 12px

  .blx-dropdown-text
    &:not(:last-child)
      margin-right: 12px

    color: #2b3836
    color: var(--dark-00, #2b3836)

  .blx-dropdown-placeholder
    color: #7c8c88
    color: var(--dark-02, #7c8c88)

  &.blx-active
    border-radius: 7px 7px 0 0

    .blx-dropdown-arrow
      -webkit-transform: rotate(180deg)
      transform: rotate(180deg)
      transition: -webkit-transform .2s
      transition: transform .2s
      transition: transform 0.2s, -webkit-transform 0.2s

  &.blx-invalid
    border: 1px solid #eb5534
    border: 1px solid var(--danger-00, #eb5534)

  &:focus, &.blx-focus
    margin: 0
    outline: none
    background-color: #fff
    background-color: var(--secondary-00, #fff)
    border: 1px solid #49a1ff
    border: 1px solid var(--focus, #49a1ff)
    color: #2b3836
    color: var(--dark-00, #2b3836)

  &:hover
    background-color: #fff
    background-color: var(--secondary-00, #fff)
    border: 1px solid #aab7b2
    border: 1px solid var(--light-02, #aab7b2)

  &.blx-hover
    border: 1px solid #aab7b2
    border: 1px solid var(--light-02, #aab7b2)

.blx-dropdown-menu, .blx-dropdown-trigger.blx-hover
  background-color: #fff
  background-color: var(--secondary-00, #fff)

.blx-dropdown-menu
  position: absolute
  top: 100%
  width: 100%
  border-radius: 0 0 7px 7px
  z-index: 1
  list-style-type: none
  overflow: hidden
  border: 1px solid #d9e1e0
  border: 1px solid var(--light-01, #d9e1e0)
  box-shadow: 0 1px 2px 0 rgba(170, 183, 178, 0.6)
  box-shadow: 0 1px 2px 0 var(--shadow, rgba(170, 183, 178, 0.6))

.blx-dropdown-list.blx-scrollable
  max-height: 140px
  overflow-y: auto

.blx-dropdown-item
  position: relative
  background: transparent
  overflow: hidden

  a, button
    display: block
    width: 100%
    height: 40px
    padding: 8px 16px
    background: transparent
    border: none
    text-decoration: none
    font-size: 14px
    font-weight: 400
    line-height: 20px
    text-align: left
    white-space: nowrap
    overflow: hidden
    text-overflow: ellipsis
    cursor: pointer
    color: #2b3836
    color: var(--dark-00, #2b3836)

  a:focus, button:focus, a.blx-focus, button.blx-focus, a:active, button:active, a.blx-active, button.blx-active
    outline: none
    color: #2b3836
    color: var(--dark-00, #2b3836)
    background-color: #ecf5f1
    background-color: var(--secondary-01, #ecf5f1)

  &.blx-selected
    a, button
      padding-left: 36px

    a:before, button:before
      content: ""
      position: absolute
      top: 16px
      left: 0
      margin-left: 16px
      width: 8px
      height: 8px
      border-radius: 50%
      background-color: #009f6f
      background-color: var(--primary-00, #009f6f)

  &:hover, &.blx-hover
    background-color: #ecf5f1
    background-color: var(--secondary-01, #ecf5f1)

  &:hover a, &.blx-hover a, &:hover button, &.blx-hover button
    text-decoration: none
    color: #2b3836
    color: var(--dark-00, #2b3836)

  &:last-child
    border-radius: 0 0 0 7px

.blx-popover-wrapper
  display: inline-flex
  align-items: center

  .blx-h5
    margin: 0 10px 0 0

.blx-popover-trigger
  display: flex
  outline: none
  padding: 5px
  margin: 0
  background-color: transparent
  cursor: pointer
  text-align: left
  border: 1px solid transparent
  border-radius: 7px

  &:active svg, &.blx-active svg
    fill: #008b61
    fill: var(--primary-01, #008b61)

  &:focus, &.blx-focus
    border: 1px solid #49a1ff
    border: 1px solid var(--focus, #49a1ff)

  &:focus svg, &.blx-focus svg
    fill: #00704f
    fill: var(--primary-02, #00704f)

  &:hover, &.blx-hover
    background-color: #f6f8f6
    background-color: var(--light-00, #f6f8f6)

  &:hover svg, &.blx-hover svg
    fill: #00704f
    fill: var(--primary-02, #00704f)

  &:disabled svg, &.blx-disabled svg
    fill: #aab7b2
    fill: var(--light-02, #aab7b2)

.blx-popover
  min-width: auto

  .blx-dropdown-item:first-child
    border-radius: 7px 0 0 0

.blx-popover-menu
  position: absolute
  top: 100%
  width: 100%
  border-radius: 7px
  z-index: 1
  list-style-type: none
  background-color: #fff
  background-color: var(--secondary-00, #fff)
  border: 1px solid #d9e1e0
  border: 1px solid var(--light-01, #d9e1e0)
  box-shadow: 0 1px 2px 0 rgba(170, 183, 178, 0.6)
  box-shadow: 0 1px 2px 0 var(--shadow, rgba(170, 183, 178, 0.6))
  min-width: 136px
  max-width: 330px
  width: auto

  &:before
    content: ""
    position: absolute
    width: 0
    height: 0
    top: -8px
    border-left: 8px solid transparent
    border-right: 8px solid transparent
    border-bottom: 8px solid #d9e1e0
    border-bottom: 8px solid var(--light-01, #d9e1e0)

  &:after
    content: ""
    position: absolute
    width: 0
    height: 0
    top: -7px
    border-left: 7px solid transparent
    border-right: 7px solid transparent
    border-bottom: 7px solid #fff
    border-bottom: 7px solid var(--secondary-00, #fff)

.blx-popover-menu-wrapper
  width: 100%
  border-radius: 7px
  overflow: hidden

.blx-popover-is-center
  left: 0
  -webkit-transform-origin: center
  transform-origin: center
  -webkit-transform: translate(calc(-50% + 10px), 12px)
  transform: translate(calc(-50% + 10px), 12px)

  &:before
    left: calc(50% - 1px)

  &:after
    left: 50%

.blx-popover-is-left
  left: 0
  -webkit-transform-origin: left
  transform-origin: left
  -webkit-transform: translate(-9px, 12px)
  transform: translate(-9px, 12px)

  &:before
    left: 15px

  &:after
    left: 16px

.blx-popover-is-right
  right: 0
  -webkit-transform-origin: right
  transform-origin: right
  -webkit-transform: translate(9px, 12px)
  transform: translate(9px, 12px)

  &:before
    right: 15px

  &:after
    right: 16px

.blx-icon
  display: inline-block
  width: 20px
  height: 20px
  flex: 0 0 auto
  border: none
  background-color: transparent
  background-repeat: no-repeat

.blx-icon-add, .blx-icon-plus
  background-image: url(#{$blocks-base-assets-path}/svgs/icon-add.svg)

.blx-icon-back
  background-image: url(#{$blocks-base-assets-path}/svgs/icon-back.svg)

.blx-icon-forward, .blx-icon-next
  background-image: url(#{$blocks-base-assets-path}/svgs/icon-next.svg)

.blx-icon-down
  background-image: url(#{$blocks-base-assets-path}/svgs/icon-down.svg)

.blx-icon-up
  background-image: url(#{$blocks-base-assets-path}/svgs/icon-up.svg)

.blx-icon-close
  background-image: url(#{$blocks-base-assets-path}/svgs/icon-close.svg)

.blx-icon-more, .blx-icon-more-horizontal
  background-image: url(#{$blocks-base-assets-path}/svgs/icon-more.svg)

.blx-icon-more-vertical
  background-image: url(#{$blocks-base-assets-path}/svgs/icon-more.svg)
  -webkit-transform: rotate(90deg)
  transform: rotate(90deg)

.blx-icon-success
  background-image: url(#{$blocks-base-assets-path}/svgs/icon-success.svg)

.blx-icon-information
  background-image: url(#{$blocks-base-assets-path}/svgs/icon-information.svg)

.blx-icon-warning
  background-image: url(#{$blocks-base-assets-path}/svgs/icon-warning.svg)

.blx-icon-error
  background-image: url(#{$blocks-base-assets-path}/svgs/icon-error.svg)

.blx-icon-drag
  background-image: url(#{$blocks-base-assets-path}/svgs/icon-drag.svg)

.blx-icon-help
  background-image: url(#{$blocks-base-assets-path}/svgs/icon-information.svg)

.blx-modal
  position: fixed
  top: 50%
  left: 50%
  -webkit-transform: translate(-50%, -50%)
  transform: translate(-50%, -50%)
  width: 600px
  max-height: 90%
  z-index: 1001
  padding: 24px 40px
  display: flex
  flex-direction: column
  border-radius: 3px
  border: 1px solid #d9e1e0
  border: 1px solid var(--light-01, #d9e1e0)
  background-color: #fff
  background-color: var(--secondary-00, #fff)
  box-shadow: 0 1px 2px 0 rgba(170, 183, 178, 0.6)
  box-shadow: 0 1px 2px 0 var(--shadow, rgba(170, 183, 178, 0.6))

  .blx-modal-close
    position: absolute
    top: 16px
    right: 16px
    background: transparent
    border: none
    padding: 0
    cursor: pointer

    svg:hover
      fill: #008b61
      fill: var(--primary-01, #008b61)

.blx-action-modal
  padding: 0

  .blx-modal-content
    padding: 24px 40px

.blx-modal-buttons
  justify-content: flex-end
  padding: 12px 0 24px
  border-top: 1px solid #d9e1e0
  border-top: 1px solid var(--light-01, #d9e1e0)

.blx-modal-overlay
  position: fixed
  top: 0
  left: 0
  width: 100%
  height: 100%
  opacity: .5
  z-index: 1000
  background-color: #aab7b2
  background-color: var(--light-02, #aab7b2)

.blx-modal-content
  display: flex
  flex-direction: column
  width: 100%
  overflow: hidden

.blx-modal-title
  display: flex
  align-items: center
  margin-bottom: 16px

.blx-modal-icon
  border-radius: 50%
  width: 30px
  height: 30px
  margin-right: 15px

.blx-success-modal
  border-top: 4px solid #39cf94
  border-top: 4px solid var(--success, #39cf94)

  .blx-modal-icon
    background: #39cf94
    background: var(--success, #39cf94)
    fill: #fff

.blx-information-modal
  border-top: 4px solid #6f6bf8
  border-top: 4px solid var(--information, #6f6bf8)

  .blx-modal-icon
    background: #6f6bf8
    background: var(--information, #6f6bf8)
    fill: #fff

.blx-warning-modal
  border-top: 4px solid #fdb100
  border-top: 4px solid var(--warning, #fdb100)

  .blx-modal-icon
    background: #fdb100
    background: var(--warning, #fdb100)
    fill: #2b3836
    fill: var(--dark-00, #2b3836)

.blx-error-modal
  border-top: 4px solid #eb5534
  border-top: 4px solid var(--danger-00, #eb5534)

  .blx-modal-icon
    background: #eb5534
    background: var(--danger-00, #eb5534)
    fill: #fff

.blx-progress-dots
  display: flex

  .blx-progress-dot-container
    position: relative
    margin-right: 8px

    input
      position: absolute
      left: -9999px
      opacity: 0
      cursor: pointer

      &:checked ~ .blx-progress-dot
        background-color: #009f6f
        background-color: var(--primary-00, #009f6f)

      &:focus ~ .blx-progress-dot
        background-color: #49a1ff
        background-color: var(--focus, #49a1ff)

    .blx-progress-dot
      display: block
      position: relative
      height: 8px
      width: 8px
      border-radius: 50%
      background-color: #d9e1e0
      background-color: var(--light-01, #d9e1e0)

      &:hover
        cursor: pointer
        background-color: #49a1ff
        background-color: var(--focus, #49a1ff)

.blx-radio
  display: block
  position: relative
  padding-left: 34px
  margin: 0
  cursor: pointer
  -webkit-user-select: none
  -moz-user-select: none
  -ms-user-select: none
  user-select: none

  .blx-with-margin
    margin: 9px 0

  .blx-label
    line-height: 18px

  .blx-radio-check
    position: absolute
    top: 1px
    left: 1px
    height: 16px
    width: 16px
    border-radius: 50%
    background-color: #fff
    background-color: var(--secondary-00, #fff)
    border: 1px solid #d9e1e0
    border: 1px solid var(--light-01, #d9e1e0)

    &:after
      display: none
      content: ""
      position: absolute
      top: 3px
      left: 3px
      width: 8px
      height: 8px
      border-radius: 50%
      background-color: #009f6f
      background-color: var(--primary-00, #009f6f)

  input[type=radio]
    position: absolute
    opacity: 0
    cursor: pointer

    &:checked
      ~ .blx-radio-check
        border: 1px solid #009f6f
        border: 1px solid var(--primary-00, #009f6f)

        &:after
          display: block

      &:focus + .blx-radio-check
        outline: none
        border: 1px solid #49a1ff
        border: 1px solid var(--focus, #49a1ff)

      &.blx-focus + .blx-radio-check
        outline: none
        border: 1px solid #49a1ff
        border: 1px solid var(--focus, #49a1ff)

        &:before
          display: block

      &:focus + .blx-radio-check:before
        display: block

      &:active + .blx-radio-check, &.blx-active + .blx-radio-check
        border: 1px solid #009f6f
        border: 1px solid var(--primary-00, #009f6f)

    &:focus + .blx-radio-check, &.blx-focus + .blx-radio-check
      outline: none
      border: 1px solid #49a1ff
      border: 1px solid var(--focus, #49a1ff)

    &:active + .blx-radio-check, &.blx-active + .blx-radio-check
      background-color: #fff
      background-color: var(--secondary-00, #fff)
      border: 1px solid #aab7b2
      border: 1px solid var(--light-02, #aab7b2)

  &:hover input[type=radio] ~ .blx-radio-check, &.blx-hover input[type=radio] ~ .blx-radio-check
    background-color: #fff
    background-color: var(--secondary-00, #fff)
    border: 1px solid #aab7b2
    border: 1px solid var(--light-02, #aab7b2)

  &:hover input[type=radio]:checked ~ .blx-radio-check, &.blx-hover input[type=radio]:checked ~ .blx-radio-check
    background-color: #fff
    background-color: var(--secondary-00, #fff)
    border: 1px solid #008b61
    border: 1px solid var(--primary-01, #008b61)

  &:hover input[type=radio]:checked ~ .blx-radio-check:after, &.blx-hover input[type=radio]:checked ~ .blx-radio-check:after
    background-color: #008b61
    background-color: var(--primary-01, #008b61)

  &.blx-disabled
    box-shadow: none
    cursor: pointer

  &.blx-invalid input[type=radio]
    ~ .blx-radio-check
      background-color: #fff
      background-color: var(--secondary-00, #fff)
      border: 1px solid #eb5534
      border: 1px solid var(--danger-00, #eb5534)

    &:hover
      ~ .blx-radio-check
        background-color: #fff
        background-color: var(--secondary-00, #fff)
        border: 1px solid #eb5534
        border: 1px solid var(--danger-00, #eb5534)

      ~ .blx-radio-check:after
        background-color: #eb5534
        background-color: var(--danger-00, #eb5534)

    ~ .blx-radio-check:after
      background-color: #eb5534
      background-color: var(--danger-00, #eb5534)

.blx-sortable-list
  background: transparent
  list-style: none
  margin: 0
  padding: 0

  .blx-sortable-list-item
    position: relative
    display: flex
    justify-content: space-between
    border-color: transparent
    border-style: solid
    border-width: 2px 0

    &.blx-dragging
      background-color: #49a1ff
      background-color: var(--focus, #49a1ff)

    &.blx-dragover-top
      border-top: 2px solid #aab7b2
      border-top: 2px solid var(--light-02, #aab7b2)

    &.blx-dragover-bottom
      border-bottom: 2px solid #aab7b2
      border-bottom: 2px solid var(--light-02, #aab7b2)

    .blx-icon-drag
      position: relative
      right: 0
      margin: 8px
      cursor: move

.blx-table
  margin: 12px 16px
  border-collapse: collapse
  color: #2b3836
  color: var(--dark-00, #2b3836)
  border: 1px solid #d9e1e0
  border: 1px solid var(--light-01, #d9e1e0)

  .blx-table-header-row
    height: 56px
    font-size: 16px
    font-weight: 500
    line-height: 20px
    background-color: #d9e1e0
    background-color: var(--light-01, #d9e1e0)

  .blx-table-row
    height: 48px
    font-size: 16px
    font-weight: 400
    line-height: 20px
    background-color: #ecf5f1
    background-color: var(--secondary-01, #ecf5f1)
    border-top: 1px solid #d9e1e0
    border-top: 1px solid var(--light-01, #d9e1e0)

    &:hover
      cursor: pointer
      background-color: #d9e1e0
      background-color: var(--light-01, #d9e1e0)
      border: 1px solid #009f6f
      border: 1px solid var(--primary-00, #009f6f)

  .blx-table-cell, .blx-table-header-cell
    padding: 0 28px
    border: none
    text-align: left

  .blx-table-cell:first-child, .blx-table-header-cell:first-child
    padding-left: 24px

  .blx-table-cell:last-child, .blx-table-header-cell:last-child
    padding-right: 24px

  .blx-table-cell
    text-align: left

    &.numeric
      text-align: right

.blx-horizontal-tabs, .blx-vertical-tabs
  list-style: none

.blx-horizontal-tabs input[type=radio], .blx-vertical-tabs input[type=radio]
  position: absolute
  left: -9999px
  opacity: 0

.blx-tab-item
  -webkit-user-select: none
  -moz-user-select: none
  -ms-user-select: none
  user-select: none
  background-color: transparent
  position: relative
  vertical-align: middle
  font-weight: 500
  font-size: 14px
  line-height: 32px
  white-space: nowrap
  overflow: hidden
  text-overflow: ellipsis
  cursor: pointer
  text-decoration: none
  color: #7c8c88
  color: var(--dark-02, #7c8c88)

  &:after
    content: ""
    position: absolute
    background-color: transparent

  &:hover
    text-decoration: none
    color: #008b61
    color: var(--primary-01, #008b61)
    background-color: transparent

  &.blx-hover
    text-decoration: none
    background-color: transparent

.blx-horizontal-tabs
  display: inline-flex

  .blx-tab-item
    padding: 10px 16px

    &:after
      bottom: 4px
      left: 16px
      width: calc(100% - 32px)
      height: 2px

  input[type=radio]:focus + .blx-tab-item
    background-color: transparent
    color: #2b3836
    color: var(--dark-00, #2b3836)
    outline: 2px solid #49a1ff
    outline: 2px solid var(--focus, #49a1ff)

  .blx-tab-item
    &:focus, &.blx-focus
      background-color: transparent
      color: #2b3836
      color: var(--dark-00, #2b3836)
      outline: 2px solid #49a1ff
      outline: 2px solid var(--focus, #49a1ff)

.blx-vertical-tabs
  display: inline-block

  .blx-tab-item
    display: block
    min-height: 48px
    height: 48px
    line-height: 48px
    padding: 0 16px 0 48px

    &:after
      top: 8px
      left: 28px
      height: 32px
      width: 4px
      color: transparent

  input[type=radio]:focus + .blx-tab-item
    background-color: transparent
    color: #2b3836
    color: var(--dark-00, #2b3836)
    outline: 1px solid #49a1ff
    outline: 1px solid var(--focus, #49a1ff)

  .blx-tab-item
    &:focus, &.blx-focus
      background-color: transparent
      color: #2b3836
      color: var(--dark-00, #2b3836)
      outline: 1px solid #49a1ff
      outline: 1px solid var(--focus, #49a1ff)

input[type=radio]:checked + .blx-tab-item, .blx-tab-item.blx-active
  color: #009f6f
  color: var(--primary-00, #009f6f)

input[type=radio]:checked + .blx-tab-item:after, .blx-tab-item.blx-active:after
  background-color: #009f6f
  background-color: var(--primary-00, #009f6f)

input[type=radio]:focus + .blx-tab-item
  background-color: transparent
  outline: 2px solid #49a1ff
  outline: 2px solid var(--focus, #49a1ff)
  color: #2b3836
  color: var(--dark-00, #2b3836)

.blx-tab-item
  &:focus
    background-color: transparent
    outline: 2px solid #49a1ff
    outline: 2px solid var(--focus, #49a1ff)
    color: #2b3836
    color: var(--dark-00, #2b3836)

  &.blx-focus
    background-color: transparent
    outline: 2px solid #49a1ff
    outline: 2px solid var(--focus, #49a1ff)
    color: #2b3836
    color: var(--dark-00, #2b3836)

    &:after
      background-color: transparent

  &:focus:after
    background-color: transparent

input[type=radio]
  &:focus + .blx-tab-item:after
    background-color: transparent

  &:hover + .blx-tab-item
    color: #008b61
    color: var(--primary-01, #008b61)

.blx-tab-item.blx-hover
  color: #008b61
  color: var(--primary-01, #008b61)

input[type=radio]:hover + .blx-tab-item:after, .blx-tab-item.blx-hover:after
  background-color: #008b61
  background-color: var(--primary-01, #008b61)

.blx-sub-tab
  display: block
  text-decoration: none
  font-size: 14px
  line-height: 18px
  padding: 6px 12px 6px 48px
  color: #aab7b2
  color: var(--light-02, #aab7b2)
  cursor: pointer

  &:hover
    color: #008b61
    color: var(--primary-01, #008b61)

  &:active
    color: #009f6f
    color: var(--primary-00, #009f6f)

  &:focus, &.blx-focus
    background: transparent
    outline: 1px solid #49a1ff
    outline: 1px solid var(--focus, #49a1ff)

input[type=radio]:checked + .blx-sub-tab, .blx-sub-tab.blx-active
  color: #009f6f
  color: var(--primary-00, #009f6f)

input[type=radio]:focus + .blx-sub-tab
  background-color: transparent
  outline: 2px solid #49a1ff
  outline: 2px solid var(--focus, #49a1ff)
  color: #2b3836
  color: var(--dark-00, #2b3836)

.blx-sub-tab
  &:focus, &.blx-focus
    background-color: transparent
    outline: 2px solid #49a1ff
    outline: 2px solid var(--focus, #49a1ff)
    color: #2b3836
    color: var(--dark-00, #2b3836)

input[type=radio]:hover + .blx-sub-tab, .blx-sub-tab.blx-hover
  color: #008b61
  color: var(--primary-01, #008b61)

.blx-text-field-container
  position: relative
  display: flex

.blx-text-field
  display: inline-flex
  position: relative
  flex-direction: column
  min-width: 150px
  max-width: 484px
  width: 300px

  &.blx-with-margin
    margin-right: 24px
    margin-bottom: 40px

  label
    margin-bottom: 6px

  input
    height: 40px
    white-space: nowrap
    overflow: hidden
    text-overflow: ellipsis

    &::-webkit-input-placeholder, &::-ms-input-placeholder, &::placeholder
      font-family: Graphik Web
      font-weight: 400
      font-size: 14px
      line-height: 18px
      letter-spacing: 0
      color: #aab7b2
      color: var(--light-02, #aab7b2)

    &.blx-with-margin
      &::-webkit-input-placeholder, &::-ms-input-placeholder, &::placeholder
        margin-top: 0
        margin-bottom: 6px

    &[type=date]
      position: relative
      width: 176px

      &:after
        content: ""
        position: absolute
        height: 16px
        pointer-events: none

      &:before
        content: ""
        position: absolute
        height: 16px
        pointer-events: none
        right: 16px
        width: 20px
        background-color: #fff
        background-color: var(--secondary-00, #fff)

      &:after
        right: 18px
        width: 20px
        height: 20px
        background-repeat: no-repeat
        background-image: url(#{$blocks-base-assets-path}/svgs/icon-calendar.svg)

  textarea
    resize: none
    overflow: hidden

    &::-webkit-input-placeholder, &::-ms-input-placeholder, &::placeholder
      font-family: Graphik Web
      font-weight: 400
      font-size: 14px
      line-height: 18px
      letter-spacing: 0
      color: #aab7b2
      color: var(--light-02, #aab7b2)

    &.blx-with-margin
      &::-webkit-input-placeholder, &::-ms-input-placeholder, &::placeholder
        margin-top: 0
        margin-bottom: 6px

  input, textarea
    width: 100%
    border-radius: 7px
    padding: 11px 16px
    font-weight: 400
    font-size: 14px
    line-height: 18px
    letter-spacing: 0
    color: #2b3836
    color: var(--dark-00, #2b3836)
    background-color: #fff
    background-color: var(--secondary-00, #fff)
    border: 1px solid #d9e1e0
    border: 1px solid var(--light-01, #d9e1e0)

  input:hover, textarea:hover, input.blx-hover, textarea.blx-hover
    border: 1px solid #aab7b2
    border: 1px solid var(--light-02, #aab7b2)

  input:focus, textarea:focus, input.blx-focus, textarea.blx-focus
    outline: none
    border: 1px solid #49a1ff
    border: 1px solid var(--focus, #49a1ff)

  input.blx-invalid, textarea.blx-invalid
    border: 1px solid #eb5534
    border: 1px solid var(--danger-00, #eb5534)

  input:-moz-read-only, textarea:-moz-read-only, input:read-only, textarea:read-only
    pointer-events: none

  input.blx-disabled, textarea.blx-disabled
    opacity: .5
    pointer-events: none

  .blx-text-field-icon, .blx-text-field-prefix, .blx-text-field-suffix
    font-size: 16px
    line-height: 20px
    padding-top: 11px
    padding-bottom: 9px
    color: #2b3836
    color: var(--dark-00, #2b3836)

  .blx-text-field-prefix
    position: absolute
    left: 16px
    -webkit-user-select: none
    -moz-user-select: none
    -ms-user-select: none
    user-select: none

    ~ input
      padding-left: 40px

  .blx-text-field-suffix
    position: absolute
    right: 8px
    -webkit-user-select: none
    -moz-user-select: none
    -ms-user-select: none
    user-select: none

    ~ input
      padding-right: 40px

  .blx-text-field-icon
    margin-left: 10px

  .blx-text-field-helper-text
    color: #7c8c88
    color: var(--dark-02, #7c8c88)
    font-size: 12px
    margin: 5px 16px 0

.blx-header-text-field
  input, textarea
    font-weight: inherit
    font-size: inherit
    line-height: inherit
    letter-spacing: inherit
    color: inherit
    background-color: inherit
    border: 1px solid transparent

  input:hover, textarea:hover, input.blx-hover, textarea.blx-hover
    border: 1px solid #d9e1e0
    border: 1px solid var(--light-01, #d9e1e0)

  input:focus, textarea:focus, input.blx-focus, textarea.blx-focus
    border: 1px solid #d9e1e0
    border: 1px solid var(--light-01, #d9e1e0)
    background-color: #fff
    background-color: var(--secondary-00, #fff)

.blx-text-field-with-dropdown
  display: flex
  align-items: flex-end
  width: 100%
  margin-right: 20px
  margin-bottom: 19px

  input
    border-radius: 7px 0 0 7px

  .blx-text-field
    margin-right: 0
    margin-bottom: 0

  .blx-dropdown
    display: inline-block
    min-width: 0

  .blx-dropdown-trigger
    border-radius: 0 7px 7px 0

    &.blx-active
      border-radius: 0 7px 0 0

.blx-toggle
  height: 40px
  font-size: 14px
  line-height: 40px

  &.blx-with-margin
    margin: 12px 16px

  &.blx-disabled
    opacity: .5
    pointer-events: none

  .blx-toggle-container
    position: relative

    .blx-toggle-text
      position: relative
      padding: 10px 30px
      cursor: pointer
      border: 1px solid #d9e1e0
      border: 1px solid var(--light-01, #d9e1e0)
      background-color: #fff
      background-color: var(--secondary-00, #fff)
      box-shadow: 0 1px 2px 0 rgba(170, 183, 178, 0.6)
      box-shadow: 0 1px 2px 0 var(--shadow, rgba(170, 183, 178, 0.6))

    input
      position: absolute
      left: -9999px
      opacity: 0
      cursor: pointer

      &:checked ~ .blx-toggle-text
        background-color: #009f6f
        background-color: var(--primary-00, #009f6f)
        border: 1px solid #009f6f
        border: 1px solid var(--primary-00, #009f6f)
        color: #fff
        color: var(--secondary-00, #fff)

      &:focus ~ .blx-toggle-text, &.blx-focus ~ .blx-toggle-text
        padding: 10px 29px
        background-color: #008b61
        background-color: var(--primary-01, #008b61)
        border: 2px solid #49a1ff
        border: 2px solid var(--focus, #49a1ff)
        color: #fff
        color: var(--secondary-00, #fff)

      &:focus ~ .blx-toggle-text:after, &.blx-focus ~ .blx-toggle-text:after
        content: ""
        position: absolute
        left: 0
        top: 0
        width: calc(100% - 2px)
        height: calc(100% - 2px)
        border: 1px solid #fff
        border: 1px solid var(--secondary-00, #fff)

      &:hover ~ .blx-toggle-text
        padding: 10px 30px
        background-color: #008b61
        background-color: var(--primary-01, #008b61)
        border: 1px solid #008b61
        border: 1px solid var(--primary-01, #008b61)
        color: #fff
        color: var(--secondary-00, #fff)
        box-shadow: none

      &.blx-hover ~ .blx-toggle-text
        padding: 10px 30px
        background-color: #008b61
        background-color: var(--primary-01, #008b61)
        border: 1px solid #008b61
        border: 1px solid var(--primary-01, #008b61)
        color: #fff
        color: var(--secondary-00, #fff)
        box-shadow: none

        &:after
          display: none

      &:hover ~ .blx-toggle-text:after
        display: none

      &:active ~ .blx-toggle-text
        padding: 10px 30px
        background-color: #00704f
        background-color: var(--primary-02, #00704f)
        border: 1px solid #00704f
        border: 1px solid var(--primary-02, #00704f)
        color: #fff
        color: var(--secondary-00, #fff)
        box-shadow: none

      &.blx-active ~ .blx-toggle-text
        padding: 10px 30px
        background-color: #00704f
        background-color: var(--primary-02, #00704f)
        border: 1px solid #00704f
        border: 1px solid var(--primary-02, #00704f)
        color: #fff
        color: var(--secondary-00, #fff)
        box-shadow: none

        &:after
          display: none

      &:active ~ .blx-toggle-text:after
        display: none

    &:first-of-type .blx-toggle-text
      border-radius: 7px 0 0 7px
      border-right: 1px solid transparent

      &:after
        border-radius: 7px 0 0 7px

    &:last-of-type .blx-toggle-text
      border-radius: 0 7px 7px 0
      border-left: 1px solid transparent

      &:after
        border-radius: 0 7px 7px 0

.blx-tooltip
  position: relative
  display: inline-block
  text-align: left

  .blx-tooltip-message, .blx-tooltip-titled-message
    position: absolute
    z-index: 1

.blx-tooltip-trigger
  cursor: pointer
  background: transparent
  border: 2px solid transparent
  width: 24px
  height: 24px
  border-radius: 50%
  padding: 0

  &:focus, &.blx-focus
    outline: none
    border: 2px solid #49a1ff
    border: 2px solid var(--focus, #49a1ff)

  &::-moz-focus-inner
    border: 0

.blx-tooltip-message, .blx-tooltip-titled-message
  border-radius: 7px
  padding: 8px 12px

.blx-tooltip-message
  min-width: 32px
  width: -webkit-max-content
  width: -moz-max-content
  width: max-content
  max-width: 265px
  min-height: 37px
  max-height: 108px

.blx-tooltip-titled-message
  width: 265px
  min-height: 60px
  max-height: 150px

.blx-tooltip-titled-message-title
  padding-bottom: 8px

.blx-tooltip-bottom
  top: 100%
  -webkit-transform: translate(calc(-50% + 10px), 8px)
  transform: translate(calc(-50% + 10px), 8px)

.blx-tooltip-top
  bottom: 100%
  -webkit-transform: translate(calc(-50% + 10px), -8px)
  transform: translate(calc(-50% + 10px), -8px)

.blx-tooltip-right
  left: calc(100% + 8px)

.blx-tooltip-left, .blx-tooltip-right
  top: 50%
  -webkit-transform: translateY(-50%)
  transform: translateY(-50%)

.blx-tooltip-left
  right: calc(100% + 8px)

.blx-hidden
  display: none

.blx-disabled
  opacity: .35
  pointer-events: none
  cursor: default

.blx-dark-bg
  background-color: #2b3836
  background-color: var(--dark-00, #2b3836)
  color: #fff
  color: var(--secondary-00, #fff)

.blx-light-bg
  background-color: #fff
  background-color: var(--secondary-00, #fff)
  color: #2b3836
  color: var(--dark-00, #2b3836)

.blx-no-scroll
  overflow: hidden

.blx-scrollable
  overflow-y: auto
