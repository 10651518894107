/********** CONTAINER PROPERTIES *************/
.flex
  display: flex

.force-flex
  display: flex !important


// FLEX DIRECTION
.flex-row
  flex-direction: row


.flex-col
  flex-direction: column



// FLEX JUSTIFY
.flex-justify-start
  justify-content: flex-start


.flex-justify-end
  justify-content: flex-end


.flex-justify-center
  justify-content: center


.flex-justify-between
  justify-content: space-between


.flex-justify-around
  justify-content: space-around



// FLEX ALIGN ITEMS
.flex-align-items-start
  align-items: flex-start


.flex-align-items-center
  align-items: center

.flex-align-items-end
  align-items: flex-end

.flex-align-items-baseline
  align-items: baseline


// FLEX WRAP
.flex-wrap
  flex-wrap: wrap


.flex-nowrap
  flex-wrap: nowrap


// SHORTCUTS / COMMON COMBINATIONS
// total center
.flex-center-all
  justify-content: center
  align-items: center



/********** ITEM PROPERTIES *************/
// FLEX ALIGN SELF
.pull-start
  align-self: flex-start


.pull-end
  align-self: flex-end

.pull-center
  align-self: center
