$navbar-background-color: $light-gray !default
$navbar-height: 5rem !default
$navbar-height-mobile: 3.5rem !default
$navbar-background-color-new: $green-forest-dark !default

$navbar-item-size: $size-6 !default
$navbar-item-color: $white !default
$navbar-item-hover-color: $teal-header !default
$navbar-item-on-transparent-color: $primary
$navbar-item-on-transparent-hover-color: $teal
$navbar-item-hover-background-color: transparent !default
$navbar-item-active-color: $black !default
$navbar-item-active-background-color: transparent !default
$navbar-item-img-max-height: 2.75rem !default

$navbar-tab-hover-background-color: transparent !default
$navbar-tab-hover-border-bottom-color: $primary !default
$navbar-tab-active-color: $primary !default
$navbar-tab-active-background-color: transparent !default
$navbar-tab-active-border-bottom-color: $primary !default
$navbar-tab-active-border-bottom-style: solid !default
$navbar-tab-active-border-bottom-width: 3px !default

$navbar-dropdown-background-color: $white !default
$navbar-dropdown-border-top: 1px solid $border !default
$navbar-dropdown-offset: -4px !default
$navbar-dropdown-arrow: $link !default
$navbar-dropdown-radius: $radius-large !default
$navbar-dropdown-z: 20 !default

$navbar-dropdown-boxed-radius: $radius-large !default
$navbar-dropdown-boxed-shadow: 0 8px 8px rgba($black, 0.1), 0 0 0 1px rgba($black, 0.1) !default

$navbar-dropdown-item-hover-color: $black !default
$navbar-dropdown-item-hover-background-color: $background !default
$navbar-dropdown-item-active-color: $link !default
$navbar-dropdown-item-active-background-color: $background !default

$navbar-divider-background-color: $border !default

=navbar-fixed
  left: 0
  position: fixed
  right: 0
  z-index: $navbar-fixed-z


.navbar
  background-color: white
  height: $navbar-height
  position: relative
  padding: 0 $size-4
  transition: background-color 0.2s ease
  transition: box-shadow $standard-transition
  @each $name, $pair in $colors
    $color: nth($pair, 1)
    $color-invert: nth($pair, 2)
    &.is-#{$name}
      background-color: $color
      color: $color-invert
      .navbar-brand
        & > .navbar-item,
        .navbar-link
          color: $color-invert
        & > a.navbar-item,
        .navbar-link
          &:hover,
          &.is-active
            background-color: transparent
            color: $color-invert
        .navbar-link
          &::after
            border-color: $color-invert
      +desktop
        .navbar-start,
        .navbar-end
          & > .navbar-item,
          .navbar-link
            color: $color-invert
          & > a.navbar-item,
          .navbar-link
            &:hover,
            &.is-active
              background-color: darken($color, 5%)
              color: $color-invert
          .navbar-link
            &::after
              border-color: $color-invert
        .navbar-item.has-dropdown:hover .navbar-link,
        .navbar-item.has-dropdown.is-active .navbar-link
          background-color: darken($color, 5%)
          color: $color-invert
        .navbar-dropdown
          a.navbar-item
            &.is-active
              background-color: $color
              color: $color-invert
  & > .container
    align-items: stretch
    display: flex
    height: $navbar-height
    width: 100%
  &.has-shadow
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1)

  &.is-fixed-bottom,
  &.is-fixed-top
    +navbar-fixed
    &.navbar-overlay
      z-index: $navbar-overlay-fixed-z
      transition: all $standard-transition
      &.is-offscreen
        top: calc(#{$navbar-height} * -1)
        visibility: hidden
  &.is-fixed-bottom
    bottom: 0
    &.has-shadow
      box-shadow: 0 -2px 3px rgba($black, 0.1)
  &.is-fixed-top
    top: 0

.navbar.is-fixed-top,
.navbar.is-fixed-bottom
  .navbar-brand,
  .navbar-item
    pointer-events: auto
    white-space: nowrap
    color: $primary

.navbar-brand,
.navbar-tabs
  align-items: center
  display: flex
  flex-shrink: 0
  height: $navbar-height

.navbar-tabs
  +overflow-touch
  max-width: 100vw
  overflow-x: auto
  overflow-y: hidden

.navbar-burger
  +hamburger($navbar-height)
  margin-left: auto

.navbar-menu
  display: none

.navbar-item,
.navbar-link
  color: $navbar-item-color
  font-family: $family-sans-serif-semibold
  font-size: $navbar-item-size
  background-color: transparent
  border: none
  display: block
  line-height: 1.5
  padding: 0.5rem 1rem
  position: relative
  cursor: pointer
  transition: all $standard-transition

  &:hover,
  &.is-active
    background-color: $navbar-item-hover-background-color
    color: $navbar-item-hover-color !important

.navbar-menu .navbar-item
  padding-left: 1.5rem
  padding-right: 0
  svg
    margin-left: 8px

.language-dropdown-top .underline-input
  border-bottom: none !important

.language-dropdown-top-result
  .underline-input
    border-bottom: none !important

.navbar.is-transparent
  .language-dropdown-top-result
    .underline-input
      border-bottom: none !important
      color: $primary
      &:hover
        color: $teal-header



.navbar.is-transparent
  background-color: transparent

.navbar.is-transparent,
.navbar.is-pale-green
  .navbar-item,
  .navbar-item a
    color: $navbar-item-on-transparent-color
    &:hover
      color: $navbar-item-on-transparent-hover-color

.navbar.in-quiz
  background-color: $primary-new

.navbar-item
  flex-grow: 0
  flex-shrink: 0
  img
    max-height: $navbar-item-img-max-height
  &.has-dropdown
    padding: 0
  &.is-tab
    border-bottom: 1px solid transparent
    height: $navbar-height
    padding-bottom: calc(0.5rem - 1px)
    &:hover
      background-color: $navbar-tab-hover-background-color
      border-bottom-color: $navbar-tab-hover-border-bottom-color
    &.is-active
      background-color: $navbar-tab-active-background-color
      border-bottom-color: $navbar-tab-active-border-bottom-color
      border-bottom-style: $navbar-tab-active-border-bottom-style
      border-bottom-width: $navbar-tab-active-border-bottom-width
      color: $navbar-tab-active-color
      padding-bottom: calc(0.5rem - #{$navbar-tab-active-border-bottom-width})

.navbar-content
  flex-grow: 1
  flex-shrink: 1

.navbar-link
  padding-right: 2.5em

.navbar-dropdown
  font-size: 0.875rem
  padding-bottom: 0.5rem
  padding-top: 0.5rem
  .navbar-item
    padding-left: 1.5rem
    padding-right: 1.5rem

.navbar-divider
  background-color: $navbar-divider-background-color
  border: none
  display: none
  height: 1px
  margin: 0.5rem 0

// +touch
//   .navbar > .container
//     display: block
//   .navbar-brand,
//   .navbar-tabs
//     .navbar-item
//       align-items: center
//       display: flex
//   .navbar-menu
//     background-color: $white
//     box-shadow: 0 8px 16px rgba($black, 0.1)
//     padding: 0.5rem 0
//     &.is-active
//       display: block

// +desktop
.navbar,
.navbar-menu,
.navbar-start,
.navbar-end
  align-items: stretch
  display: flex

.navbar
  height: $navbar-height
  &.is-transparent
    a.navbar-item,
    .navbar-link
      &:hover,
      &.is-active
        background-color: transparent !important
    .navbar-item.has-dropdown
      &.is-active,
      &.is-hoverable:hover
        .navbar-link
          background-color: transparent !important
    .navbar-dropdown
      a.navbar-item
        &:hover
          background-color: $navbar-dropdown-item-hover-background-color
          color: $navbar-dropdown-item-hover-color
        &.is-active
          background-color: $navbar-dropdown-item-active-background-color
          color: $navbar-dropdown-item-active-color
.navbar-burger
  display: none
.navbar-item,
.navbar-link
  align-items: center
  display: flex
.navbar-item.flex-align-items-start
  align-items: start
.navbar-item
  &.has-dropdown
    align-items: stretch
  &.is-active,
  &.is-hoverable:hover
    .navbar-dropdown
      display: block
      &.is-boxed
        opacity: 1
        pointer-events: auto
        transform: translateY(0)
.navbar-link
  &::after
    +arrow($navbar-dropdown-arrow)
    margin-top: -0.375em
    right: 1.125em
    top: 50%
.navbar-menu
  flex-grow: 1
  flex-shrink: 0
.navbar-start
  justify-content: flex-start
  margin-right: auto
.navbar-end
  justify-content: flex-end
  margin-left: auto
.navbar-dropdown
  background-color: $navbar-dropdown-background-color
  border-bottom-left-radius: $navbar-dropdown-radius
  border-bottom-right-radius: $navbar-dropdown-radius
  border-top: $navbar-dropdown-border-top
  box-shadow: 0 8px 8px rgba($black, 0.1)
  display: none
  font-size: 0.875rem
  left: 0
  min-width: 100%
  position: absolute
  top: 100%
  z-index: $navbar-dropdown-z
  .navbar-item
    padding: 0.375rem 1rem
    white-space: nowrap
  a.navbar-item
    padding-right: 3rem
    &:hover
      background-color: $navbar-dropdown-item-hover-background-color
      color: $navbar-dropdown-item-hover-color
    &.is-active
      background-color: $navbar-dropdown-item-active-background-color
      color: $navbar-dropdown-item-active-color
  &.is-boxed
    border-radius: $navbar-dropdown-boxed-radius
    border-top: none
    box-shadow: $navbar-dropdown-boxed-shadow
    display: block
    opacity: 0
    pointer-events: none
    top: calc(100% + (#{$navbar-dropdown-offset}))
    transform: translateY(-5px)
    transition-duration: $speed
    transition-property: opacity, transform
  &.is-right
    left: auto
    right: 0
.navbar-divider
  display: block
.container > .navbar
  .navbar-brand
    margin-left: -1rem
  .navbar-menu
    margin-right: -1rem
// Hover/Active states
a.navbar-item,
.navbar-link
  &.is-active
    color: $navbar-item-active-color
  &.is-active:not(:hover)
    background-color: $navbar-item-active-background-color
.navbar-item.has-dropdown
  &:hover,
  &.is-active
    .navbar-link
      background-color: $navbar-item-hover-background-color

.has-navbar-fixed-top
  padding-top: $navbar-height

.has-navbar-fixed-bottom
  padding-bottom: $navbar-height

.navbar-end .cta-button
  padding: $size-4 $size-3


+from($tablet)
  .navbar-menu .navbar-item.is-mobile-only
    display: none

+tablet-only
  .language-dropdown-top
    display: none
  .language-dropdown-top-result
    display: none


+mobile
  .navbar
    height: $navbar-height-mobile
    & > .container
      height: $navbar-height-mobile

  .navbar.is-transparent .navbar-item .cta-button
    display: none

  .navbar.is-fixed-top.navbar-overlay.is-offscreen
    top: calc(#{$navbar-height-mobile} * -1)

  .navbar-menu .navbar-item:not(.is-mobile)
    display: none

  .navbar-item.is-mobile .button
    font-size: $size-7

  .navbar-item
    padding: 0 !important

  .navbar-item .button
    font-size: $size-7
    &.cta-button
      min-width: unset
      padding: $size-6 1rem

  .navbar-brand
    height: $navbar-height-mobile

    .navbar-item
      padding: 0

  .navbar-end .navbar-item
    padding-right: 0

  .has-navbar-fixed-top
    padding-top: $navbar-height-mobile

.cbre-spacer-logo
  font-size: 20px
  font-weight: 500
  color: $green-forest-dark
  text-decoration: none
  &:hover
    color: $green-forest-dark !important

.navbar-brand .cbre-spacer-logo:focus
  background-color: transparent !important
.blx-buttonblx-primary
  &:hover
    background-color: #17e88f
    border-color: #17e88f
