@font-face
  font-family: 'Calibre Regular'
  src: url('/public/fonts/CalibreWeb-Regular.woff2') format('woff2'), url('/public/fonts/CalibreWeb-Regular.woff') format('woff')


@font-face
  font-family: 'Calibre Medium'
  src: url('/public/fonts/CalibreWeb-Medium.woff2') format('woff2'), url('/public/fonts/CalibreWeb-Medium.woff') format('woff')


@font-face
  font-family: 'Calibre Semibold'
  src: url('/public/fonts/CalibreWeb-Semibold.woff2') format('woff2'), url('/public/fonts/CalibreWeb-Semibold.woff') format('woff')

  @font-face
  font-family: 'Financier Medium'
  src: url('/public/fonts//financier/financier-display-web-medium.eot') format('eot'), url('/public/fonts//financier/financier-display-web-medium.woff') format('woff'), url('/public/fonts//financier/financier-display-web-medium.woff2') format('woff2')

@font-face
  font-family: 'Financier Regular'
  src:  url('/public/fonts//financier/financier-display-web-medium.eot') format('eot'), url('/public/fonts//financier/financier-display-web-medium.woff') format('woff'), url('/public/fonts//financier/financier-display-web-medium.woff2') format('woff2')

@font-face
  font-family: 'Financier Semibold'
  src:  url('/public/fonts//financier/financier-display-web-semibold.eot') format('eot'), url('/public/fonts//financier/financier-display-web-semibold.woff') format('woff'), url('/public/fonts//financier/financier-display-web-semibold.woff2') format('woff2')

@font-face
  font-family: 'Financier',sans-serif
  src:  url('/public/fonts//financier/financier-display-web-medium-italic.eot') format('eot'),  url('/public/fonts//financier/financier-display-web-medium-italic.woff') format('woff'), url('/public/fonts//financier/financier-display-web-medium-italic.woff2') format('woff2')
  font-weight: 500
  font-style: normal
  font-display: fallback

@font-face
  font-family: 'Financier',sans-serif
  src:  url('/public/fonts//financier/financier-display-web-medium-italic.eot') format('eot'),  url('/public/fonts//financier/financier-display-web-medium-italic.woff') format('woff'), url('/public/fonts//financier/financier-display-web-medium-italic.woff2') format('woff2')
  font-weight: 400
  font-style: normal
  font-display: fallback

@font-face
  font-family: 'Financier',sans-serif
  src:  url('/public/fonts//financier/financier-display-web-semibold-italic.eot') format('eot'), url('/public/fonts//financier/financier-display-web-semibold-italic.woff') format('woff'), url('/public/fonts//financier/financier-display-web-semibold-italic.woff2') format('woff2')
  font-weight: 600
  font-style: italic
  font-display: fallback

// BULMA
@import '../shared/utilities/_all'
@import './variables'
@import '../shared/base/_all'
@import '../shared/grid/columns'

@import '../shared/components/navbar'
@import '../shared/components/level'
@import '../shared/components/loading-page'
@import '../shared/elements/container'
@import '../shared/elements/other'
@import '../shared/elements/notification'
@import '../shared/grid/columns'
@import '../shared/components/tooltip'

// BLOCKS
@import './blocks/blocks'

// APP-SPECIFIC
// these files have access to variables from both Bulma and Blocks
@import './auth'
@import './form'
@import './directory'
@import './logo'
@import './modal'
@import './page'
@import './panel'
@import './user'
@import './currentState'
@import './scenario'
@import './tooltip'
@import './templateManager'
@import './templateEditor'
@import './general'

@import './scenarioEditor/scenarioEditor'
@import './scenarioEditor/scenarioRow'
@import './scenarioEditor/tinker'
@import './scenarioEditor/nestedDropdown'
@import './scenarioEditor/scenarioSummary'

@import './pages/entityInfo'
@import './pages/myProfile'
@import './pages/projectForm'
@import './pages/newClientForm'
@import '../rebranding/override-styles'
@import './default.scss'
@import './sharedAndExportModal.scss'

body
  background-color: $grey-light
  *
    font-family: $family-calibre
    